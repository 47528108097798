/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxAbstractParamValue} from '@dv/shared/backend/model/jax-abstract-param-value';
import {JaxAbstractParamValueType} from '@dv/shared/backend/model/jax-abstract-param-value-type';
import type {JaxCheckInCustomField} from '@dv/shared/backend/model/jax-check-in-custom-field';
import type {JaxCheckInCustomFieldValue} from '@dv/shared/backend/model/jax-check-in-custom-field-value';
import {checkPresent, isNullish} from '../../types';
import {DvbUtil} from '../DvbUtil';
import {ParamValueDType} from '../ParamValueDType';
import type {CustomFieldType} from './CustomFieldType';

export type CustomFieldValueWithField = {
    field: JaxCheckInCustomField;
    value: JaxCheckInCustomFieldValue;
};

export class CustomFieldUtil {

    public static customFieldTypeToParamValueType(type: CustomFieldType): ParamValueDType {
        switch (type) {
            case 'BOOLEAN':
                return ParamValueDType.BOOLEAN;
            case 'SELECT':
            case 'STRING':
                return ParamValueDType.STRING;
            default:
                throw new Error(`CustomFieldType not implemented: ${JSON.stringify(type)}`);
        }
    }

    public static splitSelectValues(selectValues: string | null, separator: string): string[] {
        return selectValues ?
            selectValues.split(separator)
                .map(value => value.trim())
                .filter(val => val.length !== 0) :
            [];
    }

    public static joinSelectValues(selectValues: string[] | undefined, separator: string): string {
        return selectValues ?
            selectValues
                .map(value => value.trim())
                .filter(val => val.length !== 0)
                .join(separator) :
            '';
    }

    public static hasValue(fieldValue: { value?: JaxAbstractParamValue }): boolean {
        const value = fieldValue.value;
        if (isNullish(value) || isNullish(value.value)) {
            return false;
        }

        return value.dtype !== JaxAbstractParamValueType.STRING || DvbUtil.isNotEmptyString(value.value);
    }

    public static customFieldValuesForCustomFields(
        customFieldValues: JaxCheckInCustomFieldValue[],
        customFields: JaxCheckInCustomField[],
    ): JaxCheckInCustomFieldValue[] {
        const ids = customFields.map(DvbUtil.mapToIdChecked);

        return customFieldValues.filter(value => ids.includes(value.customFieldId));
    }

    public static pair(
        customField: JaxCheckInCustomField,
        customFieldValues: JaxCheckInCustomFieldValue[],
    ): CustomFieldValueWithField {
        const customFieldId = checkPresent(customField.id);

        return {
            field: customField,
            value: customFieldValues.find(v => v.customFieldId === customFieldId) ?? {customFieldId},
        };
    }
}
