import {Directive, Input} from '@angular/core';
import type {AbstractControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {NG_VALIDATORS} from '@angular/forms';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {FunctionType, Nullish, SupportedDateTypes} from '@dv/shared/code';
import {DvbDateUtil, isNullish} from '@dv/shared/code';
import type moment from 'moment';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[validBis][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ValidBisDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class ValidBisDirective implements Validator {

    private _dateStart?: moment.Moment | Nullish;
    private _dateEnd?: moment.Moment | Nullish;
    private _timeStart?: moment.Moment | Nullish;
    private _onChange?: FunctionType;

    @Input({required: true})
    public set dateStart(value: SupportedDateTypes | Nullish) {
        this._dateStart = DvbDateUtil.toLocalDate(value);
        this._onChange?.();
    }

    @Input({required: true})
    public set dateEnd(value: SupportedDateTypes | Nullish) {
        this._dateEnd = DvbDateUtil.toLocalDate(value);
        this._onChange?.();
    }

    @Input({required: true})
    public set timeStart(value: Date | moment.Moment | BackendLocalTimeHHMM | Nullish) {
        this._timeStart = DvbDateUtil.toHHMMTime(value);
        this._onChange?.();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return validBis(this._dateStart, this._dateEnd, this._timeStart)(control);
    }

    public registerOnValidatorChange?(fn: FunctionType): void {
        this._onChange = fn;
    }
}

function validBis(
    dateStart: moment.Moment | Nullish,
    dateEnd: moment.Moment | Nullish,
    timeStart: moment.Moment | Nullish,
): ValidatorFn {
    return bisControl => {
        const timeEnd = DvbDateUtil.toHHMMTime(bisControl.value);

        if (isNullish(timeEnd) || isNullish(timeStart)) {
            return null;
        }

        if (DvbDateUtil.isMomentEquals(dateStart, dateEnd)) {
            return timeEnd.isSameOrAfter(timeStart) ? null : {bis: {dateStart, dateEnd, timeStart, timeEnd}};
        }

        return null;
    };
}
