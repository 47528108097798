/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '../types';
import {isNullish, isPresent, stringUnion} from '../types';

export const KORREKTUR_TYPE = stringUnion('original', 'current');

export type KorrekturType = typeof KORREKTUR_TYPE.type;

export type ToAPIConverter<T, TReturn> = (value: T) => TReturn;

export type KorrekturObject<T> = Record<KorrekturType, T>;

export class Korrektur<T, TReturn = T | null>
    implements IRestModel<KorrekturObject<TReturn>, [ToAPIConverter<T | null, TReturn>]> {

    public constructor(
        public original: T | null = null,
        public current: T | null = null,
    ) {
    }

    public static apiResponseTransformer<T>(
        data: any,
        fromAPIConverter: (data: any) => T | null = a => a,
    ): Korrektur<T> {
        return new Korrektur<T>(
            isPresent(data) ? fromAPIConverter(data.original) : null,
            isPresent(data) ? fromAPIConverter(data.current) : null,
        );
    }

    public hasValue(): boolean {
        return isPresent(this.original) || isPresent(this.current);
    }

    public getValue(): T | null {
        return isPresent(this.current) ? this.current : this.original;
    }

    public getDisplayValue(): T | string {
        const value = this.getValue();

        return isPresent(value) ? value : '';
    }

    public showDifference(): boolean {
        if (this.hasValue()) {
            return isPresent(this.current) && this.original !== this.current;
        }

        return isNullish(this.current) && isPresent(this.original);
    }

    public fillCurrent(): void {
        this.current = this.getValue();
    }

    public toRestObject(toAPIConverter: ToAPIConverter<T | null, TReturn>): KorrekturObject<TReturn> {
        return {
            original: toAPIConverter(this.original),
            current: toAPIConverter(this.current),
        };
    }
}
