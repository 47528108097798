/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed, DestroyRef, effect, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import type {SupportedLanguage, SupportedLocale} from '@dv/shared/code';
import {localeFrom} from '@dv/shared/code';
import type {Observable} from 'rxjs';

const LOCAL_STORAGE_LANGUAGE = 'LANGUAGE';

/**
 * Service that provides access to the current user language in the shape of an observable.
 */
@Injectable({
    providedIn: 'root',
})
export class UserLanguageService {

    private readonly state = signal(UserLanguageService.getLocalStorageLanguage());

    /**
     * WARNING: reacting to these changes does not work as intended. e.g. moment might change its locale a tick too
     * late, because the observable or signal are pushing the change to subscribers, where moment is reacting at
     * the same time as your component.
     */
    public readonly userLanguage = this.state.asReadonly();
    public readonly userLanguage$ = toObservable(this.state);

    public readonly userLocale = computed(() => localeFrom(this.state()));
    public readonly userLocale$ = toObservable(this.userLocale);

    // noinspection JSUnusedLocalSymbols
    private writeToLocalStorage = effect(() => {
        localStorage.setItem(LOCAL_STORAGE_LANGUAGE, this.state());
    });

    // noinspection JSUnusedLocalSymbols
    private updateHtmlLang = effect(() => {
        document.documentElement.lang = this.userLocale();
    });

    /**
     * WARNING: Only use this in places where a changing user language does not have an impact.
     * Wherever user language changes are relevant, use UserLanguageService.getUserLanguage$()
     */
    public static getLocalStorageLanguage(): SupportedLanguage {
        const localStorageLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE) as SupportedLanguage;

        return localStorageLang || 'DE';
    }

    /**
     * Call this whenever the language should change.
     */
    public changeLanguage(language: SupportedLanguage): void {
        this.state.set(language);
    }
}

export type LocaleChangeHandler = (userLocale$: Observable<SupportedLocale>) => Observable<unknown>;

export function reactOnUserLocale(handler: LocaleChangeHandler): () => void {
    const userLanguageService = inject(UserLanguageService);
    const destroyRef = inject(DestroyRef);

    return () => {
        handler(userLanguageService.userLocale$).pipe(takeUntilDestroyed(destroyRef)).subscribe();
        // not returning rxjs subscription, because it would break startup.
    };
}
