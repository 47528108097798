/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '@dv/shared/code';

const SANTA = 'santa';
const CHRISTMAS = 'christmas';
const EASTER = 'easter';

export const DECOR_TYPES = stringUnion(SANTA, CHRISTMAS, EASTER);
export type DecorType = typeof DECOR_TYPES.type;

export const DECOR_DATES: { [month: number]: { [day: number]: DecorType } } = {
    12: {
        6: SANTA,
        24: CHRISTMAS,
        25: CHRISTMAS,
        26: CHRISTMAS,
        27: CHRISTMAS,
        28: CHRISTMAS,
        29: CHRISTMAS,
        30: CHRISTMAS,
    },
    // remove / update easter dates after 2024
    3: {
        28: EASTER,
        29: EASTER,
        30: EASTER,
        31: EASTER,
    },
    4: {
        1: EASTER,
    },
};

export const COMBINE_WITH_A: Record<DecorType, boolean> = {
    [SANTA]: true,
    [CHRISTMAS]: false,
    [EASTER]: true,
};
