/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {InjectionToken} from '@angular/core';
import type {DisplayNameConfig} from './display-name-config';
import {DisplayNameOrder} from './display-name-order';

export const DISPLAY_NAME_CONFIG_TOKEN = new InjectionToken<DisplayNameConfig>('DisplayNameConfig');

export const VORNAME_FAMILIENNAME: DisplayNameConfig = {
    nameOrder: DisplayNameOrder.VORNAME_FAMILIENNAME,
};

export const FAMILIENNAME_VORNAME: DisplayNameConfig = {
    nameOrder: DisplayNameOrder.FAMILIENNAME_VORNAME,
};
