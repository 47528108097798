/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum AuthEventType {
    /* eslint-disable @typescript-eslint/naming-convention */
    loginSuccess = 'auth-login-success',
    loginRestore = 'auth-login-restore',
    logoutSuccess = 'auth-logout-success',
    notAuthenticated = 'auth-not-authenticated',
    notAuthorised = 'auth-not-authorised',
    requestLogin = 'auth-request-login',
}
