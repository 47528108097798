/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export interface IDisplayable {
    getDisplayName: () => string;
}

export function displayableComparator(a: IDisplayable, b: IDisplayable): number {
    return a.getDisplayName().localeCompare(b.getDisplayName());
}

export function displaynameComparator(a: { displayName: string }, b: { displayName: string }): number {
    return a.displayName.localeCompare(b.displayName);
}
