/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from '../types';
import type {SearchResultType} from './SearchResultType';

export class SearchResultEntry implements IPersistable {
    public constructor(
        public entity: SearchResultType = 'ALL',
        public id: string = '',
        public text: string = '',
        public mandantName: string | null = null,
        public additionalInformation: string | null = null,
        public source: unknown = null,
        public symbol: string | null = null,
        public isDisabled: boolean = false,
    ) {
    }

    public static getEnabled(arr: SearchResultEntry[]): readonly SearchResultEntry[] {
        return arr.filter(item => !item.isDisabled);
    }

    public static apiResponseTransformer(data: any): SearchResultEntry {
        return new SearchResultEntry(data.entity,
            data.id,
            data.text,
            data.mandantName,
            data.additionalInformation,
            data.source,
            data.symbol);
    }
}
