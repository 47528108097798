/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {IMapped, Log, RequiredPermission, UnparsedPermission} from '@dv/shared/code';
import {
    DvbUtil,
    isAllOfPermission,
    isOneOfPermission,
    isWildcardPermission,
    LogFactory,
    Memoizer,
    parsePermission,
} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';

// constants used to handle domain permissions
const PART_DIVIDER_TOKEN = ':';
const SUBPART_DIVIDER_TOKEN = ',';
export const WILDCARD_TOKEN = '*';

const LOG = LogFactory.createLog('AuthStore');

@Injectable({
    providedIn: 'root',
})
export class AuthStore {

    private roles: Set<UserRole> = new Set();
    private permissionMap: Map<string, IMapped<string>> = new Map();
    private permissionSet: Set<string> = new Set();
    private permissionCache: Memoizer<boolean>;

    private logger = LOG;

    public constructor() {
        this.permissionCache = new Memoizer<boolean>(this.hasPermissionUnmemoized.bind(this));
    }

    public static forTest(logger: Log): AuthStore {
        const authStore = new AuthStore();
        authStore.logger = logger;

        return authStore;
    }

    public init(rolesParam: UserRole[], permissionsParam: string[]): void {
        // clear memoization cache
        this.permissionCache.reset();

        // init roles
        this.roles = new Set(rolesParam);

        // init permissions
        this.initPermissions(permissionsParam);
    }

    public hasRole(role: UserRole): boolean {
        return this.roles.has(role);
    }

    public hasPermission(permission: string): boolean {
        if (permission.includes(SUBPART_DIVIDER_TOKEN)) {
            this.logger.error(`Unsupported permission: ${permission}`);

            return false;
        }

        return this.permissionCache.get(permission);
    }

    public hasPermissionUnmemoized(permission: string): boolean {
        if (this.permissionSet.has(permission)) {
            return true;
        }

        const parts = permission.split(PART_DIVIDER_TOKEN);
        let node = this.permissionMap as Map<string, IMapped<string>> | IMapped<string>;

        for (const item of parts) {

            const part = node instanceof Map && node.get(item);

            if (!part) {
                return node instanceof Map && node.has(WILDCARD_TOKEN);
            }

            node = part;
        }

        const map = node instanceof Map && node;

        return map && (map.size === 0 || (map.size === 1 && map.has(WILDCARD_TOKEN)));
    }

    public hasAnyPermission(permissions: string[]): boolean {
        return permissions.some(permission => this.hasPermission(permission));
    }

    /**
     * @return ein Array mit derselben Länge wie permissions. Wenn eine Permission erfüllt ist,
     * so wird bei dem entsprechenden key TRUE gesetzt, ansonsten FALSE.
     */
    public hasPermissions(permissions: string[]): boolean[] {
        return permissions.map(permission => this.hasPermission(permission));
    }

    public isPermitted(permission?: UnparsedPermission): boolean {
        const permissionValue = parsePermission(permission);

        if (!permissionValue) {
            // no required permission
            return true;
        }

        return this.isPermittedInternal(permissionValue);
    }

    private isPermittedInternal(permission: RequiredPermission): boolean {
        if (isWildcardPermission(permission)) {
            return this.hasPermission(permission);
        }

        if (isAllOfPermission(permission)) {
            return permission.every(p => this.isPermittedInternal(p));
        }

        if (isOneOfPermission(permission)) {
            return permission.oneOf.some(p => this.isPermittedInternal(p));
        }

        throw new Error(`unsupported permission type: ${JSON.stringify(permission)}`);
    }

    private initPermissions(permissionsParam: string[]): void {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const permissions: Object | string = {};

        permissionsParam.forEach(permission => {
            const actions = permission.split(PART_DIVIDER_TOKEN);
            let nodes = [permissions];

            for (const action of actions) {
                const children = nodes.slice() as ({ [p: string]: string | { [p: string]: string } })[];
                nodes = [];

                // eslint-disable-next-line @typescript-eslint/no-loop-func
                children.forEach(node => {
                    action.split(SUBPART_DIVIDER_TOKEN).forEach((subAction: string) => {
                        if (!node[subAction]) {
                            node[subAction] = {};
                        }

                        nodes.push(node[subAction]);
                    });
                });
            }
        });

        this.permissionMap = DvbUtil.objToMap(permissions as { [p: string]: string });
        this.permissionSet = new Set(permissionsParam);
    }
}
