/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '../types';
import {LoadingContext} from './LoadingContext';

export class FormContext extends LoadingContext {
    public editMode: boolean = false;
    public action?: FunctionType;

    public enableEditMode(): void {
        this.editMode = true;
    }

    public disableEditMode(): void {
        this.editMode = false;
    }

    public close(): void {
        this.finishLoading();
        this.disableEditMode();
        if (this.action) {
            this.action();
        }
    }
}
