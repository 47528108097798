/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ToastType} from './toast-type';

export const TOAST_PRIOS: Record<ToastType, number> = {
    [ToastType.ERROR_ACTIONABLE]: 1,
    [ToastType.ERROR_NAVIGABLE]: 2,
    [ToastType.ERROR]: 3,
    [ToastType.SUCCESS_ACTIONABLE]: 4,
    [ToastType.SUCCESS_NAVIGABLE]: 5,
    [ToastType.SUCCESS]: 6,
};
