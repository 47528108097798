/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DvbDateUtil, IDateRangeWithOptionalTime} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';

@Component({
    selector: 'dvlib-backend-limited-with-optional-time',
    template: '{{ backendLimitedText }}',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class BackendLimitedWithOptionalTimeComponent implements OnChanges {
    @Input({required: true}) public backendLimited!: IDateRangeWithOptionalTime;
    @Input() public displayTime: boolean = false;

    public backendLimitedText: string = '';

    public constructor(
        private readonly translate: Translator,
    ) {
    }

    public ngOnChanges(): void {
        const limitedEntity: IDateRangeWithOptionalTime = this.displayTime ? this.backendLimited : {
            gueltigAb: this.backendLimited.gueltigAb,
            gueltigBis: this.backendLimited.gueltigBis,
            von: undefined,
            bis: undefined,
        };

        this.backendLimitedText = DvbDateUtil.getGueltigkeitTextWithOptionalTime(
            limitedEntity,
            DvbDateUtil.today(),
            this.translate,
            {alwaysShowDate: true},
        );
    }
}
