/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, Input} from '@angular/core';
import type {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';

// source: https://timdeschryver.dev/blog/a-generic-angular-template-driven-validator
@Directive({
    selector: '[dvlibValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidatorDirective, multi: true}],
    standalone: true,
})
export class ValidatorDirective implements Validator {

    @Input({
        required: true,
        alias: 'dvlibValidator',
    }) public validator?: ValidatorFn;

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.validator?.(control) ?? null;
    }
}
