/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Inject, Injectable} from '@angular/core';
import {JaxBetreuungsPerson} from '@dv/shared/backend/model/jax-betreuungs-person';
import {JaxGruppe} from '@dv/shared/backend/model/jax-gruppe';
import {JaxKinderOrt} from '@dv/shared/backend/model/jax-kinder-ort';
import type {Nullish} from '@dv/shared/code';
import {checkPresent, DvbUtil, isNullish} from '@dv/shared/code';
import type {IDisplayNameService} from '@dv/shared/config';
import {DISPLAY_NAME_CONFIG_TOKEN} from './display-name-config-token';
import {DisplayNameOrder} from './display-name-order';

@Injectable({
    providedIn: 'root',
})
export class DisplayNameService implements IDisplayNameService {

    public constructor(
        // replacing any with DisplayNameOrder breaks unit tests!?
        @Inject(DISPLAY_NAME_CONFIG_TOKEN) public readonly config: any,
    ) {
    }

    public displayKindName(
        familienName: string | Nullish,
        vorName: string | Nullish,
    ): string {
        return this.printInOrder(vorName ?? '---', familienName);
    }

    public displayFraktionName(
        fraktion: JaxGruppe | JaxBetreuungsPerson,
    ): string {
        switch (checkPresent(fraktion.dtype)) {
            case 'GRUPPE':
                return this.displayGruppenName(fraktion as JaxGruppe);
            case 'BETREUUNGS_PERSON':
                return this.displayBetreuungsPersonName(fraktion as JaxBetreuungsPerson);
        }

        return '';
    }

    public displayBetreuungsPersonName(betreubetreuungsPerson: JaxBetreuungsPerson): string {
        const vorName: string = betreubetreuungsPerson.attributes?.vorName ?? '?';
        const familienName: string = betreubetreuungsPerson.attributes?.familienName ?? '?';

        return this.printInOrder(vorName, familienName);
    }

    public displayGruppenName(gruppe: JaxGruppe): string {
        return gruppe.attributes?.name ?? '?';
    }

    public displayKinderOrtName(titel: string | null, name: string | null): string {
        return [titel, name].filter(DvbUtil.isNotEmptyString).join(' ');
    }

    public displayJaxKinderOrtName(kinderOrt?: JaxKinderOrt): string {
        if (isNullish(kinderOrt)) {
            return '';
        }

        return this.displayKinderOrtName(kinderOrt.titel, kinderOrt.name);
    }

    private printInOrder(vorName: string | Nullish, familienName: string | Nullish): string {
        const names = this.config.nameOrder === DisplayNameOrder.VORNAME_FAMILIENNAME ?
            [vorName, familienName] :
            [familienName, vorName];

        return names.filter(DvbUtil.isNotEmptyString).join(' ');
    }
}
