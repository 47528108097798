/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type JaxAbstractParamValueType = 'COLLECTION' | 'LOCAL_DATE' | 'ABRECHNUNG_TYP' | 'STRING' | 'LONG' | 'BIG_DECIMAL' | 'BOOLEAN';

export const JaxAbstractParamValueType = {
    COLLECTION: 'COLLECTION' as JaxAbstractParamValueType,
    LOCAL_DATE: 'LOCAL_DATE' as JaxAbstractParamValueType,
    ABRECHNUNG_TYP: 'ABRECHNUNG_TYP' as JaxAbstractParamValueType,
    STRING: 'STRING' as JaxAbstractParamValueType,
    LONG: 'LONG' as JaxAbstractParamValueType,
    BIG_DECIMAL: 'BIG_DECIMAL' as JaxAbstractParamValueType,
    BOOLEAN: 'BOOLEAN' as JaxAbstractParamValueType
};

