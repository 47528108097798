@if (adresse) {
    <div class="dvb-adresse-display">
        @if (displayType === displayTypes.short) {
            <div class="row">
                <div class="col-md-12">
                    <p>
                        <span>{{adresseShort}}</span>
                        <br>
                        @if (displayGemeinde.length > 0) {
                            <span>{{ displayGemeinde }}</span>
                            <br>
                        }
                        <span>{{ adresse.land?.displayName }}</span>
                    </p>
                </div>
            </div>
        } @else {
            <div class="row">
                <div class="col-md-12">
                    <p>{{ adresse.strasse }} {{ adresse.hausnummer }}</p>
                </div>
            </div>
            @if (adresse.postfach) {
                <div class="row">
                    <div class="col-md-12">
                        <p>{{ adresse.postfach }}</p>
                    </div>
                </div>
            }
            <div class="row">
                <div class="col-md-12">
                    <p>{{ adresse.plz }} {{ adresse.ort }}</p>
                </div>
            </div>
            @if (displayGemeinde.length > 0) {
                <div class="row">
                    <div class="col-md-12">
                        <p>
                            <span>{{ displayGemeinde }}</span>
                        </p>
                    </div>
                </div>
            }
            <div class="row">
                <div class="col-md-12">
                    <p>{{ adresse.land?.displayName }}</p>
                </div>
            </div>
        }
    </div>
}
