/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpCodes = {
    UNKOWN: -1,
    CONTINUE: 100,                         // https://tools.ietf.org/html/rfc7231#section-6.2.1
    SWITCHING_PROTOCOLS: 101,              // https://tools.ietf.org/html/rfc7231#section-6.2.2
    PROCESSING: 102,                       // https://tools.ietf.org/html/rfc2518
    HEURISTIC_EXPIRATION: 113,             // https://tools.ietf.org/html/rfc7234#section-5.5.4
    MISCELLANEOUS_WARNING: 199,            // https://tools.ietf.org/html/rfc7234#section-5.5.5

    OK: 200,                               // https://tools.ietf.org/html/rfc7231#section-6.3.1
    CREATED: 201,                          // https://tools.ietf.org/html/rfc7231#section-6.3.2
    ACCEPTED: 202,                         // https://tools.ietf.org/html/rfc7231#section-6.3.3
    NON_AUTHORITATIVE_INFORMATION: 203,    // https://tools.ietf.org/html/rfc7231#section-6.3.4
    NO_CONTENT: 204,                       // https://tools.ietf.org/html/rfc7231#section-6.3.5
    RESET_CONTENT: 205,                    // https://tools.ietf.org/html/rfc7231#section-6.3.6
    PARTIAL_CONTENT: 206,                  // https://tools.ietf.org/html/rfc7233#section-4.1
    MULTI_STATUS: 207,                     // https://tools.ietf.org/html/rfc4918
    ALREADY_REPORTED: 208,                 // https://tools.ietf.org/html/rfc5842
    TRANSFORMATION_APPLIED: 214,           // https://tools.ietf.org/html/rfc7234#section-5.5.6
    IM_USED: 226,                          // https://tools.ietf.org/html/rfc3239
    MISCELLANEOUS_PERSISTENT_WARNING: 299, // https://tools.ietf.org/html/rfc7234#section-5.5.7

    MULTIPLE_CHOICES: 300,                 // https://tools.ietf.org/html/rfc7231#section-6.4.1
    MOVED_PERMANENTLY: 301,                // https://tools.ietf.org/html/rfc7231#section-6.4.2
    FOUND: 302,                            // https://tools.ietf.org/html/rfc7231#section-6.4.3
    SEE_OTHER: 303,                        // https://tools.ietf.org/html/rfc7231#section-6.4.4
    NOT_MODIFIED: 304,                     // https://tools.ietf.org/html/rfc7232#section-4.1
    USE_PROXY: 305,                        // https://tools.ietf.org/html/rfc7231#section-6.4.5
    UNUSED: 306,                           // https://tools.ietf.org/html/rfc7231#section-6.4.6
    TEMPORARY_REDIRECT: 307,               // https://tools.ietf.org/html/rfc7231#section-6.4.7
    PERMANENT_REDIRECT: 308,               // https://tools.ietf.org/html/rfc7538
    TOO_MANY_REDIRECTS: 310,

    BAD_REQUEST: 400,                      // https://tools.ietf.org/html/rfc7231#section-6.5.1
    UNAUTHORIZED: 401,                     // https://tools.ietf.org/html/rfc7235#section-3.1
    PAYMENT_REQUIRED: 402,                 // https://tools.ietf.org/html/rfc7231#section-6.5.2
    FORBIDDEN: 403,                        // https://tools.ietf.org/html/rfc7231#section-6.5.3
    NOT_FOUND: 404,                        // https://tools.ietf.org/html/rfc7231#section-6.5.4
    METHOD_NOT_ALLOWED: 405,               // https://tools.ietf.org/html/rfc7231#section-6.5.5
    NOT_ACCEPTABLE: 406,                   // https://tools.ietf.org/html/rfc7231#section-6.5.6
    PROXY_AUTHENTICATION_REQUIRED: 407,    // https://tools.ietf.org/html/rfc7235#section-3.2
    REQUEST_TIMEOUT: 408,                  // https://tools.ietf.org/html/rfc7231#section-6.5.7
    CONFLICT: 409,                         // https://tools.ietf.org/html/rfc7231#section-6.5.8
    GONE: 410,                             // https://tools.ietf.org/html/rfc7231#section-6.5.9
    LENGTH_REQUIRED: 411,                  // https://tools.ietf.org/html/rfc7231#section-6.5.10

    PRECONDITION_FAILED: 412,              // https://tools.ietf.org/html/rfc7232#section-4.2
    PAYLOAD_TOO_LARGE: 413,                // https://tools.ietf.org/html/rfc7231#section-6.5.11
    URI_TOO_LONG: 414,                     // https://tools.ietf.org/html/rfc7231#section-6.5.12
    UNSUPPORTED_MEDIA_TYPE: 415,           // https://tools.ietf.org/html/rfc7231#section-6.5.13
    RANGE_NOT_SATISFIABLE: 416,            // https://tools.ietf.org/html/rfc7233#section-4.4
    EXPECTATION_FAILED: 417,               // https://tools.ietf.org/html/rfc7231#section-6.5.14
    IM_A_TEAPOT: 418,                      // https://tools.ietf.org/html/rfc2324
    MISDIRECT_REQUEST: 421,                // https://tools.ietf.org/html/rfc7540#section-9.1.2
    UNPROCESSABLE_ENTITY: 422,             // https://tools.ietf.org/html/rfc4918
    LOCKED: 423,                           // https://tools.ietf.org/html/rfc4918
    FAILED_DEPENDENCY: 424,                // https://tools.ietf.org/html/rfc4918
    UNORDERED_COLLECTION: 425,             // https://tools.ietf.org/html/rfc3648
    UPGRADE_REQUIRED: 426,                 // https://tools.ietf.org/html/rfc7231#section-6.5.15
    PRECONDITION_REQUIRED: 428,            // https://tools.ietf.org/html/rfc6585
    TOO_MANY_REQUEST: 429,                 // https://tools.ietf.org/html/rfc6585
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,  // https://tools.ietf.org/html/rfc6585

    INTERNAL_SERVER_ERROR: 500,            // https://tools.ietf.org/html/rfc7231#section-6.6.1
    NOT_IMPLEMENTED: 501,                  // https://tools.ietf.org/html/rfc7231#section-6.6.2
    BAD_GATEWAY: 502,                      // https://tools.ietf.org/html/rfc7231#section-6.6.3
    SERVICE_UNAVAILABLE: 503,              // https://tools.ietf.org/html/rfc7231#section-6.6.4
    GATEWAY_TIMEOUT: 504,                  // https://tools.ietf.org/html/rfc7231#section-6.6.5
    HTTP_VERSION_NOT_SUPPORTED: 505,       // https://tools.ietf.org/html/rfc7231#section-6.6.6
    VARIANT_ALSO_NEGOTIATES: 506,          // https://tools.ietf.org/html/rfc2295
    INSUFFICIENT_STORAGE: 507,             // https://tools.ietf.org/html/rfc4918
    LOOP_DETECTED: 508,                    // https://tools.ietf.org/html/rfc5842
    NOT_EXTENDED: 510,                     // https://tools.ietf.org/html/rfc2774
    NETWORK_AUTHENTICATION_REQUIRED: 511,  // https://tools.ietf.org/html/rfc6585
} as const;
