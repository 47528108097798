import {Directive, Input} from '@angular/core';
import type {AbstractControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {NG_VALIDATORS} from '@angular/forms';
import type {FunctionType, Nullish, SupportedDateTypes} from '@dv/shared/code';
import {isValidGueltigAbGueltigBis} from './is-valid-gueltig-ab-gueltig-bis';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[maxDate][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MaxDateDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class MaxDateDirective implements Validator {

    private _gueltigBis: SupportedDateTypes | Nullish;
    private _onChange?: FunctionType;

    @Input({required: true, alias: 'maxDate'})
    public set gueltigBis(value: SupportedDateTypes | Nullish) {
        this._gueltigBis = value;
        this._onChange?.();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return validateGueltigAb(this._gueltigBis)(control);
    }

    public registerOnValidatorChange?(fn: FunctionType): void {
        this._onChange = fn;
    }
}

function validateGueltigAb(gueltigBis: SupportedDateTypes | Nullish): ValidatorFn {
    return abControl => {
        const gueltigAb = abControl.value;

        return isValidGueltigAbGueltigBis(gueltigAb, gueltigBis) ?
            null :
            {gueltigAb: {gueltigAb, gueltigBis}};
    };
}
