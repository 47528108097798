/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable} from './IDisplayable';
import {displayableComparator} from './IDisplayable';

export interface ISortable {
    orderValue: number | null;
}

export function orderValueComparator(a: ISortable, b: ISortable): number {
    const valA = a.orderValue ?? 0;
    const valB = b.orderValue ?? 0;

    if (valA !== valB) {
        return valA - valB;
    }

    return 0;
}

export function orderValueThenDisplayabelComparator(a: ISortable & IDisplayable, b: ISortable & IDisplayable): number {
    return orderValueComparator(a, b) || displayableComparator(a, b);
}
