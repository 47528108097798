/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class ColorUtil {
    public static hexToRgb(hex: string): { r: number; g: number; b: number } {
        const hexValue = hex.replace('#', '');

        /* eslint-disable @typescript-eslint/no-magic-numbers */
        return {
            r: parseInt(hexValue.substring(0, 2), 16),
            g: parseInt(hexValue.substring(2, 4), 16),
            b: parseInt(hexValue.substring(4, 6), 16),
        };
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    }

    public static rgbToRelativeLuminance(r: number, g: number, b: number): number {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        const sRGB = [r, g, b].map(value => {
            const sRGBValue = value / 255;

            return sRGBValue <= 0.03928 ? sRGBValue / 12.92 : ((sRGBValue + 0.055) / 1.055) ** 2.4;
        });

        return 0.2126 * sRGB[0] + 0.7152 * sRGB[1] + 0.0722 * sRGB[2];
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    }

    public static hasHighLuminance(hex: string): boolean {
        const {r, g, b} = this.hexToRgb(hex);
        const luminance = this.rgbToRelativeLuminance(r, g, b);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return luminance > 0.5;
    }
}
