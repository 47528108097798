import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'dvlib-button-list',
    templateUrl: './button-list.component.html',
    styleUrl: './button-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class ButtonListComponent {
}
