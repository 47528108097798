/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy, OnInit} from '@angular/core';
import {Directive, HostBinding, Input} from '@angular/core';
import type {Nullish} from '@dv/shared/code';
import {LogFactory} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import {AuthorizationProviderService} from './../authorization-provider.service';
import {AbstractAuthzDirective} from './abstract-require.directive';
import {CallbackStrategy} from './strategy';

const LOG = LogFactory.createLog('require-role.directive');

@Directive({
    // attribute directive: *no* ng-template
    selector: '[dvlibRequireRole]:not(ng-template[dvlibRequireRole])',
    standalone: true,
})
export class RequireRoleAttributeDirective extends AbstractAuthzDirective implements OnInit, OnDestroy {
    @HostBinding('attr.disabled')
    private disabled?: boolean | Nullish;

    public constructor(
        private readonly authzProvider: AuthorizationProviderService,
    ) {
        super(new CallbackStrategy(disabled => this.setDisabled(disabled)));
    }

    @Input()
    public set dvlibRequireRole(permission: UserRole | Nullish) {
        LOG.trace('dvlibRequireRole', permission);
        super.changeSource(this.authzProvider.hasRole$(permission));
    }

    @Input()
    public set dvlibRequireRoleCondition(condition: boolean) {
        LOG.trace('dvlibRequireRoleCondition', condition);
        super.changeCondition(condition);
    }

    private setDisabled(disabled: boolean): void {
        this.disabled = disabled || null;
    }
}
