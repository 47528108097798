/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Nullish, UnparsedPermission} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import type {Observable} from 'rxjs';

export abstract class AuthorizationProviderService {

    /**
     * Replay of a specific role.
     */
    public abstract hasRole$(role: UserRole | Nullish): Observable<boolean>;

    /**
     * Replay of a specific permission.
     */
    public abstract hasPermission$(permissions: UnparsedPermission | Nullish): Observable<boolean>;
}
