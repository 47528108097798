import {Pipe, type PipeTransform} from '@angular/core';
import {DayOfWeek, DvbDateUtil, isNullish, Nullish} from '@dv/shared/code';

@Pipe({
    name: 'dvDayOfWeekFormat',
    standalone: true,
})
export class DvDayOfWeekFormatPipe implements PipeTransform {
    public transform(value: DayOfWeek | Nullish, format: string = 'dd'): string {
        if (isNullish(value)) {
            return '';
        }

        return DvbDateUtil.getDayOfWeekMoment(value, DvbDateUtil.today()).format(format);
    }
}
