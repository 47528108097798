/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CdkDragDrop} from '@angular/cdk/drag-drop';
import {CdkDrag, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {trackByIdentity} from '../../template/track-by';
import type {SortListTemplateContext} from './sort-list-template-context';

@Component({
    selector: 'dvlib-sort-list',
    templateUrl: './sort-list.component.html',
    styleUrls: ['./sort-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CdkDropList, CdkDrag, NgTemplateOutlet],
})
export class SortListComponent<T> {

    @Input()
    public itemRef?: TemplateRef<SortListTemplateContext<T>>;

    @Input()
    public set items(items: T[]) {
        this.sortedItems = items.slice();
    }

    @Output()
    public readonly itemsChange = new EventEmitter<T[]>();

    public sortedItems: T[] = [];

    public trackByIdentity = trackByIdentity;

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.sortedItems, event.previousIndex, event.currentIndex);
        this.itemsChange.emit(this.sortedItems);
    }
}
