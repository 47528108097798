/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {isNullish} from '@dv/shared/code';
import moment from 'moment';
import type {DecorType} from './decor';
import {COMBINE_WITH_A, DECOR_DATES} from './decor';

@Component({
    selector: 'dvlib-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class LogoComponent {

    public decor?: DecorType;
    public showA: boolean = true;

    public constructor() {
        this.decor = DECOR_DATES[moment().month() + 1]?.[moment().date()];
        this.showA = isNullish(this.decor) || COMBINE_WITH_A[this.decor];
    }
}
