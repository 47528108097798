/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IZeitraum} from './IZeitraum';
import {TimeRangeUtil} from './TimeRangeUtil';

/**
 * Compares by IZeitraum.von and secondarily by IZeitraum.id.
 */
export function zeitraumComparator(a: IZeitraum, b: IZeitraum): number {
    const result = TimeRangeUtil.TIME_RANGE_COMPARATOR(a, b);

    if (result === 0) {
        return Number(a.id) - Number(b.id);
    }

    return result;
}
