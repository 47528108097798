/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorLevel, ErrorType} from '.';
import {DvbError} from '.';

export class HttpErrorUtil {

    public static toDvbError(errorType: ErrorType, errorLevel: ErrorLevel, msgKey: string, data?: any): DvbError {
        if (!data) {
            return new DvbError(errorType, errorLevel, msgKey);
        }

        const dvbErrorCode = DvbError.findErrorCode(data.errorCode);

        return dvbErrorCode ?
            new DvbError(errorType, errorLevel, msgKey, dvbErrorCode, data.args) :
            new DvbError(errorType, errorLevel, msgKey, null, data);
    }
}
