import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {DayOfWeek, DvbDateUtil} from '@dv/shared/code';

@Component({
    selector: 'dvlib-print-day-of-week',
    standalone: true,
    imports: [],
    template: '{{ formattedDayOfWeek() }}',
    styles: `
        :host {
            display: inline;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDayOfWeekComponent {
    public dayOfWeek = input.required<DayOfWeek>();

    public formattedDayOfWeek = computed(() => {
        return DvbDateUtil.getDayOfWeekMoment(this.dayOfWeek(), DvbDateUtil.today()).format('dd');
    });
}
