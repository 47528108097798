import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {Adresse, DvbUtil} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';

@Component({
    selector: 'dvlib-adresse-display',
    standalone: true,
    imports: [],
    templateUrl: './adresse-display.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdresseDisplayComponent implements OnChanges {
    @Input() public adresse!: Adresse;
    @Input() public displayType!: 'short' | 'long';

    private readonly translator = inject(Translator);

    public displayGemeinde: string = '';

    public displayTypes = {
        short: 'short',
        long: 'long',
    };

    public adresseShort: string | null = null;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.displayType && !this.isValidDisplayType(changes.displayType.currentValue)) {
            throw new Error('displayType should be "short" or "long"');
        }

        if (this.adresse) {
            this.displayGemeinde = this.adresse.getDisplayGemeinde(this.translator);
            this.adresseShort =
                [this.adresse.strasse, this.adresse.hausnummer, this.adresse.postfach, this.adresse.plz]
                    .filter(DvbUtil.isNotEmptyString)
                    .join(', ');
            if (DvbUtil.isNotEmptyString(this.adresse.ort)) {
                this.adresseShort += ` ${this.adresse.ort}`;
            }
        }
    }

    private isValidDisplayType(displayType: string): boolean {
        return displayType === this.displayTypes.short || displayType === this.displayTypes.long;
    }
}
