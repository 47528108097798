/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy, OnInit} from '@angular/core';
import {Directive, Inject} from '@angular/core';
import type {Observable, Subscription} from 'rxjs';
import {BehaviorSubject, combineLatest, distinctUntilChanged, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {LogFactory} from '@dv/shared/code';
import {REQUIRE_STRATEGY, Strategy} from './strategy';

const LOG = LogFactory.createLog('auth.directive');

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Directive()
export abstract class AbstractAuthzDirective implements OnInit, OnDestroy {

    private subscription?: Subscription;

    private readonly grantedSource$ = new BehaviorSubject<Observable<boolean>>(of(false));
    private readonly conditions$ = new BehaviorSubject<boolean>(true);

    protected constructor(
        @Inject(REQUIRE_STRATEGY) public readonly strategy: Strategy,
    ) {
    }

    public ngOnInit(): void {
        this.strategy.setup();

        this.subscription = combineLatest([
            this.grantedSource$.pipe(switchMap(source$ => source$)), this.conditions$,
        ]).pipe(
            map(([source, condition]) => source && condition),
            distinctUntilChanged(),
        ).subscribe({
            next: granted => this.refresh(granted),
            error: error => LOG.error(error),
        });
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    protected changeSource(source$: Observable<boolean>): void {
        this.grantedSource$.next(source$);
    }

    protected changeCondition(condition: boolean): void {
        this.conditions$.next(condition);
    }

    private refresh(granted: boolean): void {
        LOG.trace('refresh, granted: ', granted);
        this.strategy.refresh(granted);
    }
}
