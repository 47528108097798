/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// Typescript does not provide a type-safe property check.
// https://stackoverflow.com/a/69042196
// https://fettblog.eu/typescript-hasownproperty/

export function hasOwnPropertyGuarded<T1, T2 extends PropertyKey>(
    obj: T1,
    prop: T2,
): obj is T1 & Record<T2, unknown> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}
