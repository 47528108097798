import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonListComponent, LoadingButtonComponent} from '../../atoms';
import {DialogComponent} from './../dialog.component';

@Component({
    selector: 'dvlib-confirm-dialog',
    standalone: true,
    imports: [
        LoadingButtonComponent,
        NgClass,
        TranslateModule,
        DialogComponent,
        ButtonListComponent,
    ],
    templateUrl: './confirm-dialog.component.html',
    styles: `
    :host {
      display: block;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

    @Input({required: true}) public open!: boolean;
    @Input() public isLoading?: boolean;

    @Input({required: true}) public title!: string;
    @Input() public subtitle?: string;
    @Input() public confirmActionText?: string;
    @Input() public cancelActionText?: string;
    @Input() public inverseButtons: boolean = false;

    @Output() public readonly confirm = new EventEmitter<void>();
    @Output() public readonly cancel = new EventEmitter<void>();
}
