@if (placeholder && inputId) {
    <label class="sr-only" [htmlFor]="inputId">{{ placeholder }}</label>
}

<dvlib-input-with-addon-wrapper [tooltip]="placeholder ? placeholder : undefined">
    <input input
           dvlibTextInput
           [attr.id]="inputId"
           [name]="name"
           [class.input-sm]="smallInputs"
           [disabled]="isDisabled()"
           #bsdp="bsDatepicker"
           bsDatepicker
           triggers=""
           [bsValue]="pickerDate()"
           [placeholder]="placeholder"
           [bsConfig]="bsconfig"
           (bsValueChange)="changeValue($event)"
           (blur)="onTouched?.()">
    <button addon
            dvlibButton="input-addon"
            icon="calendar"
            [attr.aria-label]="'COMMON.OPEN_DATEPICKER' | translate"
            (click)="bsdp.toggle(); onTouched?.()"
            [class.btn-sm]="smallInputs"
            [disabled]="isDisabled()">
    </button>
</dvlib-input-with-addon-wrapper>
