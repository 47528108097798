/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    input,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import type {Nullish, SupportedDateTypes} from '@dv/shared/code';
import {
    DateDisplayMode,
    DEBOUNCE_TIME,
    debounceUntilIdle,
    DvbDateUtil,
    isPresent,
    typeConverter,
} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {filter, map, skip, tap} from 'rxjs';
import {InputWithAddonWrapperComponent} from '../../forms';
import {DateSwitcherStore} from './date-switcher.store';

@Component({
    selector: 'dvlib-date-switcher',
    templateUrl: './date-switcher.component.html',
    styleUrls: ['./date-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BsDatepickerModule,
        InputWithAddonWrapperComponent,
        TranslateModule,
    ],
    providers: [DateSwitcherStore],
})
export class DateSwitcherComponent<T extends SupportedDateTypes> implements OnInit {

    @Input({required: true})
    public set date(value: T | Nullish) {
        this.converter.autoDetect(value);
        this.store.setValue(DvbDateUtil.toDate(value));
    }

    @Input()
    public set gueltigAb(value: T | Nullish) {
        this.store.gueltigAb.set(DvbDateUtil.toMoment(value));
    }

    @Input()
    public set gueltigBis(value: T | Nullish) {
        this.store.gueltigBis.set(DvbDateUtil.toMoment(value));
    }

    @Input()
    public set isDisabled(value: boolean) {
        this.store.isDisabled.set(value);
    }

    @Input()
    public set mode(value: DateDisplayMode) {
        this.store.mode.set(value);
    }

    @Input()
    public set convertToFirstOfMode(value: boolean) {
        this.store.convertToFirstOfMode.set(value);
    }

    public debounceTime = input(DEBOUNCE_TIME);

    @Output()
    public readonly dateChange: EventEmitter<T> = new EventEmitter();

    @Output()
    public readonly debouncedDateChange: EventEmitter<T> = new EventEmitter();

    @Output()
    public readonly debounceStarted: EventEmitter<void> = new EventEmitter();

    public placement: any = 'bottom left'; // the API doesn't support it officially

    private converter = typeConverter();

    public store = inject(DateSwitcherStore);
    private destroyRef = inject(DestroyRef);

    private selectedInStore$ = toObservable(this.store.selectedInMode);

    public ngOnInit(): void {
        this.selectedInStore$.pipe(
            filter(isPresent), // skip initial value "undefined" of signal
            skip(1), // skip initial input of input binding "firstOfMode"
            map(value => this.converter.convert(value) as T),
            tap(value => {
                this.dateChange.emit(value);
                this.debounceStarted.emit();
            }),
            debounceUntilIdle(this.debounceTime()),
            tap(value => this.debouncedDateChange.emit(value)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }
}
