/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type moment from 'moment';
import {checkPresent} from '../../types';
import {DvbRestUtil} from '../rest';

export interface ITimeRange {
    von: moment.Moment | null;
    bis: moment.Moment | null;
}

export function toRestObject(a: ITimeRange): JaxTimeRange {
    return {
        von: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(a.von)),
        bis: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(a.bis)),
    };
}
