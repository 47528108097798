{
  "COMMON": {
    "KIND": {
      "SINGULAR": "Enfant",
      "PLURAL": "Enfants",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{enfant} other{enfants}}",
      "TITLE": "Enfant {{name}}",
      "AUSWAEHLEN": "sélectionner un enfant",
      "ANSEHEN": "Voir la fiche"
    },
    "KINDERORT": {
      "SINGULAR": "Institution",
      "PLURAL": "Institutions",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Institution} other{Institutions}}",
      "ERSTELLEN": "Créer institution",
      "AUSWAEHLEN": "Sélectionner institution"
    },
    "KINDERORT_FRAKTION": {
      "SINGULAR": "Fraction",
      "PLURAL": "Fractions",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{groupe} other{groupes}}",
      "ERSTELLEN": "Créer groupe",
      "AUSWAEHLEN": "Sélectionner groupe"
    },
    "KITA": {
      "SINGULAR": "Crèche",
      "PLURAL": "Crèches",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{crèche} other{crèches}}",
      "ERSTELLEN": "Créer crèche",
      "AUSWAEHLEN": "Sélectionner crèche"
    },
    "GRUPPE": {
      "SINGULAR": "Groupe",
      "PLURAL": "Groupes",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{groupe} other{groupes}}",
      "TITLE": "Groupe {{name}}",
      "ERSTELLEN": "Créer groupe",
      "AUSWAEHLEN": "Sélectionner groupe"
    },
    "TAGES_ELTERN": {
      "SINGULAR": "Famille d'accueil",
      "PLURAL": "Familles d'accueil",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Famille d'accueil} other{Familles d'accueil}}",
      "ERSTELLEN": "Saisir famille d'accueil",
      "AUSWAEHLEN": "Sélectionner famille d'accueil"
    },
    "BETREUUNGS_PERSON": {
      "SINGULAR": "Personne d'accueil",
      "PLURAL": "Personnes d'accueil",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{personne d'accueil} other{personnes d'accueil}}",
      "TITLE": "Personne d'accueil: {{name}}",
      "ERSTELLEN": "Créer personne d'accueil",
      "AUSWAEHLEN": "Sélectionner personne d'accueil",
      "ASSIGN": "Attribuer une personne de référence",
      "KEINE": "Aucune personne de référence"
    },
    "GRUPPE_ODER_BETREUUNGS_PERSON": {
      "SINGULAR": "Groupe ou personne d'accueil",
      "PLURAL": "Groupes ou personnes d'accueil",
      "ERSTELLEN": "Créer groupes ou personnes d'accueil",
      "AUSWAEHLEN": "Sélectionner groupe ou personne d'accueil"
    },
    "KONTAKTPERSON": {
      "SINGULAR": "Personne de contact",
      "PLURAL": "Personnes de contact",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{personne de contact} other{personnes de contact}}",
      "TITLE": "Personne de contact {{name}}",
      "AUSWAEHLEN": "Sélectionner personne de contact"
    },
    "FIRMA": {
      "SINGULAR": "Entreprise",
      "PLURAL": "Entreprises",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Entreprise} other{Entreprises}}",
      "TITLE": "Entreprise {{name}}",
      "ERSTELLEN": "Créer entreprise"
    },
    "PLATZART": {
      "SINGULAR": "Type de place",
      "PLURAL": "Types de place",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Type de place} other{Types de place}}",
      "TITLE": "Type de place {{name}}",
      "ERSTELLEN": "Créer type de place",
      "KONTINGENT_DEFINIEREN": "Définir contingent",
      "SYMBOL_DEFINIEREN": "Symbole",
      "SYMBOL": "Symbole",
      "HINZUFUEGEN": "Ajouter type de place",
      "AUSWAEHLEN": "Sélectionner type de place",
      "PLATZART_STAMMDATEN": "Données de base",
      "ALTERNATIVE_KIBON_ABZUG_DEFINIEREN": "Définir déduction kiBon",
      "ALTERNATIVE_KIBON_ABZUG": "Déduction kiBon",
      "EXTERNAL_ANMELDUNG": "Inscription externe",
      "SHOW_ON_EXTERNE_ANMELDUNG": "Afficher le type d'emplacement sur l'inscription externe"
    },
    "FIRMENPLATZ": {
      "SINGULAR": "Place d'entreprise",
      "PLURAL": "Places d'entreprise",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Place d'entreprise} other{Places d'entreprise}}",
      "TITLE": "Place d'entreprise {{name}}",
      "ERSTELLEN": "Créer place d'entreprise",
      "HINZUFUEGEN": "Ajouter place d'entreprise",
      "AUSWAEHLEN": "Sélectionner place d'entreprise",
      "BSV_RELEVANT": "Prendre en compte dans les statistiques de l'OFAS"
    },
    "FIRMENKONTINGENT": {
      "SINGULAR": "Contingent",
      "PLURAL": "Contingents",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Contingent} other{Contingents}}",
      "TITLE": "Contingent {{name}}",
      "ERSTELLEN": "Créer contingent"
    },
    "TARIF": {
      "SINGULAR": "Tarif",
      "PLURAL": "Tarifs",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Tarif} other{Tarifs}}",
      "TITLE": "Tarif {{name}}",
      "ERSTELLEN": "Créer tarif",
      "AUSWAEHLEN": "Sélectionner tarif"
    },
    "MANDANT": {
      "SINGULAR": "Mandant",
      "PLURAL": "Mandants",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Mandant} other{Mandants}}",
      "TITLE": "Mandant {{name}}",
      "ERSTELLEN": "Créer mandant",
      "AUSWAEHLEN": "Sélectionner mandant"
    },
    "BETREUUNGSFAKTOR_REGEL": {
      "SINGULAR": "Facteur de prise en charge",
      "PLURAL": "Facteurs de prise en charge",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Facteur de prise en charge} other{Facteurs de prise en charge}}",
      "TITLE": "Facteur de prise en charge {{name}}",
      "ERSTELLEN": "Créer facteur de prise en charge"
    },
    "BENUTZER": {
      "SINGULAR": "Utilisateur",
      "PLURAL": "Utilisateurs",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Utilisateur} other{Utilisateurs}}",
      "TITLE": "Utilisateur {{name}}",
      "ERSTELLEN": "Créer utilisateur"
    },
    "RECHNUNG": {
      "SINGULAR": "Facture",
      "PLURAL": "Factures",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Facture} other{Factures}}",
      "TITLE": "Facture {{name}}",
      "ERSTELLEN": "Créer facture"
    },
    "DOKUMENT_LAYOUT": {
      "SINGULAR": "Mise en page document",
      "PLURAL": "Mises en page documents",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{mise en page document} other{mises en page documents}}",
      "TITLE": "Mise en page document {{name}}",
      "ERSTELLEN": "Créer mise en page document",
      "STEUERBESCHEINIGUNG_ALTERNATIV": "Titre alternatif pour l'attestation fiscale"
    },
    "CUSTOM_FIELD": {
      "SINGULAR": "Champ supplémentaire",
      "PLURAL": "Champs supplémentaires",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Champ supplémentaire} other{Champs supplémentaires}}",
      "TITLE": "Champs supplémentaires {{name}}",
      "ERSTELLEN": "Ajouter un champ supplémentaire",
      "MONATSBELEGUNG_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{supplément de prise en charge} other{suppléments de prise en charge}}"
    },
    "WOCHENPLAN": {
      "SINGULAR": "Planning hebdomadaire",
      "PLURAL": "Plannings hebdomadaires",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Planning hebdomadaire} other{plannings hebodmadaires}}",
      "TITLE": "Planning hebdomadaire {{name}}",
      "AUSWAEHLEN": "Choisir un programme hebdomadaire"
    },
    "BELEGUNG": {
      "SINGULAR": "Occupation",
      "PLURAL": "Occupations",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Occupation} other{Occupations}}",
      "TITLE": "Occupation {{name}}",
      "ERSTELLEN": "Créer occupation"
    },
    "NAV_ERSTELLEN": "Créer",
    "NAV_UEBERSICHT": "Aperçu",
    "NAV_PROFIL": "Profil",
    "NAV_FAKTURA": "Facture",
    "NAV_ORGANISATION": "Organisation",
    "NAV_VOREINSTELLUNGEN": "Préréglages",
    "NAV_REPORTS": "Rapports",
    "NAV_COMMUNICATION": "Communication",
    "NAV_BETREUUNGSFAKTOR_REGELN": "Facteur de prise en charge",
    "NAV_OFFENE_POSTEN": "Postes non soldés",
    "NAV_CORRESPONDENCE": "Correspondance",
    "NAV_SUPPORT": "Support",
    "NAV_SUPPORT_PHONE": "031 378 24 36",
    "NAV_SUPPORT_EMAIL": "support@kitadmin.ch",
    "NAV_SUPPORT_OPENING_HOURS": "Lun à ven:\n08:00 - 12:00\n13:00 - 17:00",
    "NAV_TEAM_VIEWER": "Support à distance (TeamViewer)",
    "FILTER_STATUS": "Statuts",
    "RESET_FILTER": "Réinitialiser",
    "ADD_FILTER": "Ajouter filtre",
    "CLOSE_FILTER": "Fermer filtre",
    "KIND_GEBOREN": "Né le {{gebdatum}}",
    "PRIORITAET": "Priorité {{prioritaet}}",
    "ARCHIVIERTE_GRUPPEN_ANZEIGEN": "Afficher groupes archivés",
    "ARCHIVIERTE_GRUPPEN_AUSBLENDEN": "Cacher groupes archivés",
    "MENU": "Menu",
    "AUSWAEHLEN": "Sélectionner",
    "BEIDE": "Les deux",
    "MENU_KIND_DIREKTZUWEISUNG": "Attribuer directement un enfant",
    "ZUWEISUNG_IST_ZUGEWIESEN": "A déjà une place assignée",
    "ZUWEISUNG_IST_PROVISORISCH": "attribution provisoire",
    "ZUWEISUNG_IST_ANGEBOT": "offre ouverte",
    "ZUWEISUNG_IST_DEFINITIV": "attribution définitive",
    "ZUWEISUNG_IST_WARTELISTE": "A indiqué un souhait",
    "ZUWEISUNG_HAT_GESCHWISTER": "A des frères et sœurs dans la même institution",
    "ZUWEISUNG_IST_SUBVENTIONIERT": "Subventionnée",
    "ZUWEISUNG_IST_PRIVAT": "Privée",
    "KEINE_ZUWEISUNG": "Aucune assignation",
    "PRIVAT": "Privé",
    "SUBVENTIONIERT": "Subventionné",
    "BETREUUNGSGUTSCHEIN": "Bon de garde",
    "BETREUUNGS_UNABHAENGIG": "Tarif indépendant de la prise en charge",
    "ZUWEISUNG_PRIVAT_ICON": "P",
    "ZUWEISUNG_SUBVENTIONIERT_ICON": "S",
    "ZUWEISUNG_FIRMENPLATZ_ICON": "E",
    "KINDERGARTEN_TITLE": "École enfantine {{kgType}} {{kgLocation}}",
    "KIND_ZUWEISEN": "Assigner enfant",
    "ZUWEISUNG_ANPASSEN": "Modifier l'assignation",
    "PROFIL": "Profil",
    "AKTUELLE_PLAETZE_MIT_DATUMPREP": "Places actuelles {{preposition}}",
    "PLAETZE_MIT_DATUMPREP": "Places {{preposition}}",
    "KAPAZITAET_MAX_PLAETZE": "places max.",
    "KAPAZITAET_MAX_PLAETZE_WITH_VALUE": "{{plaetze}} places max.",
    "KAPAZITAET_PLAETZE": "Places",
    "KAPAZITAET_PLAETZE_WITH_VALUE": "{{plaetze}} places",
    "KAPAZITAET_FREIE_PLAETZE": "Places libres",
    "KAPAZITAET_BELEGTE_PLAETZE": "Places occupées",
    "KAPAZITAET_KEINE_PLAETZE": "aucun",
    "FAVORITEN": "Favoris",
    "TITEL": "Titre",
    "NAME": "Nom",
    "VORNAME": "Prénom",
    "GEBURTSTAG": "Anniversaire",
    "GEBURTSTAG_MIT_DATUM": "Date de naissance : {{date}}",
    "NATIONALITAET": "Nationalité",
    "ANMELDEDATUM_MIT_DATUM": "Date d'inscription {{datum}}",
    "MAENNLICH": "Homme",
    "MAENNLICH_KIND": "Garçon",
    "WEIBLICH": "Femme",
    "WEIBLICH_KIND": "Fille",
    "GESCHLECHT": "Sexe",
    "UNBEKANNT": "Inconnu",
    "UNBEKANNT_KIND": "Inconnu",
    "DIVERS": "Divers",
    "DIVERS_KIND": "Divers",
    "KONTAKTPERSONEN": "Personnes de contact",
    "KONTAKTPERSON_AUSWAEHLEN": "Sélectionner personne de contact",
    "RESETTABLE": "Réinitialisable",
    "HINZUGEFUEGT": "ajouté",
    "BEREITS_HINZUGEFUEGT": "déjà ajouté",
    "OPTIONEN": "Options",
    "ALLE_AUSWAEHLEN": "Tout sélectionner",
    "KEINE_AUSWAEHLEN": "Sélectionner aucun",
    "BETREUUNGSVEREINBARUNG": {
      "DOWNLOAD": "Télécharger la convention de prise en charge",
      "CONFIG": "Configuration de la convention d'accueil",
      "CONFIGS": "Configurations de la convention d'accueil",
      "NO_CONFIGS": "Pas de configurations de la convention d'accueil",
      "EDIT_CONFIG": "Modifier les configurations",
      "CREATE_CONFIG": "Créer une configuration de convention",
      "ALTERNATIVE_NAME": "Autre nom pour le lieu d'accueil",
      "TYPE": {
        "NAME": "Configurations type de la convention",
        "AUSWAHL": "Sélectionner la configuration type",
        "CREATE": "Créer une nouvelle configuration type",
        "NO_TYPES": "Aucune configuration type trouvée"
      }
    },
    "ADRESSE": "Adresse",
    "GLEICHE_ADRESSE_WIE_HAUPTKONTAKT": "même adresse que contact principal",
    "ADRESSE_MANDANT": "Adresse du client",
    "ADRESSE_KINDERORT": "Adresse de l'institution",
    "ADRESSE_CUSTOM": "Adresse spécifique",
    "ADRESSE_NONE": "Aucune adresse",
    "ADRESSZUSATZ": "Complément",
    "ADRESS_CONFIRM": "Confirmer adresse",
    "STRASSE": "Rue",
    "NUMMER": "Numéro",
    "POSTFACH": "Case postale",
    "PLZ": "Code postal",
    "ORT": "Lieu",
    "GEMEINDE": "Commune {{gemeinde}}",
    "GEMEINDE_NICHT_ERMITTELBAR": "Commune non valide",
    "LAND": "Pays",
    "TELEFON": "Téléphone",
    "MOBILE_NR": "Mobile",
    "GESCHAEFT_NR": "Téléphone professionnel",
    "ORGANISATION": "Organisation",
    "TELEFON_MIT_NUM": "Téléphone {{num}}",
    "MOBILE_MIT_NUM": "Mobile {{num}}",
    "GESCHAEFT_MIT_NUM": "Téléphone professionnel {{num}}",
    "E-MAIL": "E-Mail",
    "WEBSITE": "Site internet",
    "REPORT_GENERIEREN": "Générer rapport",
    "ALLE_KINDERORTE": "Toutes les institutions",
    "KONTAKT": "Contact",
    "LOESCHEN": "Supprimer",
    "BEARBEITEN": "Modifier",
    "ZUWEISEN": "Assigner",
    "ZUWEISUNG_AUFHEBEN": "Annuler l'attribution",
    "IGNORIEREN": "Ignorer",
    "PENSUM": "Taux de prise en charge {{pensum}}",
    "PENSUM_IN_PROZENT": "Taux de prise en charge en %",
    "PENSUM_IN_SYMBOL_MF": "Taux de prise en charge en{type, select, PERCENTAGE{ %} HOURS{ h} other{}}",
    "PENSUM_FAKTOR": "Facteur du taux de prise en charge",
    "SUBVENTIONIERTER_PLATZ": "Place subventionnée",
    "PRIVATER_PLATZ": "Place privée",
    "KINDERGARTEN": "École enfantine / École",
    "BETREUUNGSWUNSCH": "Souhait prise en charge",
    "BETREUUNGSWUNSCH_AB": "Souhait prise en charge dès le",
    "KEIN_WUNSCH_OFFEN": "Pas de souhait en cours",
    "BETREUUNG_MIT_DATUMPREP": "Prise en charge {{preposition}}",
    "AKTUELLE_BELEGUNG": "Prise en charge actuelle",
    "AKTUELLE_BETREUUNG_MIT_DATUMPREP": "Prise en charge actuelle {{preposition}}",
    "PROVISORISCHE_BETREUUNG_MIT_DATUMPREP": "Prise en charge provisoire {{preposition}}",
    "ANGEBOT_AB": "Offre dès le",
    "BEMERKUNG": "Commentaire",
    "BESTAETIGEN": "confirmer",
    "ABBRECHEN": "annuler",
    "FORTFAHREN": "continuer",
    "ZURUECK": "retour",
    "ALLE": "Tous",
    "OBJEKTE_PRO_SEITE": "Objets par page",
    "SEIT": "depuis",
    "AB": "dès le",
    "AND": "et",
    "OR": "ou",
    "TODAY": "aujourd'hui",
    "AKTUELLE_KAPAZITAET_MIT_DATUMPREP": "Capacité actuelle {{preposition}}",
    "KAPAZITAET_MIT_DATUMPREP": "Capacité {{preposition}}",
    "NEUE_KAPAZITAET": "Nouvelle capacité",
    "NEUE_KAPAZITAET_SPEICHERN": "Enregistrer nouvelle capacité",
    "GUELTIG_AB": "Valable dès le",
    "GUELTIG_BIS": "Valable jusqu'au",
    "DATE_RANGE": "Période",
    "SPEICHERN": "Enregistrer",
    "KONTINGENT": "Contingent {{kontingente}}",
    "ANZAHL_PLAETZE": "Nombre de places",
    "BEENDET_TITLE": "Terminé le : {{endedatum}}",
    "BEENDEN_REVERT": "Annuler",
    "LAST_SENT_AT": "Envoyé le",
    "ENDDATUM": "Date de fin",
    "BEENDEN": "Terminer",
    "BETREUUNGSFAKTOR_WERT": "Facteur de prise en charge ({{wert}})",
    "SPEZIFISCHER_BETREUUNGSFAKTOR_WERT": "Facteur de prise en charge spécifique ({{wert}})",
    "SHORT_KALENDERWOCHE_VON_BIS": "S {{dateAb}} - {{dateBis}}",
    "KORRESPONDENZSPRACHE": "Langue de correspondance",
    "AKTUELLE_WOHNADRESSE_MIT_DATUMPREP": "Adresse actuelle {{preposition}}",
    "WOHNADRESSE_MIT_DATUMPREP": "Adresse {{preposition}}",
    "UMZUG_ERFASSEN": "Saisir déménagement",
    "TITLE_UMZUG": "Saisir déménagement",
    "BITTE_UMZUGSDATUM_ANGEBEN": "Veuillez spécifier une date dès le",
    "ADRESSE_MITBEWOHNER": "Les personnes suivantes habitent à la même adresse",
    "MITZUEGLER": "Les personnes suivantes ont également déménagé",
    "PARAMETERS": "Paramètres définis",
    "BETRAG_VALUE": "CHF {{betrag}}",
    "DATEI_IMPORTIEREN": "Importer fichier",
    "DATEIEN_IMPORTIEREN": "Importer fichiers",
    "IMPORTIEREN": "Importer",
    "PREPOSITION_DATUM_MF": "{isActive, select, true{Actuelle } other{}} {preposition} {isBeginOfTime, select, true{ouverture} other{{date}} }",
    "GUELTIGKEIT_VON_BIS": "{{dateAb}} - {{dateBis}}",
    "PROVISORISCH": "provisoirement",
    "VERRECHNET": "facturé",
    "VERRECHENBAR": "facturable",
    "STORNIERT": "annulé",
    "GELOESCHT": "supprimé",
    "NICHT_VERRECHENBAR": "non facturable",
    "RECHNUNGSDIFFERENZ": "Différence dans la facturation",
    "VON": "De",
    "BIS": "jusqu'au",
    "ADMINISTRATION": "Administration",
    "RECHNUNGS_KONFIGURATIONEN": "Configurations de facture",
    "NEUE_KONFIGURATION": "Nouvelle configuration",
    "NEUE_KONFIGURATION_NAME": "Nouvelle configuration {{name}}",
    "SMALLINVOICE": "SmallInvoice",
    "DVB": "DVB",
    "TASKS": {
      "KINDERORT_TARIF_ZUWEISUNG": "Assigner tarif {{ZEITRAUM}} pour {{entityDisplayName}}.",
      "KINDERORT_TARIF_ZUWEISUNG_ZEITRAUM": "Assigner tarif {{ABRECHNUNG_TYP}} {{ZEITRAUM}} pour {{entityDisplayName}}.",
      "GEBURTSTAG_KIND": "{{entityDisplayName}} à besoin d'une date de naissance.",
      "KINDERORT_TARIF_FIRMENKONTINGENT": "Assigner tarif {{ZEITRAUM}} pour contingent {{entityDisplayName}}.",
      "KIND_TARIFPARAMETER": "Définir le paramètre {{PARAMETERS}} pour {{entityDisplayName}} au {{ZEITRAUM}}.",
      "KIND_AUSTRITT_PROVISORISCH": "{{entityDisplayName}} a un retrait provisoire le {{ZEITRAUM}}.",
      "KIND_BELEGUNG_PROVISORISCH": "{{entityDisplayName}} a une assignation provisoire le {{ZEITRAUM}}.",
      "KIND_BELEGUNG_ANGEBOT": "{{entityDisplayName}} a une offre ouverte le {{ZEITRAUM}}.",
      "KIND_RECHNUNGSDIFFERENZ": "{{entityDisplayName}} a une différence de facturation le {{ZEITRAUM}}.",
      "KINDERORT_LEISTUNGSBERECHNUNG_INTERNALERROR": "Une erreur interne s'est produite dans le calcul des prestations.",
      "RECHNUNGS_REVISION_DELIVERY_FAILURE": "La transmission par courrier électronique de la facture n° {{entityDisplayName}} a échoué.",
      "KONTAKTPERSON_ADRESSE": "La personne de contact {{entityDisplayName}} n'a pas d'adresse actuelle.",
      "RECHNUNG_ZAHLUNGEN": "La facture n°{{entityDisplayName}} pour {{KONTAKTPERSON}} est surpayée.",
      "MONATSBLATT": "La fiche mensuelle {{ZEITRAUM}} pour {{entityDisplayName}} n'est pas encore complètement remplie. Il manque : {{PARAMETERS}}",
      "ZEITRAUM_VON_BIS": "pour la période {{ZEITRAUM_VON}} - {{ZEITRAUM_BIS}}",
      "PERIODE": "{{VON}}/{{BIS}}",
      "LISTE_VON_MEHR_ALS_2": "{{liste}} et {{letztes}}",
      "AUSTRITT_DATUM": "le {{AUSTRITT}}",
      "ZEITRAUM_AB": "dès le {{ZEITRAUM_VON}}",
      "COMMUNICATION_BRING_ABHOLZEITEN": "Contrôler heure d'arrivée et de départ de {{entityDisplayName}} {{ZEITRAUM}}.",
      "KIBON_EXCHANGE_VERFUEGUNG": "Assigner les décisions de kiBon de {{entityDisplayName}} au {{PERIODE}}.",
      "KIBON_EXCHANGE_MUTATION": "Des annonces kiBon en suspens {{PERIODE}} pour {{entityDisplayName}} ont été trouvées.",
      "MANDATORY_CUSTOM_FIELD": "{{entityDisplayName}} a les champs obligatoires vides suivants : {{PARAMETERS}}",
      "CHECK_OUT_MISSING": "{{entityDisplayName}} n'a pas été déconnecté ou désinscrit le {{ZEITRAUM}}.",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Task} other{Tasks}}"
    },
    "LEISTUNGSRECHNUNG_WARNINGS": {
      "BG_PENSUM_MISMATCH": {
        "SHORT": "BDG-Taux",
        "LONG_MF": "{implicitlyKnown, select, KIND{Le taux d'accueil de {kindName}} other{Le taux d'accueil de {kinderOrtName}}} est inférieur au taux du bon de garde. Veuillez contrôler dans kiBon et kitAdmin."
      },
      "PENDENTE_MUTATIONS_MELDUNG": {
        "SHORT": "mutation kiBon en suspens",
        "LONG_MF": "Il y a une mutation kiBon en suspens pour {implicitlyKnown, select, KIND{{kinderOrtName}} other{{kindName}}}."
      },
      "PENDENTE_VERFUEGUNG_MELDUNG": {
        "BADGES_OVERVIEW_TITLE": "Décisions en suspens",
        "DELETE_FOR_REF_NR": "Voulez-vous supprimer les avertissements pour la prise en charge {{refNr}} ?",
        "BADGE_LOESCHEN": "Tous les badges d'alerte pour ce mois-ci",
        "DELETE_FOR_REF_NR_INFO": "kitAdmin n'a pas la possibilité de vérifier si une confirmation de place/une annonce de mutation sera décidée. Veuillez vérifier le statut directement dans kiBon.",
        "BADGE_MONAT_LOESCHEN": "Voulez-vos supprimer les décisions en suspens du mois courant ?",
        "SHORT": "Décision en suspens",
        "LONG_MF": "Pour cette période, une confirmation de place/mutation pour {implicitlyKnown, select, KIND{{kinderOrtName}} other{{kindName}}} a été envoyée à kiBon. Une nouvelle décision est en suspens."
      },
      "PENDENTE_KIND_ABWEICHUNGS_MELDUNG": {
        "SHORT": "Demande pendante groupAdmin",
        "LONG_MF": "Il y a au moins une demande groupAdmin ouverte pour {kindName}."
      }
    },
    "EINZAHLUNGSSCHEIN": "Bulletin de versement",
    "RECHNUNGEN": "Factures",
    "RECHNUNGSLAUF": "Démarrer facturation",
    "RECHNUNGSDATUM": "Date de facture",
    "FAELLIG_AM": "Date d'échéance",
    "ZAHLUNGSFRIST_PLACEHOLDER": "Date de paiement",
    "RECHNUNGS_TOTAL": "Total",
    "RECHNUNG_NR": "N°{{nummer}}",
    "RECHNUNG_PER_EMAIL_SENDEN": "Envoyer la facture par e-mail",
    "RECHNUNGEN_PER_EMAIL_SENDEN": "Envoyer {{anzahl}} factures par e-mail?",
    "KORRESPONDENZ_PER_EMAIL": "Correspondance par e-mail",
    "RECHNUNG_VERSENDEN_BESTAETIGEN": "Confirmer l'envoi de la facture N°{{nummer}}",
    "RECHNUNG_EMAIL_EMPFAENGER": "E-mail du destinataire: {{email}}",
    "VERSAND": "Envoi",
    "EMAIL_AM": "E-mail du : {{datum}}",
    "VERSAND_PER": "Envoi par {{versandart}}",
    "VERSANDART": "Mode d’envoi",
    "VERSANDART_POST": "Poste",
    "VERSANDART_EMAIL": "E-mail",
    "ANTWORT_ADRESSE": "Réponse par e-mail",
    "BCC_ADRESSE": "CCI-E-Mail",
    "BETRAG_CHF": "Montant en CHF",
    "DATUM": "Date",
    "MONTH": "Mois",
    "YEAR": "Année",
    "POSITION": "Position",
    "TOTAL": "Total",
    "ANZAHL": "Nombre",
    "BEZEICHNUNG": "Désignation",
    "TOTAL_CHF": "Total : CHF {{chf}}",
    "SOLL": "Débit",
    "HABEN": "Crédit",
    "SALDO": "Solde",
    "ZAHLUNG_TEXT": "{{dtype}} du {{datum}} avec un montant de {{betrag}} CHF",
    "VORAUSZAHLUNG": "Paiement anticipé",
    "RUECKERSTATTUNG": "Montant du remboursement",
    "RUECKERSTATTUNGSBETRAG_AUSZAHLEN": "Verser le montant du remboursement.",
    "RUECKERSTATTUNG_AUSBEZAHLT": "Montant du remboursement du {{datum}} versé",
    "RUECKERSTATTUNG_UND_AUSZAHLUNG_LOESCHEN": "Supprimer remboursement et versement",
    "VORAUSZAHLUNG_VERBUCHT": "Paiement anticipé comptabilisé au {{datum}}",
    "AUSZAHLEN": "Payer",
    "AUSZAHLUNG_BESTAETIGEN": "Confirmer le paiement",
    "AUSZAHLUNGSDATUM": "Date du paiement",
    "BETRAG": "Montant",
    "CONFIRM_DELETE_ENTITY": "Supprimer {{entity}} définitivement?",
    "CONFIRM_DELETE": "Supprimer définitivement?",
    "AKTIONEN": "Actions",
    "WARN_AUSGLEICHSZAHLUNG_WIRD_STORNIERT": "Par la suppression de {{type}} la compensation versée sur le Compte N°{{rechnungsNummer}} est annulée.",
    "OFFENE_POSTEN": "Postes non soldés",
    "ERFASSEN": "Saisir",
    "ZAHLUNGEN_BIS": "Les paiements jusqu'à",
    "LEISTUNGSPERIODE_BIS": "Période de prestations, jusqu'à",
    "BILD_HOCHLADEN": "Télécharger image",
    "KEINE_ENTITY_VORHANDEN": "Pas de {{entityName}} disponible",
    "KEINE_GRUPPEN": "Pas de groupes",
    "KEINE_WEITEREN_GRUPPEN": "Pas d'autres groupes",
    "KEINE_LEISTUNGEN": "Pas de prestations",
    "KEINE_OFFENE_POSTEN_VORHANDEN": "Pas de postes non soldés",
    "FRAKTIONEN": {
      "KITA": {
        "PLURAL": "Groupes",
        "WEITERE": "D'autres groupes",
        "ERSTELLEN": "Créer nouveau groupe",
        "ARCHIVIERTE_ANZEIGEN": "Afficher groupes archivés",
        "ARCHIVIERTE_AUSBLENDEN": "Cacher groupes archivés"
      },
      "TAGES_ELTERN": {
        "PLURAL": "Personnes d'accueil",
        "WEITERE": "D'autres personnes d'accueil",
        "ERSTELLEN": "Créer personne d'accueil",
        "ARCHIVIERTE_ANZEIGEN": "Afficher personnes d'accueil archivées",
        "ARCHIVIERTE_AUSBLENDEN": "Cacher personnes d'accueil archivées"
      }
    },
    "STANDORT": {
      "SINGULAR": "site",
      "PLURAL": "sites",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{site} other{sites}}",
      "AUSWAEHLEN": "Sélectionner un site"
    },
    "SCHLIESSTAGE": {
      "SINGULAR": "Jour de fermeture",
      "PLURAL": "Jours de fermeture",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Jour de fermeture} other{Jours de fermeture}}",
      "ERSTELLEN": "Créer des jours de fermeture",
      "TITLE_SCHLIESSTAG_DATE_RANGES": "Périodes fermées",
      "SCHLIESSTAG_DATE_RANGE_HINZUFUEGEN": "Ajouter un période",
      "SCHLIESSTAG_LOESCHEN": "Supprimer des jours de fermeture",
      "KEIN_ZEITRAUM_DEFINIERT": "Pas de période définie",
      "BIS": "Jusqu'à (en option)"
    },
    "ARCHIVED_TARIF": {
      "PLURAL": "Tarifs archivés",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Tarif archivé} other{Tarifs archivés}}",
      "TITLE": "Tarif {{name}}",
      "SINGULAR": "Tarif archivé"
    },
    "ANGESTELLTE": {
      "SINGULAR": "Employée",
      "PLURAL": "Employés"
    },
    "BELEGUNGS_EINHEIT": {
      "SINGULAR": "Unité d'occupation",
      "PLURAL": "Unités d'occupation",
      "WOCHENTAG": "Jour de la semaine",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Unité d'occupation} other{Unités d'occupation}}"
    },
    "EXTERNAL_ANMELDUNG": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Inscription externe} other{Inscriptions externes}}"
    },
    "ANMELDUNG_UEBERNEHMEN": {
      "BEREITS_ZUGEWIESEN": "Cette inscription a déjà été attribuée à un enfant :",
      "NO_KIND_MATCH": "Aucun enfant recensé trouvé",
      "NO_GESUCHSTELLER_MATCH_MF": "{geschlecht, select, WEIBLICH{requérante} other{requérant}} non trouvé",
      "NEUES_KIND_ERFASSEN": "+ ajouter un nouvel enfant",
      "FAMILIE": "Famille {{name}}",
      "RELATED_KINDER": "Enfants de cette famille",
      "KONTAKT_IN_HOUSEHOLD_MF": "{count, plural, one{personne de contact du même ménage} other{personnes de contact du même ménage}}",
      "KONTAKT_IN_OTHER_HOUSEHOLD_MF": "{count, plural, one{personne de contact d'un autre ménage} other{personnes de contact d'un autre ménage}}",
      "ASSIGN_TO_KIND": "Attribuer l'inscription {{kind}}",
      "ADD_KIND_TO_HOUSEHOLD": "ajouter comme nouvel enfant de la famille",
      "SEARCH": "Chercher un enfant",
      "BETREUUNGS_WUNSCH_ERFASSEN": "Saisir le souhait de prise en charge"
    },
    "KITADMIN": "kitAdmin",
    "NAV_PERSONALPLANUNG": "Planification du personnel",
    "NAV_PERSONALBEDARF": "Besoins en personnel",
    "NAV_DIENSTPLAN": "Plan de service",
    "NAV_PERSONAL_SETTINGS": "Équipe",
    "NAV_SCHLIESSTAGE": "Jours de fermeture",
    "FILTER_GESCHWISTER": "Frères et sœurs",
    "FILTER_ANMELDUNG": "Inscription",
    "FILTER_PENSUM": "Taux",
    "FILTER_PRIORITAET": "Priorité",
    "FILTER_APPLY": "Reprendre",
    "ALL": "tous",
    "PROVISORISCHER_BG": "Bon de garde provisoire",
    "SCHULE_TITLE": "École {{kgLocation}}",
    "KEINEN_AUSWAEHLEN": "N'en choisir aucun",
    "STUNDEN_CHART": {
      "DURCHSCHNITT_YEAR": "Heures de prise en charge {{jahr}}",
      "SOLL_STUNDEN": "Heures mensuelles prévues",
      "EFFEKTIV_STUNDEN": "Heures mensuelles effectuées",
      "AVG_SOLL_STUNDEN": "{{count}}h mensuelles selon contrat",
      "AVG_EFFEKTIV_STUNDEN": "{{count}}h travaillées",
      "FORECAST_STUNDEN": "{{count}}h prévues"
    },
    "ADDRESS_MODE": {
      "OWN": "Propre adresse",
      "HAUPTKONTAKT": "Même adresse que le contact principal",
      "NONE": " Pas d'adresse"
    },
    "ALLE_GRUPPEN": "Tous les groupes",
    "RESTORE": "Restaurer",
    "UNIGNORIEREN": "annuler \"ignorer\"",
    "DECLINE": "Refuser",
    "PROZENTPUNKTE": "Pourcentages",
    "WEEKLY_PLATZ_FREQUENCY": "fréquence hebdomadaire",
    "AKTUELLE_SEITE": "page {{page}}",
    "DAY": "jour",
    "WEEK": "semaine",
    "YES": "oui",
    "GEBUEHR": {
      "DEPOT_TO_VORAUSZAHLUNG": "Saisir un paiement anticipé",
      "DEPOT_TO_RECHNUNG": "Comptabiliser comme paiement anticipé sur facture",
      "BEZEICHNUNG_TYPE": "Désignation : {{type}}",
      "DEPOT_TO_RUECKERSTATTUNG": "Convertir en montant de remboursement",
      "DEPOT_NON": "Aucun dépôt trouvé",
      "DEPOT_MIT_RECHUNG_ERSTATTEN": "Comptabiliser le dépôt de {{betrag | number:2}} CHF pour une facture",
      "CONFIRM_DEPOT_MIT_RECHUNG_ERSTATTEN": "Comptabiliser le dépôt de {{betrag | number:2}} CHF pour la facture du {{rechnungPeriode}} ?",
      "DEPOT_VERBUCHT": "Dépôt du {{datum}} comptabilisé",
      "GEBUEHR_VERBUCHT": "Frais du {{datum}} comptabilisés",
      "DEPOT": "Dépôt",
      "GEBUEHR": "Frais",
      "MITGLIEDER_BEITRAG": "Cotisation de membre",
      "MITGLIEDER_BEITRAG_VERBUCHT": "Cotisation de membre du {{datum}} comptabilisée"
    },
    "PENSUM_FAKTOR_ANPASSEN": "Adapter le facteur de prise en charge",
    "ZUGEWIESEN_PER": "attribué depuis {{date}}",
    "STUNDEN": "{{hours}}h",
    "NO": "non",
    "KONTO": "Compte",
    "UHRZEIT": "Heure",
    "DATERANGE": "{{von}} - {{bis}}",
    "AUSZAHLUNG_PAINFILE_GENERIEREN": "Créer un fichier pain.001",
    "AUSZAHLUNG_ZAHLUNGSZWECK": "Motif du paiement",
    "CONFIRM_RESTORE": "Restaurer définitivement ?",
    "PERIOD": "{{dateAb}}/{{dateBis}}",
    "STUNDEN_BRACKETS": "{{hours}}h"
  },
  "BASE": {
    "NAVIGATION_OEFFNEN": "Ouvrir navigation",
    "MENU_LOGOUT_USER": "Déconnexion",
    "MENU_KIND_ANMELDEN": "Enregistrer réservation enfant",
    "BENUTZER_PROFIL": "Profil"
  },
  "AUTHENTICATION": {
    "LOGIN_HEADER": "Connexion",
    "LOGIN_EMAIL": "E-Mail",
    "LOGIN_PASSWORD": "Mot de passe",
    "LOGIN_LOGIN": "Login",
    "PRIVACY_TITLE": "Veuillez accepter notre politique de confidentialité afin de pouvoir continuer.",
    "PRIVACY_SUBTITLE": "<a href=\"https://blog.kitadmin.ch/privacy\" target=\"_blank\" rel=\"noopener\">politique de confidentialité</a>",
    "PRIVACY_CONFIRM": "Accepter",
    "PRIVACY_CANCEL": "Annuler",
    "LOGIN_IDP": "Connexion externe",
    "IDP_HINT": "Keycloak IDP Hint",
    "OIDC_USER": "Connexion externe",
    "OIDC_USER_MANAGEMENT": "Keycloak administration des utilisateurs",
    "OIDC_LOGIN_FAILED": "Echec de la connexion"
  },
  "VERSION": {
    "VERSION_INFO": "Version {{frontendVersion}} / {{backendVersion}}",
    "VERSION_INFO_SINGLE": "Version {{version}}",
    "VERSION_MISMATCH": "Version {{version}} est maintenant disponible.",
    "VERSION_TOOLTIP": "Frontend / Backend",
    "DO_UPDATE": "Si vous n'exécutez pas la mise à jour, il se peut que l'application ne fonctionne plus correctement.",
    "AKTUALISIEREN": "Mise à jour"
  },
  "LANGUAGE": {
    "DE": "DE",
    "FR": "FR",
    "WIP": "Expérimental",
    "NO_TEXT_DEFINED": "Pas de texte défini"
  },
  "FAKTURA": {
    "AKTUALISIERE": "mise à jour...",
    "NAV_ZAHLUNGEN": "Paiements",
    "NAV_REVISIONEN": "Révisions",
    "NAV_KONFIGURATION": "Configuration",
    "NAV_EINZAHLUNGSSCHEIN": "Bulletin de versement",
    "EMPFAENGER_LABEL": "Destinataires :",
    "KEINE_BEMERKUNG": "Pas de commentaire",
    "HINWEIS": "Note",
    "GUELTIG_BIS_OPTIONAL": "Valable jusqu'au (facultatif)",
    "ACHTUNG": "Attention",
    "FAKTURAEINSTELLUNGEN": "Paramètres de facturation",
    "RECHNUNGSDIFFERENZ": "Différence de facturation",
    "RECHNUNGSUEBERSCHUSS": "Excédent de facture",
    "RECHNUNG_DELIVERY_FEHLER": "Erreur d'envoi de facture",
    "ES_GIBT_NEUE_LEISTUNGSRECHNUNG": "<strong>Différence de calculation des prestations:</strong> Les prestations ont changé. Veuillez créer une nouvelle facture pour mettre la facture à jour.",
    "RECHNUNG_IST_UEBERBEZAHLT": "<strong>Surpayés:</strong> La somme de tous les paiements entrants dépasse le montant total de la facture.",
    "RECHNUNGS_KONFIGURATION_DEAKTIVIERT": "<strong>Désactivé:</strong> La configuration de la facture a été désactivée. Cette facture ne contient pas d'informations de paiement actuelles.",
    "RECHNUNGS_EMAIL_FEHLGESCHLAGEN": "<strong>Échoué:</strong> La transmission du courrier électronique à {{email}} le {{time}} a échoué.",
    "CONFIRM_RECHNUNG_ZUWEISEN": "Transférer l'excédent de {{betrag | number:2}} CHF sur un autre compte ?",
    "RECHNUNGSDATUM_DATUM": "Date de facture : {{datum}}",
    "ZAHLUNGSFRIST_AENDERN": "Modifier le délai de paiement",
    "HERUNTERLADEN": "Télécharger",
    "AKTUELLE_RECHNUNG_HERUNTERLADEN": "Télécharger facture actuelle",
    "ORIGINAL_RECHNUNG_HERUNTERLADEN": "Télécharger l'original",
    "STORNIEREN": "Annuler",
    "RECHNUNGS_POSITIONEN": "Positions de facture",
    "RECHNUNG_STORNIEREN": "Annuler la facture ?",
    "RECHNUNG_STORNIEREN_LINK": "Annuler la facture",
    "RECHNUNG_STORNIEREN_BETROFFENE_RECHNUNGEN": "Les factures suivantes seront annulées ensemble :",
    "ERSETZT_RECHNUNG": "Remplace la facture :",
    "IST_ERSETZTE_RECHNUNG": "Est remplacée par la facture :",
    "FAELLIG_AM_DATUM": "Dû le {{datum}}",
    "FAELLIG_AM_DATUM_LABEL": "Dû le : {{datum}}",
    "ZAHLUNGSFRIST_DATUM_LABEL": "Délai de paiement : {{datum}}",
    "MF_ZAHLUNGSFRIST_POPOVER_TEXT": "Le délai de paiement est expiré depuis le {dateDiff, plural, one{jour} other{# de jours}}. Nous vous rappelons qu'il est possible de prolonger le délai.",
    "ZAHLUNGSFRIST_POPOVER_TITLE": "Le délai de paiement est expiré depuis le {{datum}}",
    "REVISIONEN_ANZAHL": "{{anzahl}} révisions",
    "RECHNUNGS_REVISIONEN": "Révisions",
    "REVISION_ERSTELLT_AM_DATUM": "Créée le {{datum}}",
    "REVISION_STORNIERT_AM_DATUM": "Annulée le {{datum}}",
    "STATUS_AENDERN": "Changer de statut",
    "VORAUSZAHLUNG_BETRAG_VORHANDEN": "Paiement d’avance ({{betrag}} CHF)",
    "RECHNUNGSGEBUEHREN_BEARBEITEN": "Modifier frais de facturation",
    "GEBUEHREN": "Frais",
    "GEBUEHR_LOSCHEN": "Supprimer frais",
    "GEBUEHR_HINZUFUEGEN": "Ajouter frais",
    "STATUS_VERLAUF": "Historique du statut",
    "STATUS_UPDATE": {
      "STATUS_UPDATE_RECHNUNGSLAUF": "Révision créée : {{benutzer}}",
      "STATUS_UPDATE_CUSTOM_POSITION_EDITED": "Frais de facturation traités : {{benutzer}}",
      "STATUS_UPDATE_MAHNLAUF": "Rappel : {{benutzer}}",
      "STATUS_UPDATE_ZAHLUNGS_EINGANG": "Paiement reçu : {{benutzer}}",
      "STATUS_UPDATE_ZAHLUNGS_STORNIERUNG": "Paiement annulé : {{benutzer}}",
      "STATUS_UPDATE_EXTERN": "Mise à jour par un système externe reconnue : {{benutzer}}",
      "STATUS_UPDATE_USER_UPDATE": "Mise à jour : {{benutzer}}",
      "STATUS_UPDATE_KONFIGURATION_DEAKTIVIERT": "Configuration de la facturation désactivée : {{benutzer}}"
    },
    "EXT_STATE": {
      "EXT_STATE_GESENDET": "Envoyée",
      "EXT_STATE_BEZAHLT": "Payée",
      "EXT_STATE_TEILBEZAHLT": "Partiellement payée",
      "EXT_STATE_ERINNERT": "Rappel de paiement",
      "EXT_STATE_MAHNUNG1": "1. Rappel",
      "EXT_STATE_MAHNUNG2": "2. Rappel",
      "EXT_STATE_MAHNUNG3": "3. Rappel",
      "EXT_STATE_STORNIERT": "Annulée",
      "EXT_STATE_SONSTIGES": "Autres",
      "EXT_STATE_NICHT_VERFUEGBAR": "Non disponible",
      "EXT_STATE_BETRIEBEN": "Recouvrement de créances"
    },
    "RECHNUNG_FILTER": {
      "RECHNUNG_FILTER_GESENDET": "Envoyée",
      "RECHNUNG_FILTER_BEZAHLT": "Payée",
      "RECHNUNG_FILTER_TEILBEZAHLT": "Partiellement payée",
      "RECHNUNG_FILTER_ERINNERT": "Rappel de paiement",
      "RECHNUNG_FILTER_MAHNUNG1": "1. Rappel",
      "RECHNUNG_FILTER_MAHNUNG2": "2. Rappel",
      "RECHNUNG_FILTER_MAHNUNG3": "3. Rappel",
      "RECHNUNG_FILTER_BETRIEBEN": "Recouvrement de créances",
      "RECHNUNG_FILTER_STORNIERT": "Annulée",
      "RECHNUNG_FILTER_SONSTIGES": "Autres",
      "RECHNUNG_FILTER_ABGELAUFEN": "Expirée"
    },
    "RECHNUNG_PERDIODE_NR": "{{periode}} / N°{{nummer}}",
    "RECHNUNG_SEARCH_RESULT": "Facture N°{{nummer}}",
    "AUSSTEHEND_CHF": "En attente : CHF {{chf}}",
    "REFERENZNUMMER": "Numéro de référence",
    "RECHNUNG_ANSEHEN": "Voir la facture",
    "DETAILS": "Détails",
    "BANKZAHLUNG": "Paiement bancaire",
    "SKONTO": "Rabais",
    "ABSCHREIBUNG": "Abandon de créance",
    "RUNDUNGSDIFFERENZ": "Différence d'arrondi",
    "AUSGLEICHSZAHLUNG": "Paiement compensatoire",
    "AUSGLEICH_VORAUSZAHLUNG_ANSEHEN": "Voir compensation du paiement d'avance",
    "AUSGLEICH_RUECKERSTATTUNG_ANSEHEN": "Voir compensation du remboursement",
    "ERFASSTE_ZAHLUNGEN": "Paiements enregistrés",
    "AUSSTEHENDER_BETRAG": "Montant ouvert",
    "UEBERSCHUESSIGER_BETRAG": "Montant excédentaire",
    "UEBERSCHUSS_AUSGLEICHEN": "Compenser excédent",
    "RECHNUNG_AUSGLEICHEN": "Compenser facture de CHF {{betrag}} par {{type}}",
    "RECHNUNG_AUFTEILEN": "Partage de facture de {{betrag | number:2}} CHF sur un autre compte",
    "AUSGLEICHEN": "Compenser",
    "ZAHLUNG_ERFASSEN": "Saisir paiement",
    "ZAHLUNG_TYP": "Type de paiement",
    "ZAHLUNG_STORNIEREN_CONFIRM": "Annuler le paiement définitivement ?",
    "DRUCKPOSITIONIERUNG": "Positionnement de l'impression",
    "ESR_AUSLIEFERUNGSNUMMER": "Numéro d'identification",
    "EINZAHLUNGSSCHEIN_BANK": "Bulletin de versement pour banque",
    "EINZAHLUNGSSCHEIN_PAGE": "Ne pas imprimer le bulletin de versement à la page 1",
    "EINZAHLUNGSSCHEIN_PAGE_CONFIRM": "Le bulletin de versement n'est pas imprimé à la page 1",
    "NEW_EINZAHLUNGSSCHEIN": "Nouveau bulletin de versement",
    "BANK_NAME": "Nom de la banque",
    "BANK_ADRESSZEILE": "Adresse",
    "VORSCHAU_ANZEIGEN": "Voir aperçu",
    "NUR_DEFAULTS": "Les valeurs par défaut sont appliquées",
    "SEITENRAENDER": "Marges",
    "LOGO": "Logo",
    "EMPFAENGERADRESSE": "Adresse du destinataire",
    "BREITE": "Largeur",
    "ABSTAND_OBEN": "Distance au-dessus",
    "ABSTAND_UNTEN": "Distance ci-dessous",
    "ABSTAND_LINKS": "Distance à gauche",
    "ABSTAND_RECHTS": "Distance à droite",
    "ABSTAND_OBEN_MM": "Distance au-dessus (mm)",
    "ABSTAND_LINKS_MM": "Distance à gauche (mm)",
    "MM_VALUE": "{{value}} mm",
    "KEINE_ZAHLUNGEN_VORHANDEN": "Aucun paiement",
    "KEINE_EINZAHLUNGSSCHEINE": "Aucun bulletin de versement",
    "KEINE_VORSCHLAEGE": "Aucune suggestion disponible",
    "KONTENABGLEICH": {
      "KONTENABGLEICH": "Alignement des comptes",
      "NAV_TRANSAKTIONEN": "Transactions",
      "NAV_KONTOAUSZUEGE": "Relevés de compte",
      "TRANSAKTIONEN": "Transactions",
      "TRANSAKTION_STATUS": "État",
      "RECHNUNGS_STATUS": "État",
      "STATUS_ZUGEWIESEN": "Assigné",
      "STATUS_NICHT_ZUGEWIESEN": "Non assigné",
      "STATUS_IGNORIERT": "Ignoré",
      "DATEIEN_HOCHLADEN": "Télécharger des fichiers",
      "TRANSAKTION_ZUWEISEN": "Assigner",
      "ZUGEWIESEN": "Assigné",
      "NICHT_ZUGEWIESEN": "Non assigné",
      "IGNORIERT": "Ignoré",
      "ZUWEISUNG_LOESCHEN": "Supprimer l'assignation",
      "TRANSAKTION_LOESCHEN_IGNORIEREN": "Supprimer l'assignation et ignorer",
      "NICHT_IGNORIEREN": "Ne pas ignorer",
      "EINGELESEN_AM": "Importé le",
      "EMPFAENGER": "Destinataire",
      "AUTOMATISCH_ZUGEWIESEN_DURCH": "Assigné automatiquement par {{benutzer}}",
      "MANUELL_ZUGEWIESEN_DURCH": "Assigné manuellement par {{benutzer}}",
      "KONTOAUSZUG_ERFOLGREICH_HOCHGELADEN": "Alignement des comptes importé avec succès",
      "KONTOAUSZUG_IMPORT_DETAILS": "{{totalEntries}} Relevés de compte ont été importés. {{successfulAssigned}} assignés automatiquement. {{duplicate}} doublons, {{notAssigned}} non assignés et {{ignored}} ignorés.",
      "BANK_STATEMENT_AUTOMATISCH_ZUGEWIESEN": "Assigné automatiquement",
      "BANK_STATEMENT_NICHT_ZUGEWIESEN": "Non assigné",
      "BANK_STATEMENT_DUPLIKATE": "Doublons",
      "BANK_STATEMENT_IGNORIERT": "Ignoré",
      "BANK_STATEMENT_TOTAL": "Total",
      "BANK_STATEMENT_SUMME": "Somme",
      "NICHT_ZUGEWIESENE_ZUWEISEN": "Assigner les non assignés",
      "MF_BANK_STATEMENT_DUPLIKATE_ERKLAERUNG": "{duplicateCount, plural, one{Un double a} other{# Doubles ont}} été téléchargé. Si {duplicateCount, plural, one{le paiement entrant a} other{les paiements entrants ont}} réellement été reçu plusieurs fois le même jour, avec le même montant et le même numéro de référence, vous pouvez le confirmer manuellement ici.",
      "MARKIERTE_DUPLIKATE_IMPORTIEREN": "Importer les doublons sélectionnés",
      "TRANSAKTIONEN_ANSEHEN": "Voir transactions",
      "TRANSAKTIONEN_LOESCHEN": "Ficher et paiements",
      "HERUNTERLADEN": "Télécharger",
      "KONTOAUSZUG": "Relevé de compte",
      "ZEITRAUM": "Période",
      "EINLESEDATUM": "Date d'importation",
      "DATEINAME": "Nom de fichier",
      "KONTOAUSZUG_VOM": "Relevé de compte du {{datum}} ({{anzahlTransaktionen}})",
      "VORGESCHLAGEN": "Proposé",
      "MATCH_BY_REFERENZNUMMER": "Numéro de référence identique",
      "MATCH_BY_KONTAKTPERSON": "Factures ouvertes de {{name}}",
      "TRANSAKTION_DIREKT_ZUWEISEN": "Assigner transaction directement",
      "CONFIRM_TRANSAKTION_ZUWEISEN": "Assigner définitivement transaction à cette facture ?",
      "TRANSAKTION_ZUWEISEN_WARNING": "Cette transaction dépasse le montant de la facture ouverte. La facture est surpayée de CHF {{betrag}}.",
      "CONFIRM_TRANSAKTION_IGNORIEREN": "Ignorer transaction ?",
      "CONFIRM_TRANSAKTION_NICHT_IGNORIEREN": "Ne pas ignorer la transaction ?",
      "TRANSAKTION_ZUWEISUNG_LOESCHEN": "Supprimer l'assignement de la transaction",
      "TRANSAKTIONEN_TOTAL_BETRAG_MF": "Montant total {count, plural, one{de la transaction} other{de # transactions}}",
      "NAME_NOT_FOUND": "(Nom non disponible)",
      "KONTOAUSZUEGE_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{extrait de compte} other{extraits de compte}}",
      "TRANSAKTIONEN_REFRESH": "Actualiser les transactions",
      "TRANSAKTIONEN_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Transaction} other{Transactions}}",
      "TRANSAKTIONEN_AUTO_ASSIGN_MF": "{count, plural, =0{Aucune transaction n'a pu être attribuée} one{La transaction suivante a été attribuée automatiquement} other{Les # transactions suivantes ont été attribuées automatiquement}}",
      "TRANSAKTIONEN_AUTOMATISCH_ZUWEISEN": "Assigné automatiquement"
    },
    "NEGATIVES_RECHNUNGSTOTAL": "Le total des factures est négatif.",
    "ABWEICHUNG_VORMONAT": "Modification du montant trouvée par rapport au mois précédent",
    "ABWEICHUNG_REVISIONS_TOTAL": "Modification du montant trouvée par rapport à la dernière révision des comptes",
    "ABWEICHUNG_VORMONAT_REVISIONS_TOTAL": "Modification du montant trouvée par rapport au mois précédent\nModification du montant par rapport à la dernière révision de la facture trouvée",
    "VALUTA_DATE": "Date de valeur",
    "TASK_VERLAUF": "Historique des tâches",
    "KONTO": {
      "RUECKZAHLUNGSKONTO": "Compte de remboursement",
      "KEIN_KONTO": "Pas de compte",
      "MANDANT_DEFAULT": "Mandant standard",
      "KONTEN": "Comptes",
      "KEINE_KONTEN": "Aucun compte",
      "NEU": "Nouveau compte",
      "BEZEICHNUNG": "Désignation",
      "IBAN": "IBAN",
      "BIC": "BIC",
      "KONTOINHABER": "Titulaire du compte"
    },
    "NAV_KONTO": "Compte",
    "RECHNUNGEN_STORNIEREN": "Annuler les factures ?",
    "RECHNUNGEN_STORNIEREN_LINK": "Annuler les factures",
    "RECHNUNGS_REVISION_DELIVERY_FAILURE_TASK_LOESCHEN": "Tâche d'envoi des factures",
    "KEINE_GEBUEHREN": "Pas de frais ouverts",
    "QR-IBAN_KONTONUMMER": "QR-IBAN ou numéro de compte",
    "MASSENAKTIONEN": {
      "TITLE": "Actions de masse",
      "EMAILS_VERSENDEN": "Envoyer les factures par e-mail",
      "ERROR_RECH_NO": "N° de facture : {{rechnr}}",
      "ERROR_MISSING_EMAIL": "Aucune adresse e-mail enregistrée pour les personnes suivantes :",
      "ERROR_EMAIL_FAILED": "L'envoi a échoué en raison d'un problème technique pour les factures suivantes :",
      "ERROR_EMAIL_SUCCESS_MF": "{count, plural, one{1 facture a pu} other{# factures ont pu}} être envoyées.",
      "STORNIEREN": "Annuler les factures",
      "ERROR_TITLE": "Les erreurs suivantes sont apparues. Pou les autres factures, l'action a été effectuée avec succès."
    }
  },
  "ADMINISTRATION": {
    "MAINTENANCE": "Travaux de maintenance",
    "APP_CONFIG": "Configuration",
    "NAV_MANDANTEN_ABRECHNUNG": "Facturation",
    "NAV_MANDANTEN_UEBERSICHT": "Mandants",
    "NAV_MANDANTEN_MAINTENANCE": "Travaux de maintenance",
    "MTNC_ACTIONS": "Actions",
    "MTNC_BATCH_JOB_INFORMATION": "Obtenir des informations sur les travaux par lots",
    "MTNC_PURGE_BATCH_JOBS": " Supprimer les lots de travaux",
    "MTNC_LEISTUNGSRECHNUNG_STARTEN": "Démarrer calcul des prestations",
    "MTNC_DELETE_SCHULUNGSMANDANTEN": "Supprimer des clients de formation",
    "MTNC_DVB_DEMO_DATA_INIT": "Regénérer les données demo DV Bern",
    "MTNC_REINDEX": "Regénérer l'index de recherche",
    "MTNC_CLEAR_CONTROLLING_DATA": "Supprimer des informations de controlling",
    "MTNC_RECALCULATE_CURRENT_CONTROLLING_DATA": "Regénérer des dates de controlling de l'année actuelle",
    "MTNC_CONTROLLING_RECALCULATION": "Regénérer des informations de controlling",
    "MTNC_CONTROLLING_RECALCULATION_CALC_ASYNC": "Calculer asynchrone",
    "MTNC_CONTROLLING_RECALCULATION_CALC_SYNC": "Charger synchrone",
    "MTNC_CONTROLLING_RECALCULATION_DEL": "supprimer",
    "MTNC_CLEAR_AUTH_CACHE": "Supprimer Auth Cache",
    "MTNC_CREATE_RECHUNUNGS_UEBERSCHUSS_TASK": "Créer des tâches de surplus de facture",
    "MTNC_MATCH_KIBON_EXCHANGE_DATA": "Démarrer l'assignement de kiBin Exchange",
    "MANDANTEN": "Mandants",
    "FILTER_BY_NAME": "Filtrer par nom",
    "MONATLICHES_EINKOMMEN": "Revenu mensuel",
    "VERRECHENBARE_KINDERORTE": "Institutions facturables",
    "ACTIVE_TAGES_ELTERN": "Parents d'accueil actifs",
    "KEINE_RECHNUNGEN_VORHANDEN": "Aucune facture",
    "KEINE_RECHNUNGS_POSITIONEN": "Aucune position de la facture",
    "VALUE_X": "{{count}}x",
    "CUSTOM_RECHNUNG_POSITION": "Correction",
    "CUSTOM_RECHNUNG_POSITION_LOESCHEN": "Supprimer la correction",
    "CUSTOM_RECHNUNG_POSITION_HINZUFUEGEN": "Ajouter une correction",
    "ERR_INVALID_CUSTOM_POSITION": "Terminer complètement la correction (position, nombre, montant).",
    "ERR_INVALID_MANDANT_RECHNUNG": "Facture du client non trouvée",
    "EMAIL_TEST": "Test E-Mail",
    "EMAIL_EMPFAENGER": "Destinataire",
    "EMAIL_BETREFF": "Objet",
    "EMAIL_OPTIONALE_RECHNUNG": "Facture optionnelle",
    "EMAIL_SENDEN": "Envoyer par e-mail",
    "EMAIL_ERROR": "E-mail n'a pas pu être envoyé à {{to}}.",
    "VERRECHENBAR_KINDERORT": {
      "SINGULAR": "Institution facturable",
      "PLURAL": "Institutions facturables",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{institution facturable} other{institutions facturables}}"
    },
    "NICHT_VERRECHENBAR_KINDERORT": {
      "SINGULAR": "Institution non facturable",
      "PLURAL": "Institutions non facturables",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{institution non facturable} other{institutions non facturables}}"
    },
    "ACTIVE_TOTAL": "{{active}} / {{total}}",
    "PIPEDRIVE_NAME": "Ouvrir Pipedrive",
    "TIMEOUT_TESTING": {
      "TITLE": "Testing Timeout",
      "PROMISE": "Promise",
      "SEKUNDEN": "Secondes",
      "SERVER_REQUEST_DURATION": "Durée Server-Request en secondes",
      "CLIENT_REQUEST_TIMEOUT": "Client-Request-Timeout en secondes",
      "REQUEST_START": "Démarrer request",
      "REQUEST_ABORT": "Annuler request"
    },
    "MTNC_DELETE_RECHUNGS_POSITIONEN": "Supprimer postes de facture du mandant",
    "MTNC_SHIRO_TESTING_BASICS": "Shiro Basics",
    "MTNC_CLEAN_UP_BLOB_DATA": "Supprimer les BlobData obsolètes",
    "MANDANT_FEATURES": {
      "TYPES": {
        "KIBON_MELDUNGEN": "Envoyer les données à kiBon",
        "CSV_EXPORT_RECHNUNGEN_HIRSLANDEN": "Exportation CSV Hirslanden dans la facture ZIP",
        "CSV_EXPORT_RECHNUNGEN_KSA": "Exportation CSV KSA dans le fichier ZIP des factures",
        "XML_EXPORT_RECHNUNGEN_KIMI": "Exportation XML de factures Kimi",
        "XML_EXPORT_MONATSBLATT_KIBEZ": "Exportation de la feuille mensuelle des parents de jour Kibez XML",
        "XML_EXPORT_MONATSBLATT_UNTERE_EMME": "Exportation XML de la feuille mensuelle des parents de jour de la Basse Emme",
        "VERTRAGLICHES_PENSUM": "Taux d'occupation contractuel sur occupation",
        "MONATSBLATT_API_KIBE_PLUS": "Feuille mensuelle API Kibe Plus",
        "MONATSBLATT_API_NANNY_KIBE_PLUS": "Feuille mensuelle API pour les Nannies Kibe Plus",
        "ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA": "Présence mensuelle KSA Zobra",
        "EXTERNAL_ANMELDUNG": "Inscription enfants externes",
        "DAUERAUFTRAG_ZAHLUNGSZUWEISUNG": "Ordre permanent attribution de paiement",
        "RECHNUNG_REVISION_RECHNUNGSDIFFERENZ": "Mettre en évidence les différences entre les révisions de factures et la version précédente",
        "CONTROLLING_REPORT_MONTHLY": "Rapport mensuel de Controlling",
        "AUTOMATIC_ABWEICHUNGSMELDUNG_CONVERSION": "Création automatique d'écarts à partir de messages d'écart",
        "CSV_EXPORT_MONATSBLATT_LEOLEA": "Exportation Leolea CSV de la feuille mensuelle des parents d'accueil",
        "STUNDENERFASSUNG_TFO": "Saisie des heures pour les parents de jour",
        "VORMONAT_RECHNUNGSDIFFERENZ": "Détecter et afficher les différences dans le montant de la facture par rapport au mois précédent",
        "ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS": "Présence annuelle requise KSA Zwärglihuus"
      },
      "ENABLE_FOR_ALL": "Activer pour tous les lieux d'accueil",
      "ENABLED_FOR_SELECTED": "Activé pour les lieux d'accueil sélectionnés",
      "AVAILABLE": "Fonctionnalités pas encore attribuées :",
      "ENABLED_FOR_MF": "{forAll, select, true{Activé pour tous les lieux d'accueil} other{ {count, plural, one{Activé pour un lieu d'accueil} other{Activé pour {count} lieux d'accueil :}} }}"
    },
    "NAV_FEATURES": "Caractéristiques",
    "NAV_USERS": "Utilisateurs actifs",
    "NAV_NOTIFICATION": "Notifications",
    "MTNC_ARCHIVE_KINDER": "Archives enfants",
    "MTNC_UNARCHIVE_KINDER": "Désarchiver enfants",
    "MTNC_SHIRO_TESTING_SUPER_ADMIN_BLOCKING": "Shiro Super Admin (blocking)",
    "MTNC_SHIRO_TESTING_SUPER_ADMIN_NON_BLOCKING": "Shiro Super Admin (non-blocking)",
    "MTNC_RECALCULATE_MANDANT_RECHNUNG": "Recalculer la facture du mandant",
    "MTNC_RECALCULATE_ASYNC": "Recalculer asynchrone",
    "MTNC_MIGRATE_KONTAKT_IBAN": "Personne de contact migration IBAN",
    "MTNC_MIGRATE": "Migrer",
    "CORONA_KINDER_ORT_FEES": "Ordonnance d'urgence Corona",
    "CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN": "Adapter les corrections",
    "USERS": {
      "REFRESH": "Actualiser"
    },
    "MTNC_MIGRATE_KONTAKT_IBAN_CUSTOM_FIELD": "Champ supplémentaire Nom"
  },
  "KINDERORT": {
    "STAMMDATEN": "Données de base",
    "STRUKTUR_TITLE": "Structure institutionnelle",
    "STRUKTUR": {
      "KITA": "Institution avec groupes",
      "TAGES_ELTERN": "Institution avec familles d'accueil"
    },
    "DEFAULT_TITLE": {
      "KITA": "Crèche",
      "TAGES_ELTERN": "Famille d'accueil"
    },
    "SUBVENTIONIERTE_KAPAZITAET": {
      "KITA": {
        "NAME": "Places subventionnées",
        "ANZAHL": "Nombre de places subventionnées",
        "WITH_VALUE": "{{anzahl}} places subventionnées",
        "BEENDEN": "Terminer places subventionnées",
        "BEENDEN_DIALOG_HEADING": "Places subventionnées pour {{kitaname}} sont terminées."
      },
      "TAGES_ELTERN": {
        "NAME": "Heures subventionnées",
        "ANZAHL": "Nombre d'heures subventionnées",
        "WITH_VALUE": "{{anzahl}} heures subventionnées",
        "BEENDEN": "Terminer heures subventionnées",
        "BEENDEN_DIALOG_HEADING": "Heures subventionnées pour {{kitaname}} sont terminées."
      }
    },
    "NAV_BELEGUNG": "Occupation",
    "NAV_WARTELISTE": "Liste d'attente",
    "NAV_TARIFE": "Tarifs",
    "NAV_OFFENE_POSTEN_ANZEIGEN": "Afficher tous les postes",
    "NAV_RECHNUNGSKONFIGURATION": "Configuration facturation",
    "NAV_VEREINBARUNGSKONFIGURATION": "Configuration accord de prise en charge",
    "FILTER_GRUPPEN": "Groupes",
    "FILTER_PLAETZE": "Contingents",
    "FILTER_STATUS_FRIST": "Date limite",
    "FILTER_PRIVATE_PLAETZE": "Privée",
    "FILTER_SUBVENT_PLAETZE": "Subventionnée",
    "TITLE_FREIE_KAPAZITAET": "Places libres",
    "TITLE_BELEGTE_PLAETZE": "Places occupées",
    "PRIORITAET_SHORT": "Prio {{prioritaet}}",
    "KITA_TITLE_TARIF_PRIVAT": "Tarif privé :",
    "KITA_TITLE_TARIF_SUBVENTIONIERT": "Tarif subventionné :",
    "KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN": "Tarif bon de garde :",
    "KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG": "Tarif non dépendant de la prise en charge :",
    "KITA_TITLE_TARIF_PRIVAT_AUSWAEHLEN": "Sélectionner tarif privé",
    "KITA_TITLE_TARIF_SUBVENTIONIERT_AUSWAEHLEN": "Sélectionner tarif subventionné",
    "KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN_AUSWAEHLEN": "Sélectionner tarif bon de garde",
    "KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG_AUSWAEHLEN": "Sélectionner tarif non dépendant de la prise en charge",
    "NEUE_TARIF_ZUWEISUNG_SPEICHERN": "Assigner nouveaux tarifs",
    "NEUE_TARIF_ZUWEISUNG": "Nouvelle assignation tarif",
    "TARIF_ZUWEISUNG_BEENDEN": "Terminer assignation tarif",
    "PROFIL_ANSEHEN": "Voir profil",
    "PLAETZE": "Places",
    "ZEITRAUMFELD_TITLE_BELEGT": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}\nNombre de places occupées {{belegtePlaetze}}\nNombre d'enfants {{kinder}}",
    "ZEITRAUMFELD_TITLE_CAPACITY": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}",
    "ZEITRAUMFELD_TITLE_BELEGT_LIMITED": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}\nNombre de places occupées {{belegtePlaetze}}\nNombre d'enfants {{kinder}}\nDisponible jusqu'au {{verfuegbarBis}}",
    "ZEITRAUMFELD_TITLE_FREI": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}\nNombre de places libres {{freiePlaetze}}\nNombre d'enfants {{enfants}}",
    "ZEITRAUMFELD_TITLE_FREI_CAPACITY": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}",
    "ZEITRAUMFELD_TITLE_FREI_LIMITED": "Places max {{maxPlaetze}}\nNombre de places {{plaetze}}\nNombre de places libres {{freiePlaetze | roundTo:2}}\nNombre d'enfants {{kinder}}\nDisponible jusqu'au {{verfuegbarBis}}",
    "ZEITRAUMFELD_MISSING_KAPAZITET": "Cette période ne peut être réservée : aucune place n'a été définie dans le profil du groupe pour cette date.",
    "MEHR_ANZEIGEN": "Afficher plus...",
    "WENIGER_ANZEIGEN": "Afficher moins...",
    "TOTAL_KINDER_MF": "Total {anzahl} enfants ({summenProzent}{vertraglichProzent, plural, =0{} other{, #% contractuel}})",
    "EROEFFNUNGSDATUM": "Date d'ouverture",
    "GUELTIGKEIT": "Validité",
    "BEWILLIGTE_PLAETZE": {
      "KITA": "Places approuvées",
      "TAGES_ELTERN": "Validité"
    },
    "BEWILLIGTE_PLAETZE_WITH_VALUE": "{{anzahl}} places approuvées",
    "MAXIMALE_PLAETZE": "Maximum de places",
    "MAXIMALE_PLAETZE_WITH_VALUE": "{{maximalePlaetze}} places au maximum",
    "PLAETZE_WITH_VALUE": "{{plaetze}} places",
    "BELEGTE_PLAETZE_WITH_VALUE": "{{belegtePlaetze}} places occupées",
    "AUSLASTUNG_WITH_VALUE": "{{auslastung}}% d'occupation",
    "AUSLASTUNG_SUBVENTIONIERTE_PLAETZE_WITH_VALUE": "{{auslastung}}% places subventionnées",
    "ANZAHL_BEWILLIGTE_PLAETZE": "Nombre de places approuvées",
    "KITA_DELETE": "Supprimer Institution",
    "KITA_GUELTIGKEIT_INVALID": "L'institution ne peut être désignée en dehors de sa validité ({{gueltigAb}} - {{gueltigBis}})",
    "KONTINGENTE": "Contingents {{kontingente}}",
    "ARCHIVIERTE_FIRMENKONTINGENTE_ANZEIGEN": "Afficher contingents d'entreprise archivés",
    "ARCHIVIERTE_FIRMENKONTINGENTE_AUSBLENDEN": "Cacher contingents d'entreprise archivés",
    "CONFIRM_KAPAZITAETEN_BEENDEN": "Il existe encore des capacités après la date de fin. Vraiment terminer ?",
    "KITA_BEENDEN_HEADING": "{{kitaname}} est terminée.",
    "KITA_BEENDEN": "Terminer institution",
    "DURCHSCHNITT_WITH_VALUE": "Moyenne {{jahr}}",
    "SHORT_KALENDERWOCHE": "S",
    "SHORT_KALENDERWOCHE_WITH_VALUE": "S {{kalenderwoche}}",
    "VERFUEGBAR_AB": "Disponible dès",
    "ALTER": "Âge (en années)",
    "TARIFE_AUSWAEHLEN": "Sélectionner tarifs",
    "TARIFE_ZUGEWIESEN": "Tarifs assignés",
    "KITA_TARIF_AUSWAEHLEN": "Sélectionner tarifs {{tariftyp}}",
    "KITA_TARIFZUWEISUNG": "Assignation tarifs",
    "TARIF_ZUWEISUNG_BEENDEN_HEADING": "Terminer assignation tarifs pour {{kitaname}} ?",
    "CONFIRM_TARIF_ZUWEISUNG_BEENDEN": "Il y a encore des tarifs assignés après la date de fin. Vraiment terminer ?",
    "KITA_KONFIGURATION_NAME": "{{name}} ({{type}}) {{deactivated ? '[Désactivé]' : ''}}",
    "KITA_RECHNUNGS_KONFIGURATION": "Configuration de la facture de l'institution",
    "ZAHLUNGSKONTO": "Compte de paiement",
    "EINZAHLUNGSSCHEINE": "Bulletins de versement",
    "KEIN_KONTO": "Aucun compte",
    "KEIN_EINZAHLUNGSSCHEIN": "Aucun bulletin de versement",
    "KEIN_LAYOUT": "Aucune mise en page",
    "LAYOUT": "Mise en page",
    "ZUERST_MANDANT_KONFIGURATION_ERSTELLEN": "D'abord créer une configuration client",
    "EINZAHLUNGSSCHEINE_AKTUALISIEREN": "Mise à jour des bulletins de versement",
    "ZAHLUNGSKONTO_AKTUALISIEREN": "Mise à jour de la liste de paiement",
    "LAYOUTS_AKTUALISIEREN": "Mise à jour des mises en page",
    "RECHNUNGSLAEUFE": "Facturation",
    "RECHNUNGSLAUF_ERSTELLEN": "Créer facturation",
    "MAHNLAUF": "Rappel",
    "MAHNLAUF_STATUS_ERSTELLEN": "Créer {{status}}",
    "MAHNLAUF_ERSTELLEN": "Créer rappel",
    "KEINE_RECHNUNGEN_AUSGEWAEHLT": "Aucune facture sélectionnée",
    "RECHNUNGSLAUF_STARTEN": "Démarrer facturation",
    "RECHNUNGSLAUF_BEENDEN": "Confirmer les factures",
    "RECHNUNGSLAUF_SELECT_ALL_VERRECHENBAR": "Sélectionner tous les facturables",
    "RECHNUNGSLAUF_DESELECT_ALL": "Tout désélectionner",
    "RECHNUNGSLAUF_PERIODE": "Facturation période {{periode}}",
    "RECHNUNGSLAUF_PDF_ERSTELLEN": "Télécharger factures",
    "MAHNGEBUEHR": "Frais de rappel",
    "MAHNUNGEN_HERUNTERLADEN": "Télécharger rappels",
    "ERSTELLEN_SIE_EINE_KITA_RECHNUNGSKONFIGURATION": "Pour lancer la facturation, vous devez d'abord créer une configuration de facturation valide pour l'institution.",
    "RECHNUNG_NICHT_VERRECHENBAR_TOOLTIP": "La facture ne peut pas être établie parce que...",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISEN": "Assigner facteur de prise en charge",
    "NEUE_KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG": "Assigner nouveau facteur de prise en charge",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN": "Terminer assignation",
    "ZUGEWIESENE_KITA_BETREUUNGSFAKTOR_REGELN": "Facteur de prise en charge assigné",
    "KITA_BETREUUNGSFAKTOR_REGEL": "Assignation du facteur de prise en charge",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN_HEADING": "Terminer l'assignation du facteur de prise en charge de {{kitaname}} ?",
    "CONFIRM_KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN": "Il existe encore des facteurs de prise en charge attribués après la date de fin. Vraiment terminer ?",
    "BETREUUNGSFAKTOR_REGEL_AUSWAEHLEN": "Sélectionner facteur de prise en charge",
    "TITLE_BETREUUNGSFAKTOR_REGEL": "Facteur de prise en charge {{regelname}} ",
    "MF_RECHNUNGEN_TOTAL_BETRAG": "Montant total {count, plural, one{facture} other{des # factures}}",
    "FAKE_KONTOAUSZUG": "Télécharger relevé de compte Fake",
    "FAKE_ESR3_KONTOAUSZUG": "ESR3",
    "FAKE_ESR4_KONTOAUSZUG": "ESR4",
    "KEINE_ESR_RECHNUNGEN": "Parmi ces factures, il n'y a pas de factures impayées avec des bulletins de versement DVB.",
    "FAKTURA_STATUS": {
      "FAKTURIERT": "facturé",
      "TEILWEISE_FAKTURIERT": "partiellement facturé",
      "NICHT_FAKTURIERT": "pas facturé",
      "KEINE_LEISTUNGEN": "Pas de prestation"
    },
    "MASSENVERSAND": "Envoi en masse",
    "TEXT_MASSENVERSAND": "Texte d’envoi en masse",
    "ABSTAND_OBEN_SHORT": "Distance au-dessus",
    "ABSTAND_OBEN_ZAHL_MM": "{{hoehe}} mm",
    "ABSTAND_LINKS_SHORT": "Distance à gauche",
    "ABSTAND_LINKS_ZAHL_MM": "{{breite}} mm",
    "PP_FRANKIERUNG_HINZUFUEGEN": "Ajouter affranchissement PP",
    "ZAHLUNGS_ANGABEN": "Informations de paiement",
    "DEFINIERTE_TEXTE": "Textes définis",
    "NEUE_TEXTE": "Nouveaux textes",
    "EINFUEHRUNG": "Introduction",
    "KONDITIONEN": "Conditions",
    "IS_GRUPPE_VERSTECKEN": "Cacher nom du groupe",
    "IS_PLATZART_VERSTECKEN": "Cacher type de place",
    "IS_PENSUM_VERSTECKEN": "Cacher nombre d'heures / heures contractuelles",
    "IS_FAKTOR_VERSTECKEN": "Cacher le facteur de prise en charge",
    "SHOW_MODULE_NAMES": "Afficher les noms de module",
    "UNTERSCHRIFT_TITEL": "Signatures",
    "HAS_UNTERSCHRIFT1": "Activer signature 1",
    "UNTERSCHRIFT1": "Signature 1",
    "HAS_UNTERSCHRIFT2": "Activer signature 2",
    "UNTERSCHRIFT2": "Signature 2",
    "RECHNUNG_STATUS_TEXT": {
      "RECHNUNG_STATUS_TEXT_STANDARD": "Par défaut",
      "RECHNUNG_STATUS_TEXT_ZAHLUNGSERINNERUNG": "Rappel",
      "RECHNUNG_STATUS_TEXT_MAHNUNG1": "1. Rappel",
      "RECHNUNG_STATUS_TEXT_MAHNUNG2": "2. Rappel",
      "RECHNUNG_STATUS_TEXT_MAHNUNG3": "3. Rappel",
      "RECHNUNG_STATUS_TEXT_BETREIBUNG": "Recouvrement de créances"
    },
    "HEADER_KONFIGURATION": "Informations d'en-tête",
    "KONFIGURATION_EDIT_WARNUNG": "Seules les nouvelles factures seront affectées par la modification de la configuration de facture.",
    "KONFIGURATION_EDIT_WARNUNG_COUNT": "{{anzahl}} factures ont déjà été créées avec cette configuration.",
    "KONFIGURATION_EDIT_EXCLUDED_RECHNUNGEN": "Il existe {{anzahl}} factures qui n'auront désormais plus de configuration de facture.<br>La configuration précédente est conservée pour ces factures.",
    "KONFIGURATION_BCC_AKTIV": "CCI actif",
    "KONFIGURATION_BCC_AKTIVIEREN": "Activer CCI",
    "KONFIGURATION_SUMMARY_AUSBLENDEN": "Cacher le résumé",
    "KONFIGURATION_SUMMARY_AUSGEBLENDET": "Le résumé est caché",
    "KONFIGURATION_KUNDENNR_AUSBLENDEN": "Cacher le numéro de client",
    "KONFIGURATION_KUNDENNR_AUSGEBLENDET": "Le numéro de client est caché",
    "RECHNUNG_RUNDUNGSWERT": "Étape d'arrondi du total de la facture",
    "RECHNUNG_RUNDUNGSWERT_TOOLTIP": "Arrondi du montant, par exemple 0,05 arrondi 73,04 CHF à 73,05 CHF",
    "DEFINIERTE_TEXTE_ERKLAERUNG": "Vous pouvez définir l'introduction et les conditions pour chaque statut de facture. Le texte standard est copié si rien n'est défini pour un statut.",
    "KITA_SALDO": "Bilan de l'institution",
    "AKTUELLE_AENDERUNGEN": "Changements actuels",
    "CONTROLLING_CHANGE_EINTRITT": "Entrée de {{kind}}",
    "CONTROLLING_CHANGE_EINTRITT_ANGEBOT": "Offre d'entrée de {{kind}}",
    "CONTROLLING_CHANGE_EINTRITT_PROVISORISCH": "L'entrée provisoire de {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE": "Nouvelle occupation de {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE_ANGEBOT": "Nouvelle offre d'occupation de {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE_PROVISORISCH": "Occupation provisoire de {{kind}}",
    "CONTROLLING_CHANGE_BETREUUNGSFAKTOR_CHANGE": "{{kind}} obtient un nouveau facteur de prise en charge ({{args}})",
    "CONTROLLING_CHANGE_AUSTRITT": "Sortie de {{kind}}",
    "CONTROLLING_CHANGE_AUSTRITT_PROVISORISCH": "Sortie provisoire de {{kind}}",
    "KEINE_AENDERUNGEN_VORHANDEN": "Aucun changement",
    "KEINE_KITA_RECHNUNGS_KONFIGURATION": "Aucune configuration des factures de l'institution",
    "KEINE_NICHT_VERRECHNETEN_RECHNUNGEN_VORHANDEN": "Aucune facture non facturée n'est disponible",
    "KEINE_TARIFE_ZUGEWIESEN": "Aucun tarif assigné",
    "KEINE_ZUGEWIESENE_KITA_BETREUUNGSFAKTOR_REGELN": "Aucun facteur de prise en charge assigné",
    "KITA_TITLE_TARIF_PROVISORISCHER_BG": "Tarif provisoire du BG :",
    "ARCHIVIERTE_KONTINGENTE_AUSBLENDEN": "Masquer les contingents archivés",
    "ARCHIVIERTE_KONTINGENTE_ANZEIGEN": "Afficher les contingents archivés",
    "UNTERSCHRIFT_TITEL_VERSTECKEN": "Le titre \"Signatures\" doit-il être affiché ?",
    "IS_BETREUUNG_BEI_FAMILIE": "Prise en charge par la famille",
    "SCHLIESSTAGE": {
      "KEINE_ZUWEISUNG": "Pas de jours de fermeture attribués",
      "AUSWAEHLEN": "Choisir les jours de fermeture",
      "NEUE_ZUWEISUNG": "Attribuer de nouveaux jours de fermeture",
      "ZUGEWIESENE": "Jours de fermeture attribués",
      "ZUWEISEN": "Attribuer des jours de fermeture",
      "ZUWEISUNG_BEENDEN": "Terminer assignation",
      "CONFIRM_BEENDEN": "Il y a encore des jours de fermeture attribués après la date de fin. Vraiment terminer ?",
      "ZUWEISUNG": "Attribution des jours de fermeture",
      "ZUWEISUNG_BEENDEN_HEADING": "Terminer l'attribution des jours de fermeture pour {{kinderOrtName}} ?"
    },
    "ACCOUNTING_ID": "ID comptable",
    "NAV_KIBON_MUTATIONSLAUF": "Annonces kiBon de prise en charge",
    "NAV_PERSONAL_KONFIGURATION": "Configuration du personnel",
    "NAV_LOHN": "Salaire",
    "NAV_EXTERNAL_ANMELDUNGEN": "Inscriptions externes",
    "KITA_TITLE_TARIF_PROVISORISCHER_BG_AUSWAEHLEN": "Sélectionner le tarif provisoire du BG",
    "RECHNUNG_WITH_DIFF_PER_EMAIL_SENDEN": "uniquement avec modification du montant",
    "RECHNUNGSLAUF_ALLE_PDF_ERSTELLEN": "toutes ",
    "RECHNUNGSLAUF_PDF_MIT_DIFF_ERSTELLEN": "uniquement avec modification du montant",
    "RECHNUNGSLAUF_SEND_ALL_EMAILS": "toutes",
    "MAHNGEBUEHR_LOCKED": "Les frais de rappel pour les factures antérieures à {{date}} sont bloqués.",
    "IS_BELEGUNG_ZEITEN_VERSTECKEN": "Cacher les heures d'occupation",
    "IS_KINDERORT_NAME_VERSTECKEN": "Cacher le nom du lieu d'accueil",
    "IS_UNTERSCHRIFT_TITLE_VERSTECKEN": "Cacher le titre",
    "PENSUM_DISPLAY_TYPE": {
      "TITLE": "Taux d'occupation en",
      "PCT": "Pourcentage",
      "TIME": "Temps"
    },
    "TARIF_POS_KONFIGURATION": "Afficher groupe / personne de prise en charge",
    "NAV_DIENST_ADMINISTRATION": "Administration des services",
    "KONFIGURATION_TARIF_POS_FRAKTION_SUFFIX_AUSGEBLENDET": "Nom du groupe / personne de prise en charge masqué"
  },
  "DATA-INTEGRITY": {
    "NAV_MALFORMED_PHONE_NUMBERS": "Numéros de téléphone",
    "NAV_MISSING_GESCHLECHT_OR_SPRACHE": "Genre & langue",
    "NO_MISSING_GESCHLECHT_OR_SPRACHE": "Aucun genre ou langue manquant",
    "DATA_INTEGRITY": "Intégrité des données",
    "NO_MALFORMED_PHONE_NUMBERS": "Tous les numéros de téléphone sont déjà valables.",
    "MALFORMED_PHONE_NUMBERS_TEXT": "Téléchargez tous les numéros de téléphone non valables :",
    "CSV_DOWNLOAD": "Télécharger CSV",
    "EMAIL_EXPORT_DOWNLOAD": "Exporter adresses e-mail",
    "REMOVE_COMPLETED": "Cacher automatiquement les entrées corrigées",
    "KIBON_VERFUEGUNGEN": {
      "HEADER": "Décisions",
      "ASSIGN_CONFIRMATION_HEADER": "Assigner bon de garde à enfant ?",
      "NOT_ASSIGNED": "N'est pas affecté les Décisions",
      "ASSIGN_DIRECTLY": "Disposition affecter directement",
      "IGNORE_TITLE": "Disposition ignorer",
      "IGNORE_SUBTITLE": "Êtes-vous sûr que vous voulez ignorer la décision et  vous ne voulez pas l'assigner à un autre enfant?",
      "GESUCHSTELLER": "Les requérants",
      "INSTITUTION_TRAEGERSCHAFT": "Kinderort et l'organe responsable",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Disposition} other{Décisions}}"
    }
  },
  "DOKUMENT_LAYOUT": {
    "LOESCHEN": "Supprimer mise en page du document",
    "FUSSZEILE": "Pied de page",
    "MODE_NORMAL": "Normal",
    "MODE_KOMPAKT": "Compact"
  },
  "BENUTZER": {
    "NAV_BENUTZERRECHTE": "Droits d'utilisateur",
    "PASSWORD_AENDERN": "Modifier le mot de passe",
    "USERNAME_AENDERN": "Modifier le nom d'utilisateur",
    "USERNAME_AENDERN_CONFIRM": "Vous devez vous reconnecter si vous modifiez votre nom d'utilisateur.",
    "STAMMDATEN_BENUTZER": "Données des utilisateurs",
    "CURRENT_PASSWORD": "Login Mot de passe",
    "NEW_PASSWORD": "Nouveau mot de passe",
    "CONFIRM_PASSWORD": "Confirmer le mot de passe",
    "SAVE_PASSWORD": "Enregistrer le mot de passe",
    "USERNAME": "Nom d'utilisateur ( e-mail )",
    "RECHTE_ALLE_KINDERORTE": "Tous les lieux d’accueil",
    "RECHTE_ZUSAETZLICHE_KINDERORTE": "Lieux d'accueil supplémentaires",
    "NEUES_KINDERORT_RECHT": "Nouveau lieu d’accueil – Droit",
    "KINDERORT_PERMISSION": "Lieu d’accueil – Droit",
    "STANDARD": "Par défaut",
    "SUPER_ADMIN": "Super Admin",
    "MANDANT_ADMIN": "Client Admin",
    "SUPPORT_ADMIN": "Support Admin",
    "ADMIN": "Administrateur lieux d’accueils (tous)",
    "USER": "Aucune permission globale",
    "KITA_PERMISSION": {
      "ADMINISTRATE": "Administrer lieu d’accueil",
      "MANAGE": "Gestion lieu d’accueil",
      "CONTROL": "Consultation limitée",
      "VIEW": "Consultation intégrale",
      "VIEW_PLUS": "Prise en charge+",
      "BETREUUNG_ADMIN": "Administrateur de l'encadrement",
      "KEINE": "Aucune",
      "TITLE": "Autorisation de prise en charge"
    },
    "NEUES_RECHT_HINZUFUEGEN": "Ajouter nouveaux droits lieux d'accueil",
    "ROLLE_AUSWAEHLEN": "Sélectionner le rôle",
    "KEINE_KITA_RECHTE_VORHANDEN": "Pas de droits",
    "ROLE": "Droits attribués",
    "PERSONAL_PERMISSION": {
      "ADMINISTRATE": "Administrer le personnel",
      "MANAGE": "Gérer le personnel",
      "TITLE": "Autorisation",
      "VIEW": "Consulter le personnel",
      "KEINE": "Aucun"
    },
    "GRANULAR_PERMISSIONS": {
      "NONE": "Aucune autorisation spécifique disponible",
      "ADD": "Ajouter une autorisation",
      "TITLE": "Autorisations spécifiques",
      "FOR_ALL": "Pour tous les lieux d'accueil",
      "TYPE": {
        "FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE": "Ignorer le blocage de la facture"
      }
    },
    "FUER_ALLE_KINDER_ORTE": "Pour tous les lieux d'accueil",
    "KITA_OR_PERSONAL_PERMISSION": "Autorisation de prise en charge ou du personnel",
    "KITA_PERMISSION_AUSWAEHLEN": "Sélectionner l'autorisation de prise en charge",
    "PERSONAL_PERMISSION_AUSWAEHLEN": "Sélectionner l'autorisation du personnel",
    "ERR_INVALID_APPLICATION_ROLE": "Il faut sélectionner soit un rôle d'utilisateur, soit une autorisation !"
  },
  "BETREUUNGSFAKTORREGEL": {
    "KEIN_FAKTOR_DEFINIERT": "Aucun facteur défini",
    "TITLE_ALTERSBEDINGTE_BETREUUNGSFAKTOREN": "Facteurs de prise en charge liés à l'âge",
    "AB_MONATE_DISPLAY": "à partir de {{display}} mois",
    "AB_GEBURT": "à partir de la naissance",
    "FAKTOR_DISPLAY": "Facteur : {{faktor}}",
    "KINDERGARTEN_FAKTOR_DISPLAY": "Facteur école enfantine {{type}}: {{faktor}}",
    "TITLE_KINDERGARTENBEDINGTER_BETREUUNGSFAKTOR": "Facteur de prise en charge lié à l'école enfantine",
    "KINDERGARTEN_BETREUUNGSFAKTOR_HINZUFUEGEN": "Ajouter facteur école maternelle (EM)",
    "ALTERS_BEDINGTER_BETREUUNGSFAKTOR_HINZUFUEGEN": "Ajouter un facteur de prise en charge lié à l'âge",
    "FAKTOR": "Facteur",
    "KINDERGARTEN_1_FAKTOR": "Facteur École Maternelle I",
    "KINDERGARTEN_2_FAKTOR": "Facteur Maternelle II",
    "VON_MONATEN_LABEL": "Plus de (mois)",
    "BETREUUNGSFAKTOR_REGEL_LOESCHEN": "Supprimer la règle du facteur de prise en charge",
    "SCHULE_FAKTOR_DISPLAY": "Facteur école : {{faktor}}",
    "SCHULE_FAKTOR": "Facteur école"
  },
  "SEARCH": {
    "ALLE_RESULTATE": "Tous les résultats",
    "SUCHERGEBNISSE": "Résultats de recherche pour '{{suchtext}}'",
    "KEINE_SUCHERGEBNISSE": "Pas de résultats de recherche.",
    "ENTITY_TITLE_MF": "{entity, select, KINDERORT{} KINDERORT_FRAKTION{Groupe / P. de réf.} MANDANT{Client} SCHLIESSTAGE{Jours de fermeture} BENUTZER{Utilisateur} ANGESTELLTE{Employée} BETREUUNGSFAKTOR_REGEL{Règle de facteur de prise en charge} BETREUUNGS_PERSON{P. de réf.} DOKUMENT_LAYOUT{Mise en page du document} PLATZART{Type de place} KONTINGENT{Contingent} GRUPPE{Groupe} KIND{Enfant} KITA{} KONTAKTPERSON{Personne de contact} RECHNUNG{Facture} TAGES_ELTERN{} TARIF{Frais} other{}} {text}",
    "ARCHIVE_MODE": {
      "ANY": "Archives indépendantes",
      "ARCHIVED": "Uniquement les fichiers archivés",
      "NON_ARCHIVED": "Non archivés"
    },
    "ARCHIVE_MODE_WAEHLEN": "Choisir le mode d'archivage"
  },
  "KONTAKTPERSON": {
    "TITLE_KINDER": "Enfants",
    "KUNDEN_NUMMER": "Client N ° {{nummer}}",
    "KONTAKTPERSON_STAMMDATEN": "Données de base personne de contact",
    "HAUPTKONTAKT": "Contact principal",
    "ERZIEHUNGSBERECHTIGTER": "Responsable légal",
    "RECHNUNGSEMPFAENGER": "Contact de facturation",
    "SONSTIGER": "Autre contact",
    "ABHOLBERECHTIGT": "Autorisé à venir chercher l'enfant",
    "WOHNADRESSE": "Adresse",
    "RECHNUNGSUEBERSICHT": "Aperçu des factures",
    "RECHNUNG_LABEL_PERIODE": "Facture {{periode}}",
    "VORAUSZAHLUNG_ERFASSEN": "Saisir un acompte",
    "RUECKERSTATTUNG_ERFASSEN": "Saisir le montant du remboursement",
    "VORAUSZAHLUNG_ERFASSEN_LINK": "Paiement d'avance",
    "RUECKERSTATTUNG_ERFASSEN_LINK": "Montant du remboursement",
    "ZAHLUNG_BANKZAHLUNG": "Virement bancaire sur la facture {{periode}}",
    "ZAHLUNG_SKONTO": "Escompte sur facture {{periode}}",
    "ZAHLUNG_ABSCHREIBUNG": "Amortissement sur la facture {{periode}}",
    "ZAHLUNG_RUNDUNGSDIFFERENZ": "Les erreurs d'arrondi sur la facture {{periode}}",
    "ZAHLUNG_AUSGLEICHSZAHLUNG": "Compensation sur les montants excédentaires",
    "ABGEWICKELTE_POSTEN_ANZEIGEN": "Afficher les postes réglés",
    "STEUERBESCHEINIGUNG_ERSTELLEN": "Créer attestation fiscale",
    "CORRESPONDENCE": {
      "PAST": "Correspondances passées",
      "CREATE_NOTE": "Créer note"
    },
    "MAHNSPERRE": "Blocage des rappels",
    "GEBUEHR_TYPE_TITLE": "Type de frais",
    "GEBUEHR_ERFASSEN": "Saisir les frais",
    "GEBUEHR_TYPE_AUSWAEHLEN": "Sélectionner type de frais",
    "GEBUEHR_TYPE": {
      "DEPOT": "Dépôt",
      "MITGLIEDER_BEITRAG": "Cotisation de membre",
      "GEBUEHR": "Frais"
    },
    "IBAN": "IBAN",
    "DEPOT_BEREITS_EINGEFORDERT": "Ce dépôt a déjà été réclamé. Ne pas reprendre sur une facture"
  },
  "KIND": {
    "PRIORITAET_TITEL": "Priorité",
    "KITA_HINZUFUEGEN": "Ajouter lieu d’accueil",
    "BETREUUNGSGUTSCHEINE": "Bons de garde {{kitaname}}",
    "BETREUUNGSGUTSCHEIN": "Bon de garde {{kind}}",
    "KIND_DIREKTZUWEISUNG_LINK": "Attribuer directement un enfant",
    "AUSTRITT": "Sortie",
    "ANMELDUNG": "Inscription",
    "ANMELDUNG_AM": "Inscription le",
    "ANHAENGE": "Annexes",
    "ANHAENGE_VERTRAULICH": "Annexes confidentielles",
    "ANHAENGE_ALLGEMEIN": "Annexes générales",
    "ANHANG_HOCHLADEN": "Télécharger annexes",
    "KIND_STAMMDATEN": "Données de base enfant",
    "KIND_WURDE_BEREITS_EINMAL_ERFASST": "Un enfant avec ce nom et cette date de naissance existe déjà. Continuer ?",
    "GEBURTSTERMIN": "Date prévue de l'accouchement",
    "ANMELDEDATUM": "Date d'inscription",
    "GESCHWISTER": "Frères et sœurs",
    "GESCHWISTER_ANMELDEN": "Inscrire nouveau frère/sœur",
    "GESCHWISTER_HINZUFUEGEN": "Ajouter frère/sœur",
    "GESCHWISTER_AUSWAEHLEN": "Sélectionner frère/sœur",
    "KONTAKTPERSON_ERFASSEN_LINK": "Créer contact",
    "KONTAKTPERSON_ERFASSEN": "Saisir nouveau contact",
    "KONTAKTPERSON_HINZUFUEGEN": "Ajouter contact existant",
    "KONTAKT_ENTFERNEN": "Supprimer le contact",
    "DIESEN_KONTAKT_ALS_HAUPTKONTAKT_DEFINIEREN": "Définir en tant que contact principal",
    "WOHNADRESSE_KIND": "Adresse de domicile de l'enfant",
    "KIND_WOHNADRESSE_GLEICH_WIE_HAUPTKONTAKT": "L'enfant est domicilié au même endroit que la personne de contact principale",
    "RECHNUNGSANTEIL": "Part de la facture",
    "RECHNUNGSAUFTEILUNG_FIXBETRAG": "en CHF",
    "RECHNUNGSAUFTEILUNG_PROZENT": "en %",
    "KONTAKTE": "Contacts",
    "EINTRITTSDATUM_AB": "Date d'entrée à partir du",
    "BETREUUNG_AB": "Prise en charge souhaitée dès le",
    "BETREUUNGSWUNSCH_HINZUFUEGEN": "Saisir un nouveau souhait de prise en charge",
    "BETREUUNGSGRUND": "Motif de la prise en charge",
    "BETREUUNGSVERLAUF": "Historique de la prise en charge",
    "BETREUUNG": "Prise en charge",
    "KEINE_BELEGUNG": "Pas d'occupation",
    "KIND_ANMELDEN": "Inscrire enfant",
    "KIND_AUSTRITT_HEADING": "L'enfant quitte le lieu d'accueil.",
    "KIND_AUSTRITT_AUSTRITTSDATUM": "Définir la date de sortie",
    "KIND_AUSTRITT_CONFIRM_BELEGUNGEN": "Il y a encore des occupations après la date de sortie. Voulez-vous quand même valider la date de sortie ?",
    "KIND_AUSTRITT_VERLAUF_TITLE": "Sortie le :",
    "KIND_AUSTRITT_PROVISORISCH_VERLAUF_TITLE": "Sortie provisoire le :",
    "KIND_AUSTRITT_REVERT": "Annuler la procédure de sortie",
    "KIND_AUSTRITT_DEFINITIV": "Confirmer la sortie",
    "AUSTRITT_MONATSBELEGUNG": "Une occupation avec des heures de présence ne peut pas être automatiquement reprise pour le mois suivant.",
    "KIND_DIREKT_ZUWEISEN": "Attribuer directement un enfant",
    "MUTTERSPRACHE": "Langue maternelle",
    "SOZIALVERSICHERUNGSNUMMER": "Numéro d’assuré",
    "KINDKONTAKT_RECHNUNGBETRAG_PROZENT": "({{betrag}}%)",
    "KINDKONTAKT_RECHNUNGBETRAG_FIXBETRAG": "({{betrag}} CHF)",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG": "Fractionnement de factures",
    "KIND_RECHNUNGSAUFTEILUNG_PROZENT": "en %",
    "KIND_RECHNUNGSAUFTEILUNG_FIXBETRAG": "en CHF",
    "KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_PROZENT": "%",
    "KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_FIXBETRAG": "CHF",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG_SPEICHERN": "Appliquer le fractionnement des factures",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG_ERROR_PROZENT_TOTAL": "La somme des pourcentages n'est pas à 100% mais {{total}}%. Il faut encore distribuer {{remainder}}%.",
    "NEUE_PARAMETER_ERFASSEN": "Nouveaux paramètres",
    "NEUE_PARAMETER_ERFASSEN_TITLE": "Saisir nouveaux paramètres",
    "PARAMETER": "Paramètres",
    "BG_PENSUM_PROZENT": "Taux BDG (%)",
    "BG_PENSUM": "Temps BDG",
    "BG_PENSUM_PROZENT_VALUE": "Temps BDG : {{value}}%",
    "BETRAG_GUTSCHEIN": "Bon (CHF)",
    "BETRAG_VERGUENSTIGT_SHORT": "Rabais",
    "ELTERNBEITRAG_SHORT": "Contribution parentale",
    "BETRAG_GUTSCHEIN_SHORT": "Bon",
    "VOLLKOSTEN_GUTSCHEIN": "Coût total (CHF)",
    "VOLLKOSTEN_GUTSCHEIN_SHORT": "Les coûts complets",
    "BETRAG_GUTSCHEIN_VALUE": "Bon : CHF {{value}}",
    "BETRAG_VOLLKOSTEN_VALUE": "Les coûts complets: CHF {{value}}",
    "BG_ANSPRUCH": "Droit",
    "BG_PENSUM_ZEITEINHEIT_MF": "{pensumUnit, select, HOURS{ {zeiteinheiten, plural, one{1h} other{#h} }} other{ {prozent}% }}",
    "BG_PENSUM_ZEITEINHEIT_TOOLTIP_MF": "{pensumUnit, select, DAYS{ {zeiteinheiten, plural, one{1 jour} other{# jours} }} other{ {prozent}% }}",
    "NEUER_GUTSCHEIN_ERFASSEN": "Nouveau bon de garde",
    "NEUER_GUTSCHEIN_ERFASSEN_TITLE": "Saisir un nouveau bon de garde",
    "MANUELLER_BETREUUNGSGUTSCHEIN": "Bon de garde manuel",
    "STUNDENKONTINGENT": "Crédit d'heures",
    "STUNDENKONTINGENTE": "Crédit d'heures {{kitaname}}: ",
    "NEUE_STUNDENKONTINGENTE_ERFASSEN": "Nouveau crédit d'heures",
    "NEUE_STUNDENKONTINGENTE_ERFASSEN_TITLE": "Saisir nouveau crédit d'heures",
    "STUNDENTKONTINGENTE_STUNDEN": "Nombre d'heures",
    "KEIN_KONTINGENT": "Aucun contingent disponible",
    "KITAX": {
      "KITAX_KIBON_VERFUEGUNG": "Décision Ki-Tax / kiBon",
      "MF_VERFUEGUNG": "{type, select, kiTax{Ki-Tax} kiBon{kiBon} other{Ki-Tax / kiBon}} Décision",
      "MF_VERFUEGUNG_VOM": "{type, select, kiTax{Ki-Tax} kiBon{kiBon} other{Ki-Tax / kiBon}} Décision du {date}",
      "KITAX_VERFUEGUNG_IMPORT": "Importer la décision Ki-Tax / kiBon",
      "KITAX_IMPORT_UEBERSCHREIBT_BETREUUNGSGUTSCHEINE": "Il existe déjà {{anzahl}} bons de garde pour ces données. Ceux-ci seront peut-être écrasés. Continuer ?",
      "DATEN_UEBERPRUEFEN": "Vérifier les données",
      "AUSZAHLUNG_AN_ELTERN": "Le bon est directement versé aux parents par kiBon"
    },
    "LEISTUNGEN": {
      "LEISTUNGEN": "Prestations",
      "KIND_PARAMETER_DISPLAY": "{{name}} : {{value}}",
      "KIND_TARIF_PARAMETER_HISTORY_ENTRY": "Attribution des paramètres",
      "KIND_TARIF_PARAMETER_HISTORY_ENTRY_TERMINATE_HEADING": "Arrêter l'affectation des paramètres",
      "KIND_PARAMETER_BEENDEN": "Arrêter l'affectation des paramètres",
      "CONFIRM_TARIFE_PARAMETER_BEENDEN": "Il y a encore des paramètres après la date de fin. Vraiment quitter ?",
      "LEISTUNGEN_TOTAL": "Total des prestations",
      "LEISTUNGSRECHNUNG_NICHT_VORHANDEN": "Pas de prestations facturables",
      "LEISTUNGSRECHNUNG_NICHT_VERRECHENBAR_TOOLTIP": "{{kitaName ? kitaName + ' : ' : ''}}Les prestations ne peuvent être facturées car...",
      "LEISTUNGSRECHNUNG_VERRECHENBAR_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Les prestations peuvent être facturées.",
      "LEISTUNGSRECHNUNG_STORNIERT_TOOLTIP": "{{kitaName ? kitaName + ' : ' : ''}}Il y a des annulations de factures pour les prestations.",
      "LEISTUNGSRECHNUNG_RECHNUNGSDIFFERENZ_TOOLTIP": "{{kitaName ? kitaName + ' : ' : ''}}La prestation a été facturée, mais les taux ont changé entre temps.",
      "LEISTUNGSRECHNUNG_VERRECHNET_TOOLTIP": "{{kitaName ? kitaName + ' : ' : ''}}La prestation a été facturée.",
      "LEISTUNGSRECHNUNG_GELOESCHT_TOOLTIP": "{{kitaName ? kitaName + ' : ' : ''}}La prestation a été supprimée.",
      "TARIFRECHNUNG_RESULT_DOWNLOAD_LABEL": "Télécharger le calcul",
      "PLATZ_CONFIRMATION_DOWNLOAD_LABEL": "Télécharger la confirmation de placement"
    },
    "RECHNUNGEN_PERIODE": "Factures {{periode}}",
    "IDENTIFIKATIONSNUMMER": "Numéro d'identification",
    "GUTSCHRIFTEN_UND_GEBUEHREN": "Crédits et frais",
    "FIX_POSITION_TYP_GEBUEHR": "Frais",
    "FIX_POSITION_TYP_GUTSCHRIFT": "Crédit",
    "EINGEWOEHNUNG_POSITION": {
      "NAME": "Acclimatation",
      "ERFASSEN": "Saisir l'acclimatation",
      "DELETE_TITLE": "Acclimatation du {{gueltigAb}} au {{gueltigBis}}",
      "ANZAHL_TAGE": "Nombre de jours",
      "VON": "Acclimatation de",
      "BIS": "Acclimatation jusqu'à"
    },
    "GEBUEHR_ERFASSEN": "Saisir les frais",
    "GUTSCHRIFT_ERFASSEN": "Saisir une note de crédit",
    "SELECT_LEISTUNGSART": "Sélectionner le type de prestation",
    "FIX_POSITION_TEXT": "{{type}} du {{datum}}",
    "LEISTUNGSART": {
      "LEISTUNGSART_BETREUUNG": "Prise en charge",
      "LEISTUNGSART_FERIENBETREUUNG": "Prise en charge de vacances",
      "LEISTUNGSART_MITGLIEDERBEITRAG": "Cotisation de membre",
      "LEISTUNGSART_RABATT": "Rabais",
      "LEISTUNGSART_SCHULKIND": "Écolier",
      "LEISTUNGSART_SONSTIGES": "Autres",
      "LEISTUNGSART_SUBVENTION": "Subvention",
      "LEISTUNGSART_VERPFLEGUNG": "Repas",
      "LEISTUNGSART_AUSSERORDENTLICHER_BETREUUNGSAUFWAND": "Prise en charge extraordinaire"
    },
    "KEINE_ANHAENGE": "Pas de pièces jointes",
    "KEINE_BETREUUNGSGUTSCHEINE": "Pas de bon de garde",
    "KEINE_GESCHWISTER": "Pas de frères et sœurs",
    "KEINE_KIND_TARIF_PARAMETER_HISTORY_ENTRIES": "Pas de paramètres",
    "KEINE_STUNDENKONTINGENTE": "Pas de crédit d'heures",
    "KEINE_WEITEREN_KONTAKTE": "Pas d'autres contacts",
    "ZUWEISUNG": {
      "ZUWEISUNG_AB": "Prise en charge dès le :",
      "ZUWEISEN_PROVISORISCH": "Attribuer provisoirement",
      "ZUWEISEN_ANGEBOT_ERSTELLEN": "Rédiger l'offre",
      "ZUWEISEN_DEFINITIV": "Attribution définitive",
      "KINDERGARTEN": {
        "ONE": "École enfantine I",
        "ONE_SHORT": "KG I",
        "TWO": "École enfantine II",
        "TWO_SHORT": "KG II",
        "SCHULE": "École",
        "SCHULE_SHORT": "École",
        "INTERN": "interne",
        "EXTERN": "externe",
        "SCHULSTUFE": "Degré scolaire / classe"
      },
      "OPTIONEN": "Options",
      "ZUWEISUNG_SUCCESS": "L'enfant a été attribué avec succès.",
      "ZUWEISUNG_DELETE_BETREUUNGSWUNSCH": "Retirer de la liste d'attente",
      "ZUWEISUNG_KEEP_BETREUUNGSWUNSCH": "Laisser sur la liste d'attente",
      "ZUWEISUNG_MAX_PLAETZE_WARNING": "Cette occupation dépasse le maximum de places disponibles. Annuler l'attribution ?",
      "ZUWEISUNG_CONFIRM_ADJUSTED_GUELTIGKEIT": "Cette occupation s'arrêtera automatiquement le {{gueltigbis}}.",
      "NEUE_BETREUUNG_FESTLEGEN": "Définir la nouvelle prise en charge",
      "ZUWEISUNGSDATUM": "Date d'attribution",
      "KEIN_ZUWEISUNGSDATUM_AUSGEWAEHLT": "Pas de date d'attribution sélectionnée",
      "ZUWEISUNG": "Attribution",
      "AB_DATEVALUE": "À partir du {{date}}",
      "SPEZ_BETREUUNGSFAKTOR": "Facteur de prise en charge spécifique",
      "VERTRAGLICHE_BEMERKUNG": "Remarques contractuelles",
      "BEMERKUNG_IN_BETREUUNGSVEREINBARUNG": "Imprimer la remarque dans l'accord de prise en charge",
      "VERTRAGLICHE_STUNDEN": {
        "PRIVAT": "Heures contractuelles : Privées",
        "DISPLAY_PRIVAT": "Privées : {{value}}h",
        "SUBVENTIONERT": "Heures contractuelles : subventionnées",
        "DISPLAY_SUBVENTIONIERT": "Subventionnées : {{value}}h",
        "FIRMA": "Heures contractuelles : {{name}}",
        "DISPLAY_FIRMA": "{{name}} : {{value}}h",
        "PERCENTAGE": {
          "TITLE": "Taux d'occupation contractuel",
          "PRIVAT": "Taux d'occupation contractuel : privé",
          "DISPLAY_PRIVAT": "Privé : {{value}}%",
          "SUBVENTIONERT": "Taux d'occupation contractuel : subventionné",
          "FIRMA": "Taux d'occupation contractuel : {{name}}",
          "DISPLAY_FIRMA": "{{name}} : {{value}}%",
          "DISPLAY_SUBVENTIONIERT": "Subventionné : {{value}}%"
        }
      },
      "EINGEWOEHNUNG_PHASE": "Acclimatation",
      "NO_FLEXIBLE_PLAETZE": "Place d'accueil fixe",
      "BELEGUNG_ZEIT_ADD": "Heures d'arrivée et de départ",
      "BELEGUNG_ZEIT_FROM": "Présence de",
      "BELEGUNG_ZEIT_TO": "Présence jusqu'à",
      "BELEGUNG_ZEIT_MORE": "Ajouter les heures d'arrivée et de départ"
    },
    "MONATSBELEGUNG": {
      "MONATSPLAN": "Plan mensuel",
      "ANWESENHEIT_ERFASSEN": "Saisir les présences",
      "TAG": "Jour",
      "PLATZART": "Type de place",
      "VON": "de",
      "BIS": "jusqu'au",
      "ZEITEN_LEEREN": "Vider toutes les périodes",
      "BELEGUNG_FUER": "Occupation mensuelle pour {{name}}",
      "PRINT": "Imprimer",
      "AVAILABLE_ANWESENHEITSZEITEN": "Heures de présence visibles",
      "COMPLETE_UNTIL": "Inscrire jusqu'au : {{date}}",
      "DEADLINE_EXPIRED": "<strong>Le délai est expiré.</strong> Le planning mensuel est encore visible jusqu'au {{date}}",
      "RELEASE_FOR_MAIN_CONTACT": "Partager avec le contact principal",
      "RELEASE_HEADER_MF": "Partager les heures de présence {count, plural, one {{name}} other{}} ?",
      "READ_UNTIL": "Lecture autorisée jusqu'au <strong>{{date}}</date>",
      "WRITE_UNTIL": "Modifications autorisées jusqu'au <strong>{{date}}</date>",
      "RESEND": "Envoyer à nouveau",
      "RESEND_HEADER_MF": "{count, plural, one{E-Mail} other{E-Mail}} renvoyer {count, plural, one {{nom}} other{}}",
      "ADJUST_DEADLINE": "Adapter le délai",
      "ADJUST_DEADLINE_HEADER_MF": "{count, plural, one{ajustement du délai pour {name}} other{ajustements du délai}}",
      "AFFECTED_MONTH": "Mois : {{month}}",
      "PREVIOUS_DEADLINE": "Délai précédent : {{date}}",
      "WITHDRAW": "Annuler",
      "WITHDRAW_HEADER": "Annuler le partage",
      "WITHDRAW_WRITE": "Annuler les droits d'écriture",
      "WITHDRAW_ALL": "Annuler les autorisations pour {{month}}",
      "ENTER_NEW_DEADLINE": "Saisir un nouveau délai",
      "ENTER_DEADLINE": "Saisir le délai",
      "CONFIRM_HEADER": "Envoyer la confirmation par e-mail ?",
      "CONFIRM_WITHOUT_EMAIL": "Attribuer sans e-mail de confirmation",
      "ERROR_INVALID_TIMES": "Les heures du {{date}} {{name}} ne sont pas valides",
      "MONATSBELEGUNG_PROVISORISCH_SAVED": "L'occupation mensuelle provisoire a été enregistrée avec succès.",
      "SETTINGS": "Temps de présence Paramètres",
      "MAX_DAILY_HOURS": "Maximum d'heures par jour : {{stunden}}",
      "ANWESENHEITS_ZEIT": "Temps de présence",
      "MAX_DAILY_HOURS_WARNING": "Les heures quotidiennes maximales recommandées de {{stunden}} ont été dépassées.",
      "ALLOWED_PICK_UP_TIMES": "Heures de départ autorisées",
      "ALLOWED_TIMES_PLACEHOLDER": "hh:mm-hh:mm, ...",
      "PARENT_DEFAULT_VALUES": "Standard pour les parents",
      "PARENT_DEFAULT_VALUES_VERTRAGLICHES_PENSUM": "Standard Taux d'occupation contractuel",
      "TAGESBASIERT": "Sur base journalière",
      "ALLOWED_ARRIVAL_TIMES": "Heures d'arrivée autorisées",
      "ALLOWED_TIMES": "Les heures autorisées sont :\n{{constraints}}"
    },
    "EXTRA_DAY": {
      "TITLE": "Différences",
      "EDIT_MF": "{count, plural, =0{Saisir} other{Traiter}} les différences de prise en charge",
      "CREATED_DAYS": "Différences saisies",
      "CURRENT": "Différences actuelles depuis {{von}}",
      "FUTURE": "Différences dès le {{von}}",
      "WITH_RANGE": "Différences {{von}} - {{bis}}",
      "NO_EXTRA_DAYS": "Pas de différences enregistrées",
      "CATEGORY": {
        "TYP_AUSWAEHLEN": "Sélectionner le type de différence",
        "TYPES": {
          "STANDARD": "Par défaut",
          "KOMPENSATION": "Compensation",
          "KRANKHEIT": "Maladie",
          "AUSFALL": "Abscence",
          "FERIEN": "Vacances",
          "EINGEWOEHNUNG": "Acclimatation",
          "UNBEKANNT": "Inconnue"
        }
      },
      "TYPE": {
        "ABSENCE": "Absence",
        "ADDITIONAL": "Jour supplémentaire",
        "TITLE": "Type"
      },
      "NO_PERMISSION_FOR_KINDER_ORT": "Vous n'êtes pas autorisé à saisir des différences pour ce lieu d'accueil."
    },
    "KIND_ERFASSEN": "Ajouter un nouvel enfant",
    "KIND_HINZUFUEGEN": "Ajouter enfant saisi",
    "KIND_ERFASSEN_LINK": "Saisir enfant",
    "DELETE_KIBON_DATEN": "<p>Des données ont déjà été envoyées à kiBon.</p><p>Veuillez vous assurer que les données dans kiBon sont correctes.</p>",
    "PLATZBESTAETIGUNG_DEAKTIVIEREN": "Désactiver la confirmation automatique de place kiBon/la notification de mutation",
    "FIX_POSITION_TYP_CORONA_REFUND": "Crédit Corona",
    "ANWESENHEITS_SOLL": {
      "NEUE_ZUWEISUNG": "Saisir la consigne de présence",
      "ZUWEISEN": "Attribuer la consigne",
      "SUMMARY_MF": "{tage, plural, =0{} one{# Tag} other{# Tage}} {halbtage, plural, =0{} one{# Halbtag} other{# Halbtage}} {frueh, plural, =0{} one{# Frühbetreuung} other{# Frühbetreuungen}} {mittage, plural, =0{} one{# Mittag} other{# Mittage}} {nachmittage, plural, =0{} one{# Halbe Nachmittage} other{# Halbe Nachmittage}}",
      "TITLE": "Consigne de présence",
      "NONE": "Pas de consigne de présence",
      "ZUWEISUNG": "Consigne de présence",
      "ZUWEISUNG_BEENDEN": "Terminer la consigne",
      "ZUWEISUNG_BEENDEN_HEADING": "Terminer la consigne de présence",
      "TAGE": "Jours",
      "HALBTAGE": "Demi-journées",
      "FRUEHBETREUUNGEN": "Accueils précoces",
      "MITTAGE": "Encadrements à midi",
      "PERIOD": "Période {{von}} - {{bis}}",
      "VERBRAUCH": "{{ist}}/{{soll}}",
      "NACHMITTAGE": "Demi-après-midi",
      "WARNING": "ATTENTION : ne prend en compte que les jours déjà facturables."
    },
    "IRRELEVANT_PARAMETERS": "Paramètres actuellement non pertinents",
    "IMPORTED_PARAMETER": "Paramètre kiBon importé"
  },
  "DASHBOARD": {
    "TITLE": "Tableau de bord",
    "GOOD_MORNING": "Bonjour {{vorname}} !",
    "GOOD_AFTERNOON": "Bon après-midi {{vorname}} !",
    "GOOD_EVENING": "Bonsoir {{vorname}} !",
    "FAVORIT_SUCHEN": "Recherche de favoris...",
    "FAVORIT_HINZUFUEGEN": "Ajouter aux favoris",
    "AUFGABENLISTE": "Liste des tâches",
    "MEHR_AUFGABEN_ANZEIGEN": "Afficher plus de tâches",
    "KEINE_AUFGABEN_VORHANDEN": "Aucune tâche ouverte disponible pour les favoris sélectionnés",
    "KEINE_AUFGABEN_OHNE_FAVORITEN": "Sans favoris, il n'y a pas de tâches",
    "KEINE_FAVORITEN": "Ajoutez des favoris !",
    "TASK_FILTER": {
      "CUSTOM_FIELD": "Champs supplémentaires",
      "KIBON": "KiBon",
      "LEISTUNGSRECHNUNG": "Calcul des prestations",
      "BELEGUNG": "Occupation",
      "BRING_ABHOLZEITEN": "Heures d'arrivée et de départ"
    },
    "ORT_HINZUFUEGEN": "Filtres lieu de prise en charge",
    "TASK_FILTER_TITLE": "Filtre type de tâche"
  },
  "FRAKTION": {
    "NAV": {
      "PROFIL": "Profil",
      "MONATSBLATT": "Fiche mensuelle",
      "BETREUUNGS_SCHLUESSEL": "Taux d'encadrement"
    },
    "KONFIGURATION": "Configuration",
    "GRUPPE": {
      "LOESCHEN": "Supprimer le groupe",
      "BEENDEN": "Terminer groupe"
    },
    "BETREUUNGS_PERSON": {
      "LOESCHEN": "Supprimer l'APE",
      "BEENDEN": "Terminer l'APE",
      "KINDER_MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{a Pas d'enfants ce mois-ci} one{Enfant} other{Enfants}}"
    },
    "PLAETZE_NEU_FESTLEGEN": "Définir de nouvelles places",
    "ANZAHL_MAX_PLAETZE": "Nombre max de places",
    "MAXIMALE_PLAETZE_FESTLEGEN": "Définir le nombre de places max",
    "NAME": "Nom",
    "KAPAZITAET": "Capacité",
    "BEENDEN_HEADING": "{{name}} est terminé",
    "MONATSBLATT": {
      "RECHNUNGSMONAT": "Mois de facturation",
      "KEINE_EINTRAEGE": "Aucune entrée disponible",
      "LEISTUNGSRECHNUNG_AKTUALISIEREN": "Mettre à jour la comptabilité des prestations",
      "STUNDEN": "Heures",
      "STUNDEN_ANZAHL": "{{anzahl}} Heures",
      "PERIODE": "Période {{von}} - {{bis}}",
      "VERBUCHTE_STUNDEN_ANZAHL": "{{verbucht}} / {{gesamt}}",
      "STUNDEN_VERFUEGBAR": "Heures disponibles",
      "ZUSAETZLICHE_BELEGUNG": "En plus : ",
      "CREATE_ZUSAETZLICHE_BELEGUNG": "Saisir une occupation privée supplémentaire",
      "DELETE_ZUSAETZLICHE_BELEGUNG": "Supprimer l'occupation privée supplémentaire",
      "CONFIRM_DELETE_ZUSAETZLICHE_BELEGUNG": "Voulez-vous les occupations privées supplémentaires",
      "API": {
        "SEND_TITLE": "Envoyer {{monthYear}} à l'interface salariale ?",
        "SEND_INFO_BETREUUNGSPERSON": "Ainsi, les heures et les frais saisis par {{name}} seront envoyés à l'interface salariale.",
        "SEND_INFO_KINDERORT": "Par cette action, les heures et les notes de frais saisies de toutes les APE de {{name}} sont envoyés à l'interface salariale - même celles qui ont déjà été envoyées.",
        "SEND": "Envoyer à l'interface salariale",
        "VERAENDERT_INFO_MF": "{status, select, VERAENDERT{Envoyé à l'interface salariale, mais modifié depuis dans kitAdmin.} FAILED{Un problème technique est survenu lors de la transmission des données} other{}}",
        "STATUS": {
          "VERSANDT": "Envoyé",
          "VERAENDERT": "Modifié",
          "FAILED": "Erreur de transmission"
        }
      }
    },
    "LEISTUNGS_TYP": {
      "PRIVAT": "Occupation privée",
      "SUBVENTIONIERTES_KONTINGENT": "Occupation subventionnée",
      "FIRMEN_KONTINGENT": "Firmenkontingent Occupation",
      "BETREUUNGS_GUTSCHEIN": "Bon de garde Occupation",
      "BETREUUNGS_UNABHAENGIG": "Occupation indépendante de la prise en charge",
      "KONTINGENT": "Contingent occupation"
    },
    "BETREUUNGS_SCHLUESSEL": {
      "ZUGEWIESENE": "Taux d'encadrement attribués",
      "ZUWEISUNG_BEENDEN_HEADING": "Arrêter l'assignation du taux de prise en charge pour {{assignedEntity}} ?",
      "CONFIRM_BEENDEN": "Il y a encore des taux d'encadrement attribués après le {{date}}. Comment voulez-vous procéder ?",
      "NEUE_ZUWEISUNG": "Attribuer un nouveau taux d'encadrement",
      "TERMINATION_MODE": {
        "TERMINATE": "Arrêter le taux d'encadrement le {{date}}. Les futurs taux d'encadrement seront conservés.",
        "DELETE_TILL_NEXT_GAP": "Terminer le taux de prise en charge le {{date}} et supprimer les taux de prise en charge suivants.",
        "DELETE_FOLLOWING": "Terminer le taux de prise en charge le {{date}} et supprimer les taux de prise en charge suivants."
      },
      "ZUWEISUNG": "Attribution du taux d'encadrement",
      "ZUWEISEN": "Attribuer un taux d'encadrement",
      "ZUWEISUNG_BEENDEN": "Mettre fin au taux d'encadrement",
      "KEINE_ZUWEISUNG": "Pas de taux d'encadrement attribués",
      "AUSWAEHLEN": "Choisir un taux d'encadrement",
      "NONE_FOR_FRAKTION": "Il n'y a pas de taux d'encadrement pour le planning hebdomadaire de ce groupe."
    }
  },
  "BETREUUNGS_PERSON": {
    "STAMMDATEN": "Données de base",
    "WOHNADRESSE": "Adresse",
    "KORRESPONDENZSPRACHE": "Langue de correspondance"
  },
  "FIRMA": {
    "TITLE_TARIF": "Frais {{tarifname}}",
    "ANZAHL_PLAETZE": "Nombre de places",
    "NEUES_KONTINGENT": "Créer nouveau contingent",
    "KONTINGENT_NEUE_PLAETZE": "Nouvelles places",
    "KONTINGENT_PLAETZE": "Places",
    "KONTINGENT_DEFINIEREN": "Définir un contingent",
    "BEZEICHNUNG_KONTINGENT": "Nom du contingent",
    "FIRMA_LOESCHEN": "Supprimer type de place",
    "FIRMA_DELETE": "Supprimer type de place",
    "KONTINGENT_BEENDEN": "Terminer contingent",
    "FIRMEN_KONTINGENT_BEENDEN_HEADING": "Les places pour le contingent {{name}} sont arrêtées.",
    "CONFIRM_FIRMEN_KONTINGENT_BEENDEN": "Il y a encore places après la date de fin. Vraiment quitter ?",
    "KEINE_KONTINGENTE": "Aucun contingent"
  },
  "CUSTOM_FIELD": {
    "BEZEICHNUNG": "Désignation",
    "TYP_AUSWAEHLEN": "Sélectionner le type de champ",
    "HINZUFUEGEN": "Ajouter un champ",
    "TYPE": {
      "BOOLEAN": "On / Off",
      "STRING": "Texte",
      "SELECT": "Sélection"
    },
    "SELECT_VALUES": "Sélection des valeurs",
    "SORTIEREN": "Trier les champs",
    "MANDATORY": "Champ obligatoire"
  },
  "SCHULUNG": {
    "SCHULUNG_ABSOLVIEREN": "Suivre une formation",
    "SCHULUNG_AUSWAEHLEN": "Choisir une formation",
    "SCHULUNG": "Formation",
    "INHALT": "Contenu",
    "ZIEL": "Objectif",
    "ABLAUF": "Déroulement",
    "BESCHREIBUNG": "Un mandant de formation avec des données de démonstration est créé pour chaque formation.",
    "BENUTZERNAME_VALUE": "Nom d'utilisateur : <strong>{{value}}</strong>",
    "PASSWORT_VALUE": "Mot de passe : <strong>{{value}}</strong>",
    "SCHULUNGSBENUTZER_CREATED_AND_DATA_INITIALIZED": "Votre utilisateur a été créé et initialisé avec les données correspondantes.\n Bonne formation !",
    "UNTERLAGEN_ANSEHEN": "Voir la documentation",
    "SCHULUNG_DURCHFUEHREN": "Organiser une formation",
    "SCHULUNG_ERSTELLEN": "Créer une formation",
    "WIE_FUNKTIONIERTS": "Comment ça marche ?",
    "EINTRITT_BIS_AUSTRITT": {
      "TITLE": "De l'inscription à la sortie",
      "SUBJECT": "Les données de base sont saisies et gérées sur la base des inscriptions, des déménagements, des changements d'occupation et des départs. Le taux d'occupation est visible sur la base de l'occupation et les droits d'utilisateur sont passés en revue.",
      "GOAL": "L'objectif de cette formation est que l'utilisateur connaisse les bases de kitAdmin. Il est en mesure de trouver rapidement les données correspondantes et de naviguer entre ces données. L'utilisateur peut administrer les lieux d'accueil ainsi que gérer les données des enfants.",
      "STEPS": {
        "NEUER_BENUTZER_ANLEGEN": "Saisir un nouvel utilisateur et attribuer des droits d'utilisateur",
        "ABLOESUNG_MIGRATION": "Remplacement des anciennes listes Excel (migration)",
        "ARBEITEN_MIT_SUCHE": "Travailler avec le mode recherche",
        "FAVORITEN_AUFGABENLISTE": "Favoris et liste de tâches",
        "KIND_PROFIL_ANSEHEN": "Afficher enfant / gérer les données",
        "KITA_PROFIL_ANSEHEN": "Afficher / gérer le lieu d'accueil",
        "ARBEITEN_MIT_BELEGUNGSLISTE": "Travailler avec la liste d'attente / la liste d'occupation",
        "KINDER_ZUWEISEN": "Assigner enfant / modifier les assignations",
        "BERECHTIGUNGEN_BETREUER_VERWALTER": "Autorisations en tant qu'APE / Administrateur"
      }
    },
    "MODELLIERUNG_KITA": {
      "TITLE": "Modélisation lieu d'accueil",
      "SUBJECT": "Ouverture complète d'un lieu d'accueil pour enfants. Les places autorisées, les contingents et les facteurs de prise en charge sont enregistrés. Les réglages pour la configuration de l'accord sont effectués.",
      "GOAL": "A la fin de la formation, l'utilisateur peut créer et gérer lui-même de nouveaux lieux pour enfants, groupes et (contingents) d'entreprises via kitAdmin.",
      "STEPS": {
        "KITA_EROEFFNEN": "Créer un lieu d'accueil",
        "STAMMDATEN_BEARBEITEN": "Modifier des données de base",
        "GRUPPE_ERSTELLEN_ANPASSEN": "Créer et personnaliser un groupe",
        "KITA_KONFIGURIEREN": "Configurer un lieu d'accueil",
        "BETREUUNGSFAKTOREN_ERSTELLEN": "Créer des facteurs de prise en charge",
        "VEREINBARUNGSKONFIG_LAYOUT_ANPASSEN": "Personnaliser la configuration de l'accord et la mise en page du document",
        "PLATZART_ERSTELLEN": "Créer type de place"
      }
    },
    "TARIFE_LEISTUNGSRECHNUNG": {
      "TITLE": "Tarifs / calcul des prestations",
      "SUBJECT": "Les tarifs sont créés et édités à l'aide de modèles. Les tarifs sont attribués à un lieu d'accueil ou à une entreprise. Le calcul des prestations des enfants est complété par un paramètre et un bon de prise en charge. Les tâches peuvent être effectuées.",
      "GOAL": "L'utilisateur sait quels composants sont nécessaires pour qu'un calcul de prestation puisse être effectué. L'utilisateur peut régler les paramètres nécessaires pour chaque enfant ou compléter les données avec un bon de prise en charge. Les tâches à accomplir peuvent être effectuées de manière autonome.",
      "STEPS": {
        "TARIF_ERSTELLEN": "Créer un tarif",
        "TARIF_BEARBEITEN": "Modifier le tarif",
        "TARIF_KITA_ZUWEISEN": "Attribuer un tarif à un lieu d'accueil",
        "TARIF_FIRMA_ZUWEISEN": "Attribuer un tarif à une entreprise",
        "KIND_PARAMETER_SETZEN": "Attribuer des paramètres à un enfant",
        "KIND_BETREUUNGS_GUTSCHEIN_SETZEN": "Assigner un bon de garde à un enfant",
        "FAVORIT_ERSTELLEN": "Créer des favoris",
        "AUFGABEN_ERLEDIGEN": "Effectuer des tâches"
      }
    },
    "FAKTURA": {
      "TITLE": "Facturation",
      "SUBJECT": "Les paramètres de configuration des factures sont définis. Les cycles de facturation sont créés et envoyés par e-mail / courrier. Des corrections de factures sont effectuées. Les paiements anticipés et les remboursements de surplus de factures sont effectués. Les rappels sont créés et exécutés.",
      "GOAL": "L'utilisateur comprend comment le mandant et les lieux d'accueil doivent être configurés pour pouvoir facturer les prestations et les frais. L'utilisateur connaît les processus de facturation et leur ordre. Les cycles de facturation et de rappel peuvent être effectués de manière autonome. Les corrections et les paiements anticipés peuvent être correctement comptabilisés.",
      "STEPS": {
        "EINRICHTUNG_DVB_FAKTURA": "Mise en place de la facturation DVB",
        "RECHNUNGSKONFIGURATION_EINER_KITA_ZUWEISEN": "Attribuer la configuration de la facture à un lieu d'accueil",
        "RECHNUNGSLAUF_ERSTELLEN": "Créer un cycle de facturation",
        "RECHNUNGSKORREKTUR_ERSTELLEN": "Créer une correction de facture",
        "RECHNUNG_STORNIEREN": "Annuler une facture",
        "RECHNUNG_BEZAHLEN": "Payer une facture",
        "RECHNUNG_MAHNEN": "Créer un rappel",
        "OFFENE_POSTEN_VORAUSZAHLUNGEN_UND_RUECKERSTATTUNGEN": "Postes ouverts (paiements anticipés et remboursements)",
        "UEBERSCHUESSIG_BEZAHLTE_RECHNUNGEN": "Factures payées en trop",
        "DER_KONTENABGLEICH": "Rapprochement des comptes"
      }
    },
    "PERSONAL": {
      "TITLE": "Équipe",
      "SUBJECT": "Gérer les taux d'encadrement et les engagements. Affecter le personnel en fonction des besoins liés à l'occupation.",
      "GOAL": "L'utilisateur comprend comment le personnel peut être saisi et affecté de manière optimale."
    }
  },
  "ORGANISATION": {
    "ORGANISATION": "L'organisation"
  },
  "VOREINSTELLUNGEN": {
    "VOREINSTELLUNGEN": "Préférences",
    "PARAMETER_SORTIERUNG": "Tri des paramètres",
    "ARCHIVIERTE_TARIFE": "Afficher les tarifs archivés"
  },
  "MANDANT": {
    "DISABLED": "Désactivé",
    "READ_ONLY": "Seulement lisible",
    "ACTIVE": "Actif",
    "ABGERECHNET_BIS": "Décompté jusqu'au",
    "ABRECHNUNG_VORAUS_ZUSATZ_MONATE": "Mois de facturation en avance",
    "ABRECHNUNG_VORAUS_ZUSATZ_MONATE_VALUE": "Facturation à l'avance : {{monate}} mois",
    "MANDANT_MIT_DEMODATEN": "Initialiser le mandant avec des données de démonstration",
    "MIT_STANDARD_WOCHENPLAN": "Avec planning hebdomadaire standard",
    "WARNING_INTERVAL_IN_DAYS": "Avertissement de disponibilité de place : {{tage}} jours",
    "FAKTURA": "Facturation",
    "MODULES": "Modules",
    "NO_MODULES": "Aucune",
    "RECHNUNGS_KONFIGURATION": "Configuration de la facture",
    "CONFIRM_DEACTIVATE_RECHNUNGS_KONFIGURATION": "Si vous désactivez la configuration des factures, toutes les factures concernées qui n'ont pas été annulées reçoivent le statut \"Payée\".",
    "API_TOKEN": "Jeton API",
    "API_TOKEN_LEER_LASSEN": "Jeton API (laisser vide pour que le jeton ne soit pas modifié)",
    "KONFIGURATION": "Configuration",
    "KEINE_RECHNUNGS_KONFIGURATIONEN": "Aucune configuration de facturation",
    "AUTOMATIC_PAYMENTS": "Paiements automatiques",
    "EINZAHLUNGSSCHEIN_ALL_PAGES": "Ajouter un bulletin de versement sur chaque page",
    "KORRESPONDENZ_ADRESSE": "Adresse de correspondance",
    "LANGUAGES": "Langues",
    "DEFAULT_LANGUAGE": "Langue standard",
    "SUPPORTED_LANGUAGE": "Langues supportées",
    "FR": "Français",
    "DE": "Allemand",
    "MODULE": {
      "BASIC": "Base",
      "FAKTURA": "Facturation",
      "ANWESENHEIT": "Places flexibles",
      "TAGESELTERN": "Parents de jour",
      "DYNAMISCHER_BELEGUNGSPLAN": "Plan d'occupation dynamique",
      "KOMMUNIKATION": "Communication",
      "BSV": "OFAS",
      "GROUP_ADMIN": "Administrateur de groupe",
      "PERSONALPLANUNG": "Planification du personnel"
    },
    "SUBSCRIPTION_MODEL": {
      "LIGHT": "Light",
      "LIGHT_ERKLAERT": "(50% de réduction)",
      "PREMIUM": "Premium",
      "PREMIUM_ERKLAERT": "(y compris assistance, conseil, formation)",
      "FREE": "Free"
    },
    "PREISE": {
      "KITA": "CHF par crèche",
      "BETREUUNGS_PERSON": "CHF par APE"
    },
    "INDIVIDUAL_FILES": "par destinataire de facture 1 PDF",
    "RECHNUNGSLAUF_LOCKED_BEFORE": "Blocage de la facture jusqu'au",
    "RECHNUNG_CONFIG": {
      "TITLE": "Configuration de la facturation",
      "POSITIONS_TITLE": "Corrections spécifiques",
      "VERRECHNUNG_AB": "Facturer à partir de",
      "KINDERORT_RABATT_PCT": "{{count}}% de réduction",
      "PAUSCHAL_PREIS": "Prix forfaitaire CHF {{count}}",
      "PAUSCHAL_PREIS_BIS": "Date de fin du prix forfaitaire",
      "ANZAHL_RABATT": "{{count}} lieux d'accueil gratuits pour les enfants"
    }
  },
  "REPORT": {
    "TITLE": "Rapports",
    "REPORT_AUSWAEHLEN": "Sélectionner un rapport",
    "PURPOSE": "À quoi sert le rapport ?",
    "STRUCTURE": "Comment le rapport est-il structuré ?",
    "ALLE_PLAETZE": "Toutes les places",
    "STICHTAG": "Date de référence",
    "STICHTAGBELEGUNG": "Date de référence Occupation",
    "STICHTAGADRESSE": "Date de référence Adresse",
    "ALLE_KONTAKTE": "Tous les contacts",
    "TEMPLATE": {
      "ERSTELLTE_KONFIGURATIONEN": "Configurations de modèles créées",
      "KONFIGURATION": "Configuration du modèle",
      "DEFAULT_HERUNTERLADEN": "Télécharger le modèle standard",
      "HOCHLADEN": "Télécharger le modèle",
      "HERUNTERLADEN": "Télécharger",
      "LOESCHEN": "Supprimer",
      "TEMPLATE_VALUE": "Modèle personnalisé {{name}}",
      "TEMPLATE": "Modèle personnalisé",
      "KEINE": "Aucun modèle individuel n'a été créé pour ce rapport.",
      "ERR_INCOMPLETE": "Le modèle n'est pas encore entièrement rempli.",
      "DEFAULT": "Modèle standard",
      "OUTDATED": "Une nouvelle version de modèle est disponible.\nVeuillez mettre à jour le modèle :\nVersion actuelle : {{current}}\nNouvelle version : {{new}}"
    },
    "BETRIEB": {
      "TITLE": "Exploitation"
    },
    "ADRESSLISTE_KINDER_KONTAKTE": {
      "TITLE": "Liste d'adresses",
      "PURPOSE": "<ul><li>Lettres en série par ménage</li><li>Adresses (étiquettes, lettres, etc.)</li><li>Reconnaître le nombre d'enfants / de frères et sœurs par ménage</li></ul>",
      "STRUCTURE": "<p>Une ligne est créée par adresse. Pour chaque adresse, toutes les personnes de contact et les enfants qui y habitent sont indiqués</p>"
    },
    "AKTUELLE_AENDERUNGEN": {
      "TITLE": "Changements actuels",
      "PURPOSE": "Informer l'équipe des changements fixes ou prévus dans l'occupation du lieu d'accueil.",
      "STRUCTURE": "<p>Les changements sont classés par ordre chronologique. Les changements sont différenciés selon qu'il s'agit d'un changement de facteur de prise en charge, d'une entrée, d'une mutation ou d'un départ. En outre, il est indiqué de combien les taux ont été réduits ou augmentés. Les groupes concernés sont marqués d'un X.</p>"
    },
    "BELEGUNGSPLAN": {
      "TITLE": "Plan d'occupation",
      "PURPOSE": "<ul><li>Information à l'équipe sur l'occupation du lieu des enfants</li><li>Contrôle des présences (conseil : l'occupation effective peut être indiquée directement dans le rapport)</li></ul>",
      "STRUCTURE": "<p>Dans le rapport, une feuille de calcul individuelle est créée pour chaque semaine de la période sélectionnée. Les enfants sont listés dans les groupes respectifs. Leur occupation est marquée en gris. Le type de place n'est pas visible</p>",
      "INCLUDE_EXTRA_PLAETZE": "Prendre en compte les jours supplémentaires et les absences"
    },
    "BELEGUNGSPLAN_ANWESENHEITSZEITEN": {
      "TITLE": "Plan d'occupation avec heures de présence",
      "PURPOSE": "Information à l'équipe sur l'occupation des lieux d'accueil et les heures de présence.",
      "STRUCTURE": "<p>Dans le rapport, une feuille de calcul individuelle est créée pour chaque semaine de la période sélectionnée. Les enfants sont listés dans les groupes respectifs. Les heures de présence sont inscrites par enfant pour chaque jour</p>"
    },
    "GEBURTSTAGSLISTE_KINDER": {
      "TITLE": "Liste des anniversaires",
      "PURPOSE": "Information à l'équipe sur tous les anniversaires d'enfants de l'année.",
      "STRUCTURE": "<p>Les anniversaires des enfants sont représentés par mois. En plus de la date de naissance, le rapport calcule également l'âge que l'enfant aura atteint</p>"
    },
    "TELEFON_EMAIL_ADRESSLISTE": {
      "TITLE": "Liste de téléphones, d'e-mails et d'adresses",
      "PURPOSE": "<ul><li>Liste de téléphones d'un lieu d'accueil</li><li>Envoyer un e-mail en masse aux personnes de contact</li><li>Ecrire aux personnes de contact avec leur adresse</li></ul>",
      "STRUCTURE": "<p>Le rapport contient, par enfant et par personne de contact, les numéros de téléphone, l'adresse e-mail et l'adresse de domicile de la personne de contact. Chaque type de communication est géré dans une feuille de calcul séparée. La feuille de calcul \"Total\" répertorie le tout.</p>"
    },
    "KINDERGARTENKINDER": {
      "TITLE": "Enfants de l'école maternelle",
      "PURPOSE": "Rapport sur les enfants de l'école enfantine de la Ville de Berne",
      "STRUCTURE": "<p>Le rapport énumère par ligne un enfant de maternelle avec l'adresse de domicile correspondante de l'enfant. De plus, il est déclaré si les options d'occupation EM 1 ou EM 2 et si interne ou externe sont indiquées.</p>"
    },
    "FRUEH_SPAET_DIENST": {
      "TITLE": "Service du matin et du soir",
      "PURPOSE": "Rapport pour faciliter la répartition du personnel avec les heures de présence des enfants",
      "STRUCTURE": "<p>Le rapport indique dans la colonne de gauche l'heure d'arrivée des enfants et dans la colonne de droite l'heure à laquelle les enfants sont récupérés. Les entrées sont triées et séparées par intervalles d'une demi-heure. Les enfants dont le facteur de prise en charge est supérieur à 1 sont marqués en gras, ceux dont le facteur de prise en charge est inférieur à 1 sont marqués en italique. En bas, des lignes sont générées pour toutes les périodes dans lesquelles le personnel peut être affecté.</p>",
      "FRUEH_BIS": "Service du matin jusqu'à",
      "SPAET_VON": "Service du soir dès"
    },
    "CONTROLLING": {
      "TITLE": "Controlling",
      "PURPOSE": "<ul><li>Contrôle de l'utilisation du lieu d'accueil</li><li>Contrôle de l'utilisation du contingent</li><li>Exportation des données présentées sous forme de graphique dans l'onglet Aperçu du lieu d'accueil</li></ul>",
      "STRUCTURE": "<p>Chaque modification est classée par ordre chronologique. Les modifications sont différenciées selon qu'il s'agit d'une modification du facteur de prise en charge, d'une modification de la prise en charge (entrée, mutation et sortie) ou d'une correction effectuée la semaine suivante. De plus, la liste montre un report de l'année précédente et une clôture annuelle. Pour chaque semaine, le taux d'occupation du lieu d'accueil, le contingent subventionné et les contingents sont calculés. Une moyenne annuelle est calculée pour les contingents.</p>"
    },
    "BELEGUNGSPLAN_PLATZART": {
      "TITLE": "Plan d'occupation (avec type de place)",
      "PURPOSE": "Exportation des occupations",
      "STRUCTURE": "<p>Dans le rapport, une feuille individuelle est créée pour chaque semaine de la période sélectionnée. Les enfants sont listés dans les groupes respectifs. Leur occupation est indiquée par un P (privée), un S (subventionnée) ou un F (place entreprise) selon le type de place</p>",
      "PLATZART": "Avec type de place",
      "ANWESENHEIT": "Avec temps de présence"
    },
    "KENNZAHLEN": {
      "TITLE": "Chiffres clés",
      "PURPOSE": "<ul><li>Détermination de chiffres clés individuels sur un lieu d'accueil ou le mandant (p. ex. taux d'occupation le plus apprécié ou nombre d'enfants par année de naissance, commune, sexe, etc.)</li><li>Evaluation des paramètres tarifaires</li></ul>",
      "STRUCTURE": "<p>Une ligne est générée par enfant et par type de place occupant un poste dans le lieu d'accueil à la date de référence indiquée.</p>"
    },
    "WARTELISTE": {
      "TITLE": "Liste d'attente",
      "PURPOSE": "Exportation de la liste d'attente par lieu d'accueil ou sur tous les lieux d'accueil.",
      "STRUCTURE": "<p>Chaque ligne du rapport correspond à un enfant avec un souhait de prise en charge. Le souhait de prise en charge est complété par les informations suivantes : motifs de prise en charge, occupation actuelle, contact principal et remarque.</p>"
    },
    "BETREUUNGSGUTSCHEINE": {
      "TITLE": "Bons de garde",
      "PURPOSE": "Exportation des bons de garde saisis.",
      "STRUCTURE": "<p>Chaque ligne du rapport correspond à un bon de garde. Les bons de garde sont complétés par le contact principal et d'éventuelles autres personnes responsables de l'éducation de l'enfant.</p>"
    },
    "KIBON_REPORT": {
      "TITLE": "kiBon",
      "PURPOSE": "<p>Le rapport est destiné à contacter les parents qui vont probablement demander une BDG pour le renouvellement des bons.</p>",
      "STRUCTURE": "<p>Dans le rapport, chaque enfant qui dispose encore d'une place à la période indiquée est représenté sur une ligne. En outre, les données telles que demande de place, confirmation de place, mutation, décision sont également visibles.</p>"
    },
    "LEISTUNGEN_KALKULIERT": {
      "TITLE": "Prestations calculées",
      "PURPOSE": "<ul><li>Prévision des recettes et des centres de coûts</li><li>Évaluation des tarifs</li></ul>",
      "STRUCTURE": "<p>Alors que la feuille de calcul \"Journal des prestations\" contient toutes les prestations calculées, cette feuille sert également de base de données pour les autres feuilles de calcul intégrées au rapport avec des tableaux croisés dynamiques. Ces tableaux montrent l'évolution prévisionnelle des prestations calculées sur tous les mois de l'année correspondante. Les prestations sont regroupées par place et par type de prestation. Les modifications de tarifs peuvent ainsi être facilement adaptées et donc analysées de manière ludique. Remarque : les données sont recalculées chaque nuit. Les résultats d'une modification de tarif, par exemple, ne sont donc entièrement visibles que le lendemain.</p>"
    },
    "FINANZLASTENAUSGLEICH": {
      "TITLE": "Péréquation financière et compensation des charges (PFCC)",
      "PURPOSE": "Informer les autorités sur quels les enfants bénéficient d'une place subventionnée depuis quelle commune.",
      "STRUCTURE": "<p>Tous les enfants ayant une place subventionnée sont affichés avec leur commune politique correspondante et le taux d'encadrement. Un tableau croisé permet de voir le nombre de places subventionnées auprès du lieu d'accueil, par commune.</p>"
    },
    "BELEGTE_PROZENT_PUNKTE": {
      "TITLE": "Points de pourcentage occupés par jour",
      "PURPOSE": "Pour communiquer au canton les heures d'école de jour effectuées par facteur d'encadrement à la fin de l'année scolaire.",
      "STRUCTURE": "<p>Le rapport comporte 3 onglets : \"Sommes pourcentages\", \"Paramètres\" et \"Jours fermés\".</p><p>Sous \"Sommes des pourcentages\", on peut lire la somme des heures d'encadrement par facteur d'encadrement. Pour que le chiffre correct puisse être calculé, il faut inscrire sous l'onglet \"Paramètres\" les \"jours ouverts par semaine\" ainsi que les \"heures ouvertes par jour\".</p><p>Pour que les jours fermés (vacances ou jours fériés) soient pris en compte, ils peuvent être marqués d'un \"x\" dans la 3ème feuille de calcul.</p>"
    },
    "TARIFRECHNUNG_REPORTING_KALKULIERT": {
      "TITLE": "Ratios tarifaires (éléments de déclaration)",
      "PURPOSE": "<ul><li>Tarif et évaluations définies qui peuvent être contrôlées par le tarif</li><li>Pour communiquer au canton les heures d'école de jour effectuées par facteur d'encadrement à la fin de l'année scolaire. (formulaire 1 et 2)</li></ul>",
      "STRUCTURE": "<p>Une ligne est prévue dans le rapport pour chaque section tarifaire. Cette ligne contient toutes les positions du rapport, les colonnes étant définies par les désignations. De plus, le rapport contient si possible un groupe/une personne d'encadrement ainsi que le facteur d'encadrement. Le type de place concerné (privé, subventionné, entreprise) ainsi que la période correspondante permettent également d'autres possibilités d'évaluation.</p>"
    },
    "STUNDENBLATT": {
      "TITLE": "Feuille horaire",
      "PURPOSE": "Transfert des heures facturées dans le logiciel externe de comptabilité salariale.",
      "STRUCTURE": "<p>Le rapport comporte trois feuilles de calcul : \"Heures effectives\", \"Filag\" et \"StundenblattData\".</p><p>La feuille de calcul \"Heures effectives\" affiche, via un tableau croisé dynamique, le nombre d'heures saisies par personne d'accueil.<br>La feuille de calcul \"Filag\" répertorie, par commune, le nombre d'heures subventionnées et privées.<br> La feuille de calcul \"StundenblattData\" sert de base de données.</p>"
    },
    "MONATSBLATT_AUSZUG": {
      "TITLE": "Extrait de la feuille mensuelle (PDF)",
      "PURPOSE": "<ul><li>Sert de feuille annexe à la fiche de salaire des parents de jour</li><li>Détailler les heures d'accueil par enfant selon le facteur d'encadrement</li><li>Affichage des paramètres tarifaires mensuels saisis dans la feuille mensuelle</li></ul>",
      "STRUCTURE": "<p>Le programme crée un dossier dans lequel les feuilles mensuelles sont affichées en PDF par parent/s de jour. La mise en page peut être adaptée : en ajoutant une adresse d'expéditeur, le lieu apparaît également à côté de la date. Sinon, le lieu est remplacé par \"date\".</p>"
    },
    "FAKTURA": {
      "TITLE": "Facturation"
    },
    "ZAHLUNGS_EINGAENGE": {
      "TITLE": "Paiements entrants",
      "PURPOSE": "<ul><li>Exportation des paiements saisis</li><li>Comparaison dans la comptabilité</li></ul>",
      "STRUCTURE": "<p>Chaque paiement saisi apparaît comme une ligne séparée dans ce rapport. Le paiement est, si possible, attribué à un lieu d'accueil et à un contact de facturation. Les types de paiement sont différenciés selon :</p><ul><li>Amortissement</li><li>Compensation pour paiement anticipé</li><li>Compensation pour remboursement</li><li>Paiement bancaire</li><li>Paiement bancaire issu d'un paiement anticipé</li><li>Paiement bancaire issu d'un alignement des comptes</li><li>Différence d'arrondi</li><li>Remboursement</li><li>Remboursement d'une compensation</li><li>Remise</li><li>Paiement anticipé</li><li>Paiement anticipé pour compensation</li></ul>"
    },
    "LEISTUNGEN_FAKTURIERT": {
      "TITLE": "Prestations facturées",
      "PURPOSE": "<ul><li>Alignement comptabilité</li><li>Exportation des prestations facturées</li></ul>",
      "STRUCTURE": "<p>La feuille de calcul \"Journal de facturation\" répertorie toutes les prestations facturées. Elle sert également de base de données pour les autres feuilles de calcul intégrées dans le rapport avec des tableaux croisés dynamiques. Les tableaux montrent l'évolution des prestations déjà facturées au cours de l'année correspondante. Les prestations sont regroupées par emplacement et par type de prestation.</p>"
    },
    "KONTAKTPERSONEN_ZAHLUNGEN": {
      "TITLE": "Avances et remboursements impayés",
      "PURPOSE": "Sécurité, afin de ne pas oublier les avances impayées et les montants non remboursés.",
      "STRUCTURE": "<p>Tous les paiements anticipés et les remboursements liés au lieu d'accueil et à la personne de contact sont présentés.</p>",
      "INCLUDE_PAYED_RUECKERSTATTUNGEN": "Y compris les remboursements versés"
    },
    "OFFENE_POSTEN": {
      "TITLE": "Postes non-soldés",
      "PURPOSE": "<ul><li>Exportation de la liste des postes non-soldés de l'ensemble du mandant ou du lieu d'accueil ou/et de la personne de contact</li><li>Clôture mensuelle / trimestrielle et annuelle</li></ul>",
      "STRUCTURE": "<p>Les postes non-soldés sont regroupés par personne de contact/lieu d'accueil. Seuls les lieux d'accueil/personnes de contact qui ont encore des postes non-soldés sont représentés.</p>"
    },
    "AKTUELLE_OFFENE_POSTEN": {
      "TITLE": "Les postes non-soldés (sans \"terminés\")",
      "PURPOSE": "<ul><li>Exportation de la liste des postes non-soldés de l'ensemble du mandant ou du lieu d'accueil ou/et de la personne de contact</li><li>Clôture mensuelle / trimestrielle et annuelle</li></ul><p>Les paiements qui ont entre-temps été attribués à une facture et qui sont donc clôturés sont ignorés par ce rapport, indépendamment de la \"période de prestation jusqu'au\" indiquée.</p>",
      "STRUCTURE": "<p>Les postes non-soldés sont regroupés par personne de contact/lieu d'accueil. Seuls les lieux d'accueil/personnes de contact qui ont encore des postes non-soldés sont représentés.</p>"
    },
    "RECHNUNGS_GEBUEHREN": {
      "TITLE": "Frais de facturation",
      "PURPOSE": "<ul><li>Exportation de tous les frais de facturation en dehors de la comptabilité des prestations</li><li>Clôture mensuelle / trimestrielle et annuelle</li></ul>",
      "STRUCTURE": "<p>Tous les frais de facturation facturés sont représentés.</p>"
    },
    "STEUERBESCHEINIGUNGEN": {
      "TITLE": "Attestations fiscales (PDF)",
      "PURPOSE": "Certifie les prestations facturées d'une personne de contact par année civile.",
      "STRUCTURE": "<p>Le rapport crée un dossier dans lequel les confirmations fiscales sont affichées au format PDF par contact de facturation. La mise en page peut être adaptée. En ajoutant une adresse d'expéditeur, le lieu apparaît également à côté de la date. Sinon, le lieu est remplacé par \"date\".</p>",
      "STRUCTURE_LEISTUNGSART": "Par type de prestation",
      "STRUCTURE_TITLE": "Regroupement",
      "STRUCTURE_MONTHLY": "Par mois"
    },
    "MIT_BELEGUNG": "Avec occupation",
    "AUF_WARTELISTE": "Sur liste d'attente",
    "CUSTOM_FIELD_CONFIGURATION": {
      "TITLE": "Configuration du champ supplémentaire",
      "KEINE": "Aucune configuration de champ supplémentaire n'a été créée.",
      "KONFIGURATION_NAME": "Configuration du champ supplémentaire {{name}}",
      "KIND": "Tous les suppléments enfants",
      "BELEGUNG": "Tous les suppléments d'occupation",
      "ERSTELLT": "Configurations de champs supplémentaires créées",
      "LOESCHEN": "Supprimer la configuration des champs supplémentaires",
      "DEFAULT": "Tous les champs supplémentaires"
    },
    "ABWEICHUNGEN_KINDER": {
      "STRUCTURE": "Les jours supplémentaires et les absences sont listés par enfant et par groupe/APE.",
      "TITLE": "Différences",
      "PURPOSE": "Aperçu des écarts d'occupation, jours d'échange d'un enfant."
    },
    "CONTROLLING_MONTHLY": {
      "TITLE": "Controlling mensuel",
      "STRUCTURE": "<p>Pour chaque lieu d'accueil, les données de controlling mensuel par fraction sont listées dans une feuille séparée.</p>",
      "PURPOSE": "<ul><li>Contrôle de l'utilisation mensuelle du lieu d'accueil</li><li>Exportation des données présentées sous forme de graphique dans l'onglet Aperçu du lieu d'accueil</li></ul>"
    },
    "CORONA_REFUNDS": {
      "TITLE": "Ordonnance d'urgence \"Remboursement Berne pour les crèches publiques\"",
      "PURPOSE": "<p>Sert à calculer les absences dues à Corona par enfant.</p><p>Ne prend en compte que la période 17 mars - 16 mai 2020.</p>",
      "STRUCTURE": "<p>Liste, par enfant, le nombre de jours de fréquentation normale de la crèche par mois, ainsi que le nombre de jours où la crèche n'a pas été fréquentée en raison de Corona</p><p>Les frais de garde qui peuvent être remboursés pour les absences dues à Corona sont calculés.</p>",
      "DOWNLOAD_PDFS": "Télécharger les lettres aux parents",
      "PDF_INTRODUCTION": "Libre Introduction à la lettre aux parents",
      "RETURN_DATE": "Date de retour souhaitée",
      "ADD_SIGNATURES": "Créer une ligne de signature pour les tuteurs légaux",
      "PDF_ADDITIONAL_TEXT": "Texte libre supplémentaire pour lettre aux parents (avec absences)",
      "PDF_ADDITIONAL_TEXT_NO_ABSENCES": "Texte libre supplémentaire pour lettre aux parents (sans absences)",
      "CREATE_REFUNDS": "Créer des notes de crédit",
      "VERPFLEGUNG_REFUND_PCT": "Remboursement par la crèche des frais de repas",
      "FEE_APPROVAL": "Si vous utilisez cette fonction, vous devrez payer des frais uniques de 150 CHF par lieu d'accueil, qui vous seront facturés avec la facture d'août.",
      "ERR_GENERATION_FAILED": "La création du rapport a échoué. Tous les mois concernés par le lockdown sont-ils facturables ?",
      "DEFAULTS": {
        "PDF_INTRODUCTION_MF": "-",
        "PDF_ADDITIONAL_TXT_NO_ABSENCES_MF": "Nous vous prions de nous retourner la confirmation signée { email, select, null{} other{oder per E-Mail an {email}} } jusqu'au {returnDate}. Nous ne pouvons pas corriger les factures sans ces démarches de votre part. \n\nVeuillez annoncer les corrections ou erreurs de saisie à { email, select, null{{sender}} other{{email}} }.\n\nMeilleures messages\n{sender}",
        "PDF_ADDITIONAL_TXT_MF": "Veuillez confirmer qu'il s'agit d'absence dues à Corona.\n\nNous vous prions de nous retourner la confirmation signée { email, select, null{} other{oder per E-Mail an {email}} } jusqu'au {returnDate}. Nous ne pouvons pas corriger les factures sans ces démarches de votre part. \n\nVeuillez annoncer les corrections ou erreurs de saisie à { email, select, null{{sender}} other{{email}} }.\n\nMeilleures messages\n{sender}"
      }
    },
    "CORONA_REFUNDS_BERN_PRIVAT": {
      "TITLE": "Ordonnance d'urgence \"Remboursement Berne pour les crèches privées\"",
      "STRUCTURE": "<p>Liste par enfant le nombre de jours de fréquentation normale de la crèche par mois, ainsi que le nombre de jours d'absence de la crèche pour cause de Corona</p><p>Les frais de garde qui peuvent être remboursés pour les absences dues à Corona sont calculés.</p>",
      "PURPOSE": "<p>Sert à calculer les absences dues à Corona par enfant.</p><p>Prend en compte la période 17 mars - 17 juin 2020.</p>"
    },
    "BSV_PRAESENZKONTROLLE": {
      "TITLE": "OFAS (Formulaires pour le décompte de l'aide financière)",
      "PURPOSE": "<p>Contrôle de présence pour l'Office fédéral des assurances sociales pour les crèches.</p><p>Le formulaire est requis par l'OFAS pour le financement de nouvelles places d'accueil.</p>",
      "STRUCTURE": "<p>Remplit directement le modèle Excel officiel pour le contrôle de présence.</p><p>Les jours fériés et les jours de fermeture doivent encore être remplis manuellement.</p><p>Les occupations avec jardin d'enfants ne sont pas prises en compte.</p><p>Les jours supplémentaires et les absences ne sont pas pris en compte.</p>",
      "FULL_DAY_BETREUUNG_HOURS": "Nombre d'heures à partir duquel l'accueil est considéré comme une journée complète"
    },
    "ANWESENHEITSSOLL_VERBRAUCH": {
      "TITLE": "Consigne de présence",
      "PURPOSE": "Contrôle de la consigne de présence ainsi que de l'occupation, pour calculer la consigne non consommée.",
      "STRUCTURE": "Liste la somme des consignes de présence, de l'occupation et de la différence par enfant pour la période sélectionnée."
    },
    "BSV_PRAESENZKONTROLLE_KG": {
      "TITLE": "OFAS (contrôle de présence pour l'accueil extrascolaire)",
      "PURPOSE": "<p>Contrôle de présence pour l'Office fédéral des assurances sociales pour l'accueil parascolaire.</p><p>Le formulaire est requis par l'OFAS pour le financement de nouvelles places d'accueil.</p>",
      "STRUCTURE": "<p>Remplit directement le modèle Excel officiel pour le contrôle de présence.</p><p>Les jours fériés, les jours de fermeture ainsi que les vacances doivent encore être remplis manuellement.</p><p>Seules les occupations avec jardin d'enfants sont prises en compte.</p><p>Les jours supplémentaires et les absences ne sont pas pris en compte.</p>"
    },
    "DEPOTS": {
      "TITLE": "Dépôts",
      "PURPOSE": "Aperçu des dépôts saisis",
      "STRUCTURE": "<p>Tous les dépôts ouverts sont listés pour les lieux d'accueil sélectionnés.<br /> Ouvert signifie qu'ils n'ont pas encore été remboursés par paiement anticipé ou remboursement.</p><p>La date de référence est utilisée pour déterminer le statut des factures sur lesquelles les dépôts ont été compensés.</p><p>Si les dépôts remboursés doivent également être listés, ils peuvent être limités en option par date de remboursement.</p>",
      "INCLUDE_ERSTATTET": "Y compris les frais remboursés",
      "ERSTATTET_VON": "Remboursé par",
      "ERSTATTET_BIS": "Remboursé jusqu'au"
    },
    "GESPEICHERTE": "Rapports enregistrés",
    "GESPEICHERTE_AKTUALISIEREN": "Actualiser la liste",
    "BELEGUNGSPLAN_MONTHLY": {
      "TITLE": "Plan d'occupation avec vue mensuelle",
      "PURPOSE": "Information à l'équipe sur l'occupation des lieux d'accueil et les changements d'occupation.",
      "STRUCTURE": "<p>Dans le rapport, une feuille de calcul unique est créée pour une semaine du mois sélectionné. Les enfants sont listés dans les groupes respectifs. Les changements d'occupation tels que les entrées, les sorties et les mutations dans le mois concerné sont listés.</p>"
    },
    "BELEGUNGSPLAN_DAY_BASED": {
      "TITLE": "Plan d'occupation journalière",
      "PURPOSE": "Information à l'équipe sur l'occupation des lieux d'accueil.",
      "STRUCTURE": "<p>Dans le rapport, une feuille de calcul individuelle est créée pour chaque jour d'une semaine. Les enfants sont listés dans les groupes respectifs</p>",
      "WEEK_OF": "Semaine du"
    },
    "STUNDENBASIERTES_CONTROLLING": {
      "TITLE": "Controlling sur base horaire",
      "PURPOSE": "<ul><li>Contrôle du taux d'occupation mensuel des parents de jour</li><li>Exportation des données, qui sont présentées sous forme de graphique dans l'onglet Aperçu des parents de jour</li></ul>",
      "STRUCTURE": "<p>Les données de controlling mensuel sont listées par parent de jour et par enfant</p>"
    },
    "CORONA_REFUNDS_LUZERN": {
      "TITLE": "Ordonnance d'urgence \"Remboursement Lucerne\"",
      "PURPOSE": "<p>Sert à calculer les absences dues à Corona par enfant.</p>",
      "STRUCTURE": "<p>Liste par enfant le nombre de jours de fréquentation normale de la crèche par mois, ainsi que le nombre de jours d'absence de la crèche pour cause de Corona</p><p>Les frais de garde qui peuvent être remboursés pour les absences dues à Corona sont calculés.</p>"
    },
    "STUNDENVERGLEICH_SOLL_IST": {
      "TITLE": "Comparaison des heures réelles/consignées",
      "PURPOSE": "<p>Contrôle des heures consignes et réelles journalières par enfant.</p><p>Rapport pouvant être remis aux parents pour contrôle.</p>",
      "STRUCTURE": "Le rapport peut être généré par enfant pour un mois donné. Chaque jour y est listé avec les heures d'arrivée et de départ (consigne) ainsi que les heures de présence effectives (réelles)."
    },
    "PERSONAL": {
      "TITLE": "Équipe"
    },
    "PERSONALPLANUNG": {
      "TITLE": "Planification du personnel",
      "PURPOSE": "Personnel planifié, y compris les pauses et les rendez-vous.",
      "STRUCTURE": "Dans le rapport, une feuille de calcul individuelle est créée pour chaque semaine de la période sélectionnée. Les employés sont listés dans la fraction correspondant au lieu d'accueil. L'affectation est marquée par la couleur de service correspondante, si elle est disponible.</p>",
      "INCLUDE_TERMINE": "avec rendez-vous",
      "INCLUDE_KINDERORT_SCHLUESSEL": "avec attributions des clés de répartition des lieux d'accueil",
      "INCLUDE_TAGESINFOS": "avec les infos du jour",
      "EVENT_TOOLTIP_NOT_AVAILABLE": "non disponible de {{von}} à {{bis}}"
    }
  },
  "COMMUNICATION": {
    "TITLE": "Communication",
    "SELECT_RECIPIENTS": "Sélectionner les destinataires",
    "PROBLEM": "Problème",
    "INCOMPLETE_RECIPIENTS": "{{count}} destinataires incomplets",
    "POST_RECIPIENTS": "{{count}} destinataires du courrier",
    "READY_TO_SEND": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{E-mail prêt pour envoi} other{E-Mails prêts pour envoi}}"
    },
    "SENT": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{E-mail envoyé} other{E-mails envoyés}}"
    },
    "DEADLINE": "Délai",
    "EXPIRED": "expiré",
    "SEND_EMAILS": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{E-Mail} one{envoyer e-mail} other{envoyer les e-mails}}"
    },
    "RESEND_EMAILS": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{renvoyer e-mail} one{renvoyer e-mail} other{renvoyer les e-mails}}"
    },
    "CHECK_COMPLETENESS": "Vérifier si les données sont complètes",
    "KONTAKT_MISMATCH": "Le contact affiché pour lequel un e-mail a été envoyé ne correspond pas au contact principal actuel.",
    "BRING_ABHOLZEITEN": {
      "RELEASE": "Partager les heures d'arrivée et de départ",
      "CONFIRMATION": "Confirmation des heures d'arrivée et de départ",
      "TITLE": "Heures d'arrivée et de départ {{date}}",
      "PURPOSE": "Envoi des e-mails d'invitation à remplir les heures d'arrivée et de départ",
      "STRUCTURE": "<p>Envoyer et gérer des emails d'invitation pour les contacts principaux des enfants d'une crèche sélectionnée.</p><p>Pour gérer les emails déjà envoyés, sélectionner à nouveau le mois concerné.</p>",
      "PRESENCE": "Présence",
      "NO_FLEXIBLE_PLAETZE": "Avec place d'accueil fixe",
      "NO_FLEXIBLE_PLAETZE_EXISTING_MONATSBELEGUNG": "Avec place d'accueil fixe. Heures d'arrivée et de départ disponibles",
      "CREATE_MONATSBELEGUNG_MF": "{showcount, select, true{{count} } other {}}{count, plural, one{Saisir le temps de présence par défaut} other {Saisir le temps de présence par défaut}}"
    },
    "STEUERBESCHEINIGUNG": {
      "NAME": "Attestation fiscale",
      "TITLE": "Envoyer attestations fiscales {{date}}",
      "PURPOSE": "déclencher des e-mails avec les attestations fiscales pour l'année concernée",
      "STRUCTURE": "<p>Processus d'envoi en masse d'attestations fiscales par e-mail et par courrier.</p><p>Pour gérer les e-mails déjà envoyés, sélectionnez à nouveau l'année concernée.</p>"
    },
    "BULK_EMAILS": {
      "NAME": "E-mail",
      "TITLE": "Écrire e-mail {{date}}",
      "PURPOSE": "Envoyer un e-mail aux personnes de contact sélectionnées",
      "STRUCTURE": "<p>Sélectionner un destinataire, écrire un e-mail et l'envoyer</p>",
      "SEND_EMAIL": "Envoyer un e-mail aux destinataires sélectionnés",
      "HISTORY": {
        "TITLE": "Historique des e-mails de masse",
        "MF": "{showcount, select, true{{count} } other{}} E-mails de masse",
        "SEARCH_TEXT_SHORT": "Recherche d'e-mail",
        "SEARCH_TEXT": "Recherche de sujet et de contenu",
        "SENT_DATE": "Date d'envoi",
        "SENT_BY_USER": "Envoyé par l'utilisateur",
        "ABSENDER_INFO": "Adresse et nom de l'expéditeur",
        "RECIPIENTS": "Destinataires du mail de masse",
        "SEE_RECIPIENTS": "Liste de destinataires",
        "SEND_MAIL": "Envoyer à nouveau"
      },
      "DISPOSITION_NOTIFICATION": "Demander un accusé de réception",
      "TERMINATION_MAIL": "Confirmation de résiliation par e-mail"
    },
    "EMAIL": {
      "ABSENDER_ADRESSE": "Adresse de l'expéditeur",
      "SUBJECT": "Objet",
      "CONTENT": "Message e-mail",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{E-mail} one{E-mail} other{E-mails}}",
      "HEADER_INFO": "À : {{to}}\nRéponse à : {{replyToName}} <{{replyToAddr}}>\nEnvoyé par : {{sentBy}}",
      "TEST_RECIPIENT": "Test des destinataires d'e-mails",
      "SEND_TEST_EMAIL": "Envoyer un e-mail de test",
      "TEST_MAIL_SUCCESS": "L'e-mail de test a été envoyé avec succès"
    },
    "ABSENDER_NAME": "Nom de l'expéditeur",
    "NONE_AVAILABLE": "Vous n'avez pas activé de module de communication.",
    "EMAIL_TEMPLATE": {
      "TEMPLATE_NAME": "Nom",
      "CONTENT": "Contenu",
      "TITLE": "Modèles de mails",
      "SINGULAR": "Modèle d'e-mail",
      "PLURAL": "Modèles de mails",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{modèle d'e-mail} other{modèles d'e-mail}}",
      "CREATE": "Créer un modèle d'e-mail",
      "EDIT": "Éditer un modèle d'e-mail"
    },
    "FOR": {
      "BRING_ABHOLZEITEN": "Pour {{text}}",
      "BRING_ABHOLZEITEN_CONFIRM": "Confirmé pour {{text}}"
    },
    "CONTACT_USER_MUTIERT": "Dernière adaptation des parents"
  },
  "ERRORS": {
    "VALUE_REQUIRED": "Saisir une valeur valide.",
    "ERR_ADRESSE_VALIDATION_UNAVAILABLE": "Malheureusement, l'adresse ne peut pas être vérifiée pour le moment.",
    "ERR_KITA_ODER_GRUPPE": "Choisir au moins un lieu d'accueil ou un groupe.",
    "ERR_PLATZ": "Sélectionner au moins un type d'emplacement.",
    "ERR_BELEGUNG_NOT_EMTPY": "Un enfant avec des affectations ne peut pas être supprimé. Supprimez d'abord toutes les affectations pour pouvoir supprimer l'enfant.",
    "ERR_INCOMPLETE_ANMELDUNG": "Remplir intégralement l'inscription.",
    "ERR_INCOMPLETE_FORM": "Remplir entièrement le formulaire.",
    "ERR_KITA_UND_GRUPPE": "Sélectionnez un lieu d'accueil ou un groupe.",
    "ERR_ALTERS_BEDINGTE_BETREUUNGSFAKTOREN_FORM": "Aucun mois ne peut être inférieur à 1 ou défini deux fois. Un facteur ne peut pas être inférieur à 0,01. Un facteur peut avoir au maximum 2 chiffres après la virgule.",
    "ERR_NO_BETREUUNGSFAKTOR_SET": "Il faut définir au moins un facteur de prise en charge.",
    "ERR_KINDERGARTENFAKTOR_FORM": "Le facteur de maternelle peut avoir au maximum 2 décimales.",
    "ERR_FAKTOR": "Un facteur ne peut pas être inférieur à 0,01. Un facteur peut avoir au maximum 2 chiffres après la virgule.",
    "ERR_DOWNLOAD": "Le fichier n'a pas pu être téléchargé.",
    "ERR_DIFFERENT_PASSWORD": "Saisir un mot de passe unique.",
    "ERR_REQUIRED_GESCHLECHT": "Veuillez sélectionner un sexe.",
    "ERR_REQUIRED_KONTAKTPERSON": "Indiquer au moins une personne de contact.",
    "ERR_REQUIRED_KORRESPONDENZ_ADRESSE": "L'adresse de correspondance n'est pas valable. Vérifiez que tous les champs d'adresse sont activés.",
    "ERR_REQUIRED_KITA": "Choisir au moins un lieu d'accueil.",
    "ERR_REQUIRED_PLATZ": "Choisissez le type d'emplacement souhaité (privé, subventionné ou entreprise).",
    "ERR_REQUIRED_PLATZ_DYNAMIC": "Sélectionnez un emplacement souhaité ({{plaetze}}).",
    "ERR_REQUIRED_BELEGUNGSEINHEIT": "Sélectionnez au moins une unité d'occupation correcte (jour + matin / midi / après-midi).",
    "ERR_REQUIRED_ANMELDEDATUM": "Saisir la date d'inscription.",
    "ERR_INVALID_RECHNUNGSAUFTEILUNG": "Le fractionnement de la facture n'est pas valable. La somme de tous les pourcentages doit être égale à 100%. Le montant / le pourcentage doit être supérieur à 0.",
    "ERR_INVALID_ERZIEHUNGSBERECHTIGTE": "Un enfant ne peut avoir plus de deux personnes responsables.",
    "ERR_INVALID_DATE": "Saisir une date valable (par ex. 24.12.2018).",
    "ERR_INVALID_DATES": "La date de début ne doit pas être après la date de fin.",
    "ERR_INVALID_TIME_RANGE": "Jusqu'à doit être après de.",
    "ERR_DUPLICATE_TIME_RANGE": "Modules trouvés avec la même période.",
    "ERR_TECHNICAL": "Oops ! Une erreur inattendue s'est produite.",
    "ERR_REQUIRED_NAME": "Le nom ne doit pas être vide.",
    "ERR_REQUIRED_FAMILIENNAME": "Indiquer le nom de l'enfant.",
    "ERR_REQUIRED_GRUPPENNAME": "Indiquer le nom du groupe.",
    "ERR_REQUIRED_BETREUUNG_VON": "Saisir la date pour la prise en charge souhaitée.",
    "ERR_REQUIRED_PENSUM": "Saisir le taux de garde souhaité.",
    "ERR_INCOMPLETE_BEWERBUNG": "Remplir complètement le souhait de prise en charge.",
    "ERR_INCOMPLETE_KONTAKTPERSON": "Remplissez intégralement la personne de contact.",
    "ERR_REQUIRED_BIRTHDAY": "Veuillez indiquer soit la date de naissance, soit la date d'accouchement.",
    "ERR_KEINE_BENUTZER": "Il n'y a pas d'utilisateurs non-FREE.",
    "ERR_INTERNAL": "Oops ! Une erreur est survenue.",
    "ERR_BADREQUEST": "La demande n'était pas valide.",
    "ERR_REQUEST_ABORTED": "Timeout : la requête a été interrompue.",
    "ERR_FORBIDDEN": "Accès refusé.",
    "ERR_BELEGUNGSEINHEIT_CONFLICT": "Le taux d'occupation du groupe {{gruppe}} pour {{wochentag}} n'est pas valable.",
    "ERR_BELEGUNG_BEFORE_BIRTHDAY": "On a essayé de saisir une attribution à un moment où l'enfant n'est pas encore né.",
    "ERR_INVALID_BELEGUNGSEINHEIT": "La période sélectionnée pour {{wochentag}} n'est pas valide.",
    "ERR_NO_PLATZ_FOR_ZEITRAUMFELDER": "Oups, l'affectation du groupe {{gruppe}} n'a pas pu être créée.",
    "ERR_LOGIN": "Échec de la connexion. Réessayez ou connectez-vous via « Connexion externe ».",
    "ERR_INCOMPLETE_LOGIN": "Saisir une adresse e-mail et un mot de passe valables.",
    "ERR_UNKNOWN_WOCHENTAG": "Oups, le jour de la semaine {{wochentag}} est inconnu.",
    "ERR_KIND_AUSTRITT_FORM": "Remplir entièrement le formulaire de sortie.",
    "ERR_IDENTICAL_PASSWORD": "Le nouveau mot de passe ne doit pas être identique à l'ancien.",
    "ERR_STRONG_PASSWORD": "Le nouveau mot de passe doit comporter au moins 8 signes et contenir au moins une lettre, un chiffre et un caractère spécial.",
    "ERR_MAX_KLEINER_ALS_ANZAHL": "Nombre max. de places ne doit pas être vide, invalide ou inférieur au nombre de places.",
    "ERR_ANZ_PLAETZE_VALUE_UNGUELTIG": "La valeur du nombre de places ne peut pas être calculée correctement, car au moins un champ contient une valeur non valide.",
    "ERR_MAX_PLAETZE_VALUE_UNGUELTIG": "La valeur du nombre maximal de places ne peut pas être calculée correctement, car au moins un champ contient une valeur non valide.",
    "ERR_ANZ_PLAETZE_CALCULATION_FAILED": "La valeur du nombre de places ne peut pas être calculée, car le calcul n'est pas valable.",
    "ERR_MAX_PLAETZE_CALCULATION_FAILED": "La valeur du nombre maximal de places ne peut pas être calculée, car le calcul n'est pas valable.",
    "ERR_ANZ_EMPTY_MAX_NOT": "Aucune place maximale ne peut être attribuée si le nombre de places correspondant est vide ou non valide.",
    "ERR_INCOMPLETE_FIRMA": "Remplir entièrement le formulaire de l'entreprise.",
    "ERR_KONTAKT_NOT_REMOVED": "Le contact n'a pas pu être supprimé.",
    "ERR_HAUPTKONTAKT_REQUIRES_TELEPHONE": "Un contact principal a besoin d'un numéro de téléphone.",
    "ERR_ERZIEHUNGSBERECHTIGTER_REQUIRES_ADDRESS": "Les tuteurs légaux ont besoin d'une adresse.",
    "ERR_RECHNUNGSEMPFAENGER_REQUIRES_ADDRESS": "Un contact de facturation nécessite une adresse.",
    "ERR_HAUPTKONTAKT_REQUIRES_ADDRESS": "Un contact principal a besoin d'une adresse.",
    "ERR_DUPLICATE_NAME": "Les noms doivent être uniques.",
    "ERR_EMAIL_REQUIRES_SUBJECT_AND_CONTENT": "L'e-mail doit avoir un objet et un contenu",
    "ERR_NOTIZ_REQUIRES_SUBJECT_AND_CONTENT": "La note doit avoir un objet et un contenu",
    "ERR_EMAIL_REQUIRES_ABSENDER_ADRESSE": "L'e-mail doit contenir nom et l'adresse de l'expéditeur",
    "ERR_FILE_TOO_LARGE": "Un fichier est trop volumineux.",
    "ERR_FILES_TOO_LARGE": "Les fichiers sont trop volumineux.",
    "ERR_UPLOAD_FAILED": "Échec du téléchargement.",
    "ERR_FILE_NOT_VALID": "Le fichier n'est pas autorisé.",
    "ERR_FILE_FORMAT_NOT_ALLOWED": "Le format de fichier n'est pas autorisé (est autorisé {{value}}).",
    "ERR_IMAGE_SMALL_HEIGHT": "L'image n'est pas assez haute (au moins {{value}}px).",
    "ERR_IMAGE_LARGE_HEIGHT": "L'image est trop haute (max. {{value}}px).",
    "ERR_IMAGE_SMALL_WIDTH": "L'image n'est pas assez haute (au moins {{value}}px).",
    "ERR_IMAGE_LARGE_WIDTH": "L'image est trop haute (max. {{value}}px).",
    "ERR_IMAGE_RATIO": "Format d'image n'est pas autorisé ({{value}}).",
    "ERR_IMAGE_SMALL_RATIO": "Format d'image trop petite (> {{value}}).",
    "ERR_IMAGE_LARGE_RATIO": "Format d'image trop grand (< {{value}}).",
    "ERR_IMAGE_DIMENSIONS": "Taille de l'image non autorisée.",
    "ERR_TARIFPARAMETER_WITHOUT_NAMES": "Les paramètres doivent avoir un nom.",
    "ERR_UNABHAENGIG_TARIF_MIT_MONATS_BASIERTE_PARAM": "Un tarif indépendant de la prise en charge ne peut pas avoir de paramètres basés sur le mois.",
    "ERR_ABRECHUNG_VORAUS_ZUSATZ_MONATE": "Veuillez saisir un nombre entier pour les mois de facturation à l'avance.",
    "ERR_INTEGER": "Veuillez saisir un nombre entier.",
    "ERR_SMALLINVOICE_FORM": "Le jeton doit comporter exactement 32 caractères.",
    "ERR_KITA_RECHNUNGSKONFIGURATION_GUELTIG_BIS_NACH_GUELTIG_AB": "\"Valable jusqu'au\" doit être placé après \"Valable à partir du\".",
    "ERR_KITA_RECHNUNGSLAUF_FAELLIG_AM_NACH_RECHNUNGS_DATUM": "\"Échu le\" doit être postérieur à \"Date de la facture\".",
    "ERR_REVERT_AUSTRITT": "La sortie n'a pas pu être annulée.",
    "ERR_ENTITY_EXISTS": "Il existe déjà une entité de type {{entityClassName}} avec {{constraintName}}. {{duplicateValue}}.",
    "ERR_ENTITY_NOT_FOUND": "L'entité de type {{entityClassName}} avec l'ID {{entityId}} n'a pas été trouvée.",
    "ERR_RECHNUNGSLAUF": "Une erreur est survenue lors du traitement du cycle de facturation.",
    "ERR_KINDERGARTEN_CHOICE": "Veuillez faire une sélection valable pour le jardin d'enfants / l'école.",
    "ERR_KITA_RECHTE_WERDEN_GELOESCHT": "Ce rôle standard exclut les droits de lieu d'accueil. Il s'applique à tous les lieux. Les droits spécifiques aux lieux d'accueils sont donc supprimés.",
    "ERR_AUTHORIZATION": "Accès refusé.",
    "ERROR": "Erreur",
    "ERR_RULE_VIOLATION": {
      "ERR_INVALID_TEMPLATE_REPORT_TYPE": "Type du rapport du modèle ne correspond pas. Attendu : \"{{expectedValue}}\" ; à partir du modèle lu : \"{{actualValue}}\""
    },
    "PW_VIOLATION_EMPTY_PASSWORD": "Un mot de passe vide n'est pas autorisé.",
    "PW_VIOLATION_TOO_LONG": "Le mot de passe est trop long.",
    "PW_VIOLATION_EMPTY_SALT": "L'enregistrement du mot de passe a échoué.",
    "PW_VIOLATION_CONFIRMATION_FAILURE": "Les mots de passe ne correspondent pas.",
    "SI_ERR_CLIENT_DOES_NOT_EXIST": "Le contact de facturation {{rechnungsEmpfaengerDisplayName}}, n° de client {{numéro de client}, n'existe plus sur SmallInvoice. Contactez le support technique.",
    "SI_ERR_CLIENT_IS_DELETED": "La facture {{rechnungsNummer}} ne peut pas être créée car le contact de facturation {{rechnungsEmpfaengerDisplayName}} a été supprimé sur Smallnvoice.",
    "SI_ERR_CLIENT_NUMBER_DUPLICATE": "Il y a déjà un contact de facturation sur SmallInvoice avec le n° de client. {{kundenNummer}}.",
    "SI_ERR_COULD_NOT_GET_PDF": "Le PDF n'a pas pu être téléchargé par SmallInvoice.",
    "SI_ERR_CONTACT_OF_INVALID_CLIENT": "Le contact de facturation n'est pas valide. Contactez le support technique.",
    "SI_ERR_CLIENT_ADDRESS_DOES_NOT_EXIST": "L'adresse du client n'existe plus sur SmallInvoice. Contactez le support technique.",
    "SI_ERR_ESR_DOES_NOT_EXIST": "La configuration du bulletin de versement n'existe plus sur SmallInvoice. Contactez le support technique.",
    "SI_ERR_EMAIL_ADDRESS": "L'adresse e-mail de {{rechnungsEmpfaengerDisplayName}} n'a pas été accepté par SmallInvoice.",
    "SI_ERR_INVALID_CONTACT_ID": "Les contacts de {{rechnungsEmpfaengerDisplayName}} n'ont pas pu être mis à jour. Contactez le support technique.",
    "SI_ERR_ESR_NUMBER_DUPLICATE": "Il y a déjà une facture sur SmallInvoice avec le numéro de BVR {{esrNummer}}.",
    "SI_ERR_MISSING_DOCUMENT_TYPE": "La facture {{rechnungsNummer}} n'a pas pu être transmise. Contactez le support technique.",
    "SI_ERR_INVOICE_DOES_NOT_EXIST": "La facture {{rechnungsNummer}} n'existe plus sur SmallInvoice.",
    "SI_ERR_INVOICE_NUMBER_DUPLICATE": "Il y a déjà une facture sur SmallInvoice avec le n° {{rechnungsNummer}}.",
    "SI_ERR_OBJECT_DOES_NOT_EXIST": "Un objet n'a plus été trouvé sur SmallInvoice.",
    "SI_ERR_OVERDUE_PAYMENT": "La facture de SmallInvoice n'a pas été payée à temps. SmallInvoice a bloqué le compte pour cette raison.",
    "SI_ERR_PAYDATE": "La date de la facture doit être antérieure à la date du paiement.",
    "SI_ERR_PAYMENTS_TOO_LARGE_AMOUNT": "Un paiement n'a pas pu être transmis, car aucun montant supérieur au total de la facture n'est autorisé.",
    "SI_ERR_RATE_LIMIT": "SmallInvoice est surchargé. Réessayez dans quelques minutes et contactez le support.",
    "SI_ERR_TOKEN_EXPIRED": "Le jeton API des configurations de facturation n'est pas valide.",
    "SI_ERR_NO_TOKEN": "Le jeton API des configurations de facturation est manquant.",
    "SI_ERR_NO_ACCESS_RIGHTS": "Droits manquants pour au moins un objet demandé.",
    "SI_ERR_UNKNOWN": "La communication avec SmallInvoice a échoué en raison d'une erreur inconnue : {{message}}.",
    "SI_ERR_USER_DELETED": "La communication avec SmallInvoice échoue parce que l'utilisateur a été supprimé sur SmallInvoice.",
    "SI_SERVICE_UNAVAILABLE": "SmallInvoice n'est malheureusement pas disponible pour le moment. Réessayez plus tard.",
    "SI_ERR_ZAHLUNG_STORNIEREN": "Un paiement SmallInvoice ne peut être annulé que sur SmallInvoice.",
    "ERR_FAKTURA_ENDPOINT_ESR": "Les options de bulletin de versement ne peuvent pas être chargées depuis {{provider}} pour le moment.",
    "ERR_FAKTURA_ENDPOINT_INVOICE": "Les factures ne peuvent pas être chargées depuis {{provider}} pour le moment.",
    "ERR_FAKTURA_ENDPOINT_ACCOUNT": "Les comptes de paiement ne peuvent pas être chargés depuis {{provider}} pour le moment.",
    "ERR_FAKTURA_ENDPOINT_PAYMENT": "Les paiements ne peuvent pas être chargés depuis {{provider}} pour le moment.",
    "ERR_FAKTURA_ENDPOINT_LAYOUT": "Les options de mise en page ne peuvent pas être chargées depuis {{provider}} pour le moment.",
    "ERR_FAKTURA_ENDPOINT_CLIENT": "Les clients ne peuvent pas être chargés depuis {{provider}} pour le moment.",
    "ERR_STORNIEREN": "Les factures n'ont pas pu être annulées en raison d'un problème technique.",
    "ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL": "La personne de contact n'a pas laissé d'adresse e-mail.",
    "ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL_SHORT": "Pas d'adresse e-mail enregistrée.",
    "PRIORITIZE_JIRA_TASK": "Pour ne plus voir cette erreur, augmentez la priorité de {{task}}.",
    "KITAX_IMPORT_ERROR": "Le fichier téléchargé n'a pas pu être lu.",
    "ERR_CHF": "Le montant (CHF) doit être supérieur à 0.",
    "ERR_ANZAHL": "Le nombre doit être supérieur à 0 et arrondi à 3 chiffres maximum après la virgule.",
    "ERR_VON_BIS_SAME_MONTH": "De & À doivent être dans le même mois.",
    "ERR_KIND_NOT_FIND": "Pas d'enfant sélectionné pour l'assignation",
    "ERR_KONTAKT_NOT_FIND": "Aucun contact sélectionné pour l'assignation",
    "ERR_EINZAHLUNGSSCHEIN_KONTO": "Le numéro de participant (bulletin de versement orange) ou le QR-IBAN (facture QR) doit être activé.",
    "ERR_DOWNLOAD_MALWARE": "Un malware/virus a été détecté dans le fichier. Si vous souhaitez tout de même télécharger le fichier, veuillez contacter le support.",
    "ERR_SCHLIESSTAG_FORM": "Il y a une erreur dans les noms ou les périodes.",
    "ERR_REQUIRED_BELEGUNGSEINHEIT_FOR": "Sélectionnez au moins une unité d'occupation correcte (jour + matin / midi / après-midi) pour {{name}}.",
    "ERR_INVALID_REPEAT_COUNT": "nombre de répétitions non valable",
    "ERR_WARTELISTE_MIT_GRUPPEN": "Si \"Sur liste d'attente\" est sélectionné, aucun groupe ne peut être sélectionné.",
    "ERR_WARTELISTE_BELEGUNG": "Parmi les options \"Sur liste d'attente\" et \"Avec occupation\", au moins une doit être sélectionnée.",
    "ERR_ONE_KONTAKT_REQUIRED": "Aucun destinataire n'a été trouvé avec les paramètres de filtrage.",
    "ERR_INVALID_JAHR": "Saisir une année valable (par ex. 2018).",
    "ERR_SERVICE_UNAVAILABLE": "kitAdmin n'est pas disponible pour le moment. Nous revenons tout de suite.",
    "CONFIGURATION_ERROR": "La configuration de la facturation est erronée : {{message}}.",
    "SPF": {
      "TITLE": "Avertissement",
      "TEXT": "L'entrée SPF n'est pas correcte.<br>Les e-mails n'arriveront probablement pas correctement.<br>Veuillez contacter le support technique.",
      "LINK_TEXT": "Article de blog sur le SPF",
      "LINK_URL": "https://blog.kitadmin.ch/e-mails-aus-kitadmin-verschicken-e-mail-werden-von-empfaengern-zurueckgewiesen"
    },
    "ERR_EMAIL_REQUIRES_EMPFAENGER_ADRESSE": "L'adresse du destinataire doit être valide",
    "ERR_FIRMA_REQUIES_ADDRESS_NAME_AND_TELEFON": "Une entreprise nécessite un nom, d'une adresse et d'un numéro de téléphone",
    "ERR_INVALID_KINDERORT_ANGESTELLTE": "Choisir au moins un lieu d'accueil ou un employé.",
    "ERR_REVERT_BEENDEN": "La fin n'a pas pu être annulée.",
    "ERR_EINGEWOEHNUNG_VON_BIS": "Veuillez indiquer le début et la fin de la période d'adaptation.",
    "ERR_LOGIN_TOKEN_EXPIRED": "Désolé, cette méthode d'inscription n'est plus valable.",
    "ERR_ACCEPT_AGB": "Pour continuer, les conditions générales doivent être acceptées.",
    "ERR_UPLOAD_FAILED_MULTI_FILE": "Échec du téléchargement. Veuillez supprimer le fichier qui a échoué.",
    "ERR_INVALID_ARBEITSZEIT": "Le temps de fin ne peut pas être antérieur au temps de début."
  },
  "TARIF": {
    "TYPE": {
      "BETREUUNGS_ABHAENGIG": "Dépend de soins",
      "BETREUUNGS_UNABHAENGIG": "Indépendant de soins",
      "BETREUUNGS_STUNDEN": "Heures de prise en charge",
      "UNMODIFIABLE": "le type de tarif ne peut plus être modifié ultérieurement",
      "PROVISORISCHER_BG": "Correction provisoire du bon"
    },
    "PARAM_TYPE": {
      "GLOBAL_PARAM": "Global",
      "MONATS_BASIERT": "Mensuel"
    },
    "PARAM_DESCRIPTION": {
      "GLOBAL_PARAM": "Global : sur les données de l'enfant, les valeurs des paramètres tarifaires sont appliquées avec une validité et reliées à un lieu d'accueil.",
      "MONATS_BASIERT": "Mensuel : Sur la fraction (groupe/personne de garde), les valeurs des paramètres tarifaires par mois et par enfant sont consignées dans la feuille mensuelle et reliées à la fraction."
    },
    "ABRECHNUNG_TYP": {
      "MF": "{name, select, PRIVAT{Privater Tarif} SUBVENTIONIERT{Subventionierter Tarif} BETREUUNGSGUTSCHEIN{Betreuungsgutschein Frais} BETREUUNGS_UNABHAENGIG{Betreuungsunabhängiger Tarif} PROVISORISCHER_BG{Provisorische Gutscheinkorrektur Tarif} other{}}"
    },
    "PARAMETER_HINZUFUEGEN": "Définir les paramètres",
    "NAME_PARAMETER": "Nom Paramètre",
    "AKTUELLER_TARIF_VALUE_MIT_DATUMPREP": "Version actuelle du tarif {{preposition}}",
    "TARIF_VALUE_MIT_DATUMPREP": "Version actuelle du tarif {{preposition}}",
    "TARIF_VALUE": "Version tarifaire",
    "TARIF_VALUE_VERSION": "V{{version}}",
    "TARIF_HOCHLADEN_LABEL": "Télécharger le calculateur de tarif Excel",
    "TARIF_DOWNLOAD_LABEL": "Télécharger le calculateur de tarif Excel",
    "TARIF_VORLAGE_LABEL": "Modèle de tarif",
    "TARIF_STAMMDATEN": "Tarif Données de base",
    "VORLAGEN": "Modèles",
    "TARIF_NEUER_VALUE": "Télécharger une nouvelle version du tarif",
    "TARIF_BEENDEN": "Mettre fin au tarif",
    "TARIF_DELETE": "Supprimer le tarif",
    "EROEFFNUNG": "Ouverture",
    "POSITIONS_TOTAL_MANUAL": "Lire le total des postes dans la colonne du montant total",
    "POSITIONS_TOTAL_AUTO": "Calculer le total des positions à partir du nombre et du montant",
    "BETREUUNGS_UNABHAENGIG_TARIF": "Tarif indépendant de la prise en charge",
    "TARIF_TYPE_WAEHLEN": "1. choisir le type",
    "NAME_EINGEBEN": "2. saisir le nom",
    "TARIF_PARAMETER": "3. paramètres",
    "EXCEL_TEMPLATE_HERUNTERLADEN": "4. télécharger le modèle Excel préparé",
    "EXCEL_DATEI_HOCHLADEN": " 5. télécharger le fichier Excel",
    "OPTIONEN": "6. options",
    "TARIF_TYP": "Type : {{type}} ",
    "PARAM_TYP": "Type de param",
    "USED_BY": "Utilisé par",
    "ERRORS": {
      "ERR_INCOMPLETE_TARIF": "Remplir complètement le tarif (avec le fichier tarif à télécharger).",
      "ERR_DUPLICATED_TARIFPARAMETER_NAMES": "Les noms des paramètres doivent être uniques.",
      "ERR_INCOMPLETE_PARAMETER_HISTORY_ENTRY": "Remplir \"Valable à partir de\" et ne saisir que des chiffres comme valeurs de paramètres.",
      "ERR_BETREUUNGSGUTSCHEIN_VERGUENSTIGTER_BETRAG": "Bon (CHF) doit être supérieur à 0.",
      "ERR_BETREUUNGSGUTSCHEIN_VOLLKOSTEN": "Coût total (CHF) doit être supérieur à 0.",
      "ERR_BETREUUNGSGUTSCHEIN_GUTSCHEIN_LARGER_VOLLKOSTEN": "Le montant du bon de garde ne peut pas être supérieur aux coûts complets.",
      "ERR_BETREUUNGSGUTSCHEIN_BG_PENSUM_PROZENT": "BDG-Taux d'activité (%) doit être supérieur à 0%.",
      "ERR_BETREUUNGSGUTSCHEIN_KITA": "Veuillez sélectionner un lieu d'accueil.",
      "ERR_BETREUUNGSGUTSCHEIN_WERTE": "Le montant du bon de garde ne peut pas être supérieur aux coûts complets."
    },
    "NULL_TARIF": "Tarif zéro"
  },
  "KIBON": {
    "KIBON": "kiBon",
    "NAV_VERFUEGUNGEN": "Décisions",
    "NAV_INSTITUTIONEN": "Institutions",
    "INSTITUTION_MATCHING": {
      "INSTITUTIONS": "Institutions",
      "INSTITUTIONS_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Institution} other{Institutions}}",
      "ZUWEISEN": "Assigner l'institution <strong>{{institution}}</strong> au lieu d'accueil <strong>{{lkinderOrt}}</strong> ?",
      "ZUWEISUNG_ENTFERNEN": "L'institution assignée <strong>{{institution}}</strong> doit-elle être dissociée du lieu d'accueil <strong>{{kinderOrt}}</strong> ?",
      "ASSIGNED_KINDER_ORT": "Institution assignée :",
      "MATCHING_STATE": "Statut",
      "MATCHING_STATE_OPEN": "Non assignée",
      "MATCHING_STATE_REMOVED": "Supprimé",
      "MATCHING_STATE_MATCHED": "Assigné",
      "MATCHING_STATE_SKIPPED": "Ignoré",
      "MATCHING_STATE_MANUALLY_ASSIGNED": "Assigné manuellement",
      "MATCHING_STATE_IGNORED": "Ignoré"
    },
    "OHNE_INSTITUTION": "Sans institution attribuée",
    "MUTATION": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{déclaration de prise en charge} other{déclarations de prise en charge}}",
      "IGNORED_UM": "{{time}} par {{benutzer}}",
      "KEINE_IGNORIERTE_MELDUNGEN": "Il n'y a pas de déclarations de prise en charge ignorées.",
      "MELDEN": "Envoyer les prises en charge à kiBon",
      "MELDEN_CONFIRM_TITLE_MF": "{count, plural, one{Envoyer la prise en charge à kiBon ?} other{Envoyer # prises en charge à kiBon ?}}",
      "MELDEN_CONFIRM_TEXT": "Pour chaque prise en charge envoyée à kiBon, une déclaration de mutation est établie. Ces avis de mutation ne peuvent pas être retirés<br><br>Contrairement au cycle de facturation, les données ne sont pas mises à jour automatiquement. C'est-à-dire que si, par exemple, une occupation ou un tarif change, les données doivent être recalculées manuellement.",
      "NEU_GENERIEREN_CONFIRM_TEXT": "Pour les lieux d'accueil avec beaucoup d'enfants, il faut compter jusqu'à une heure pour que les calculs soient terminés. Pendant ce temps, il ne faut pas modifier l'occupation.",
      "STATUS_TOOLTIP_MF": "{provisorisch, select, true{Cette déclaration de prise en charge ne peut pas être envoyée à kiBon car il y a une occupation non définitive ou une sortie provisoire dans la période.} other{{status, select, MUTIERBAR{La déclaration de prise en charge peut être envoyée à kiBon} NOT_MUTIERBAR{Cette déclaration de prise en charge est probablement incomplète et ne peut donc pas être envoyée à kiBon.} other{???}}}}",
      "KIBON_GENERATION_VIOLATION_MF": "Aucune déclaration de prise en charge n'a pu être générée pour le numéro de référence {refNr} car : <ul>\n{KIND_ENABLED, select, true{<li>La case \"Désactiver la confirmation de place/la notification de mutation\" a été cochée pour l'enfant</li>} other{}}\n{PRIVATE_PLAETZE, select, true{<li>L'enfant n'a pas de place privée dans la période</li>} other{}}\n{MODUL_DYNAMISCHER_BELEGUNGSPLAN, select, true{<li>Le module plan d'occupation dynamique est absent</li>} other{}}\n{FEATURE_KIBON_MELDUNGEN, select, true{<li>L'autorisation KiBon spécifique au lieu d'accueil des enfants est manquante</li>} other{}}</ul>",
      "VON": "De",
      "GENERATION_RESULTS": "Calcul actuel",
      "GENERTION_RESULT_MF": "{success, select, true{message généré} other{message non généré}}}",
      "TRIGGER": {
        "MUTATION": "Mutation",
        "BETREUUNG_ANFRAGE": "Confirmation de place",
        "ERNEUTE_BETREUUNG_ANFRAGE": "Confirmation de place"
      },
      "NEBEN_MAHLZEITEN": "Repas secondaires\nTotal CHF",
      "PENDENTE_MELDUNGEN": "Déclarations de prise en charge en suspens",
      "PENDENTE_MELDUNGEN_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{déclaration de prise en charge en attente} other{déclarations de prise en charge en attente}}",
      "GESENDETE_MELDUNGEN": "Déclarations de prise en charge envoyées",
      "IGNORIERTE_MELDUNGEN": "Déclarations de prise en charge ignorées",
      "GESENDET_UM": "{{time}} par {{benutzer}}",
      "KEINE_PENDENTE_MELDUNGEN": "Il n'y a pas de déclaration de prise en charge en suspens.",
      "KEINE_GESENDETE_MELDUNGEN": "Il n'y a pas de déclaration de prise en charge envoyées.",
      "IGNORIEREN": "Ignorer la prise en charge",
      "MELDEN_IGNORE_TITLE_MF": "{count, plural, one{Ignorer la prise en charge ?} other{Ignorer # prises en charge ?}}",
      "MELDEN_IGNORE_TEXT": "Les prises en charge sélectionnées sont ignorées et ne peuvent pas être envoyées à kiBon.",
      "AUSSERORDENTLICHER_BETREUUNGSAUFWAND": "Prise en charge extraordinaire",
      "BIS": "Jusqu'à",
      "BETREUUNGS_KOSTEN": "Frais de prise en charge CHF",
      "BETREUUNGS_PENSUM": "Taux",
      "BETREUUNGS_PENSUM_MF": "{pensumUnit, select, DAYS{ {betreuungspensum} jours } HOURS{ {betreuungspensum} heures } other{ {betreuungspensum}% }}",
      "BETREUUNGS_PENSUM_MONTLY_MF": "{pensumUnit, select, DAYS{ {betreuungspensum} ({monthly})<sup>1</sup> jours } HOURS{ {betreuungspensum} ({monthly})<sup>1</sup> heures } other{ {betreuungspensum}% }}",
      "HAUPT_MAHLZEITEN": "Repas principaux\nTotal CHF",
      "NEU_GENERIEREN": "Recalculer les données pour les périodes actives",
      "MONTHLY_INFO": "¹ Pour kiBon, les valeurs (entre parenthèses) doivent être saisies pour un mois entier.",
      "COMPARISON": {
        "DIFFERENT": "Des modifications ont été trouvées",
        "UNDEFINED": "Les valeurs ne peuvent pas être comparées"
      },
      "NICHT_IGNORIEREN": "Ne plus ignorer la déclaration de prise en charge ?",
      "BETREUUNGSMELDUNG_GENERIERT": "Une déclaration de prise en charge a pu être générée.",
      "BETREUUNGSMELDUNG_IGNORIERT": "L'enfant a déjà une décision plus récente. La déclaration de prise en charge est donc superflue et a été ignorée."
    },
    "VERFUEGUNGEN": {
      "STATE_INFO": {
        "IGNORED": "Ignoré",
        "OPEN": "Non assigné",
        "MATCHED": "Assigné automatiquement",
        "MANUALLY_ASSIGNED": "Assigné manuellement",
        "REMOVED": "Assignation supprimée"
      },
      "VERFUEGUNG_VOM": "Décision du {{date}}",
      "MATCHING_TEXT": "Pour qu'une décision soit attribuée automatiquement, les points suivants doivent concorder :<br>Nom, prénom(s) et date de naissance de l'enfant.<br>Nom et prénom(s) du contact et du demandeur.<br>En outre, la décision ne doit pas contenir de bons pour des périodes durant lesquelles l'enfant dispose déjà de bons d'une autre décision dans kitAdmin.",
      "BULK_UN_IGNORE_CONFIRM_PERIODE": "Ainsi, le statut de toutes les décisions ignorées qui sont entièrement contenues dans la période sélectionnée est changé en \"non assignée\"",
      "IGNORE_TITLE": "Ignorer la décision",
      "IGNORE_SUBTITLE": "Êtes-vous sûr de vouloir ignorer la décision et de ne pas l'attribuer à un enfant ?",
      "VERFUEGUNGS_DATEN": "Dates des décisions",
      "INSTITUTION_TRAEGERSCHAFT": "Lieu d'accueil et organisme responsable",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{décision} other{décisions}}",
      "HEADER": "Décisions",
      "STATE": {
        "OPEN": "Non assigné",
        "MATCHED": "Assigné automatiquement",
        "MANUALLY_ASSIGNED": "Assigné manuellement",
        "REMOVED": "Assignation supprimée",
        "IGNORED": "Ignoré"
      },
      "VERSION_MF": "{version, plural, =0{1. décision} other{#. mutation}}",
      "MATCHING_HEADER": "Assignation automatique",
      "NOT_ASSIGNED": "Décisions non attribuées",
      "ASSIGN_DIRECTLY": "Attribuer directement une décision",
      "ASSIGN_CONFIRMATION_HEADER": "Attribuer définitivement la décision à cet enfant ?",
      "UN_IGNORE_TITLE": "Ignorer la décision",
      "GESUCHSTELLER": "Requérant",
      "BULK_IGNORE": "Ignorer en masse",
      "BULK_IGNORE_CONFIRM_ALLE": "Ainsi, toutes les décisions sont ignorées - même celles qui ont déjà été attribuées.",
      "BULK_IGNORE_CONFIRM_PERIODE": "Ainsi, toutes les décisions qui sont entièrement contenues dans les périodes sélectionnées sont ignorées - même celles qui ont déjà été affectées.",
      "BULK_UN_IGNORE": "Annuler ignorer en masse",
      "BULK_UN_IGNORE_CONFIRM_ALLE": "Le statut de toutes les décisions ignorées passe alors à \"non assignée\""
    },
    "ANFRAGEN": {
      "STATE_INFO": {
        "PENDING": "Affiche les demandes de confirmation de place qui n'ont pas pu être attribuées automatiquement à un enfant.\n\nLe bouton Attribuer permet de les attribuer à l'enfant et d'envoyer ensuite un message de confirmation\nde place, dans la mesure où il trouve un message de prise en charge à envoyer.",
        "ASSIGNED": "Demandes de confirmation de place qui ont été attribuées à un enfant,\nmais pour lesquelles aucune déclaration n'a pu être générée.\n\nVérifier l'attribution et cocher la case\n\"Désactiver la confirmation automatique de place kiBon/la notification de mutation\".",
        "PREPARED": "Demandes de confirmation de place pour lesquelles un message de prise en charge non encore envoyé a été généré.\n\nEnvoyer des annonces de prise en charge via Enfant -> kiBon ou Lieu d'accueil -> envoyer annonce de prise en charge kiBon.",
        "COMPLETED": "La notification de prise en charge/confirmation de place a été envoyée à kiBon.",
        "IGNORED": "La confirmation de place a été ignorée et ne sera pas prise en compte\npour la période en cours."
      },
      "STATE": {
        "IGNORED": "Ignoré",
        "PENDING": "Non assigné",
        "ASSIGNED": "Assigné",
        "PREPARED": "Prêt pour envoi",
        "COMPLETED": "Envoyé"
      },
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{confirmation de place} other{confirmations de place}}",
      "HEADER": "Confirmations de place",
      "MATCHING_TEXT": "Pour qu'une confirmation de place soit automatique, les points suivants doivent correspondre :<br>Nom, prénom(s) et date de naissance de l'enfant.<br>Nom et prénom(s) du contact et du mandant.",
      "ACHTUNG_TEXT": "S'il ne trouve pas d'avis de prise en charge à envoyer, cela peut être dû au fait que l'avis de prise en charge a déjà été envoyé, ou que la case \"Désactiver la confirmation automatique de place kiBon/avis de mutation\" est cochée, ou encore que l'enfant n'a peut-être pas de prise en charge privée du tout. ",
      "ACHTUNG_HEADER": "Attention",
      "ASSIGN_DATEN": "Données de la demande",
      "ANFRAGE_VOM": "Demande du {{date}}",
      "MATCHING_HEADER": "Assignation automatique",
      "ZUWEISUNG_AUFHEBEN": "Annuler l'assignation ?",
      "IGNORIEREN": "Ignorer la confirmation de place ?",
      "NICHT_IGNORIEREN": "Ne plus ignorer la confirmation de place ?",
      "ASSIGN_CONFIRMATION_HEADER": "Confirmer définitivement cette place avec cet enfant ?"
    },
    "NAV_TAGESSCHULE_ANMELDUNGEN": "Inscriptions",
    "KIND_MATCHING": {
      "VALUES_NOT_EQUAL": "Les valeurs qui ne correspondent pas (données kiBon et kitAdmin) sont marquées en rouge.",
      "KINDER_NAME": "Nom de l'enfant",
      "GEBURTSDATUM_KIND": "Date de naissance de l'enfant",
      "GESUCHSTELLER_NAME": "Nom du requérant",
      "KONTAKT_NAME_MF": "{count, plural, one{Nom personne de contact} other {Noms personnes de contact}}",
      "KINDERORT_DATEN": "Données kitAdmin"
    },
    "KIND_NAME_FILTER": "Nom de l'enfant",
    "NAV_ALLE_VERFUEGUNGEN": "Toutes les décisions",
    "NAV_ANFRAGEN": "Confirmations de place",
    "REFERENZ_NUMMER": "Numéro de référence",
    "IMPORTIERT_AM": "Importé le {{date}}",
    "PERIODE": "Période",
    "TAGESSCHULE_ANMELDUNG": {
      "ABLEHEN": "Refuser l'inscription ?",
      "ASSIGN_DATEN": "Données de l'inscription",
      "ABWEICHUNG_SEMESTER_2": "L'enfant a besoin d'autres heures de garde au deuxième semestre, car l'horaire a changé.",
      "STATE": {
        "ABGELEHNT": "Refusé",
        "TITLE": "Statut",
        "PENDING": "Non assignée",
        "ASSIGNED": "Assignée",
        "COMPLETED": "Envoyé",
        "IGNORED": "Ignoré"
      },
      "STATE_INFO": {
        "PENDING": "Affiche les inscriptions à l'école de jour qui n'ont pas encore été attribuées à un enfant.\n\nLe bouton \"Assigner\" permet de les attribuer à un enfant.",
        "ASSIGNED": "Inscriptions école de jour qui ont été attribuées à un enfant.",
        "COMPLETED": "Une confirmation d'inscription à l'école de jour a été envoyée à kiBon.",
        "IGNORED": "L'inscription à l'école de jour a été ignorée.",
        "ABGELEHNT": "L'inscription à l'école de jour a été refusée.",
        "ASSIGN_CONFIRMATION_HEADER": "Inscription définitivement attribuée à cet enfant ?"
      },
      "IGNORIEREN": "Ignorer l'inscription ?",
      "UEBERNEHMEN": {
        "MATCHES": "Importer l'inscription",
        "ABGELEHNT": "Cette inscription a été refusée.",
        "IGNORED": "Cette inscription a été ignorée.",
        "MATCHES_INFO_MF": "{count, plural, =0{Aucun ménage} one{Un ménage} other{# ménages trouvés}} à la même adresse. Veuillez sélectionner {count, plural, =0{} other{un}} pour rechercher vous-même un enfant déjà saisi ou saisir un nouvel enfant.",
        "NO_MATCHES": "Aucun enfant n'a été trouvé avec un nom et une adresse similaires."
      },
      "NICHT_IGNORIEREN": "Ne plus ignorer l'inscription ?",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Inscription à l'école de jour} other{Inscriptions à l'école de jour}}",
      "HEADER": "Inscriptions à l'école de jour",
      "KIBON_ANMELDUNG": "Inscription kiBon",
      "CONFIRMED_KIBON_ANMELDUNG": "Inscription kiBon confirmée",
      "KIBON_STATE": {
        "SCHULAMT_ANMELDUNG_ERFASST": "Saisit",
        "SCHULAMT_ANMELDUNG_AUSGELOEST": "Déclenchée",
        "SCHULAMT_MODULE_AKZEPTIERT": "Acceptée",
        "SCHULAMT_ANMELDUNG_UEBERNOMMEN": "Repris",
        "SCHULAMT_ANMELDUNG_ABGELEHNT": "Refusée",
        "SCHULAMT_FALSCHE_INSTITUTION": "Fausse institution",
        "SCHULAMT_ANMELDUNG_STORNIERT": "Annulé"
      },
      "PROFILE": "Profil",
      "DECLINE": "Refuser l'inscription ?",
      "ANGEMELDET_AM": "Inscrit le {{date}}",
      "ZUWEISUNG_AUFHEBEN": "Annuler l'assignation ?",
      "ANMELDEN": "Reprendre l'inscription",
      "ASSIGN_CONFIRMATION_HEADER": "Attribuer l'inscription à cet enfant ?",
      "BESTAETIGEN": "Confirmer",
      "MODIFY": "Confirmation adaptée pour kiBon",
      "INTERVALL": "Intervalle",
      "WEEKLY": "Hebdomadaire",
      "ALTERNATING_MODULE": "Toutes les 2 semaines",
      "EINTRITTSDATUM": "Date d'entrée :",
      "PLAN_KLASSE": "(Plan) Classe",
      "CONFIRM": "Confirmer l'inscription ?",
      "CONFIRM_SUCCESS": "L'inscription a été confirmée avec succès."
    },
    "REFERENZ_NUMMER_INFO": {
      "FALL_NR": "Cas n° : {{fallNr}}",
      "PERIOD": "Période : {{dateAb}}/{{dateBis}}",
      "GEMEINDE": "Commune : {{gemeinde}}"
    },
    "MAINTENANCE": {
      "QUERY_API": "Importer des données de kiBon",
      "LIMIT": "limite",
      "AFTER_ID": "after_id",
      "INSTITUTION": "Institution"
    }
  },
  "KIBON_ABZUG": {
    "ABZUG_DISPLAY": "Valeur de déduction kiBon : {{abzug}}%",
    "KIBON_ABZUG_PRO_TAG": "Déduction par jour",
    "INFO_PAUSCHAL_ABZUG": "Déduction forfaitaire générale pour les enfants fréquentant une école maternelle interne.",
    "INFO_BETREUUNGSFAKTOR_REGEL": "Pour les enfants qui vont à l'école maternelle, une valeur de correction pour la déclaration de prise en charge automatisée via l'interface kiBon est nécessaire, car l'enfant n'est pas pris en charge par le lieu d'accueil pendant les heures de fréquentation de l'école.\nIl est possible de définir ici une déduction qui sera déduite de la charge de travail pour chaque jour de garde.\nPar défaut, la valeur n'est déduite que si une matinée est occupée. Cela peut être modifié via la case à cocher supplémentaire.\nSi des déductions individuelles sont nécessaires, elles peuvent être effectuées via un nouveau type de place avec une déduction spécifique.",
    "INFO_PLATZ_ART": "Pour des enfants qui fréquentent l'école maternelle et qui occupent ce type de place, la charge de travail quotidienne est réduite. Ainsi, la charge de travail résultant de la fréquentation de l'école maternelle peut être corrigée.",
    "NACHMITTAGS_ABZUG": "Tenir compte des déductions également l'après-midi",
    "KEIN_ABZUG_DEFINIERT": "Pas de valeur de déduction kiBon définie",
    "TITLE": "Déduction kiBon de la charge de travail liée à l'école maternelle par jour",
    "HINZUFUEGEN": "Ajouter la déduction de la charge de travail kiBon",
    "KIBON_INTERN_KG_PAUSCHAL_ABZUG": "kiBon Déduction forfaitaire pour école maternelle interne"
  },
  "PERSONAL": {
    "ANGESTELLTE": {
      "EXCLUDED_AUSWAEHLEN": "sélectionner les employés exclus",
      "SINGULAR": "Employée",
      "PLURAL": "Employés",
      "MF": "{showcount, select, true{{count} } other{}} employés",
      "NONE": "Pas d'employés",
      "ALLE": "Tous les employés",
      "TITLE": "Données de base des employés",
      "CREATE": "Saisir les employés",
      "REMOVE_ADDRESS": "Supprimer l'adresse",
      "ASSIGN_USER": "Attribuer des utilisateurs",
      "AUSWAEHLEN": "Sélectionner les employés",
      "EXCLUDED": "Employés exclus :",
      "ADD_ADDRESS": "Ajouter une adresse"
    },
    "ZUWEISUNG": {
      "VERLAUF": "Historique des attributions",
      "OPTIONAL_BIS": "Jusqu'à (en option)",
      "DIENST_BESCHREIBUNG": "{{name}} : {{von}} - {{bis}}",
      "ZUWEISUNG_BEENDEN_HEADING": "Arrêter l'attribution de service pour {{assignedEntity}} ?",
      "TERMINATION_MODE": {
        "TERMINATE": "Terminer l'attribution de service le {{date}}. Les attributions de service futures sont conservées.",
        "DELETE_TILL_NEXT_GAP": "Terminer l'attribution de service le {{date}} et directement supprimer les attributions de service qui suivent.",
        "DELETE_FOLLOWING": "Mettre fin à l'attribution de service le {{date}} et supprimer toutes les attributions de service futures."
      },
      "TITLE": "Attribution à un service",
      "PLURAL": "Attribution à un service",
      "COPY_TARGET": "Insérer dans les semaines",
      "COPY_CONFLICT_TITLE": "Conflit lors de la copie",
      "COPY_CONFLICT_SUBTITLE": "Des attributions existent déjà dans la zone d'insertion.<br> Faut-il les supprimer ou les conserver ?",
      "COPY_CONFLICT_DELETE": "supprimer",
      "COPY_CONFLICT_KEEP": "maintenir",
      "ZUWEISUNG_BEENDEN": "Mettre fin à l'attribution de service",
      "FROM": "Assignation à partir de {{date}}",
      "NONE": "Aucune assignation disponible",
      "EDIT": "Modifier l'assignation",
      "ZUWEISUNG": "Attribution à un service",
      "ZUWEISBARE_DIENSTE": "Services attribuables",
      "UEBERNEHMEN": "Reprendre l'assignation",
      "KOPIE_MIT_ZUSATZ": "Nouveau avec des services supplémentaires",
      "KOPIE_OHNE": "Supprimer des services",
      "COPY": "Copier",
      "COPY_TITLE": "Copier les assignations",
      "COPY_SOURCE": "Semaines à copier",
      "NEUE_ZUWEISUNG": "Nouvelle attribution de service",
      "CONFIRM_BEENDEN": "Il y a encore des services attribués après {{date}}. Comment les gérer ?",
      "VON_BIS_TOOLTIP": "{{von}}-{{bis}}\nAttribution de service ",
      "EMAIL_TITLE": "Envoyer le planning par e-mail",
      "EMAIL": "E-mail",
      "EMAIL_RECIPIENT_COUNT_MF": "{count} {count, plural, one{E-Mail} other{E-Mails}} envoyable-s",
      "SEND_EMAIL": "Envoyer le planning",
      "ANGESTELLTE_EMAIL_MISSING": "Aucune adresse e-mail n'a été enregistrée pour les employés suivants :",
      "PERSONALPLAN_GENERIEREN": "Rapport"
    },
    "BETREUUNGS_SCHLUESSEL": {
      "TYPE": {
        "SINGULAR": "Type du taux de prise en charge",
        "FRAKTION": "Taux de fraction",
        "KINDER_ORT": "Taux du lieu d'accueil"
      },
      "BEDARF_MF": "{type, select, BOTH{à partir de {plaetze, plural, =1{# place} other{# places}}, à partir de {kinder, plural, =1{# enfant} other{# enfants}}} KINDER{à partir de {kinder, plural, =1{# enfant} other{# enfants}}} PLAETZE{à partir de {plaetze, plural, =1{# place} other{# places}}} other{}}",
      "CREATE": "Créer un taux de prise en charge",
      "DEFAULT_TAG": "Taux indépendant du jour",
      "MF": "{showcount, select, true{{count} } other{}}taux de prise en charge",
      "PLURAL": "Taux de prise en charge",
      "SINGULAR": "Taux de prise en charge",
      "SCHLUESSEL": "Taux",
      "KEIN_BETREUUNGS_SCHLUESSEL": "Pas de taux de prise en charge",
      "TAG": "Jour de la semaine",
      "ADD_RULE": "Ajouter un taux",
      "DELETE_RULE": "Supprimer un taux",
      "ADD_AUSBILDUNGS_BEDARF": "Ajouter un niveau de formation",
      "ADD_BEDARF": "Besoin supplémentaire",
      "VON_PLAETZE": "à partir de nombre de places",
      "VON_KINDER": "à partir de nombre d'enfants",
      "CHILD_COUNTS_MF": "{plaetze, plural, =1{# place} other{# places}}, {children, plural, =1{# enfant} other{# enfants}}",
      "BEDARF": "Besoins en personnel"
    },
    "STUNDENERFASSUNG": {
      "FREIGEBEN_CONFIRM": "Une fois partagé, le mois ne peut plus être édité. Vraiment valider ?",
      "KORREKTUR_BEMERKUNG": "Remarque Admin",
      "VERTRAGLICHE_STUNDEN_MISMATCH": "Les heures de prise en charge sont inférieures aux heures contractuelles",
      "MONAT": "Mois",
      "BESTAETIGEN": "Confirmer",
      "TITLE": "Saisie des heures",
      "KEINE_EINTRAEGE": "Il n'y a pas d'enfants attribués pour le jour sélectionné",
      "CALENDAR_TOOLTIP": "Jour de prise en charge",
      "STUNDEN": "Total des heures",
      "ADD_TIMES": "Saisir des temps supplémentaires",
      "REOPEN": "Ouvrir à nouveau",
      "FREIGEBEN": "Partager",
      "KORRIGIERT": "Correction terminée",
      "NAV_TO_MONATSBLATT": "vers la feuille mensuelle",
      "ABWEICHUNG": "Ajouter un écart",
      "MONTH_SUMMARY": "Aperçu mensuel",
      "ORIGINAL_MF": "Original {original, select, null{vide} other{{original}}}",
      "ORIGINAL_ZEITEN": "Temps originaux",
      "NO_DOWNLOAD_DATA": "Il n'y a pas encore de données disponibles.",
      "ERFASSUNGS_BEMERKUNG": "Remarque",
      "VERTRAGLICHE_STUNDEN": "Heures contractuelles : {{stunden}}h"
    },
    "STUNDENBLAETTER": {
      "DESCRIPTION": {
        "NONE": "Aucune donnée n'a encore été saisie, mais elle peuvent être saisies par les personnes responsables de la prise en charge.",
        "BESTAETIGT": "La saisie des heures a été confirmée par l'administrateur. Les données ont été reprises de la feuille mensuelle",
        "OFFEN": "Des données ont été saisies et la saisie des heures incombe à la personne responsable de la prise en charge.",
        "FREIGEGEBEN": "La saisie des heures a été validée par l'encadrant pour contrôle par l'administrateur.",
        "KORRIGIERT": "La saisie des heures a été contrôlée par l'administrateur."
      },
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{feuille d'heures} other{feuilles d'heures}}",
      "PLURAL": "Feuilles d'heures",
      "STATE": {
        "NONE": "Pas de saisie",
        "OFFEN": "Ouvert",
        "FREIGEGEBEN": "Partager",
        "KORRIGIERT": "Corrigé",
        "BESTAETIGT": "Confirmé"
      }
    },
    "AUSBILDUNGEN": {
      "SINGULAR": "Formation",
      "PLURAL": "Formations",
      "NONE": "Aucune formation",
      "HINZUFUEGEN": "Ajouter une formation",
      "REQUIRED": "{{ausbildungName}} : {{assigned}}/{{required}}"
    },
    "CHART": {
      "BEDARF": "Besoins en personnel en tant que nombre d'ETP",
      "EMPLOYED_COUNT": "{{count}} Emplois à temps plein",
      "FTE_EXPLANATION": "ETP = Équivalent temps plein\nNombre d'emplois à temps plein",
      "EMPLOYED": "Emplois à temps plein",
      "ASSIGNED_FTE": "{{fte}} ETP attribué",
      "BEDARF_FTE": "{{fte}} besoin ETP",
      "ASSIGNED": "Nombre d'ETP attribués"
    },
    "FILTER": {
      "TERMIN": {
        "BEDARFSRELEVANT": "Uniquement les dates en rapport avec les besoins",
        "HIDE": "Présents seulement",
        "ONLY_ABSENT": "Seulement les absents"
      },
      "ANSTELLUNG_OHNE": "Engagement sans",
      "DIENST_ZUWEISUNG": {
        "TITLE": "Assignation",
        "ASSIGNED": "Assigné",
        "NOT_ASSIGNED": "Non assigné"
      },
      "ANSTELLUNG_MIT": "Engagement avec",
      "ANSTELLUNG_ZUWEISUNG_PENSUM": "Taux d'engagement / affectation",
      "BEZUGS_PERSON": "Personnes de référence uniquement"
    },
    "KONFIGURATION": {
      "WEEKLY_HOURS": "Heures de travail hebdomadaires",
      "CONFIRM_BEENDEN": "Il y a encore des configurations attribuées après {{date}}. Comment les gérer ?",
      "WEEKLY_HOURS_VAL": "{{hours}} h/semaine",
      "TERMINATION_MODE": {
        "DELETE_FOLLOWING": "Terminer la configuration le {{date}} et supprimer toutes les configurations futures.",
        "TERMINATE": "Terminer la configuration le {{date}}. Les configurations futures seront conservées.",
        "DELETE_TILL_NEXT_GAP": "Terminer la configuration le {{date}} et directement supprimer les configurations qui suivent."
      },
      "SINGULAR": "Configuration du personnel",
      "PLURAL": "Configurations du personnel",
      "ZUWEISUNG": "Configuration du personnel",
      "TO_MANDANT_CONFIG": "Vers la configuration de l'organisation",
      "FTE_HOURS": "Heures d'ETP hebdomadaires",
      "FTE_HOURS_VAL": "{{hours}} ETP h/semaine",
      "KEINE": "Aucune configuration disponible",
      "NEUE_ZUWEISUNG": "Nouvelle configuration",
      "ZUWEISEN": "Attribuer la configuration",
      "ZUWEISUNG_BEENDEN": "terminer",
      "ZUWEISUNG_BEENDEN_HEADING": "Quitter la configuration du personnel"
    },
    "TERMIN": {
      "BIS_TYPE": {
        "BIS_EWIG": "Jamais",
        "TITLE": "Se termine",
        "BIS_DATUM": "Le",
        "ANZAHL": "Après"
      },
      "INVALID_WIEDERHOLUNG_END": "Les répétitions ne peuvent pas se terminer avant le début du rendez-vous.",
      "SINGULAR": "Rendez-vous",
      "PLURAL": "Rendez-vous",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{rendez-vous} other{rendez-vous}}}",
      "TITLE": "Rendez-vous",
      "BEMERKUNG": "Remarque",
      "ABWESENHEIT": "Absence",
      "DELETE_TITLE": "Supprimer définitivement {{entity}} de {{angestellter}}?",
      "DELETE_TITLE_ALL": "Supprimer définitivement {{entity}}?",
      "DELETE_ALL_SUBTITLE": "{{entity}} sera supprimé pour tous les employés.",
      "DELETE_SOME_SUBTITLE": "{{entity}} sera supprimé pour les personnes suivantes:",
      "DELETE_IN_KITA_MF": "{entity} sera supprimé pour {count} {count, plural, one{Institution} other{Institutions}}.",
      "DELETE_IN_ALL_KITAS": "supprimer {{entity}} dans toutes les Institutions concernées",
      "DELETE_UPDATE_MODE": "Supprimer tous les éléments de la série de rendez-vous ou seulement certains?",
      "EDIT_UPDATE_MODE": "Modifier tous les éléments de la série de rendez-vous ou seulement certains?",
      "UPDATE_MODE": {
        "ALL": "Tous",
        "ALL_FUTURE": "Tous les futurs",
        "SINGLE": "Cet élément de série"
      },
      "DEDUCT_FROM_BEDARF_RECHNUNG": "déduire du calcul des besoins",
      "DEDUCT_FROM_ARBEITSZEIT": "déduire du temps de travail",
      "KINDERORT_TERMIN": "Rdv du personnel",
      "WIEDERKEHRENDE": "récurrent",
      "FOR_KINDER_ORT": "Rdv du lieu d'accueil",
      "FOR_ANGESTELLTE": "Rdv employés",
      "FREQUENZ_TYP": {
        "DAILY": {
          "INTERVAL": "Jours",
          "TITLE": "Tous les jours"
        },
        "MONTHLY": {
          "INTERVAL": "Mois",
          "TITLE": "Mensuel"
        },
        "WEEKLY": {
          "TITLE": "Hebdomadaire",
          "INTERVAL": "Semaines"
        }
      },
      "INTERVAL": "Intervalle",
      "FREQUENZ_PREFIX": "Tous",
      "BIS_TITLE": "Répéter jusqu'au",
      "WIEDERHOLUNGEN": "Répétitions",
      "RRULE": {
        "DAY": "Jour",
        "DAYS": "Jours",
        "WEEK": "Semaine",
        "WEEKS": "Semaines",
        "OR": "ou",
        "AT": "à",
        "LAST": "dernier",
        "ST": ".,",
        "ND": ".,",
        "RD": ".,",
        "MINUTES": "Minutes",
        "HOURS": "Heures",
        "WEEKDAYS": "Jour de la semaine",
        "WEEKDAY": "Jour de la semaine",
        "EVERY": "tous",
        "UNTIL": "jusqu'à",
        "ON": "le",
        "IN": "dans",
        "ON THE": "le",
        "FOR": "pour",
        "AND": "et",
        "TH": ".,",
        "TIMES": "fois",
        "TIME": "Heure",
        "MONTHS": "Mois",
        "MONTH": "Mois",
        "YEARS": "Années",
        "YEAR": "Année",
        "(~ APPROXIMATE)": ""
      },
      "WIEDERHOLUNGS_TYP": "Type de répétition",
      "ERSTELLEN": "Saisir un rdv",
      "EDIT": "Modifier le rdv",
      "VON": "Heure de (facultatif)",
      "BIS": "Heure jusqu'à (facultatif)",
      "NAME": "Titre",
      "NONE": "Pas de rdv saisis",
      "BACKGROUND_COLOR": "Couleur",
      "TEXT_COLOR": "Couleur du texte",
      "OWN": "Propre"
    },
    "DIENSTKONFIGURATION": {
      "EDIT": "Modifier le service",
      "PAUSE": "Pause",
      "PAUSE_VON": "Pause de",
      "PAUSE_BIS": "Pause jusqu'à",
      "TITLE": "Administration des services",
      "SINGULAR": "Service",
      "NONE": "Aucun service disponible",
      "NEW": "Créer un nouveau service",
      "NAME": "Nom",
      "KUERZEL": "Abréviation",
      "BACKGROUND_COLOR": "Couleur",
      "TEXT_COLOR": "Couleur du texte"
    },
    "BEZUGSPERSON": {
      "PLURAL": "Personnes de référence",
      "SEARCH": "Chercher une personne de référence",
      "FOR_CHILDREN": "Personne de référence pour :\n{{names}}",
      "MISSING_MF": "{count, plural, one{personne de référence} other{personnes de référence}} manquantes :\n{names}"
    },
    "ERROR": {
      "PERSONAL_SELECTION_REQUIRED": "Au moins une personne doit être sélectionnée.",
      "DIENST_OVERLAP_SUBTITLE": "<p>Pour éviter que les personnes assignées ne travaillent dans deux services qui se chevauchent dans le temps, les services déjà assignés qui se chevauchent dans les autres groupes sont supprimés.</p><p>Concerné : {{displayNames}}<p><p>Voulez-vous continuer ? <p>",
      "DIENST_OVERLAP_LIST": "Les services se chevauchent.\n {{conflicts}}",
      "AUSBILDUNG_REQUIRED": "Au moins un niveau de formation doit être sélectionné.",
      "DUPLICATE_AUSBILDUNGSNAME": "Les noms de formation doivent être uniques.",
      "ZUWEISUNG_GUELTIG_BIS": "Date de fin non valide.",
      "DIENST_OVERLAP": "Il y a des chevauchements avec des services déjà attribués.",
      "ZEITRAUM_SELECTION_REQUIRED": "Au moins une période doit être sélectionnée."
    },
    "TITLE": "Équipe",
    "PERSONAL_SETTINGS": "Réglages équipe",
    "BEDARF": "Besoins en personnel",
    "ZUWEISUNGEN": "Attributions",
    "STAFF_COUNT": "Total {{count}} personnes",
    "ANSTELLUNG": {
      "NO_ANSTELLUNG": "Aucun emploi disponible",
      "CREATE": "Créer un nouvel emploi",
      "TITLE": "Emploi",
      "VERLAUF": "Déroulement de l'emploi",
      "EDIT": "Modifier l'emploi",
      "FROM": "Emploi à partir du {{date}}",
      "CURRENT": "Emploi actuel",
      "TO": "Engagement jusqu'au {{date}}",
      "WEEKLY_HOURS": "Heures / semaine",
      "PENSUM": "Taux d'occupation",
      "NICHT_VERFUEGBAR": "Non disponible",
      "ARBEITSPENSUM_PROZENT": "Pourcentage",
      "WEEKLY_HOURS_COUNT": "{{hours}} heures / semaine",
      "ARBEITSPENSUM_PROZENT_COUNT": "{{prozent}}%",
      "DAILY_HOURS": "Heures quotidiennes",
      "ZEIT_FROM": "Période d'engagement de",
      "ZEIT_TO": "Durée d'engagement jusqu'à",
      "EDUCATION": "Niveau de formation",
      "ADDITIONAL": "Autre",
      "VERTRETUNGSPERSON": "Personnel de remplacement",
      "NO_DAILY_ANSTELLUNG": "Pas de services prédéfinis",
      "ZUWEISUNG": "Engagement",
      "NEUE_ZUWEISUNG": "Attribuer un nouvel engagement",
      "ZUWEISUNG_BEENDEN": "Mettre fin à l'engagement",
      "ZUWEISUNG_BEENDEN_HEADING": "Terminer l'engagement pour {{assignedEntity}} ?",
      "CONFIRM_BEENDEN": "Il y a encore des engagements attribués après {{date}}. Comment gérer cela ?",
      "TERMINATION_MODE": {
        "TERMINATE": "Fin de l'engagement le {{date}}. Les engagements futurs sont maintenus.",
        "DELETE_TILL_NEXT_GAP": "Terminer l'engagement le {{date}} et directement supprimer les engagements qui suivent.",
        "DELETE_FOLLOWING": "Mettre fin à l'engagement le {{date}} et supprimer tous les engagements futurs."
      }
    },
    "PLANUNG": {
      "KINDER_ORT_SCHLUESSEL": "Clé du lieu d'accueil",
      "OTHER_ANGESTELLTE": "Autres employés",
      "ADDITIONAL_ANGESTELLTE": "+ ajouter des collaborateurs",
      "SEARCH_ANGESTELLTE": "Chercher des collaborateurs",
      "DAY_INFO": "Infos du jour"
    },
    "ANGESTELLTE_ZUWEISUNG": {
      "TITLE": "Attribution"
    },
    "KORRESPONDENZ": {
      "TITLE": "Correspondance",
      "PAST": "Correspondance passée"
    }
  },
  "WOCHENPLAN": {
    "BETREUUNGS_ZEITRAUM": {
      "VERPFLEGUNGS_TYP": {
        "MITTAGESSEN": "Déjeuner",
        "FRUEHSTUECK": "Petit déjeuner",
        "ZWISCHENVERPFLEGUNG_VORMITTAG": "Goûter",
        "ZWISCHENVERPFLEGUNG_NACHMITTAG": "Goûter après-midi",
        "ABENDESSEN": "Dîner"
      },
      "VERPFLEGUNG": "Repas",
      "VERPFLEGUNG_OHNE": "aucun"
    },
    "SHOW_ZEITRAUM_NAME": "Afficher les noms des périodes ?",
    "WOCHENTAGE": "Jours de la semaine : ",
    "BETREUUNGSZEITRAEUME": "Périodes de prise en charge : ",
    "PROZENTPUNKTE": "{{prozent}}%",
    "PRIO": "Prio : {{prio}}"
  },
  "EXTERNAL_ANMELDUNG": {
    "CONFIG": {
      "WOCHENPLAN_NAME": "Nom",
      "REPLY_TO_EMAIL": "E-mail de l'expéditeur pour l'e-mail de confirmation",
      "INFO_EMAIL": "Adresse e-mail pour l'info-mail",
      "SHOW_SUBVENTIONIERTER_PLATZ": "Afficher la place subventionnée",
      "ADD_WOCHENPLAN": "Ajouter un programme hebdomadaire",
      "TITLE": "Inscription externe",
      "TAG_MANAGER_CONTAINER": "Google Tag Manager Container Id",
      "REDIRECT_URL": "URL de redirection après l'envoi",
      "AGB_URL": "URL vers les conditions générales",
      "CUSTOM_STYLE": "Propre FSC",
      "SHOW_PENSUM": "Afficher le taux",
      "SHOW_PRIVATER_PLATZ": "Afficher la place privée",
      "BEMERKUNG": "Remarque",
      "WOCHENPLAN_BEMERKUNG": "Remarque",
      "WOCHENPLAN_DISPLAY_TYPE": {
        "TIMES": "Afficher les horaires de module",
        "NAMES": "Afficher les noms des modules"
      },
      "SELECT_WOCHENPLAN": "Choisir un programme hebdomadaire",
      "WOCHENPLAENE": "Programmes hebdomadaires"
    },
    "SUBVENTIONIERTER_PLATZ": "Place subventionnée ou bon de garde",
    "ADDRESS_KIND": "Adresse du domicile {{name}}",
    "DETAIL": {
      "MATCHES": "Importer l'inscription",
      "MATCHES_INFO_MF": "{count, plural, =0{Aucun ménage} one{Un ménage} other{# ménages trouvés}} à la même adresse. Veuillez sélectionner {count, plural, =0{} other{un}} pour rechercher vous-même un enfant déjà saisi ou saisir un nouvel enfant.",
      "TITLE": "Reprendre l'inscription",
      "NAME": "Détails"
    },
    "BEWERBUNG": {
      "BEWERBUNG_UEBERNEHMEN": "Créer une nouvelle demande de prise en charge",
      "DUPLICATE": "Il existe déjà un souhait de prise en charge ouvert pour l'enfant assigné",
      "BEWERBUNG_NICHT_UEBERNEHMEN": "Ignorer la nouvelle demande de prise en charge",
      "BERECHNETES_PENSUM": "Taux calculé {{pensum}}%"
    },
    "NAME": "Inscription",
    "TITLE": "Inscription enfants",
    "KIND_SAVED": "L'inscription a été enregistrée avec succès",
    "BETREUUNG_AB": "Prise en charge à partir de : {{date}}",
    "ANMELDE_DATUM": "Date d'inscription : {{date}}",
    "ACCEPT_AGB": "J'accepte les",
    "ACCEPT_AGB_LINK_PART": "conditions générales",
    "ACCEPT_PRIVACY_POLICY": "Veuillez accepter nos ",
    "ADD_CHILD": "Inscrire un enfant supplémentaire",
    "REMOVE_CHILD": "Supprimer l'enfant {{name}}",
    "ADD_KONTAKT": "Saisir un contact supplémentaire",
    "STAMMDATEN_KIND": "Données de base {{name}}",
    "BETREUUNG_KIND": "Souhait de prise en charge {{name}}",
    "ANMELDEN": "Inscrire",
    "ASSIGNED": "Attribuées",
    "UNASSIGNED": "Ouvertes",
    "GESCHLECHT": {
      "MAENNLICH": "Homme",
      "WEIBLICH": "Femme",
      "UNBEKANNT": "Inconnu",
      "DIVERS": "Divers"
    },
    "GESCHLECHT_KIND": {
      "MAENNLICH": "Garçon",
      "WEIBLICH": "Fille",
      "UNBEKANNT": "Inconnu",
      "DIVERS": "Divers"
    },
    "WIZARD": {
      "BACK": "Retour",
      "NEXT": "Continuer",
      "CONCLUSION": "Finaliser",
      "SUMMARY": "Résumé"
    },
    "CUSTOM_FIELDS": {
      "TITLE": "Inscription externe",
      "SELECT_NAMED_ENTITY_TYPE": "Appartenance",
      "NAMED_ENTITY_TYPE": {
        "KIND": "Enfant",
        "KONTAKTPERSON": "Contact"
      }
    },
    "DELETE": {
      "ZUGEWIESENE_KIND_TEXT": "Un enfant est déjà attribué à l'inscription externe",
      "DELETE_CHILD": "Supprimer aussi l'enfant attribué",
      "DELETE_ASSIGNMENT": "Supprimer uniquement l'inscription"
    }
  },
  "CHECK_IN": {
    "LISTE": {
      "ZEITEN_BIS": "{{bis}}",
      "NAVIGATE_TO_TAGESINFO": "Vers les infos du jour",
      "GEPLANT": "Prévu",
      "WIRD_ABGEHOLT": "Est récupéré à",
      "BETREUT": "Pris en charge",
      "ZEITEN": "{{von}} - {{bis}}",
      "CHECK_IN_SUCCESS": "{{kindDisplayName}} a été enregistré",
      "CHECK_OUT_SUCCESS": "{{kindDisplayName}} a été désinscrit",
      "WURDE_ABGEMELDET": "A été désinscrit",
      "KEINE_KINDER_EINGEPLANT": "Aucun enfant n'est prévu aujourd'hui.",
      "KEINE_RESULTATE": "Aucun résultat."
    },
    "ABWEICHUNG": {
      "FORM": {
        "GROUP": "Groupe",
        "ALL_GROUPS": "Tous les groupes",
        "CATEGORY": "Catégorie",
        "TYPE": "Type",
        "FROM_DATE": "De la date",
        "TO_DATE": "Jusqu'à la date",
        "FROM_TIME": "De l'heure",
        "TO_TIME": "Jusqu'à l'heure",
        "REMARK": "Remarque",
        "SAVE": "Enregistrer la demande",
        "ABORT": "Annuler",
        "VALIDATION": {
          "ALL_GROUP_WITH_ADDITIONAL_EXTRA_PLATZ_TYPE_WARN": "Il n'est pas possible de créer un jour supplémentaire sur tous les groupes."
        },
        "TITLE": "Saisir une demande pour {{kinderOrtDisplayName}}"
      },
      "TITLE": "Ecart : {{kindDisplayName}}",
      "ADD_ABWEICHUNG": "Ajouter un écart",
      "REMOVE_ABWEICHUNG": "Supprimer"
    },
    "CUSTOM_FIELD": {
      "DAILY": "Tous les jours",
      "DISPLAYED_ON_OVERVIEW": "Afficher sur l'aperçu",
      "DISPLAYED_ON_DAY_OVERVIEW": "Afficher sur l'aperçu du jour",
      "NO_LINK_FIELD_AVAILABLE": "Aucun champ pouvant être lié n'a été trouvé",
      "YES": "Oui",
      "INTERN_ONLY": "Interne uniquement",
      "EDITABLE": "Modifiable",
      "CHECK-IN": "Enregistrement",
      "NO": "Non",
      "LINK": "Champ lié",
      "LINK_KIND_FIELD": "Champ lié \"Enfant\"",
      "LINK_CHECK_IN_FIELD": "Champ lié \"Enregistrement\"",
      "LINK_FIELD": "lier",
      "LINKED_TO": "Lié à : {{name}}",
      "CREATE": "Créer"
    },
    "STATUS": {
      "TIME": "Moment",
      "VALUES": {
        "ABGEHOLT": "Désinscrit",
        "ABWESEND": "Absence",
        "ANGEKOMMEN": "Enregistré",
        "PAUSIERT": "Pause",
        "EINGEPLANT": "Planifié"
      }
    },
    "FILTER": {
      "LABEL": "Filtre",
      "RESET": "Réinitialiser",
      "INVALID": "Pas de données",
      "SUCHE": {
        "PLACEHOLDER": "Recherche de noms",
        "CLEAR": "Réinitialiser",
        "GLOBAL": "Rechercher un enfant dans toute la crèche"
      }
    },
    "BUTTON": {
      "CHECK-IN": "Enregistrement",
      "CHECK-OUT": "Désinscrire",
      "PAUSIEREN": "mettre en pause",
      "ANTRAG_ERFASSEN": "Saisir une demande",
      "ABSENZ": "Absence"
    },
    "NAVIGATION": {
      "KINDERORT": "Changer vers : {{kinderOrtDisplayName}}"
    },
    "FORM": {
      "REQUIRED": "{{field}} est nécessaire",
      "SPEICHERN": "Enregistrer",
      "ABBRECHEN": "Annuler"
    },
    "TAGESINFO": {
      "SECTION": {
        "WEITERE": "Plus d'infos",
        "LISTS": "Listes",
        "TAGESINFO": "Info du jour"
      },
      "NAV": "Ouvrir l'info du jour",
      "ANTRAG_ERFASSEN": "Saisir une demande",
      "LISTS": {
        "RESTORE_STATUS": "Retour",
        "RESTORE_DEVIATION": "Retour",
        "OPEN_DEVIATION_REQUESTS": "Demandes en cours",
        "OPEN_DEVIATION_REQUESTS_EMPTY": "Aucune demande ouverte en cours",
        "STATUS_DELETED": "Statut {{status}} {{zeit}} a été supprimé",
        "STATUS_RESTORED": "Statut {{status}} {{zeit}} a été rétabli",
        "DEVIATION_DELETED": "Demande {{antrag}} {{zeitraum}} a été supprimée",
        "DEVIATION_RESTORED": "Demande {{antrag}} {{zeitraum}} a été rétablie",
        "LAST_STATUS_CHANGES": "Dernières modifications",
        "LAST_STATUS_CHANGES_EMPTY": "Aucun changement disponible"
      },
      "TITLE": "Info du jour : {{kindDisplayName}}"
    },
    "EVENT": "Entrée",
    "TITLE": "Aperçu"
  },
  "NOTIZ": {
    "UPDATE_NOTIZ": "Mettre à jour la note",
    "UPDATE_NOTIZ_CONFIRM": "Mettre à jour la note ?",
    "CONTENT": "Note Message",
    "HEADER_INFO": "Créé par : {{createdBy}}",
    "NAME": "Note",
    "PLURAL": "Notes",
    "TITLE": "Écrire une note {{date}}",
    "CREATE_NOTIZ": "Créer une note",
    "CREATE_NOTIZ_CONFIRM": "Créer une note ?"
  },
  "NOTIFICATION": {
    "TITLE": "Notifications",
    "SINGULAR": "Notification",
    "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{notification} other{notifications}}",
    "CREATE": "Créer une notification",
    "DUE_TIME": "Heure de début (facultatif)"
  },
  "BETREUUNGS_ANGEBOT": {
    "NAME": "Offre de prise en charge",
    "NONE": "Pas d'offre de prise en charge",
    "HORT": "Garderie",
    "KRIPPE": "Crèche",
    "HORT_AND_KRIPPE": "Garderie et crèche"
  }
}
