import type {SupportedLocale} from '@dv/shared/code';

export class ConfigService {
    public constructor(
        /** Contains version specified in package.json */
        public readonly version: string,
        /** Default locale to be used when no other locale the user wants is supported. */
        public readonly defaultLocale: SupportedLocale,
        /** URL to the REST API */
        public readonly restBackend: string,
    ) {
    }

    public static create(config: { [key in keyof ConfigService]: ConfigService[key] }): ConfigService {
        return new ConfigService(
            config.version,
            config.defaultLocale,
            config.restBackend,
        );
    }
}
