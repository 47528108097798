/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CountryCode} from '@dv/shared/backend/model/country-code';
import type {IDisplayable, IPersistable, IPersisted} from '../types';
import {checkPersisted, checkPresent, displayableComparator, isNullish, isPresent} from '../types';
import {TypeUtil} from './TypeUtil';

const HUNDRED = 100;

export interface IMappable<T> {
    [key: string]: T | IMappable<T>;
}

export interface IMapped<T> {
    [key: string]: T | Map<string, IMapped<T>>;
}

export class DvbUtil {
    public static findFirst<T>(
        arr: readonly T[],
        filter: (value: T, index: number, array: readonly T[]) => boolean,
    ): T | null {
        return arr.find(filter) ?? null;
    }

    public static orderByDisplayName<T extends IDisplayable>(array: T[]): T[] {
        return array.sort(displayableComparator);
    }

    public static compareByDisplayName(a?: IDisplayable | null, b?: IDisplayable | null): number {
        if (a && !b) {
            return -1;
        }

        if (!a && b) {
            return 1;
        }

        if (a && b) {
            return displayableComparator(a, b);
        }

        return 0;
    }

    public static mapById<T extends IPersisted>(array: T[]): { [id: string]: T } {
        const result: { [id: string]: T } = {};
        array.forEach(a => {
            result[a.id] = a;
        });

        return result;
    }

    public static mapToId<T extends IPersistable>(obj: T): T['id'] {
        return obj.id;
    }

    public static mapToIdChecked<T extends IPersistable>(obj: T | {id: undefined}): string {
        return checkPresent(obj.id);
    }

    /**
     * @return a new array with unique values
     */
    public static uniqueArray<T, TMapped>(array: T[], mapper?: (o: T) => TMapped): T[] {
        if (!Array.isArray(array)) {
            return array;
        }

        if (mapper) {
            return DvbUtil.uniqueByMapper(array, mapper);
        }

        return array.filter((v, i, arr) => this.onlyUnique(v, i, arr));
    }

    public static onlyUnique<T>(value: T, index: number, self: T[]): boolean {
        return self.indexOf(value) === index;
    }

    public static uniqueByMapper<T, TMapped>(array: T[], mapper: (o: T) => TMapped): T[] {
        return array.filter((obj, pos, arr) => arr.map(mapper).indexOf(mapper(obj)) === pos);
    }

    /**
     * @return value deviced by 100
     */
    public static pctToFraction(value: number): number {
        return value / HUNDRED;
    }

    /**
     * @return true if an array element was removed, false otherwise.
     */
    public static removeFromArray<T>(object: T, array: T[]): boolean {
        const index = array.indexOf(object);
        if (index > -1) {
            array.splice(index, 1);

            return true;
        }

        return false;
    }

    public static replaceArrayElement<T>(oldObject: T, newObject: T, array: T[]): void {
        const index = array.indexOf(oldObject);
        if (index > -1) {
            array.splice(index, 1, newObject);
        }
    }

    public static joinNonEmpty(values: string[]): string {
        return values.filter(DvbUtil.isNotEmptyString).join(' ');
    }

    /**
     * Returns a new array instance with value added (if it didn't exists or value removed (if it existed).
     */
    public static addToOrRemoveValueFromArray<T>(array: T[], value: T): T[] {
        return array.includes(value) ? array.filter(item => item !== value) : [...array, value];
    }

    /**
     * @return TRUE for a string or number that is like a non-negative integer. FALSE otherwise
     */
    public static isLikeNotNegativeInteger(value: unknown): value is number | string {
        if (typeof value !== 'number' && this.isEmptyString(value)) {
            return false;
        }

        let checkedValue = value;

        if (typeof value !== 'number') {
            checkedValue = Number(value);
        }

        return this.isInteger(checkedValue) && checkedValue >= 0;
    }

    public static isInteger(value: unknown): value is number {
        return typeof value === 'number' && !isNaN(value) && Math.floor(value) === value;
    }

    /**
     * @param value
     * @param min (inclusive)
     * @param max (inclusive)
     */
    public static isInRange(value: number, min: number, max: number): boolean {
        return min <= value && value <= max;
    }

    public static isNotEmptyString(value: unknown): value is string {
        return typeof value === 'string' && value.length > 0;
    }

    /**
     * Attention: Returns true for non string types. Use isEmptyStringType() where the string type is relevant.
     */
    public static isEmptyString(value: any): boolean {
        return !this.isNotEmptyString(value);
    }

    public static isEmptyStringType(value: any): boolean {
        return typeof value === 'string' && value.length === 0;
    }

    public static isIterable<T>(value: unknown): value is Iterable<T> {
        return Symbol.iterator in Object(value);
    }

    /**
     * Checks if an object is an array that is not empty.
     */
    public static isNotEmptyArray<T>(obj: unknown): obj is T[] {
        return Array.isArray(obj) && obj.length > 0;
    }

    /**
     * @return TRUE, if value is positve and has a maximum of 2 decimal places.
     */
    public static isCHF(value: unknown): value is number {
        return this.isDecimal(value) && value >= 0;
    }
    public static isDecimal(value: unknown): value is number {
        return TypeUtil.isNumber(value) && isFinite(value) && parseFloat(value.toFixed(2)) === value;
    }

    public static isRequestTimeoutInMs(timeout?: PromiseLike<unknown> | number): timeout is number {
        return isPresent(timeout) && TypeUtil.isNumber(timeout);
    }

    public static isRequestTimeoutPromise(timeout?: PromiseLike<unknown> | number): timeout is PromiseLike<unknown> {
        return !isNullish(timeout) && !this.isRequestTimeoutInMs(timeout);
    }
    public static objToMap<T>(obj: IMappable<T>): Map<string, IMapped<T>> {
        const entries = Object.entries(obj).map(entry => [
            entry[0],
            typeof entry[1] === 'object' ? DvbUtil.objToMap(entry[1] as any) : entry[1],
        ] as [string, any]);

        return new Map(entries);
    }

    /**
     * There is a potential runtime glitch: if an parameter is given which is compatible to the signature
     * (T2 extends T), then more keys (keyof T2)[] are returned than just (keyof T)[].
     */
    public static keys<T extends object | undefined>(o: T): (keyof T)[] {
        if (isNullish(o)) {
            return [];
        }

        return Object.keys(o) as (keyof T)[];
    }

    public static getKey<TIndex extends string, T>(o: { [s in TIndex]: T }, value: T): TIndex {
        const entry = Object.entries<T>(o)
            .find(e => e[1] === value)!;

        return entry[0] as TIndex;
    }

    public static mapValues<TKey, TValue>(o: any, mapper: (o: TKey) => TValue): Map<TKey, TValue> {
        const result = new Map<TKey, TValue>();

        DvbUtil.keys(o)
            .map(v => o[v])
            .forEach(k => {
                result.set(k, mapper(k));
            });

        return result;
    }

    public static mapToValuesArray<TKey, TValue>(o: any, mapper: (o: TKey) => TValue): TValue[] {
        return Array.from(DvbUtil.mapValues(o, mapper).values());
    }

    /**
     * Comparator based on IPersistables ID as number.
     */
    public static persistableComparatorAsc<T extends IPersistable>(a: T, b: T): number {
        checkPersisted(a);
        checkPersisted(b);

        return parseInt(a.id!, 10) - parseInt(b.id!, 10);
    }

    public static capitalize(value: string): string {
        return value
            ? value.charAt(0).toUpperCase() + value.substring(1)
            : '';
    }

    /**
     * If the string is longer than the given length, truncates it to length - 1 and adds the suffix '…'
     */
    public static truncate(value: string | null, length: number): string | null {
        if (isNullish(value)) {
            return null;
        }

        return value.length - 1 > length ? `${value.substring(0, length - 1)}…` : value;
    }

    public static getDisplayNameFromIsoCode(isoCode: CountryCode): string {
        return checkPresent(new Intl.DisplayNames(navigator.languages, {type: 'region'}).of(isoCode));
    }
}
