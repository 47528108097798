import {HttpErrorResponse} from '@angular/common/http';
import {tapResponse} from '@ngrx/operators';
import type {MonoTypeOperatorFunction} from 'rxjs';

export type HandleResponseObserver<T> = {
    next: (value: T) => void;
    complete?: () => void;
    finalize?: () => void;
};

/**
 * Shortcut to tapResponse with default error handling.
 * HttpErrorResponse errors are ignored, as they are expected to be handled by a global error hook.
 * Other errors are logged with console.error()
 */
export function handleResponse<T>(observer: HandleResponseObserver<T>): MonoTypeOperatorFunction<T> {
    return source$ => source$.pipe(tapResponse<T>({
        next: observer.next,
        error: error => {
            if (error instanceof HttpErrorResponse) {
                return;
            }
            console.error(error);
        },
        complete: observer.complete,
        finalize: observer.finalize,
    }));
}
