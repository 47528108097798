/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {Translator} from '@dv/shared/translator';
import {TranslateService} from '@ngx-translate/core';
import {checkInstanceOf} from '@dv/shared/code';

// Provided manually in i18n module
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class TranslatorNGXTranslateAdapterService extends Translator {
    public constructor(
        private readonly ngxTranslate: TranslateService,
    ) {
        super();
        checkInstanceOf(ngxTranslate, TranslateService);
    }

    public override instant(
        key: string,
        interpolateParams?: any,
        _interpolationId?: 'messageformat',
    ): string {
        return this.ngxTranslate.instant(key, interpolateParams);
    }
}
