/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type moment from 'moment';
import type {IRestModel, IValidable} from '../../types';
import {checkPresent, isNullish, isPresent} from '../../types';
import {DvbRestUtil} from '../rest';
import type {ITimeRange} from './ITimeRange';
import {TimeRangeUtil} from './TimeRangeUtil';

export type TimeRangeRestModel = { von: BackendLocalTimeHHMM | null; bis: BackendLocalTimeHHMM | null };

export class TimeRange implements IRestModel, IValidable, ITimeRange {

    public constructor(
        public von: moment.Moment | null = null,
        public bis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: TimeRangeRestModel | null): TimeRange {
        return new TimeRange(
            DvbRestUtil.localeHHMMTimeToMoment(data?.von ?? ''),
            DvbRestUtil.localeHHMMTimeToMoment(data?.bis ?? ''),
        );
    }

    public static toApiTransformer(a: TimeRange | null = null): TimeRangeRestModel | null {
        return isPresent(a) && a.hasValue() && a.isValid() ?
            a.toRestObject() :
            null;
    }

    public toRestObject(): TimeRangeRestModel {
        return {
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
        };
    }

    public hasValue(): boolean {
        return isPresent(this.von) || isPresent(this.bis);
    }

    public isValid(): boolean {
        const von = this.von;
        const bis = this.bis;
        if (isNullish(von) && isNullish(bis)) {
            return true;
        }

        if (isNullish(von) || isNullish(bis)) {
            return false;
        }

        return von.isValid() && bis.isValid() && von.isBefore(bis);
    }

    public toHours(): number {
        if (!this.hasValue() || !this.isValid()) {
            return 0;
        }

        return checkPresent(this.bis).diff(checkPresent(this.von), 'hours', true);
    }

    public formatted(): string {
        return TimeRangeUtil.formatTimeRange(this);
    }
}
