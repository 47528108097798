/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpResponse} from '@angular/common/http';
import {HttpCodes, NO_DATA} from '@dv/shared/code';
import type {OperatorFunction} from 'rxjs';
import {Observable} from 'rxjs';

export function mapIfData<TResponse, TReturn>(
    mapFunction: (val: HttpResponse<TResponse>) => TReturn,
): OperatorFunction<HttpResponse<TResponse>, TReturn> {

    return source$ => new Observable(observer =>
        source$.subscribe({
            next: (value: HttpResponse<TResponse>) => {
                if (value.status === HttpCodes.NO_CONTENT) {
                    observer.error(NO_DATA);
                } else {
                    observer.next(mapFunction(value));
                }
            },
            error: (e: unknown) => observer.error(e),
            complete: () => observer.complete(),
        }));
}
