/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpResponse} from '@angular/common/http';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {checkPresent} from '@dv/shared/code';

export function idFromLocation(response: HttpResponse<unknown>): EntityId {
    const url = response.headers.get('location') ?? '';
    const lastIndexOf = url.lastIndexOf('/');
    const id = url.substring(lastIndexOf + 1);

    return checkPresent(id);
}
