/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable} from './IDisplayable';
import type {IPersisted} from './IPersisted';

export class Displayable implements IPersisted, IDisplayable {

    public constructor(
        public id: string,
        public displayName: string,
    ) {
    }

    public static apiResponseTransformer(data: any): Displayable {
        return new Displayable(data.id, data.displayName);
    }

    public getDisplayName(): string {
        return this.displayName;
    }
}
