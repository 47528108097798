/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TranslateLoader} from '@ngx-translate/core';
import type {Observable} from 'rxjs';
import {of} from 'rxjs';
import type {SupportedLocale} from '@dv/shared/code';
import {SUPPORTED_LOCALES} from '@dv/shared/code';
import {SHARED_TRANSLATIONS} from './shared-translations';

export class KitAdminTranslationLoader implements TranslateLoader {

    public getTranslation(locale: SupportedLocale): Observable<unknown> {
        SUPPORTED_LOCALES.check(locale);

        switch (locale) {
            case 'de-CH':
                return of(SHARED_TRANSLATIONS.de);
            case 'fr-CH':
                return of(SHARED_TRANSLATIONS.fr);
        }
    }
}

export function createNgxTranslationLoader(): TranslateLoader {
    return new KitAdminTranslationLoader();
}
