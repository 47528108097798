/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';

export type ParamValueBaseType = string | moment.Moment | number | boolean;
export type ParamValueDisplayType = string | number | boolean;

export enum ParamValueDType {
    COLLECTION = 'COLLECTION',
    LOCAL_DATE = 'LOCAL_DATE',
    ABRECHNUNG_TYP = 'ABRECHNUNG_TYP',
    STRING = 'STRING',
    LONG = 'LONG',
    BIG_DECIMAL = 'BIG_DECIMAL',
    BOOLEAN = 'BOOLEAN',
}
