/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {DeployedVersions} from '@dv/shared/code';
import {ConfigService} from '@dv/shared/config';
import type {Observable} from 'rxjs';
import {BehaviorSubject, distinctUntilKeyChanged} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeployedVersionsService {

    private readonly deployedVersions$ = new BehaviorSubject<DeployedVersions>(
        DeployedVersions.initWithBackendUnknown(this.configService.version),
    );

    public constructor(
        private configService: ConfigService,
    ) {
    }

    public getFrontendVersion(): string {
        return this.configService.version;
    }

    public setDeployedVersions(deployedVersions: DeployedVersions): void {
        this.deployedVersions$.next(deployedVersions);
    }

    public getDeployedVersions$(): Observable<DeployedVersions> {
        return this.deployedVersions$.pipe(
            distinctUntilKeyChanged('backendVersion'),
        );
    }
}
