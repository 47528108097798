/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TemplateLanguage} from '../constants';
import type {IPersistable, IRestModel, IValidable} from '../types';
import {TypeUtil} from '../util';
import type {ReportType} from './ReportType';

export class ReportTemplate implements IPersistable, IRestModel, IValidable {

    public constructor(
        public reportType: ReportType,
        public id: string | null = null,
        public templateVersion: number = 0,
        public language: TemplateLanguage = TemplateLanguage.ANY_LANGUAGE,
        public name: string = '',
        public bemerkung: string = '',
        public blobId: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ReportTemplate {
        return new ReportTemplate(
            data.reportType,
            data.id,
            data.templateVersion,
            data.language,
            data.name,
            data.bemerkung,
            data.blobId,
        );
    }

    public isValid(): boolean {
        return this.hasName();
    }

    public hasName(): boolean {
        return TypeUtil.isString(this.name) && this.name.length > 0;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            reportType: this.reportType,
            id: this.id,
            // templateVersion is read-only and defined in uploaded template file
            language: this.language,
            name: this.name,
            bemerkung: this.bemerkung,
            blobId: this.blobId,
        };
    }

}
