/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type moment from 'moment';
import type {Nullish} from '../../types/nullish';
import {DvbRestUtil} from '../rest/dvbRestUtil';
import {DvbDateUtil} from './DvbDateUtil';

type SupportedDateTypeMap = {
    date: Date;
    moment: moment.Moment;
    backendLocalDate: BackendLocalDate;
};

type TypeIdentifiers = keyof SupportedDateTypeMap;
export type SupportedDateTypes = SupportedDateTypeMap[TypeIdentifiers];

export function parseType(val: SupportedDateTypes | Nullish): TypeIdentifiers {
    if (val instanceof Date) {
        return 'date';
    }

    if (typeof val === 'string') {
        return 'backendLocalDate';
    }

    return 'moment';
}

const converter: { [k in TypeIdentifiers]: (val: moment.Moment | Nullish) => SupportedDateTypeMap[k] | undefined } = {
    backendLocalDate: val => DvbRestUtil.momentToLocalDate(val) ?? undefined,
    moment: val => val?.isValid() ? val.startOf('day') : undefined,
    date: val => val?.isValid() ? val.startOf('day').toDate() : undefined,
};

const validator: { [k in TypeIdentifiers]: (val: moment.Moment | Nullish) => boolean } = {
    backendLocalDate: DvbDateUtil.isValidBackendLocalDate,
    moment: DvbDateUtil.isValidMoment,
    date: DvbDateUtil.isValidDate,
};

class TypeConverter {

    private type: TypeIdentifiers = 'moment';

    public convert(value: SupportedDateTypes | Nullish): SupportedDateTypes | undefined {
        return converter[this.type](DvbDateUtil.toMoment(value));
    }

    public autoDetect(value: SupportedDateTypes | Nullish): void {
        this.type = parseType(value);
    }

    public isValid(value: SupportedDateTypes): boolean {
        return validator[this.type](value as any);
    }
}

export function typeConverter(): TypeConverter {
    return new TypeConverter();
}
