/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translator} from '@dv/shared/translator';
import {checkPresent} from '../types';
import {DvbUtil, TypeUtil} from '../util';
import type {IDvbError} from './DvbError';
import {DvbError, ERROR_CODE, ErrorType} from './DvbError';
import {isFakturaEndpointError, isFakturaStornierenError, isRechnungslaufError} from './faktura';

export class DvbErrorUtil {

    public static getErrorMessage(error: IDvbError, translate: Translator): string {
        switch (error.type) {
            case ErrorType.VALIDATION:
                return this.translate(translate, checkPresent(error.msgKey), error.args, error.useMessageformat);
            case ErrorType.INTERNAL:
                return this.getErrorMessageFromInternalError(translate, error);
            case ErrorType.BADREQUEST:
                return this.translate(translate, checkPresent(error.msgKey), error.args, error.useMessageformat);
            case ErrorType.SUCCESS_TRANSLATED:
                return checkPresent(error.msgKey);
            default:
                return this.argsOrMsg(translate, error);
        }
    }

    // noinspection OverlyComplexFunctionJS
    // eslint-disable-next-line complexity
    private static getErrorMessageFromInternalError(
        translate: Translator,
        error: IDvbError,
    ): string {
        switch (error.errorCode) {
            case ERROR_CODE.ENTITY_EXISTS: {
                const constraintName = DvbUtil.isNotEmptyString(error.args.constraintName) ?
                    DvbUtil.capitalize(error.args.constraintName) :
                    error.args.constraintName;

                const args = {
                    entityClassName: error.args.entityClassName,
                    constraintName,
                    duplicateValue: error.args.duplicateValue,
                };

                return this.translate(translate, 'ERRORS.ERR_ENTITY_EXISTS', args, error.useMessageformat);
            }
            case ERROR_CODE.ENTITY_NOT_FOUND:
                return this.translate(translate, 'ERRORS.ERR_ENTITY_NOT_FOUND', error.args, error.useMessageformat);
            case ERROR_CODE.FAKTURA_ERROR:
                return this.getFakturaErrorMessage(translate, error);
            case ERROR_CODE.PASSWORD_RULE_VALIDATION:
                if (DvbError.checkArgs(error)) {
                    return this.translate(translate, `ERRORS.PW_VIOLATION_${String(error?.args?.violation)}`);
                }

                return this.argsOrMsg(translate, error);
            case ERROR_CODE.KITAX_IMPORT_ERROR:
                return this.translate(translate, 'ERRORS.KITAX_IMPORT_ERROR');
            case ERROR_CODE.EMAIL_ERROR:
                return this.translate(translate, 'ADMINISTRATION.EMAIL_ERROR', error.args, error.useMessageformat);
            case ERROR_CODE.RULE_VIOLATION:
                return this.getRuleViolationMessage(translate, error);
            default:
                return this.argsOrMsg(translate, error);
        }
    }

    private static translate(
        translate: Translator,
        msgKey: string,
        args?: any,
        useMessageFormat: boolean = false,
    ): string {
        return translate.instant(msgKey, args, useMessageFormat ? 'messageformat' : undefined);
    }

    private static getRuleViolationMessage(translate: Translator, error: IDvbError): string {
        if (DvbError.checkArgs(error) && error.args.ruleName === 'ERR_INVALID_TEMPLATE_REPORT_TYPE') {
            const vals = {
                expectedValue: translate.instant(`REPORT.${String(error.args.expectedValue)}.TITLE`),
                actualValue: translate.instant(`REPORT.${String(error.args.actualValue)}.TITLE`),
            };

            return translate.instant(`ERRORS.ERR_RULE_VIOLATION.${String(error.args.ruleName)}`, vals);
        }

        return this.argsOrMsg(translate, error);
    }

    private static getFakturaErrorMessage(translate: Translator, error: IDvbError): string {
        if (isRechnungslaufError(error)) {
            return translate.instant('ERRORS.ERR_RECHNUNGSLAUF');
        }

        if (isFakturaEndpointError(error)) {
            const arg = error.args.fakturaError;
            const provider = translate.instant(`COMMON.${String(arg.provider)}`);

            return translate.instant(`ERRORS.ERR_FAKTURA_ENDPOINT_${String(arg.endpoint)}`, {provider});
        }

        if (isFakturaStornierenError(error)) {
            return translate.instant('ERRORS.ERR_STORNIEREN');
        }

        return this.argsOrMsg(translate, error);
    }

    private static argsOrMsg(translate: Translator, error: IDvbError): string {
        if (!TypeUtil.isString(error.args) && Object.keys(error.args).length > 0) {
            return Object.values(error.args).join(' ');
        }

        return translate.instant(checkPresent(error.msgKey));
    }
}
