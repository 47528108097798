/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import moment from 'moment';
import type {ISelectableZeitraum} from '../../types';
import {checkPresent} from '../../types';
import {DvbDateUtil} from './DvbDateUtil';
import type {IBackendLocalTimeHHMMRange} from './IBackendLocalTimeHHMMRange';
import type {ILimited} from './ILimited';
import type {ITimeRange} from './ITimeRange';
import {TimeRange} from './TimeRange';
import {Zeitraum} from './Zeitraum';

export class TimeRangeUtil {

    /**
     * Compares by ITimeRange.von then by ITimeRange.bis
     */
    public static readonly TIME_RANGE_COMPARATOR: (a: ITimeRange, b: ITimeRange) => number = (a, b) => {
        const vonComparison = DvbDateUtil.stichtagComparatorAsc(a.von, b.von);

        return vonComparison === 0 ?
            DvbDateUtil.stichtagComparatorAsc(a.bis, b.bis) :
            vonComparison;
    };

    /**
     * Merges the given Zeitraeume. Gaps between the given ranges are filled with spacers.
     *
     * @return the merged Zeitraume. Elements containing times from the given ranges are flagged as selected,
     *     spacers as not selected.
     */
    public static mergeTimeRanges(ranges: ITimeRange[]): ISelectableZeitraum[] {
        ranges.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);

        const mergedZeitraeume: ISelectableZeitraum[] = [];
        for (const zeitraum of ranges) {
            if (mergedZeitraeume.length === 0) {
                this.addToMergedZeitraeume(mergedZeitraeume, zeitraum);
            } else {
                const last = mergedZeitraeume[mergedZeitraeume.length - 1];
                if (DvbDateUtil.isTimeEqual(last.bis!, zeitraum.von!)) {
                    last.bis = moment(zeitraum.bis);
                } else {
                    this.addToMergedZeitraeume(mergedZeitraeume, zeitraum);
                }
            }
        }

        return mergedZeitraeume;
    }

    public static todayAtTime(time: BackendLocalTimeHHMM | undefined): moment.Moment {
        return moment(time, 'HH:mm');
    }

    public static getCurrentTime(): BackendLocalTimeHHMM {
        return moment().format('HH:mm') as BackendLocalTimeHHMM;
    }

    public static formatTimeRange(timeRange: ITimeRange): string {
        return `${timeRange.von?.format('HH:mm')} - ${timeRange.bis?.format('HH:mm')}`;
    }

    public static convertToTimeRange(timeRange: IBackendLocalTimeHHMMRange): ITimeRange {
        return {
            von: this.todayAtTime(timeRange.von),
            bis: this.todayAtTime(timeRange.bis),
        };
    }

    /**
     * Merges overlapping and adjacent time ranges.
     */
    public static mergeOverlappingTimeRanges(timeRanges: ITimeRange[]): ITimeRange[] {
        timeRanges.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);

        const merged: ITimeRange[] = [];
        for (const item of timeRanges) {
            if (merged.length === 0) {
                merged.push(new TimeRange(moment(item.von), moment(item.bis)));
                continue;
            }
            const last = merged[merged.length - 1];

            // skip time ranges that are already included
            if (item.von?.isBetween(last.von, last.bis, undefined, '[]') &&
                item.bis?.isBetween(last.von, last.bis, undefined, '[]')) {
                continue;
            }

            if (last.bis?.isSameOrAfter(item.von)) {
                // extend last if it intersects current
                last.bis = moment(item.bis);
            } else {
                // no intersections, simply add current
                merged.push(new TimeRange(moment(item.von), moment(item.bis)));
            }
        }

        return merged;
    }

    public static timeRangesWithoutToRemoves(timeRanges: ITimeRange[], toRemoves: ITimeRange[]): ITimeRange[] {
        let withoutToRemove: ITimeRange[] = timeRanges;

        for (const toRemove of toRemoves) {
            withoutToRemove = this.timeRangesWithoutToRemove(withoutToRemove, toRemove);
        }

        return withoutToRemove;
    }

    public static timeRangesWithoutToRemove(timeRanges: ITimeRange[], toRemove: ITimeRange): ITimeRange[] {
        const withoutToRemove: ITimeRange[] = [];

        timeRanges.forEach(timeRange => withoutToRemove.push(...this.timeRangeWithoutToRemove(timeRange, toRemove)));

        return withoutToRemove;
    }

    public static timeRangeWithoutToRemove(timeRange: ITimeRange, toRemove: ITimeRange): ITimeRange[] {

        // no intersection
        if (!(timeRange.von!.isBefore(toRemove.bis) &&
            timeRange.bis!.isAfter(toRemove.von))) {
            return [new TimeRange(moment(timeRange.von), moment(timeRange.bis))];
        }

        // toRemove includes full range
        if (timeRange.von!.isBetween(toRemove.von, toRemove.bis, undefined, '[]') &&
            timeRange.bis!.isBetween(toRemove.von, toRemove.bis, undefined, '[]')) {
            return [];
        }

        const withoutToRemove: ITimeRange[] = [];
        if (toRemove.von!.isBetween(timeRange.von, timeRange.bis, undefined, '()')) {
            withoutToRemove.push(new TimeRange(moment(timeRange.von), moment(toRemove.von)));
        }

        if (toRemove.bis!.isBetween(timeRange.von, timeRange.bis, undefined, '()')) {
            withoutToRemove.push(new TimeRange(moment(toRemove.bis), moment(timeRange.bis)));
        }

        return withoutToRemove;
    }

    public static hasAnyOverlapping(zeitraeume: ITimeRange[], zeitraum: ITimeRange): boolean {
        for (const z of zeitraeume) {
            if (this.isOverlapping(z, zeitraum)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns true if a overlaps b. Equal start/end times are not considered an overlap.
     */
    public static isOverlapping(a: ITimeRange, b: ITimeRange): boolean {
        return DvbDateUtil.getTimeDiff(checkPresent(a.von), checkPresent(b.bis)) > 0 &&
            DvbDateUtil.getTimeDiff(checkPresent(a.bis), checkPresent(b.von)) < 0;
    }

    /**
     * Returns true if a intersects b. Equal start/end times are considered to be an intersection.
     */
    public static isIntersecting(a: ITimeRange, b: ITimeRange): boolean {
        return !checkPresent(a.von).isAfter(b.bis) && !checkPresent(a.bis).isBefore(b.von);
    }

    public static contains(a: ITimeRange, b: ITimeRange): boolean {
        return DvbDateUtil.getTimeDiff(checkPresent(b.von), checkPresent(a.von)) <= 0
            && DvbDateUtil.getTimeDiff(checkPresent(b.bis), checkPresent(a.bis)) >= 0;
    }

    public static isSame(a: ITimeRange, b: ITimeRange): boolean {
        return DvbDateUtil.isTimeEqual(a.von!, b.von!) && DvbDateUtil.isTimeEqual(a.bis!, b.bis!);
    }

    public static findMatching<T extends ITimeRange>(ranges: T[], range: ITimeRange): T | undefined {
        return ranges.find(r => TimeRangeUtil.isSame(r, range));
    }

    public static toTimeRangeAtLocalDate(value: ITimeRange & ILimited, date: moment.Moment): ITimeRange {
        if (!DvbDateUtil.isGueltigOn(value, date)) {
            return {von: null, bis: null};
        }

        const startOfDay = moment(date).startOf('day');
        const von = startOfDay.isSame(value.gueltigAb, 'day') && value.von?.isValid() ?
            DvbDateUtil.setTime(moment(date), moment(value.von)) :
            startOfDay;
        const bis = startOfDay.isSame(value.gueltigBis, 'day') && value.bis?.isValid() ?
            DvbDateUtil.setTime(moment(date), moment(value.bis)) :
            moment(startOfDay).endOf('day');

        return {von, bis};
    }

    private static addToMergedZeitraeume(
        mergedZeitraeume: ISelectableZeitraum[],
        timeRange: ITimeRange,
    ): void {

        if (mergedZeitraeume.length !== 0) {
            const lastZeitraum = mergedZeitraeume[mergedZeitraeume.length - 1];
            const spacer =
                new Zeitraum(moment(lastZeitraum.bis), moment(timeRange.von));
            mergedZeitraeume.push(spacer);
        }

        const feld = new Zeitraum(moment(timeRange.von), moment(timeRange.bis)) as ISelectableZeitraum;
        feld.selected = true;
        mergedZeitraeume.push(feld);
    }
}
