export class DeployedVersions {
    private static readonly HEADER_NAME = 'X-kitadmin-version';

    public constructor(
        public readonly frontendVersion: string,
        public readonly backendVersion: string | null,
    ) {
    }

    /**
     * @param frontendVersion take from configuration/environment.
     *   It is parameterized so it can be used in both kitAdmin and groupAdmin
     */
    public static initWithBackendUnknown(
        frontendVersion: string,
    ): DeployedVersions {
        return new DeployedVersions(frontendVersion, null);
    }

    public static parse(
        frontendVersion: string,
        backendVersion: ((headerName: string) => string | null),
    ): DeployedVersions {
        const backend = backendVersion(this.HEADER_NAME);

        return new DeployedVersions(frontendVersion, backend);
    }

    public versionMismatch(): boolean {
        // Wir erwarten, dass die Versionsnummern im Frontend und Backend immer synchronisiert werden
        return this.backendVersion !== null
            && this.backendVersion !== this.frontendVersion;
    }
}
