/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpEvent, HttpHeaders, HttpInterceptorFn} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {inject} from '@angular/core';
import {DeployedVersions} from '@dv/shared/code';
import {map} from 'rxjs';
import {DeployedVersionsService} from './deployed-versions.service';

export const VERSION_INTERCEPTOR: HttpInterceptorFn = (req, next) => {
    const deployedVersionsService = inject(DeployedVersionsService);
    const frontendVersion = deployedVersionsService.getFrontendVersion();

    function parseBackendVersion(event: HttpEvent<unknown>): HttpEvent<unknown> {
        if (!(event instanceof HttpResponse)) {
            return event;
        }

        handleHeader(event.headers);

        return event;
    }

    function handleHeader(headers: HttpHeaders): void {
        const nextVersion = DeployedVersions.parse(frontendVersion, headerName => headers.get(headerName));

        deployedVersionsService.setDeployedVersions(nextVersion);
    }

    return next(req).pipe(
        map(event => parseBackendVersion(event)),
    );
};
