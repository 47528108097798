<button type="button"
        class="btn btn-link prev hidden-print"
        [attr.aria-label]="'COMMON.DATE_SWITCHER.PREV' | translate:{value: store.previousInDisplayFormat()}"
        [disabled]="store.isDisabled() || !store.hasPrevious()"
        (click)="store.setPreviousValue()">
    <i class="fa fa-chevron-left"></i>
</button>
<button type="button"
        class="btn btn-link date-navigation"
        [class.year-width]="store.mode() === 'year'"
        [class.month-width]="store.mode() === 'month'"
        [class.week-width]="store.mode() === 'week'"
        [class.day-width]="store.mode() === 'day'"
        [disabled]="store.isDisabled()"
        (click)="dp.show()">
    {{ store.dateInDisplayFormat() }}
</button>
<button type="button"
        class="btn btn-link next hidden-print"
        [attr.aria-label]="'COMMON.DATE_SWITCHER.NEXT' | translate:{value: store.nextInDisplayFormat()}"
        [disabled]="store.isDisabled() || !store.hasNext()"
        (click)="store.setNextValue()">
    <i class="fa fa-chevron-right"></i>
</button>

<div #dp="bsDatepicker"
     bsDatepicker
     [placement]="placement"
     [bsValue]="store.selectedDate()"
     (bsValueChange)="store.setValue($event)"
     [bsConfig]="store.bsConfig()"
     [isDisabled]="store.isDisabled()">
</div>
