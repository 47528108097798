/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, HostBinding, Input, signal, viewChild, WritableSignal} from '@angular/core';
import type {AbstractControl, ControlValueAccessor, ValidationErrors, Validator} from '@angular/forms';
import {FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import type {FunctionType, Nullish, SupportedDateTypes} from '@dv/shared/code';
import {DvbDateUtil, isNullish, typeConverter} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import type {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {BsDatepickerInputDirective, BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ButtonComponent} from '../../../atoms';
import {I18nModule} from '../../../i18n';
import {TextInputDirective} from '../../text-input.directive';
import {InputWithAddonWrapperComponent} from '../../wrapper';
import {getDefaultConfig} from '../datepicker-config';

@Component({
    selector: 'dvlib-datepicker-textfield',
    templateUrl: './datepicker-textfield.component.html',
    styleUrls: ['./datepicker-textfield.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatepickerTextfieldComponent},
        {provide: NG_VALIDATORS, multi: true, useExisting: DatepickerTextfieldComponent},
    ],
    imports: [
        FormsModule,
        I18nModule,
        BsDatepickerModule,
        InputWithAddonWrapperComponent,
        TextInputDirective,
        ButtonComponent,
        TooltipModule,
        TranslateModule,
    ],
})
export class DatepickerTextfieldComponent implements ControlValueAccessor, Validator {

    @Input() public placeholder: string | undefined;

    @Input() public smallInputs: boolean = false;

    @Input() public inputId: string | undefined;

    @Input() @HostBinding('attr.name') public name?: string;

    private bsDatepicker = viewChild.required(BsDatepickerInputDirective);

    public pickerDate: WritableSignal<Date | undefined> = signal(undefined);
    public isDisabled: WritableSignal<boolean> = signal(false);
    public bsconfig: Partial<BsDatepickerConfig> = getDefaultConfig();

    private converter = typeConverter();

    public onChange?: FunctionType;
    public onTouched?: FunctionType;

    public writeValue(value: SupportedDateTypes | Nullish): void {
        this.converter.autoDetect(value);

        const date = DvbDateUtil.toDate(value);
        this.pickerDate.set(date);

        // bsDatepicker is unable to automatically update its model value -> force it
        this.bsDatepicker().writeValue(date ?? '');
    }

    public registerOnChange(fn: FunctionType): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled.set(isDisabled);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return isNullish(control.value) || this.converter.isValid(control.value) ? null : {localDate: true};
    }

    public changeValue(date?: Date): void {
        if (date === this.pickerDate()) {
            // don't emit the initial change when it's equal to the input pickerDate (fixes expression changed error)
            return;
        }
        const converted = this.converter.convert(date);
        this.onChange?.(converted);
    }
}
