/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CheckInStatus} from '@dv/shared/backend/model/check-in-status';
import {getFontawesomeIcon, IconName} from '@dv/shared/code';

export type IconWidth = 'auto' | 'fixed';

@Component({
    selector: 'dvlib-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class IconComponent {
    @Input() public iconWidth: IconWidth = 'auto';

    public faIcon?: string;

    @Input()
    public set iconName(value: IconName) {
        this.faIcon = getFontawesomeIcon(value);
    }

    @Input()
    public set status(value: CheckInStatus) {
        // set status instead of iconName
        this.iconName = this.setStatusIcon(value);
    }

    private setStatusIcon(status: CheckInStatus): IconName {
        switch (status) {
            case 'EINGEPLANT':
                return 'eingeplant';
            case 'ANGEKOMMEN':
                return 'angekommen';
            case 'PAUSIERT':
                return 'pausiert';
            case 'ABGEHOLT':
                return 'abgeholt';
            case 'ABWESEND':
                return 'abwesend';
        }
    }
}
