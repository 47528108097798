/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {DayOfWeek} from '../../constants/DayOfWeek';
import {DvbDateUtil} from './DvbDateUtil';
import type {IZeitraum, TagUndZeitraum} from './IZeitraum';

/**
 * Basic IZeitraum implementation for not persisted, dynamically created zeitraume.
 */
export class Zeitraum implements IZeitraum {

    public id: string | null = null;
    public name: string | null = null;

    public durationInMinutes: number = 0;
    private _von: moment.Moment;
    private _bis: moment.Moment;

    public constructor(
        von: moment.Moment,
        bis: moment.Moment,
    ) {
        this._von = von;
        this._bis = bis;
        this.calcDuration();
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }

    public isSame(zeitraumFeld: TagUndZeitraum, dayOfWeek: DayOfWeek): boolean {
        return zeitraumFeld.dayOfWeek === dayOfWeek &&
            DvbDateUtil.isTimeEqual(zeitraumFeld.zeitraum.von!, this._von) &&
            DvbDateUtil.isTimeEqual(zeitraumFeld.zeitraum.bis!, this._bis);
    }

    public get von(): moment.Moment {
        return this._von;
    }

    public set von(value: moment.Moment) {
        this._von = value;
        this.calcDuration();
    }

    public get bis(): moment.Moment {
        return this._bis;
    }

    public set bis(value: moment.Moment) {
        this._bis = value;
        this.calcDuration();
    }

    protected calcDuration(): void {
        this.durationInMinutes = DvbDateUtil.getTimeDiff(this._von, this._bis);
    }
}
