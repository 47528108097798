/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {RestIncludes} from '@dv/shared/backend/model/rest-includes';
import {isPresent} from '../../types';
import {hasOwnPropertyGuarded} from '../hasOwnProperty';

/**
 * Tell the server to include sub properties within the response.
 */
export type RestInclude = Partial<Record<'includes', string>>;

export function isRestInclude(val: unknown): val is Record<'includes', string> {
    return typeof val === 'object' && isPresent(val) && hasOwnPropertyGuarded(val, 'includes');
}

export function restIncludes(includes: string): {
    includes: RestIncludes;
} {
    return {includes: new RestIncludes(includes)};
}
