import type {AfterViewInit, OnDestroy} from '@angular/core';
import {Directive, inject, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import type {Subscription} from 'rxjs';
import {debounceTime, ReplaySubject, tap} from 'rxjs';

@Directive({
    selector: '[dvlibSetValue]',
    standalone: true,
})
export class SetValueDirective implements AfterViewInit, OnDestroy {

    private readonly ngControl = inject(NgControl, {self: true});

    private readonly subject = new ReplaySubject(1);
    private readonly setValue$ = this.subject.pipe(
        debounceTime(0), // makes sure the the value updates in the next change detection cycle
        tap(value => this.ngControl.control?.setValue(value, {emitEvent: false})),
    );

    private subscription?: Subscription;

    @Input({required: true})
    public set dvlibSetValue(val: any) {
        this.subject.next(val);
    }

    public ngAfterViewInit(): void {
        this.subscription = this.setValue$.subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
