/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input} from '@angular/core';
import type {CollapsibleIcon, Nullish} from '@dv/shared/code';
import {ButtonIcon} from '@dv/shared/code';
import {isBs3} from 'ngx-bootstrap/utils';
import {SpinnerComponent} from '../../../spinner';
import {IconComponent} from '../../icon';
import type {ButtonType} from '../button-type';
import {DEFAULT_BUTTON_TYPE} from '../button-type';

export type ButtonStyle = 'primary' | 'secondary' | 'link-button' | 'collapsible-toggle' | 'input-addon' | 'bare';
export type IconPosition = 'left' | 'right';

function getBs3Classes(): string {
    return isBs3() ? ' btn-default popup-btn' : '';
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dvlibButton], a[dvlibButton]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent, SpinnerComponent],
})
export class ButtonComponent {
    @Input() public badgeCounter: number = 0;
    @Input() public isLoading: boolean = false;
    @Input() public iconPosition: IconPosition = 'right';
    @Input({transform: booleanAttribute}) public truncate: boolean = false;
    @HostBinding('attr.type') @Input() public type!: ButtonType | Nullish;
    @HostBinding('class.btn') private readonly bsStyles = true;
    @HostBinding('class') private colorStyles = '';
    public _icon: ButtonIcon | CollapsibleIcon | Nullish;

    @Input()
    public set dvlibButton(value: ButtonStyle) {
        this.colorStyles = this.getStyleClass(value);
    }

    @Input()
    public set icon(value: ButtonIcon) {
        this._icon = value;
    }

    @Input()
    public set isCollapsed(isCollapsed: boolean) {
        this._icon = isCollapsed ? 'zugeklappt' : 'aufgeklappt';
    }

    public constructor(
        private readonly element: ElementRef<HTMLButtonElement | HTMLAnchorElement>,
    ) {
        this.type = element.nativeElement instanceof HTMLButtonElement ? DEFAULT_BUTTON_TYPE : undefined;
    }

    private getStyleClass(button: ButtonStyle): string {
        switch (button) {
            case 'primary':
                return 'btn-primary';
            case 'secondary':
                return 'btn-secondary';
            case 'link-button':
            case 'collapsible-toggle':
                return 'btn-link';
            case 'input-addon':
                return `btn-main button-input-addon${getBs3Classes()}`;
            case 'bare':
                return 'bare-button';
        }
    }
}
