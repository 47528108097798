<div class="dv-loading-button">
    <button [attr.type]="type"
            class="btn"
            [ngClass]="{
                      'btn-primary': !asLink,
                      'btn-link': asLink,
                      'btn-link-dropdown': dropdown && asLink,
                      'dropdown-toggle': dropdown,
                      'btn-margin': margin
                    }"
            (click)="click$.next($event)"
            [disabled]="isLoading || disabled">
        @if (isLoading && !spinnerRight) {
            <div class="spinner">
                <dvlib-spinner [show]="isLoading && !spinnerRight" [ngClass]="{ 'not-loading': !isLoading }"/>
            </div>
        }
        <ng-content/>
        @if (isLoading && spinnerRight) {
            <div class="spinner-right">
                <dvlib-spinner [show]="isLoading && spinnerRight" [ngClass]="{ 'not-loading': !isLoading }"/>
            </div>
        }
    </button>
</div>
