import { createAdapter, createSelectorsCache, buildAdapter, getMemoizedSelector } from '@state-adapt/core';
const booleanAdapter = createAdapter()({
  setTrue: () => true,
  setFalse: () => false,
  toggle: state => !state
});
const baseBooleanAdapter = createAdapter()({});
const numberAdapter = createAdapter()({
  increment: n => n + 1,
  decrement: n => n - 1,
  add: (n, n2) => n + n2,
  subtract: (n, n2) => n - n2,
  multiply: (n, n2) => n * n2,
  divide: (n, n2) => n / n2,
  pow: (n, pow) => Math.pow(n, pow),
  sqrt: n => Math.sqrt(n),
  max: (n, n2) => Math.max(n, n2),
  min: (n, n2) => Math.min(n, n2)
});
const baseNumberAdapter = createAdapter()({
  selectors: {}
});
const stringAdapter = createAdapter()({
  concat: (str, str2) => str + str2,
  lowercase: str => str.toLowerCase(),
  uppercase: str => str.toUpperCase(),
  selectors: {
    lowercase: str => str.toLowerCase(),
    uppercase: str => str.toUpperCase()
  }
});
const baseStringAdapter = createAdapter()({
  selectors: {}
});
function getNewEntitiesCopy() {
  return [[], {}];
}
function createEntityState(state = {}) {
  return {
    ...state,
    ids: state.ids || [],
    entities: state.entities || {}
  };
}
const ignoredKeys = ['selectors', 'set'];
function createEntityAdapter() {
  return (adapter, options) => {
    const fullAdapter = createAdapter()(adapter);
    const entitySelectors = fullAdapter.selectors;
    entitySelectors.id = entitySelectors.id || (entity => entity.id);
    const useCache = options?.useCache ?? false;
    const getEntityCache = (entity, cache) => {
      if (useCache) {
        const id = entitySelectors.id(entity);
        const namespace = 'entity_' + id;
        const children = cache?.__children;
        return children && (children[namespace] = children[namespace] || createSelectorsCache());
      }
      return;
    };
    const destroyEntityCache = (entity, cache) => {
      if (useCache) {
        delete cache?.__children['entity_' + entitySelectors.id(entity)];
      }
    };
    const destroyEntityCaches = cache => {
      if (useCache) {
        const children = cache?.__children;
        if (children) {
          for (const key in children) {
            if (key.startsWith('entity_')) {
              delete children[key];
            }
          }
        }
      }
    };
    const entityAdapter = buildAdapter()({
      addOne: (state, entity) => {
        const id = entitySelectors.id(entity);
        return {
          ...state,
          ids: [...state.ids, id],
          entities: {
            ...state.entities,
            [id]: entity
          }
        };
      },
      addMany: (state, entities) => {
        const newEntities = {
          ...state.entities
        };
        entities.forEach(entity => {
          const id = entitySelectors.id(entity);
          newEntities[id] = entity;
        });
        return {
          ...state,
          ids: [...state.ids, ...entities.map(entity => entitySelectors.id(entity))],
          entities: newEntities
        };
      },
      setOne: (state, entity) => {
        const id = entitySelectors.id(entity);
        const oldEntity = state.entities[id];
        const ids = oldEntity ? state.ids : [...state.ids, id];
        const entities = {
          ...state.entities,
          [id]: entity
        };
        return {
          ...state,
          ids,
          entities
        };
      },
      setMany: (state, entities) => {
        const newIds = [];
        const newEntities = {
          ...state.entities
        };
        entities.forEach(entity => {
          const id = entitySelectors.id(entity);
          if (!state.entities[id]) {
            newIds.push(id);
          }
          newEntities[id] = entity;
        });
        return {
          ...state,
          ids: newIds.length ? [...state.ids, ...newIds] : state.ids,
          entities: newEntities
        };
      },
      setAll: (state, entities, i, cache) => {
        destroyEntityCaches(cache);
        const [ids, newEntities] = getNewEntitiesCopy();
        entities.forEach(entity => {
          const id = entitySelectors.id(entity);
          ids.push(id);
          newEntities[id] = entity;
        });
        return {
          ...state,
          ids,
          entities: newEntities
        };
      },
      removeOne: (state, id, i, cache) => {
        const newIds = state.ids.filter(entityId => entityId !== id);
        const newEntities = {
          ...state.entities
        };
        delete newEntities[id];
        destroyEntityCache(state.entities[id], cache);
        return {
          ...state,
          ids: newIds,
          entities: newEntities
        };
      },
      removeMany: (state, ids, i, cache) => {
        const newIds = [];
        const newEntities = {};
        state.ids.forEach(id => {
          if (!ids.includes(id)) {
            newIds.push(id);
            newEntities[id] = state.entities[id];
          } else {
            destroyEntityCache(state.entities[id], cache);
          }
        });
        return {
          ...state,
          ids: newIds,
          entities: newEntities
        };
      },
      removeAll: (state, payload, i, cache) => {
        destroyEntityCaches(cache);
        return {
          ...state,
          ids: [],
          entities: {}
        };
      },
      upsertOne: (state, entity) => {
        const id = entitySelectors.id(entity);
        const oldEntity = state.entities[id];
        return {
          ...state,
          ids: oldEntity ? state.ids : [...state.ids, id],
          entities: {
            ...state.entities,
            [id]: oldEntity ? {
              ...oldEntity,
              ...entity
            } : entity
          }
        };
      },
      upsertMany: (state, entities) => {
        const ids = !entities.length ? state.ids : [...state.ids];
        const newEntities = !entities.length ? state.entities : {
          ...state.entities
        };
        entities.forEach(entity => {
          const id = entitySelectors.id(entity);
          const oldEntity = state.entities[id];
          if (!oldEntity) {
            ids.push(id);
            newEntities[id] = entity;
          } else {
            newEntities[id] = {
              ...oldEntity,
              ...entity
            };
          }
        });
        return {
          ...state,
          ids,
          entities: newEntities
        };
      },
      selectors: {
        entities: state => state.entities,
        ids: state => state.ids
      }
    })({
      all: s => s.ids.map(id => s.entities[id]),
      count: s => s.ids.length
    })();
    // // Summary
    // - `${state}One${Change}` // setOneSelected(false)
    // - `${state}All${Change}` // setAllSelected(false)
    // - `${state}${Filter}${Change}` // setSelectedSelected(false)
    // - `oneBy{Sorter}`: NO
    // - `allBy{Sorter}`: Person[]; // allByAge
    // - `${filter}By{Sorter}`: Person[]; // selectedByAge
    // - `count`: number; // count
    // - `${filter}Count`: number; // selectedCount
    // - `allAre${Filter}`: boolean; // allAreSelected
    const reactions = entityAdapter;
    const selectors = entityAdapter.selectors;
    const createSortSelector = (key, parentSelectorKey, sorter) => getMemoizedSelector(key, (state, cache) => [...selectors[parentSelectorKey](state, cache)].sort((a, b) => entitySelectors[sorter](a, getEntityCache(a, cache)) > entitySelectors[sorter](b, getEntityCache(b, cache)) ? 1 : -1));
    for (const stateChangeName in adapter) {
      if (!ignoredKeys.includes(stateChangeName)) {
        const stateChange = adapter[stateChangeName];
        let stateChangeVerb = stateChangeName;
        let stateChangeNoun = '';
        for (let i = 0; i < stateChangeName.length; i++) {
          const char = stateChangeName[i];
          if (char === char.toUpperCase()) {
            stateChangeVerb = stateChangeName.slice(0, i);
            stateChangeNoun = stateChangeName.slice(i);
            break;
          }
        }
        // One
        reactions[`${stateChangeVerb}One${stateChangeNoun}`] = (state, [id, payload], initialState, cache) => {
          const entity = state.entities[id];
          const initialEntity = initialState.entities[id];
          const newEntity = stateChange(entity, payload, initialEntity, getEntityCache(entity, cache));
          return {
            ...state,
            entities: {
              ...state.entities,
              [id]: newEntity
            }
          };
        };
        // Many
        reactions[`${stateChangeVerb}Many${stateChangeNoun}`] = (state, updates, initialState, cache) => {
          const newEntities = {
            ...state.entities
          };
          updates.forEach(([id, payload]) => {
            const entity = state.entities[id];
            const initialEntity = initialState.entities[id];
            const newEntity = stateChange(entity, payload, initialEntity, getEntityCache(entity, cache));
            newEntities[id] = newEntity;
          });
          return {
            ...state,
            entities: newEntities
          };
        };
        // All
        const ignoredKeys = ['set', 'reset']; // 'set', 'reset'
        if (!ignoredKeys.includes(stateChangeName)) {
          reactions[`${stateChangeVerb}All${stateChangeNoun}`] = (state, payload, initialState, cache) => {
            const newEntities = {
              ...state.entities
            };
            state.ids.forEach(id => {
              const entity = state.entities[id];
              const initialEntity = initialState.entities[id];
              const newEntity = stateChange(entity, payload, initialEntity, getEntityCache(entity, cache));
              newEntities[id] = newEntity;
            });
            return {
              ...state,
              entities: newEntities
            };
          };
        }
        // Filters
        let sortersLooped = false;
        const filters = options?.filters || [];
        const sorters = options?.sorters || [];
        filters.forEach(filter => {
          const filterStr = String(filter);
          const Filter = filterStr[0].toUpperCase() + filterStr.slice(1);
          reactions[`${stateChangeVerb}${Filter}${stateChangeNoun}`] = (state, payload, initialState, cache) => {
            const newEntities = {
              ...state.entities
            };
            state.ids.forEach(id => {
              const entity = state.entities[id];
              const entityCache = getEntityCache(entity, cache);
              if (entitySelectors[filter](entity, entityCache)) {
                const initialEntity = initialState.entities[id];
                const newEntity = stateChange(entity, payload, initialEntity, entityCache);
                newEntities[id] = newEntity;
              }
            });
            return {
              ...state,
              entities: newEntities
            };
          };
          if (!selectors[filterStr]) {
            selectors[filterStr] = getMemoizedSelector(filterStr, (state, cache) => {
              return selectors.all(state, cache).filter(entity => {
                const entityCache = getEntityCache(entity, cache);
                return entitySelectors[filter](entity, entityCache);
              });
            });
            const filterStrCount = `${filterStr}Count`;
            selectors[filterStrCount] = getMemoizedSelector(filterStrCount, (state, cache) => selectors[filterStr](state, cache).length);
            const allAreFilter = `allAre${Filter}`;
            selectors[allAreFilter] = getMemoizedSelector(allAreFilter, (state, cache) => selectors[filterStrCount](state, cache) === selectors.count(state, cache));
            sorters.forEach(sorter => {
              const sorterstr = String(sorter);
              if (sorter === filter) return;
              const Sorter = sorterstr[0].toUpperCase() + sorterstr.slice(1);
              const filterStrBySort = `${filterStr}By${Sorter}`;
              selectors[filterStrBySort] = createSortSelector(filterStrBySort, filterStr, sorter);
              const allBySorter = `allBy${Sorter}`;
              if (!selectors[allBySorter]) {
                createSortSelector(allBySorter, 'all', sorter);
              }
            });
            sortersLooped = true;
          }
        });
        // Sorters
        // Might have to loop through sorters for filters, so don't loop again later
        if (!sortersLooped) {
          sortersLooped = true;
          sorters.forEach(sorter => {
            const sorterstr = String(sorter);
            const Sorter = sorterstr[0].toUpperCase() + sorterstr.slice(1);
            const allBySorter = `allBy${Sorter}`;
            selectors[allBySorter] = createSortSelector(allBySorter, 'all', sorter);
          });
        }
      }
    }
    // Might add later, for each selector
    // - `${selector}
    // movies.id$ [1, 4, 5, 8]
    // movies.uiModel$ [whatever, whatever]
    // movies.selected$ [true, false, false, true]
    // All selectors
    // Object.entries(entitySelectors).forEach(([name, selector]: [string, any]) => {
    //   selectors3[name] =
    //     selectors3[name] ||
    //     ((state: State, cache: SelectorsCache) => selector(state.entities, cache));
    // });
    // I could do more, but this is already a lot
    return entityAdapter;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { baseBooleanAdapter, baseNumberAdapter, baseStringAdapter, booleanAdapter, createEntityAdapter, createEntityState, numberAdapter, stringAdapter };
