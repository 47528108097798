/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {BackendLocalDateTime} from '@dv/shared/backend/model/backend-local-date-time';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {BackendZonedDateTime} from '@dv/shared/backend/model/backend-zoned-date-time';
import {RestIncludes} from '@dv/shared/backend/model/rest-includes';
import moment from 'moment';
import type {RestInclude, RestLimited} from '.';
import {isRestInclude} from '.';
import {ErrorType, isDvbError} from '../../errors';
import type {ApiResponseTransformer} from '../../types';
import {checkPresent} from '../../types';
import {DvbDateUtil} from '../datetime';
import {DvbUtil} from '../DvbUtil';
import {TypeUtil} from '../TypeUtil';

export const NO_DATA = 'NO_DATA';

export type NoContent = null;
export type MatrixParamValue = boolean | string[] | string | number | null | undefined;
export type MatrixParams = Record<string, MatrixParamValue>;
export type RestLimitedMatrixParams = { gueltigAb?: string | null; gueltigBis?: string | null };

/**
 * Utilities for REST-Calls (angular-independent)
 */
export class DvbRestUtil {

    /**
     * @param paramMap Key: param name, Value: param value
     */
    public static encodeMatrixParams(paramMap?: MatrixParams): string {
        let matrix = '';
        Object.entries(paramMap ?? {}).forEach(([k, v]) => {
            // noinspection IfStatementWithTooManyBranchesJS
            if (TypeUtil.isNumber(v)) {
                matrix += `;${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
            } else if (typeof v === 'boolean') {
                matrix += `;${encodeURIComponent(k)}=${v.toString()}`;
            } else if (Array.isArray(v)) {
                v.forEach(vv => {
                    matrix += v ? `;${encodeURIComponent(k)}=${encodeURIComponent(vv)}` : '';
                });
            } else {
                matrix += v ? `;${encodeURIComponent(k)}=${encodeURIComponent(v)}` : '';
            }
        });

        return matrix;
    }

    public static toIncludes(params?: RestInclude): { includes?: RestIncludes } {
        return isRestInclude(params) ? {includes: new RestIncludes(params.includes)} : {};
    }

    /**
     * A helper function to convert "gueltigAb" and "gueltigBis" to MatrixParams compatible strings.
     */
    public static toMatrixParams<T extends RestLimited>(val: T): Omit<T, keyof RestLimited> & RestLimitedMatrixParams {
        const {gueltigAb, gueltigBis, ...remaining} = val;

        return {
            ...remaining,
            gueltigAb: DvbRestUtil.toRestLimitedMatrixParamValue(gueltigAb),
            gueltigBis: DvbRestUtil.toRestLimitedMatrixParamValue(gueltigBis),
        };
    }

    /**
     * @param aMoment time instance
     * @return a Date (YYYY-MM-DD) representation of the given moment. NULL when aMoment is invalid
     */
    public static momentToLocalDate(aMoment: unknown): BackendLocalDate | null {
        if (DvbDateUtil.isValidMoment(aMoment)) {
            return moment(aMoment).startOf('day').format('YYYY-MM-DD');
        }

        return null;
    }

    public static momentToLocalDateChecked(aMoment: unknown): BackendLocalDate {
        return checkPresent(DvbRestUtil.momentToLocalDate(aMoment));
    }

    /**
     * @param aMoment time instance
     * @return a timestamp (YYYY-MM-DDTHH:mm:ss) representation of the given moment. NULL when aMoment is invalid
     */
    public static momentToLocalDateTime(aMoment: unknown): BackendLocalDateTime | null {
        if (DvbDateUtil.isValidMoment(aMoment)) {
            return moment(aMoment).format('YYYY-MM-DDTHH:mm:ss.000');
        }

        return null;
    }

    /**
     * @param localDateString string with format YYYY-MM-DD
     */
    public static localDateToMoment(localDateString: unknown): moment.Moment | null {
        if (!localDateString) {
            return null;
        }

        const theMoment = moment(localDateString, 'YYYY-MM-DD', true);

        return theMoment.isValid() ? theMoment : null;
    }

    public static localDateToMomentChecked(localDateString: unknown): moment.Moment {
        return checkPresent(DvbRestUtil.localDateToMoment(localDateString));
    }

    public static localDateTimeToMoment(localDateTimeString: string | undefined): moment.Moment | null {
        if (!localDateTimeString) {
            return null;
        }

        const theMoment = moment(localDateTimeString, moment.ISO_8601, true);

        return theMoment.isValid() ? theMoment : null;
    }

    public static localeHHMMTimeToMoment(
        localTimeString: string | null | undefined | moment.Moment,
    ): moment.Moment | null {
        if (!localTimeString) {
            return null;
        }

        const theMoment = moment(localTimeString, 'HH:mm', true);

        return DvbDateUtil.toValidHHMMMoment(theMoment);
    }

    public static momentTolocaleHHMMTime(localeTime: unknown): BackendLocalTimeHHMM | null {
        if (DvbDateUtil.isValidMoment(localeTime)) {
            return moment(localeTime).format('HH:mm') as BackendLocalTimeHHMM;
        }

        return null;
    }

    public static momentToBackendZonedDateTime(dateTime: moment.Moment): BackendZonedDateTime {
        return dateTime.format();
    }

    public static backendZonedDateTime(): BackendZonedDateTime {
        return DvbRestUtil.momentToBackendZonedDateTime(moment());
    }

    public static toBackendZonedDateTime(
        timestamp: BackendLocalTimeHHMM,
        datum: BackendLocalDate,
    ): BackendZonedDateTime {
        const time = DvbRestUtil.localeHHMMTimeToMoment(timestamp)!;
        const datetime = DvbDateUtil.setTime(DvbRestUtil.localDateToMoment(datum)!, time);
        const dateTimeStr = DvbRestUtil.momentToBackendZonedDateTime(datetime);

        return dateTimeStr;
    }

    /**
     * If data is an array, returns an array of its transformed elements.
     */
    public static transformArray<T>(data: any, transformer: ApiResponseTransformer<T>): T[] {
        if (!Array.isArray(data)) {
            return [];
        }

        return data.map((dataEntry: any) => transformer.apiResponseTransformer(dataEntry));
    }

    /**
     * @param matrix the Matrix Param object
     * @param params
     * @return the given matrix object, extendend with gueltigAb & gueltigBis, if these inputs are valid moments
     */
    public static setGueltigkeitParams(matrix: MatrixParams, params?: RestLimited): MatrixParams {
        if (!params) {
            return matrix;
        }

        if (DvbDateUtil.isValidMoment(params.gueltigAb)) {
            matrix.gueltigAb = DvbRestUtil.momentToLocalDate(params.gueltigAb);
        }
        if (DvbDateUtil.isValidMoment(params.gueltigBis)) {
            matrix.gueltigBis = DvbRestUtil.momentToLocalDate(params.gueltigBis);
        }

        return matrix;
    }

    public static isRequestCancelled(err: unknown): boolean {
        return isDvbError(err) && err.type === ErrorType.ABORT;
    }

    public static hasNoData(response: unknown): boolean {
        return response === NO_DATA;
    }

    private static toRestLimitedMatrixParamValue(value: unknown): string | undefined {
        if (DvbUtil.isNotEmptyString(value)) {
            return value;
        }

        const convertedValue = DvbRestUtil.momentToLocalDate(value);
        if (convertedValue) {
            return convertedValue;
        }

        return undefined;
    }
}
