/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type CheckInStatus = 'EINGEPLANT' | 'ANGEKOMMEN' | 'ABGEHOLT' | 'ABWESEND' | 'PAUSIERT';

export const CheckInStatus = {
    EINGEPLANT: 'EINGEPLANT' as CheckInStatus,
    ANGEKOMMEN: 'ANGEKOMMEN' as CheckInStatus,
    ABGEHOLT: 'ABGEHOLT' as CheckInStatus,
    ABWESEND: 'ABWESEND' as CheckInStatus,
    PAUSIERT: 'PAUSIERT' as CheckInStatus
};

