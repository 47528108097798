/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpInterceptorFn} from '@angular/common/http';
import {HttpXsrfTokenExtractor} from '@angular/common/http';
import {inject} from '@angular/core';
import {XSRF_HEADER_NAME} from '@dv/shared/authentication/model';
import {ConfigService} from '@dv/shared/config';

/**
 * Sets the XSRF header on all requests as long as the cookie is available.
 *
 * Needed because by default, the header is only set for HTTP methods that modify data (POST, PUT, PATCH,...)
 */
export const HTTP_XSRF_INTERCEPTOR: HttpInterceptorFn = (request, next) => {
    const token = inject(HttpXsrfTokenExtractor).getToken();
    const restBackend = inject(ConfigService).restBackend;

    return token !== null && !request.headers.has(XSRF_HEADER_NAME) && request.url.startsWith(restBackend) ?
        next(request.clone({headers: request.headers.set(XSRF_HEADER_NAME, token)})) :
        next(request);
};
