/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '../types';

export const SUPPORTED_LANGUAGES = stringUnion('DE', 'FR');

export type SupportedLanguage = typeof SUPPORTED_LANGUAGES.type;

export const SUPPORTED_LOCALES = stringUnion('de-CH', 'fr-CH');

export type SupportedLocale = typeof SUPPORTED_LOCALES.type;

const languageToLocale: Record<SupportedLanguage, SupportedLocale> = {
    DE: 'de-CH',
    FR: 'fr-CH',
};

export const DEFAULT_LOCALE: SupportedLocale = languageToLocale.DE;

export function localeFrom(language: SupportedLanguage): SupportedLocale {
    const found = languageToLocale[language];
    if (found === undefined) {
        throw Error(`Cannot convert language to locale: ${language}`);
    }

    return found;
}
