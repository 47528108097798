/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed, inject, Pipe, PipeTransform} from '@angular/core';
import {DvbDateUtil, isNullish, Nullish} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {UserLanguageService} from '../i18n';

/**
 * Takes decimal hours as an input and returns hours and minutes in the format 'hh:mm h'
 */
@Pipe({
    name: 'dvHoursFormat',
    standalone: true,
})
export class DvHoursFormatPipe implements PipeTransform {

    private userLanguageService = inject(UserLanguageService);
    private translator = inject(Translator);

    private numberFormat = computed(() => {
        const locale = this.userLanguageService.userLocale();

        return new Intl.NumberFormat(locale, {minimumIntegerDigits: 2});
    });

    public transform(input: number | Nullish): string {
        if (isNullish(input)) {
            return '';
        }

        const hours = Math.floor(input);
        const minutes = this.numberFormat().format(Math.floor(input % 1 * DvbDateUtil.MINUTES_PER_HOUR));

        return this.translator.instant('COMMON.HOURS_MINUTES', {hours, minutes});
    }
}
