/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {TranslateParser} from '@ngx-translate/core';
import {TranslateDefaultParser} from '@ngx-translate/core';

/**
 * We have several messageformat translation strings that we mostly use without providing its parameters (e.g.
 * showcount).
 *
 * In those cases, the default parser implementation passes undefined as the param object to messageformat.
 * The result is an exception: "Cannot read properties of undefined (reading 'showcount')".
 *
 * This custom parser avoids the exception by replacing undefined parameters with an empty object.
 */
@Injectable({
    providedIn: 'root',
})
export class KitAdminTranslationParser implements TranslateParser {

    private parser = new TranslateDefaultParser();

     // eslint-disable-next-line @typescript-eslint/ban-types
    public interpolate(expr: string | Function, params?: unknown): string {
        return this.parser.interpolate(expr, params ?? {});
    }

    public getValue(target: any, key: string): any {
        return this.parser.getValue(target, key);
    }
}
