/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '../types';

// FIXME: lich: from @app/backend?
export enum NamedEntityType {
    BELEGUNG = 'BELEGUNG',
    BENUTZER = 'BENUTZER',
    BETREUUNGSFAKTOR_REGEL = 'BETREUUNGSFAKTOR_REGEL',
    BETREUUNGS_PERSON = 'BETREUUNGS_PERSON',
    DOKUMENT_LAYOUT = 'DOKUMENT_LAYOUT',
    FIRMA = 'FIRMA',
    FIRMENKONTINGENT = 'FIRMENKONTINGENT',
    GRUPPE = 'GRUPPE',
    KIND = 'KIND',
    KITA = 'KITA',
    KONTAKTPERSON = 'KONTAKTPERSON',
    RECHNUNG = 'RECHNUNG',
    TAGES_ELTERN = 'TAGES_ELTERN',
    TARIF = 'TARIF',
}

export function isNamedEntityType(value: any): value is NamedEntityType {
    return Object.values(NamedEntityType).includes(value);
}

export class NamedEntity implements IRestModel {
    public static readonly DTYPE: typeof NamedEntityType = NamedEntityType;

    public constructor(
        public entityId: string | null = null,
        public dtype: NamedEntityType | null = null,
        public displayName: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): NamedEntity {
        return new NamedEntity(data.entityId, data.dtype, data.displayName);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            entityId: this.entityId,
            dtype: this.dtype,
            displayName: this.displayName,
        };
    }
}
