/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgIf} from '@angular/common';
import {Directive, inject, Input} from '@angular/core';
import type {Nullish, UnparsedPermission} from '@dv/shared/code';
import {LogFactory} from '@dv/shared/code';
import {AuthorizationProviderService} from './../authorization-provider.service';
import {AbstractAuthzDirective} from './abstract-require.directive';
import {CallbackStrategy} from './strategy';

const LOG = LogFactory.createLog('require-permission.directive');

@Directive({
    selector: '[dvlibRequirePermission]',
    standalone: true,
    hostDirectives: [NgIf],
})
export class RequirePermissionDirective extends AbstractAuthzDirective {

    private ngIfDirective = inject(NgIf);

    public constructor(
        private readonly authzProvider: AuthorizationProviderService,
    ) {
        super(new CallbackStrategy(disabled => this.setDisabled(disabled)));
    }

    private setDisabled(disabled: boolean): void {
        this.ngIfDirective.ngIf = !disabled;
    }

    @Input()
    public set dvlibRequirePermission(permission: UnparsedPermission | Nullish) {
        LOG.trace('dvlibRequirePermission', permission);
        super.changeSource(this.authzProvider.hasPermission$(permission));
    }

    @Input()
    public set dvlibRequirePermissionCondition(condition: boolean) {
        LOG.trace('dvlibRequirePermissionCondition', condition);
        super.changeCondition(condition);
    }
}
