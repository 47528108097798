/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// Source: https://stackoverflow.com/a/53746948
// TypeScript will infer a string union type from the literal values passed to
// this function. Without `extends string`, it would instead generalize them
// to the common string type.

export interface StringUnion<T> {
    type: T;
    values: T[];

    guard: (value: unknown) => value is T;
    check: (value: unknown) => T;
}

export function stringUnion<T extends string>(...values: T[]): StringUnion<T> {
    Object.freeze(values);
    const valueSet: Set<string> = new Set(values);

    const guard = (value: unknown): value is T => typeof value === 'string' && valueSet.has(value);

    const check = (value: unknown): T => {
        if (!guard(value)) {
            const actual: string = JSON.stringify(value);
            const expected: string = values.map(s => JSON.stringify(s)).join(' | ');
            throw new TypeError(`Value '${actual}' is not assignable to type '${expected}'.`);
        }

        return value;
    };

    const unionNamespace = {guard, check, values};

    return Object.freeze(unionNamespace as typeof unionNamespace & { type: T });
}
