/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {hasOwnPropertyGuarded} from '../util';

/**
 * An actual permission string
 */
export type WildcardPermission = string;

/**
 * AND combination of permissions
 */
export type AllOfPermission = RequiredPermission[];

/**
 * OR combination of permissions in the oneOf Array
 */
export type OneOfPermission = { oneOf: RequiredPermission[] };

/**
 * An actual permission request
 */
export type RequiredPermission = WildcardPermission | AllOfPermission | OneOfPermission;

/**
 * can be a string (requiring parsing), or actual permissions
 */
export type UnparsedPermission = string | RequiredPermission;

/**
 * Specifically for State declarataions (data.permission)
 */
export type StatePermission<T> = UnparsedPermission | ((toParams: T) => RequiredPermission);

export function isOneOfPermission(value: unknown): value is OneOfPermission {
    return typeof value === 'object'
        && value !== null
        && hasOwnPropertyGuarded(value, 'oneOf')
        && Array.isArray(value.oneOf);
}

export function isAllOfPermission(value: unknown): value is AllOfPermission {
    return Array.isArray(value);
}

export function isWildcardPermission(value: unknown): value is WildcardPermission {
    return typeof value === 'string';
}
