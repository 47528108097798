/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {LogSeverity} from './LogSeverity';

export function parseSeverity(logSeverity: LogSeverity): LogSeverity {
    return Object.values(LogSeverity).includes(logSeverity) ? logSeverity : LogSeverity.DEBUG;
}

export function isSeverityEnabled(severity: LogSeverity, minLevel: LogSeverity): boolean {
    const keys = Object.keys(LogSeverity);

    return keys.indexOf(severity) >= keys.indexOf(minLevel);
}
