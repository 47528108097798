import {registerLocaleData} from '@angular/common';
import {APP_INITIALIZER, inject, LOCALE_ID, NgModule} from '@angular/core';
import type {SupportedLocale} from '@dv/shared/code';
import {DEFAULT_LOCALE, localeFrom, LogFactory} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {
    TranslateCompiler,
    TranslateLoader,
    TranslateModule,
    TranslateParser,
    TranslateService,
} from '@ngx-translate/core';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import {MESSAGE_FORMAT_CONFIG} from 'ngx-translate-messageformat-compiler';
import {switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {KitAdminTranslationCompiler} from './KitAdminTranslationCompiler';
import {createNgxTranslationLoader} from './KitAdminTranslationLoader';
import {KitAdminTranslationParser} from './KitAdminTranslationParser';
import {TranslatorNGXTranslateAdapterService} from './translator-ngxtranslate-adapter.service';
import {reactOnUserLocale, UserLanguageService} from './user-language.service';

const LOG = LogFactory.createLog('i18n');

@NgModule({
    declarations: [],
    imports: [
        TranslateModule.forRoot({
            defaultLanguage: DEFAULT_LOCALE,
            loader: {
                provide: TranslateLoader,
                useFactory: createNgxTranslationLoader,
            },
            compiler: {provide: TranslateCompiler, useClass: KitAdminTranslationCompiler},
            parser: {provide: TranslateParser, useClass: KitAdminTranslationParser},
        }),
    ],
    providers: [
        {provide: LOCALE_ID, useValue: DEFAULT_LOCALE},
        {provide: MESSAGE_FORMAT_CONFIG, useValue: {currency: 'CHF'}},
        {provide: Translator, useClass: TranslatorNGXTranslateAdapterService},
        {provide: APP_INITIALIZER, useFactory: initNgxTranslate, multi: true},
        {provide: APP_INITIALIZER, useFactory: initMoment, multi: true},
        {provide: APP_INITIALIZER, useFactory: initAngularLocales, multi: true},
    ],
})
export class I18nModule {
}

function initMoment(): () => void {
    const momentLocales: Record<SupportedLocale, string> = {
        'de-CH': 'de',
        'fr-CH': 'fr',
    };

    const initialLocale = localeFrom(UserLanguageService.getLocalStorageLanguage());
    moment.locale(momentLocales[initialLocale] || 'de');

    return reactOnUserLocale(locale$ => locale$.pipe(
        map(locale => momentLocales[locale]),
        map(momentLocale => moment.locale(momentLocale)),
        tap(momentLocale => LOG.trace('moment locale', momentLocale)),
    ));
}

function initNgxTranslate(): () => void {
    const translateService = inject(TranslateService);

    return reactOnUserLocale(locale$ => locale$.pipe(
        switchMap(locale => translateService.use(locale)),
        tap(() => LOG.trace('ngxTranslate language changed')),
    ));
}

function initAngularLocales(): () => void {
    return reactOnUserLocale(locale$ => locale$.pipe(
        switchMap(lazyAngularLocale),
    ));
}

async function lazyAngularLocale(locale: SupportedLocale): Promise<void> {
    switch (locale) {
        case 'de-CH': {
            // eslint-disable-next-line import/no-internal-modules
            const localeDe = await import('@angular/common/locales/de-CH');
            registerLocaleData(localeDe.default);
            break;
        }
        case 'fr-CH': {
            // eslint-disable-next-line import/no-internal-modules
            const localeFr = await import('@angular/common/locales/fr-CH');
            registerLocaleData(localeFr.default);
            break;
        }
    }
    LOG.trace('angular locale ', locale);
}
