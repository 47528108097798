/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestPaginated} from '@dv/shared/code';
import {createAdapter, joinAdapters} from '@state-adapt/core';
import type {ItemsPerPageOptions} from './items-per-page';
import {ITEMS_PER_PAGE_OPTIONS} from './items-per-page';

export type PaginationState = {
    itemsPerPage: ItemsPerPageOptions;
    currentPage: number;
    itemsCount?: number;
};

export const paginationAdapter = joinAdapters<PaginationState>()({
    itemsPerPage: createAdapter<ItemsPerPageOptions>()({}),
    currentPage: createAdapter<number>()({}),
    itemsCount: createAdapter<number | undefined>()({}),
})({
    restParams: (state): RestPaginated => {
        return {
            top: state.itemsPerPage,
            skip: state.itemsPerPage * (state.currentPage - 1),
        };
    },
})();

export function defaultPaginationState(): PaginationState {
    return {
        itemsPerPage: ITEMS_PER_PAGE_OPTIONS.TEN,
        currentPage: 1,
    };
}
