/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable} from './IPersistable';
import {isPresent} from './nullish';

export interface IPersisted extends IPersistable {
    id: string;
}

export type Persisted<T> = T & IPersisted;

/**
 * Checks if the given value is null or undefined and has a string "id" property.
 */
export function checkPersisted<T extends Partial<IPersistable>>(val?: T | null): Persisted<T> {
    if (!isPersisted(val)) {
        throw new Error('value requires an id');
    }

    return val;
}

export function isPersisted<T extends Partial<IPersistable>>(val?: T | null): val is Persisted<T> {
    return isPresent(val?.id);
}
