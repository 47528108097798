/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, Persisted} from '../types';
import {checkPersisted} from '../types';

export type AsyncResponseStatus = 'RUNNING' | 'FINISHED' | 'ERROR';

export class AsyncResponse implements IPersistable {

    public constructor(
        public id: string | null = null,
        public status: AsyncResponseStatus,
        public result: any,
        public errorMessage: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Persisted<AsyncResponse> {
        return checkPersisted(new AsyncResponse(data.id, data.status, data.result, data.errorMessage));
    }
}
