import {Directive, Input} from '@angular/core';
import type {AbstractControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {NG_VALIDATORS} from '@angular/forms';
import type {FunctionType, Nullish, SupportedDateTypes} from '@dv/shared/code';
import {isValidGueltigAbGueltigBis} from './is-valid-gueltig-ab-gueltig-bis';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[minDate][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MinDateDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class MinDateDirective implements Validator {

    private _gueltigAb: SupportedDateTypes | Nullish;
    private _onChange?: FunctionType;

    @Input({required: true, alias: 'minDate'})
    public set gueltigAb(value: SupportedDateTypes | Nullish) {
        this._gueltigAb = value;
        this._onChange?.();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return validateGueltigBis(this._gueltigAb)(control);
    }

    public registerOnValidatorChange?(fn: FunctionType): void {
        this._onChange = fn;
    }
}

function validateGueltigBis(gueltigAb: SupportedDateTypes | Nullish): ValidatorFn {
    return bisControl => {
        const gueltigBis = bisControl.value;

        return isValidGueltigAbGueltigBis(gueltigAb, gueltigBis) ?
            null :
            {gueltigBis: {gueltigAb, gueltigBis}};
    };
}
