/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CDK_DRAG_CONFIG} from '@angular/cdk/drag-drop';
import type {EnvironmentProviders} from '@angular/core';
import {makeEnvironmentProviders} from '@angular/core';

export function provideSortList(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: CDK_DRAG_CONFIG, useValue: {zIndex: 5001}},
    ]);
}
