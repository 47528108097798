/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MonoTypeOperatorFunction, SchedulerLike, Subscription} from 'rxjs';
import {asyncScheduler, Observable} from 'rxjs';

/**
 * Debouncing all but first:
 * 1. when the source observable emits the first time, this operator emits immediately (forwarding the source value).
 * 2. when the source observable emits again within a delay, this operator holds back the value until the delay passed.
 * 3. only after the source observable stopped emitting for the given delay, the last source value is emitted.
 */
export function debounceUntilIdle<T>(
    idleTime: number,
    scheduler: SchedulerLike = asyncScheduler,
): MonoTypeOperatorFunction<T> {
    return (source$: Observable<T>): Observable<T> => new Observable(subscriber => {

        let sourceChangedTimestamp = 0;
        let scheduledAction: Subscription | undefined;
        let sourceCompleted = false;

        const emit = (value: T): void => {
            subscriber.next(value);
            if (sourceCompleted) {
                subscriber.complete();
            }
        };

        const subscription = source$.subscribe({
            next(value) {
                const now = scheduler.now();
                if (now - sourceChangedTimestamp > idleTime) {
                    emit(value);
                } else {
                    if (scheduledAction) {
                        scheduledAction.unsubscribe();
                        scheduledAction = undefined;
                    }
                    scheduledAction = scheduler.schedule(() => emit(value), idleTime);
                }

                // noinspection ReuseOfLocalVariableJS
                sourceChangedTimestamp = now;
            },
            error(error) {
                subscriber.error(error);
            },
            complete() {
                // noinspection ReuseOfLocalVariableJS
                sourceCompleted = true;
                if (!scheduledAction) {
                    subscriber.complete();
                }
            },
        });

        return () => subscription.unsubscribe();
    });
}
