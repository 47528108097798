import {Directive, Input} from '@angular/core';
import type {SortListTemplateContext} from './sort-list-template-context';

@Directive({
    selector: 'ng-template[dvlibSortListTemplate]',
    standalone: true,
})
export class SortListTemplateDirective<T> {

    @Input('dvlibSortListTemplate')
    public items!: T[];

    public static ngTemplateContextGuard<TContext>(
        dir: SortListTemplateDirective<TContext>,
        ctx: unknown,
    ): ctx is SortListTemplateContext<TContext> {
        return true;
    }
}
