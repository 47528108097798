/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';
import {BEGIN_OF_TIME, END_OF_TIME} from '../../constants';
import type {IEquals, IValidable} from '../../types';
import {DvbUriUtil} from '../DvbUriUtil';
import type {MatrixParams} from '../rest';
import {DvbRestUtil} from '../rest';
import {DvbDateUtil} from './DvbDateUtil';
import type {ILimited} from './ILimited';
import {isLimited} from './ILimited';

export class Gueltigkeit implements ILimited, IEquals<Gueltigkeit>, IValidable {

    public constructor(
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
    ) {
    }

    public static copy(value: unknown): Gueltigkeit | null {
        return isLimited(value) ? new Gueltigkeit(moment(value.gueltigAb), moment(value.gueltigBis)) : null;
    }

    public static fromUriParam(param: string): Gueltigkeit {
        const obj = DvbUriUtil.applyUriToObject<any>(param, {});

        return Gueltigkeit.apiResponseTransformer(obj);
    }

    public static apiResponseTransformer(data: any): Gueltigkeit {
        return new Gueltigkeit(
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
        );
    }

    public setNullTimeLimits(): void {
        if (DvbDateUtil.isBeginOfTime(this.gueltigAb)) {
            this.gueltigAb = null;
        }

        if (DvbDateUtil.isEndOfTime(this.gueltigBis)) {
            this.gueltigBis = null;
        }
    }

    public toEndOfTime(): void {
        this.gueltigAb ??= BEGIN_OF_TIME;
        this.gueltigBis ??= END_OF_TIME;
    }

    public setCurrentMonthWhenUndefined(): void {
        if (!DvbDateUtil.isValidMoment(this.gueltigAb)) {
            this.gueltigAb = DvbDateUtil.startOfMonth();
        }

        if (!DvbDateUtil.isValidMoment(this.gueltigBis)) {
            this.gueltigBis = DvbDateUtil.endOfMonth();
        }
    }

    public toRestObject(): MatrixParams {
        const result: MatrixParams = {};

        if (DvbDateUtil.isValidMoment(this.gueltigAb)) {
            result.gueltigAb = DvbRestUtil.momentToLocalDate(this.gueltigAb);
        }

        if (DvbDateUtil.isValidMoment(this.gueltigBis)) {
            result.gueltigBis = DvbRestUtil.momentToLocalDate(this.gueltigBis);
        }

        return result;
    }

    public toUriParam(): string {
        return DvbUriUtil.plainObjectToUriParam(this.toRestObject());
    }

    public equals(other: Gueltigkeit | null): boolean {
        if (!other) {
            return false;
        }

        return DvbDateUtil.isMomentEquals(this.gueltigAb, other.gueltigAb)
            && DvbDateUtil.isMomentEquals(this.gueltigBis, other.gueltigBis);
    }

    public isValid(): boolean {
        if (!DvbDateUtil.isValidMoment(this.gueltigAb) || !DvbDateUtil.isValidMoment(this.gueltigBis)) {
            return false;
        }

        return this.gueltigAb.isSameOrBefore(this.gueltigBis);
    }

    public intersects(other: Gueltigkeit): boolean {
        return !this.gueltigAb?.isAfter(other.gueltigBis) &&
            !this.gueltigBis?.isBefore(other.gueltigAb);
    }

    public contains(other: Gueltigkeit): boolean | undefined {
        return this.gueltigAb?.isSameOrBefore(other.gueltigAb) &&
            this.gueltigBis?.isSameOrAfter(other.gueltigBis);
    }
}
