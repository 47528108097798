import {NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, TemplateRef} from '@angular/core';
import {DayOfWeek} from '@dv/shared/code';
import {DayOfWeekFormContext} from './day-of-week-form-context';
import {PrintDayOfWeekComponent} from './print-day-of-week.component';

@Component({
    selector: 'dvlib-day-of-week-form',
    standalone: true,
    imports: [
        PrintDayOfWeekComponent,
        NgTemplateOutlet,
    ],
    templateUrl: './day-of-week-form.component.html',
    styleUrls: ['./day-of-week-form.component.scss'],
    host: {
        '[class.wochenplan]': 'true',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayOfWeekFormComponent {
    public templateRef = input.required<TemplateRef<DayOfWeekFormContext>>();
    public readonly daysOfWeek: DayOfWeek[] = Object.values(DayOfWeek);
}
