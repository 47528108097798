/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RequiredPermission, UnparsedPermission} from './authorisation-types';
import {isAllOfPermission, isOneOfPermission} from './authorisation-types';

export function parsePermission(permission?: UnparsedPermission): RequiredPermission | undefined {
    // extraction happens often, thus do the most common checks first

    if (typeof permission === 'string') {
        const stringStart = permission.trim().substring(0, 1);
        if (stringStart === '[' || stringStart === '{') {
            try {
                return JSON.parse(permission);
            } catch (e) {
                throw new Error(`Failed to parse permission as JSON: ${permission}. Nested error: ${String(e)}`);
            }
        }

        return permission;
    }

    if (isAllOfPermission(permission) || isOneOfPermission(permission)) {
        return permission;
    }

    if (!permission) {
        return undefined;
    }

    throw new Error(`unsupported permission type: ${JSON.stringify(permission)}`);
}
