/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EnvironmentProviders, Type} from '@angular/core';
import {makeEnvironmentProviders} from '@angular/core';
import {AuthorizationProviderService} from '../authorization-provider.service';
import {NOOP_STRATEGY, REQUIRE_STRATEGY} from './strategy';

export function provideAuthorization(authzProviderService: Type<AuthorizationProviderService>): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: AuthorizationProviderService, useClass: authzProviderService},
        {provide: REQUIRE_STRATEGY, useValue: NOOP_STRATEGY},
    ]);
}
