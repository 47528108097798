/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translator} from '@dv/shared/translator';
import {DvbError, ErrorLevel, ErrorType} from '../DvbError';
import type {RechnungsRevisionError} from './RechnungsRevisionError';
import {RechnungsUebermittlungsEinheitError} from './RechnungsUebermittlungsEinheitError';

export class RechnungsLaufError {
    public constructor(public failedRechnungsUebermittlungsEinheiten: RechnungsUebermittlungsEinheitError[] = []) {
    }

    public static apiResponseTransformer(data: any): RechnungsLaufError {
        const answer = new RechnungsLaufError();

        if (Array.isArray(data.failedRechnungsUebermittlungsEinheiten)) {
            answer.failedRechnungsUebermittlungsEinheiten = data.failedRechnungsUebermittlungsEinheiten
                .map((f: any) => RechnungsUebermittlungsEinheitError.apiResponseTransformer(f));
        }

        return answer;
    }

    private static toDvbError(error: RechnungsRevisionError, translator: Translator): DvbError {
        const msg = translator.instant(error.errorType.msgKey, error);

        return new DvbError(ErrorType.INTERNAL, ErrorLevel.SEVERE, msg);
    }

    public mapErrorsByRechnung(): { [key: string]: RechnungsRevisionError[] } {
        const result: { [key: string]: RechnungsRevisionError[] } = {};

        this.failedRechnungsUebermittlungsEinheiten.forEach(uebermittlungsEinheitError => {
            uebermittlungsEinheitError.causes.forEach(rechnungsRevisionError => {
                if (!Array.isArray(result[rechnungsRevisionError.rechnungsId])) {
                    result[rechnungsRevisionError.rechnungsId] = [];
                }

                result[rechnungsRevisionError.rechnungsId].push(rechnungsRevisionError);
            });
        });

        return result;
    }

    public toDvbErrors(translator: Translator): DvbError[] {
        const result: DvbError[] = [];

        return result.concat.apply([], this.failedRechnungsUebermittlungsEinheiten
            .map(uebermittlungsEinheitError => uebermittlungsEinheitError.causes
                .map(rechnungsRevisionError => RechnungsLaufError.toDvbError(rechnungsRevisionError, translator))));
    }
}
