import {Directive, Input} from '@angular/core';
import type {AbstractControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {NG_VALIDATORS} from '@angular/forms';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {FunctionType, Nullish, SupportedDateTypes} from '@dv/shared/code';
import {DvbDateUtil, isNullish} from '@dv/shared/code';
import type moment from 'moment';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[validVon][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ValidVonDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class ValidVonDirective implements Validator {

    private _dateStart?: moment.Moment | Nullish;
    private _dateEnd?: moment.Moment | Nullish;
    private _timeEnd?: moment.Moment | Nullish;
    private _onChange?: FunctionType;

    @Input({required: true})
    public set dateStart(value: SupportedDateTypes | Nullish) {
        this._dateStart = DvbDateUtil.toLocalDate(value);
        this._onChange?.();
    }

    @Input({required: true})
    public set dateEnd(value: SupportedDateTypes | Nullish) {
        this._dateEnd = DvbDateUtil.toLocalDate(value);
        this._onChange?.();
    }

    @Input({required: true})
    public set timeEnd(value: Date | moment.Moment | BackendLocalTimeHHMM | Nullish) {
        this._timeEnd = DvbDateUtil.toHHMMTime(value);
        this._onChange?.();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return validVon(this._dateStart, this._dateEnd, this._timeEnd)(control);
    }

    public registerOnValidatorChange?(fn: FunctionType): void {
        this._onChange = fn;
    }
}

function validVon(
    dateStart: moment.Moment | Nullish,
    dateEnd: moment.Moment | Nullish,
    timeEnd: moment.Moment | Nullish,
): ValidatorFn {
    return vonControl => {
        const timeStart = DvbDateUtil.toHHMMTime(vonControl.value);

        if (isNullish(timeStart) || isNullish(timeEnd)) {
            return null;
        }

        if (DvbDateUtil.isMomentEquals(dateStart, dateEnd)) {
            return timeStart.isSameOrBefore(timeEnd) ? null : {von: {dateStart, dateEnd, timeStart, timeEnd}};
        }

        return null;
    };
}
