/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import type {Nullish} from '@dv/shared/code';
import {checkPresent, DvbUtil, isPresent} from '@dv/shared/code';
import type {BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take, tap} from 'rxjs';
import type {DialogModel} from './dialog-model';
import {dialogOpen$} from './private/internal-dialog-actions';

@Component({
    selector: 'dvlib-dialog',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class DialogComponent implements OnChanges, OnDestroy {

    @Input() public enableEscClose?: boolean = true;
    @Input() public open?: boolean = false;
    @Input() public component?: (new (...args: unknown[]) => DialogModel) | TemplateRef<unknown>;
    @Input() public options?: { class: string };
    @Output() public readonly dialogClose = new EventEmitter<void>();
    @ContentChild(TemplateRef, {static: false}) private template?: TemplateRef<unknown>;

    private dialogRef?: BsModalRef;

    public constructor(
        private readonly bsModalService: BsModalService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.open) {
            dialogOpen$.next(changes.open.currentValue);
            this.applyDialogState(changes.open?.currentValue);
        }
    }

    public ngOnDestroy(): void {
        // setTimeout because applyDialogState is sometimes on a timeout
        // when this runs and can reopen the dialog
        // after this component is already destroyed.
        setTimeout(() => this.closeDialog());
    }

    public applyDialogState = (shouldBeOpen: boolean | Nullish): void => {
        setTimeout(() => {
            // Change detection console error without this
            const isOpen = isPresent(this.dialogRef);
            if (!isOpen && shouldBeOpen) {
                this.openDialog();
            } else if (isOpen && !shouldBeOpen) {
                this.closeDialog();
            }
        });
    };

    public openDialog(): void {
        const config: ModalOptions = {
            class: `dv-modal-window ${this.options?.class ?? ''}`.trim(),
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: this.enableEscClose,
        };

        this.dialogRef = this.bsModalService.show(checkPresent(this.template ?? this.component), config);
        this.dialogRef.onHidden?.pipe(
            take(1),
            tap(event => {
                if (DvbUtil.isNotEmptyString(event)) {
                    // modal was closed by ESC or backdrop click. Make sure to notify change
                    this.dialogClose.emit();
                }
            }),
        ).subscribe();
    }

    public closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.hide();
        }
        this.dialogRef = undefined;
    }
}
