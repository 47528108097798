<dvlib-page [itemsOnPageCount]="itemsOnPageCount()" [isLoading]="isLoading()" [entityName]="entityName()">
    <ng-content select="[pageItems]"/>
</dvlib-page>

@if (showSummary()) {
    <ng-content select="[totalSummary]"/>
}

@if (showPagination()) {
    <div class="normal-abstand-top page-switcher">
        <pagination class="pagination-sm hidden-print"
                    [(ngModel)]="currentPage"
                    [totalItems]="totalItems() ?? 0"
                    [itemsPerPage]="itemsPerPage()"
                    [maxSize]="maxSize()"
                    [boundaryLinks]="true"
                    [previousText]="'&#xf104;'"
                    [nextText]="'&#xf105;'"
                    [firstText]="'&#xf048;'"
                    [lastText]="'&#xf051;'">
        </pagination>
        <div class="items-per-page">
            <select class="form-control select-sm"
                    [(ngModel)]="itemsPerPage"
                    [attr.aria-label]="'COMMON.OBJEKTE_PRO_SEITE' | translate"
                    [tooltip]="'COMMON.OBJEKTE_PRO_SEITE' | translate"
                    placement="top left">
                @for (option of itemsPerPageOptions(); track $index) {
                    <option [ngValue]="option">
                        {{ option }}
                    </option>
                }
            </select>
            <span>/</span>
            <span class="no-wrap"
                  [translate]="entityName()"
                  [translateParams]="{count: totalItems(), showcount: true}">
            </span>
        </div>
        <p class="no-wrap visible-print-inline-block"
           [translate]="'COMMON.AKTUELLE_SEITE'"
           [translateParams]="{page: currentPage()}">
        </p>
    </div>
}
