/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import {isPresent} from '../../types/nullish';

export interface ILimited {
    gueltigAb: moment.Moment | null;
    gueltigBis: moment.Moment | null;
}

export function isLimited(val: unknown): val is ILimited {
    return typeof val === 'object'
        && isPresent(val)
        && 'gueltigAb' in val
        && 'gueltigBis' in val;
}
