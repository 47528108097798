<div class="input-group">
    <ng-content select="[input]"/>

    @if (isBs3Version) {
        <span class="input-group-btn">
            <ng-container *ngTemplateOutlet="addon"/>
        </span>
    } @else {
        <ng-container *ngTemplateOutlet="addon"/>
    }

    <ng-template #addon>
        <ng-content select="[addon]"/>
    </ng-template>
</div>
