/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ApiResponseTransformer} from '../types';
import {TypeUtil} from './TypeUtil';

export class PageContainer<T> {
    public constructor(
        public items: T[] = [],
        public count: number = 0,
        public summe: number | null = null,
    ) {
    }

    public static apiResponseTransformer<T>(modelAPI: ApiResponseTransformer<T>, data: any): PageContainer<T> {
        const answer = new PageContainer<T>();

        answer.count = parseInt(data.count, 10);
        answer.summe = TypeUtil.isNumber(data.summe) && parseFloat(data.summe) || null;

        if (Array.isArray(data.items)) {
            answer.items = data.items.map((entry: any) => modelAPI.apiResponseTransformer(entry));
        }

        return answer;
    }
}
