<dvlib-spinner data-testid="centered-spinner" class="centered-spinner" [show]="isLoading && itemsOnPageCount === 0"/>

@if (!isLoading && itemsOnPageCount === 0) {
    <div>
        <p translate="COMMON.KEINE_ENTITY_VORHANDEN"
           [translateParams]="{entityName: entityName | translate : {} : 'messageformat'}">
        </p>
    </div>
}

<ng-content class="spinner-container" [class.spinning]="isLoading"/>

@if (isLoading && itemsOnPageCount > 0) {
    <div class="overlay-spinner">
        <dvlib-spinner data-testid="overlay-spinner"/>
    </div>
}
