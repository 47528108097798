/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxAdresse} from '@dv/shared/backend/model/jax-adresse';
import type {Translator} from '@dv/shared/translator';
import {Country} from '../locale/Country';
import type {IEquals} from '../types/IEquals';
import type {IPersistable} from '../types/IPersistable';
import type {IRestModel} from '../types/IRestModel';
import type {IValidable} from '../types/IValidable';
import {checkPresent} from '../types/nullish';
import {DvbUtil} from '../util/DvbUtil';

export class Adresse implements IRestModel<JaxAdresse>, IPersistable, IValidable, IEquals<Adresse | undefined> {

    public constructor(
        public id: string | null = null,
        public externalId: string | null = null, // aus geoadmin api
        public strasse: string | null = null,
        public hausnummer: string | null = null,
        public postfach: string | null = null,
        public plz: string | null = null,
        public ort: string | null = null,
        public gemeinde: string | null = null,
        public land: Country | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxAdresse): Adresse {
        const adresse = new Adresse(
            data.id,
            data.externalId,
            data.strasse,
            data.hausnummer,
            data.postfach,
            data.plz,
            data.ort,
            data.gemeinde,
        );

        adresse.land = new Country(data.land);

        return adresse;
    }

    public equals(other?: Adresse | null): boolean {
        if (!other) {
            return false;
        }

        return this.plz === other.plz
            && this.hausnummer === other.hausnummer
            && this.strasse === other.strasse
            && this.land === other.land;
    }

    public getDisplayGemeinde(translator: Translator): string {
        if (typeof this.gemeinde === 'string' && this.gemeinde.length > 0) {
            // FIXME KIT-1714 double sanitation when getDisplayGemeinde is used with ng-bind
            return `(${translator.instant('COMMON.GEMEINDE', {gemeinde: this.gemeinde})})`;
        }

        return `(${translator.instant('COMMON.GEMEINDE_NICHT_ERMITTELBAR')})`;
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.strasse) &&
            DvbUtil.isNotEmptyString(this.plz) &&
            DvbUtil.isNotEmptyString(this.ort) &&
            this.land !== null;
    }

    public toRestObject(): JaxAdresse {
        return {
            id: this.id ?? undefined,
            externalId: this.externalId ?? undefined,
            strasse: checkPresent(this.strasse),
            hausnummer: this.hausnummer ?? undefined,
            postfach: this.postfach ?? undefined,
            plz: checkPresent(this.plz),
            ort: checkPresent(this.ort),
            gemeinde: this.gemeinde ?? undefined,
            land: checkPresent(this.land?.toRestObject()),
        };
    }

    public clone(): Adresse {
        return new Adresse(
            null,
            this.externalId,
            this.strasse,
            this.hausnummer,
            this.postfach,
            this.plz,
            this.ort,
            this.gemeinde,
            this.land,
        );
    }
}
