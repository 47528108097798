/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EnvironmentProviders} from '@angular/core';
import {APP_INITIALIZER, importProvidersFrom, inject, makeEnvironmentProviders} from '@angular/core';
import type {SupportedLocale} from '@dv/shared/code';
import {LogFactory} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {AccordionConfig, AccordionModule} from 'ngx-bootstrap/accordion';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {BsDatepickerModule, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {BsDropdownConfig, BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TooltipConfig, TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {initDatepickerConfig} from './forms';
import {reactOnUserLocale} from './i18n';

const LOG = LogFactory.createLog('ngx-bootstrap');

export function provideNgxBootstrap(): EnvironmentProviders {

    return makeEnvironmentProviders([
        importProvidersFrom(
            ModalModule.forRoot(),
            TypeaheadModule.forRoot(),
            BsDropdownModule.forRoot(),
            ButtonsModule.forRoot(),
            BsDatepickerModule.forRoot(),
            AccordionModule.forRoot(),
            TooltipModule.forRoot(),
            PaginationModule.forRoot(),
        ),
        {provide: AccordionConfig, useFactory: defaultAccordionConfig},
        {provide: BsDropdownConfig, useFactory: defaultDropdownConfig},
        {provide: TooltipConfig, useFactory: defaultTooltipConfig},
        {provide: APP_INITIALIZER, useFactory: initDatepicker, multi: true},
    ]);
}

type NgxBootstrapLocales = 'de' | 'fr';

function initDatepicker(): () => void {
    const translator = inject(Translator);
    const bsLocaleService = inject(BsLocaleService);
    const ngxBootstrapLocales: Record<SupportedLocale, NgxBootstrapLocales> = {
        'de-CH': 'de',
        'fr-CH': 'fr',
    };

    return reactOnUserLocale(locale$ => locale$.pipe(
        map(locale => ngxBootstrapLocales[locale]),
        switchMap(locale => lazyBootstrapLocale(locale)),
        tap(locale => {
            initDatepickerConfig(translator.instant('COMMON.TODAY'));
            bsLocaleService.use(locale);
            LOG.trace('using ', locale);
        })));
}

async function lazyBootstrapLocale(locale: NgxBootstrapLocales): Promise<NgxBootstrapLocales> {
    switch (locale) {
        case 'de': {
            const {deLocale} = await import('ngx-bootstrap/locale');
            defineLocale('de', deLocale);
            break;
        }
        case 'fr': {
            const {frLocale} = await import('ngx-bootstrap/locale');
            defineLocale('fr', frLocale);
            break;
        }
    }

    return locale;
}

function defaultAccordionConfig(): AccordionConfig {
    return Object.assign(new AccordionConfig(), {closeOthers: true, isAnmiated: true});
}

function defaultDropdownConfig(): BsDropdownConfig {
    return Object.assign(new BsDropdownConfig(), {isAnimated: true, autoClose: true});
}

function defaultTooltipConfig(): TooltipConfig {
    return Object.assign(new TooltipConfig(), {placement: 'top start', container: 'body'});
}
