/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Action} from '@state-adapt/core';
import {getAction} from '@state-adapt/core';
import {splitSources, toSource} from '@state-adapt/rxjs';
import type {Observable} from 'rxjs';
import {catchError, map, of} from 'rxjs';

export type RequestError = {
    id: EntityId;
    error: unknown;
};

export type Update<Payload> = {
    id: EntityId;
    payload: Payload;
};

export function toIdRequestSource<Payload, TypePrefix extends string>(
    typePrefix: TypePrefix,
    id: EntityId,
) {
    return (
        source$: Observable<Payload>,
    ): Observable<
        Action<Update<Payload>, `${TypePrefix}.success$`> | Action<RequestError, `${TypePrefix}.error$`>
    > => source$.pipe(
        map(payload => ({id, payload})),
        toSource(`${typePrefix}.success$`),
        catchIdErrorSource(typePrefix, id),
    );
}

export function catchIdErrorSource<Payload, TypePrefix extends string>(
    typePrefix: TypePrefix,
    id: EntityId,
) {
    return (source$: Observable<Payload>): Observable<Payload | Action<RequestError, `${TypePrefix}.error$`>> =>
        source$.pipe(catchError(error => of(getAction(`${typePrefix}.error$`, {error, id}))));
}

export function splitIdRequestSources<TypePrefix extends string, A extends Action<any, `${TypePrefix}.success$` | `${TypePrefix}.error$`>>(
    typePrefix: TypePrefix,
    obs$: Observable<A>,
): {
    success$: Observable<A extends Action<infer Payload, `${TypePrefix}.success$`> ? Action<Payload, `${TypePrefix}.success$`> : never>;
    error$: Observable<Action<RequestError, `${TypePrefix}.error$`>>;
} {
    return splitSources(obs$, {
        success$: `${typePrefix}.success$`,
        error$: `${typePrefix}.error$`,
    }) as any;
}
