/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {TranslateCompiler} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';

/**
 * Deals with the compilation file consisting of a mix of messageformat and "normal" translation values.
 */
// Provided manually in Core module
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class KitAdminTranslationCompiler implements TranslateCompiler {

    private messageFormatCompiler: TranslateMessageFormatCompiler = new TranslateMessageFormatCompiler();

    // eslint-disable-next-line @typescript-eslint/ban-types
    public compile(value: string, lang: string): string | Function {
        return this.messageFormatCompiler.compile(value, lang);
    }

    public compileTranslations(translations: any, lang: string, translationKey?: string): any {
        if (typeof translations === 'string') {
            if (translationKey?.endsWith('MF')) {
                // messageformat value --> compile
                return this.compile(translations, lang);
            }

            // --> default, no compilation needed
            return translations;
        }

        // nested translation object --> compile all entries
        if (typeof translations === 'object') {
            const translatedObject: Record<string, unknown> = {};
            Object.entries(translations).forEach(([key, value]) => {
                translatedObject[key] = this.compileTranslations(value, lang, key);
            });

            return translatedObject;
        }

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Cannot translate translations: ${translations}`);
    }
}
