{
  "COMMON": {
    "KIND": {
      "SINGULAR": "Kind",
      "PLURAL": "Kinder",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kind} other{Kinder}}",
      "TITLE": "Kind {{name}}",
      "AUSWAEHLEN": "Kind auswählen",
      "ANSEHEN": "Kind ansehen"
    },
    "KINDERORT": {
      "SINGULAR": "Kinderort",
      "PLURAL": "Kinderorte",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kinderort} other{Kinderorte}}",
      "ERSTELLEN": "Kinderort eröffnen",
      "AUSWAEHLEN": "Kinderort auswählen"
    },
    "STANDORT": {
      "SINGULAR": "Standort",
      "PLURAL": "Standorte",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Standort} other{Standorte}}",
      "AUSWAEHLEN": "Standort auswählen"
    },
    "KINDERORT_FRAKTION": {
      "SINGULAR": "Fraktion",
      "PLURAL": "Fraktionen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Fraktion} other{Fraktionen}}",
      "ERSTELLEN": "Kinderortfraktion eröffnen",
      "AUSWAEHLEN": "Kinderortfraktion auswählen"
    },
    "KITA": {
      "SINGULAR": "Kita",
      "PLURAL": "Kitas",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kita} other{Kitas}}",
      "ERSTELLEN": "Kita eröffnen",
      "AUSWAEHLEN": "Kita auswählen"
    },
    "GRUPPE": {
      "SINGULAR": "Gruppe",
      "PLURAL": "Gruppen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Gruppe} other{Gruppen}}",
      "TITLE": "Gruppe {{name}}",
      "ERSTELLEN": "Gruppe erstellen",
      "AUSWAEHLEN": "Gruppe auswählen"
    },
    "TAGES_ELTERN": {
      "SINGULAR": "Tageseltern",
      "PLURAL": "Tageseltern",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Tageseltern} other{Tageseltern}}",
      "ERSTELLEN": "Tageseltern eröffnen",
      "AUSWAEHLEN": "Tageseltern auswählen"
    },
    "BETREUUNGS_PERSON": {
      "SINGULAR": "Betreuungsperson",
      "PLURAL": "Betreuungspersonen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Betreuungsperson} other{Betreuungspersonen}}",
      "TITLE": "Betreuungsperson {{name}}",
      "ERSTELLEN": "Betreuungsperson erstellen",
      "AUSWAEHLEN": "Betreuungsperson auswählen",
      "ASSIGN": "Betreuungsperson zuweisen",
      "KEINE": "Keine Betreuungspersonen"
    },
    "GRUPPE_ODER_BETREUUNGS_PERSON": {
      "SINGULAR": "Gruppe oder Betreuungsperson",
      "PLURAL": "Gruppen oder Betreuungspersonen",
      "ERSTELLEN": "Gruppen oder Betreuungsperson erstellen",
      "AUSWAEHLEN": "Gruppe / Betreuungsperson auswählen"
    },
    "KONTAKTPERSON": {
      "SINGULAR": "Kontaktperson",
      "PLURAL": "Kontaktpersonen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kontaktperson} other{Kontaktpersonen}}",
      "TITLE": "Kontaktperson {{name}}",
      "AUSWAEHLEN": "Kontaktperson auswählen"
    },
    "FIRMA": {
      "SINGULAR": "Firma",
      "PLURAL": "Firmen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Firma} other{Firmen}}",
      "TITLE": "Firma {{name}}",
      "ERSTELLEN": "Firma erstellen"
    },
    "PLATZART": {
      "SINGULAR": "Platzart",
      "PLURAL": "Platzarten",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Platzart} other{Platzarten}}",
      "TITLE": "Platzart {{name}}",
      "ERSTELLEN": "Platzart erstellen",
      "KONTINGENT_DEFINIEREN": "Kontingent definieren",
      "SYMBOL_DEFINIEREN": "Symbol definieren",
      "SYMBOL": "Symbol",
      "HINZUFUEGEN": "Platz hinzufügen",
      "AUSWAEHLEN": "Platz auswählen",
      "PLATZART_STAMMDATEN": "Platzart stammdaten",
      "ALTERNATIVE_KIBON_ABZUG_DEFINIEREN": "Abzug für kiBon Schnittstelle überschreiben",
      "ALTERNATIVE_KIBON_ABZUG": "Alternativer Abzugswert für kiBon Schnittstelle",

      "EXTERNAL_ANMELDUNG": "Externe Anmeldung",
      "SHOW_ON_EXTERNE_ANMELDUNG": "Platzart auf Externer Anmeldung anzeigen"
    },
    "SCHLIESSTAGE": {
      "SINGULAR": "Schliesstag",
      "PLURAL": "Schliesstage",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Schliesstag} other{Schliesstage}}",
      "ERSTELLEN": "Schliesstage erstellen",
      "TITLE_SCHLIESSTAG_DATE_RANGES": "Geschlossene Zeiträume",
      "SCHLIESSTAG_DATE_RANGE_HINZUFUEGEN": "Zeitraum hinzufügen",
      "SCHLIESSTAG_LOESCHEN": "Schliesstage löschen",
      "KEIN_ZEITRAUM_DEFINIERT": "Kein Zeitraum Definiert",
      "BIS": "Bis (optional)"
    },
    "FIRMENPLATZ": {
      "SINGULAR": "Firmenplatz",
      "PLURAL": "Firmenplätze",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Firmenplatz} other{Firmenplätze}}",
      "TITLE": "Firmenplatz {{name}}",
      "ERSTELLEN": "Firmenplatz erstellen",
      "HINZUFUEGEN": "Firmenplatz hinzufügen",
      "AUSWAEHLEN": "Firmenplatz auswählen",
      "BSV_RELEVANT": "In BSV Statistik berücksichtigen"
    },
    "FIRMENKONTINGENT": {
      "SINGULAR": "Kontingent",
      "PLURAL": "Kontingente",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kontingent} other{Kontingente}}",
      "TITLE": "Kontingent {{name}}",
      "ERSTELLEN": "Kontingent erstellen"
    },
    "TARIF": {
      "SINGULAR": "Tarif",
      "PLURAL": "Tarife",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Tarif} other{Tarife}}",
      "TITLE": "Tarif {{name}}",
      "ERSTELLEN": "Tarif erstellen",
      "AUSWAEHLEN": "Tarif auswählen"
    },
    "ARCHIVED_TARIF": {
      "SINGULAR": "Archivierte Tarif",
      "PLURAL": "Archivierte Tarife",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Archivierte Tarif} other{Archivierte Tarife}}",
      "TITLE": "Tarif {{name}}"
    },
    "MANDANT": {
      "SINGULAR": "Mandant",
      "PLURAL": "Mandanten",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Mandant} other{Mandanten}}",
      "TITLE": "Mandant {{name}}",
      "ERSTELLEN": "Mandant erstellen",
      "AUSWAEHLEN": "Mandant auswählen"
    },
    "BETREUUNGSFAKTOR_REGEL": {
      "SINGULAR": "Betreuungsfaktorregel",
      "PLURAL": "Betreuungsfaktorregeln",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Betreuungsfaktorregel} other{Betreuungsfaktorregeln}}",
      "TITLE": "Betreuungsfaktorregel {{name}}",
      "ERSTELLEN": "Betreuungsfaktorregel erstellen"
    },
    "BENUTZER": {
      "SINGULAR": "Benutzer",
      "PLURAL": "Benutzer",
      "MF": "{showcount, select, true{{count} } other{}}Benutzer",
      "TITLE": "Benutzer {{name}}",
      "ERSTELLEN": "Benutzer erstellen",
      "GROUP_ADMIN": "groupAdmin",
      "PASSKEY_ERSTELLEN": "Passkey erstellen",
      "PASSKEY_SUCCESS": "Es wurde eine E-Mail für die Generierung eines Passkeys versendet"
    },
    "ANGESTELLTE": {
      "SINGULAR": "Angestellte",
      "PLURAL": "Angestellte"
    },
    "RECHNUNG": {
      "SINGULAR": "Rechnung",
      "PLURAL": "Rechnungen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Rechnung} other{Rechnungen}}",
      "TITLE": "Rechnung {{name}}",
      "ERSTELLEN": "Rechnung erstellen"
    },
    "DOKUMENT_LAYOUT": {
      "SINGULAR": "Dokumentlayout",
      "PLURAL": "Dokumentlayouts",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Dokumentlayout} other{Dokumentlayouts}}",
      "TITLE": "Dokumentlayout {{name}}",
      "ERSTELLEN": "Dokumentlayout erstellen",
      "STEUERBESCHEINIGUNG_ALTERNATIV": "Alternativer Titel für Steuerbescheinigung",
      "STEUERBESCHEINIGUNG_BEMERKUNG": "Bemerkung in Steuerbescheinigung"
    },
    "CUSTOM_FIELD": {
      "SINGULAR": "Zusatz",
      "PLURAL": "Zusätze",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Zusatz} other{Zusätze}}",
      "MONATSBELEGUNG_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Betreuungszusatz} other{Betreuungszusätze}}",
      "TITLE": "Zusatz {{name}}",
      "ERSTELLEN": "Zusatz hinzufügen"
    },
    "WOCHENPLAN": {
      "SINGULAR": "Wochenplan",
      "PLURAL": "Wochenpläne",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Wochenplan} other{Wochenpläne}}",
      "TITLE": "Wochenplan {{name}}",
      "AUSWAEHLEN": "Wochenplan auswählen"
    },
    "BELEGUNG": {
      "SINGULAR": "Belegung",
      "PLURAL": "Belegungen",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Belegung} other{Belegungen}}",
      "TITLE": "Belegung {{name}}",
      "ERSTELLEN": "Belegung erstellen"
    },
    "BELEGUNGS_EINHEIT": {
      "SINGULAR": "Belegungseinheit",
      "PLURAL": "Belegungseinheiten",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Belegungseinheit} other{Belegungseinheiten}}",
      "WOCHENTAG": "Wochentag"
    },
    "EXTERNAL_ANMELDUNG": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Externe Anmeldung} other{Externe Anmeldungen}}"
    },
    "ANMELDUNG_UEBERNEHMEN": {
      "BEREITS_ZUGEWIESEN": "Diese Anmeldung wurde bereits einem Kind zugewiesen:",
      "NO_KIND_MATCH": "kein erfasstes Kind gefunden",
      "NO_GESUCHSTELLER_MATCH_MF": "{geschlecht, select, WEIBLICH{Gesuchstellerin} other{Gesuchsteller}} nicht gefunden",
      "NEUES_KIND_ERFASSEN": "+ neues Kind erfassen",
      "FAMILIE": "Familie {{name}}",
      "RELATED_KINDER": "Kinder dieser Familie",
      "KONTAKT_IN_HOUSEHOLD_MF": "{count, plural, one{Kontaktperson im selben Haushalt} other{Kontaktpersonen im selben Haushalt}}",
      "KONTAKT_IN_OTHER_HOUSEHOLD_MF": "{count, plural, one{Kontaktperson in anderem Haushalt} other{Kontaktpersonen in anderem Haushalt}}",
      "ASSIGN_TO_KIND": "Anmeldung {{kind}} zuweisen",
      "ADD_KIND_TO_HOUSEHOLD": "als neues Kind der Familie hinzufügen",
      "SEARCH": "Kind suchen",
      "BETREUUNGS_WUNSCH_ERFASSEN": "Betreuungswunsch erfassen"
    },
    "KITADMIN": "kitAdmin",
    "NAV_HOME": "Startseite",
    "NAV_ERSTELLEN": "Erstellen",
    "NAV_UEBERSICHT": "Übersicht",
    "NAV_PROFIL": "Profil",
    "NAV_PERSONALPLANUNG": "Personalplanung",
    "NAV_WORK_TIME_CONTROLLING": "Arbeitszeitkontrolle",
    "NAV_DIENSTPLAN": "Dienstplan",
    "NAV_FAKTURA": "Faktura",
    "NAV_ORGANISATION": "Organisation",
    "NAV_VOREINSTELLUNGEN": "Voreinstellungen",
    "NAV_PERSONAL_SETTINGS": "Personal",
    "NAV_REPORTS": "Reports",
    "NAV_COMMUNICATION": "Kommunikation",
    "NAV_BETREUUNGSFAKTOR_REGELN": "Betreuungsfaktoren",
    "NAV_SCHLIESSTAGE": "Schliesstage",
    "NAV_OFFENE_POSTEN": "Offene Posten",
    "NAV_CORRESPONDENCE": "Korrespondenz",
    "NAV_SUPPORT": "Support",
    "NAV_SUPPORT_PHONE": "031 378 24 36",
    "NAV_SUPPORT_EMAIL": "support@kitadmin.ch",
    "NAV_SUPPORT_OPENING_HOURS": "Mo-Fr:\n08:00 - 12:00\n13:00 - 17:00",
    "NAV_TEAM_VIEWER": "Remote Support (TeamViewer)",
    "FILTER_STATUS": "Status",
    "FILTER_GESCHWISTER": "Geschwister",
    "FILTER_ANMELDUNG": "Anmeldung",
    "FILTER_PENSUM": "Pensum",
    "FILTER_PRIORITAET": "Priorität",
    "RESET_FILTER": "Zurücksetzen",
    "ADD_FILTER": "Filter hinzufügen",
    "CLOSE_FILTER": "Filter schliessen",
    "FILTER_APPLY": "Übernehmen",
    "KIND_GEBOREN": "Geboren {{gebdatum}}",
    "PRIORITAET": "Priorität {{prioritaet}}",
    "ARCHIVIERTE_GRUPPEN_ANZEIGEN": "Archivierte Gruppen anzeigen",
    "ARCHIVIERTE_GRUPPEN_AUSBLENDEN": "Archivierte Gruppen ausblenden",
    "MENU": "Menü",
    "AUSWAEHLEN": "Auswählen",
    "EINBLENDEN": "Einblenden",
    "AUSBLENDEN": "Ausblenden",
    "BEIDE": "beide",
    "ALL": "alle",
    "MENU_KIND_DIREKTZUWEISUNG": "Kind direkt zuweisen",
    "ZUWEISUNG_IST_ZUGEWIESEN": "Hat bereits einen zugewiesenen Platz",
    "ZUWEISUNG_IST_PROVISORISCH": "Provisorisch zugewiesen",
    "ZUWEISUNG_IST_ANGEBOT": "Angebot offen",
    "ZUWEISUNG_IST_DEFINITIV": "Definitiv zugewiesen",
    "ZUWEISUNG_IST_WARTELISTE": "Hat einen offenen Wunsch",
    "ZUWEISUNG_HAT_GESCHWISTER": "Hat Geschwister im selben Kinderort",
    "ZUWEISUNG_IST_SUBVENTIONIERT": "Subventioniert",
    "ZUWEISUNG_IST_PRIVAT": "Privat",
    "KEINE_ZUWEISUNG": "Keine Zuweisung",
    "PRIVAT": "Privat",
    "SUBVENTIONIERT": "Subventioniert",
    "BETREUUNGSGUTSCHEIN": "Betreuungsgutschein",
    "BETREUUNGS_UNABHAENGIG": "Betreuungsunabhängig",
    "PROVISORISCHER_BG": "Provisorischer Betreuungsgutschein",
    "ZUWEISUNG_PRIVAT_ICON": "P",
    "ZUWEISUNG_SUBVENTIONIERT_ICON": "S",
    "ZUWEISUNG_FIRMENPLATZ_ICON": "F",
    "KINDERGARTEN_TITLE": "Kindergarten {{kgType}} {{kgLocation}}",
    "SCHULE_TITLE": "Schule {{kgLocation}}",
    "KIND_ZUWEISEN": "Kind zuweisen",
    "ZUWEISUNG_ANPASSEN": "Zuweisung ändern",
    "PROFIL": "Profil",
    "AKTUELLE_PLAETZE_MIT_DATUMPREP": "Aktuelle Plätze {{preposition}}",
    "PLAETZE_MIT_DATUMPREP": "Plätze {{preposition}}",
    "KAPAZITAET_MAX_PLAETZE": "Max Plätze",
    "KAPAZITAET_MAX_PLAETZE_WITH_VALUE": "{{plaetze}} max. Plätze",
    "KAPAZITAET_PLAETZE": "Plätze",
    "KAPAZITAET_PLAETZE_WITH_VALUE": "{{plaetze}} Plätze",
    "KAPAZITAET_FREIE_PLAETZE": "Freie Plätze",
    "KAPAZITAET_BELEGTE_PLAETZE": "Belegte Plätze",
    "KAPAZITAET_KEINE_PLAETZE": "keine",
    "FAVORITEN": "Favoriten",
    "TITEL": "Titel",
    "NAME": "Name",
    "VORNAME": "Vorname",
    "GEBURTSTAG": "Geburtstag",
    "GEBURTSTAG_MIT_DATUM": "Geburtstag: {{date}}",
    "NATIONALITAET": "Nationalität",
    "ANMELDEDATUM_MIT_DATUM": "Anmeldedatum {{datum}}",
    "MAENNLICH": "Männlich",
    "MAENNLICH_KIND": "Junge",
    "WEIBLICH": "Weiblich",
    "WEIBLICH_KIND": "Mädchen",
    "GESCHLECHT": "Geschlecht",
    "UNBEKANNT": "Nicht bekannt",
    "DIVERS": "Divers",
    "DIVERS_KIND": "Divers",
    "UNBEKANNT_KIND": "Nicht bekannt",
    "KONTAKTPERSONEN": "Kontaktpersonen",
    "KONTAKTPERSON_AUSWAEHLEN": "Kontaktperson auswählen",
    "RESETTABLE": "Zurücksetzbar",
    "HINZUGEFUEGT": "hinzugefügt",
    "BEREITS_HINZUGEFUEGT": "bereits hinzugefügt",
    "OPTIONEN": "Optionen",
    "ALLE_AUSWAEHLEN": "Alle auswählen",
    "KEINE_AUSWAEHLEN": "Keine auswählen",
    "KEINEN_AUSWAEHLEN": "Keinen auswählen",
    "OPEN_DATEPICKER": "Datum auswählen",

    "STUNDEN_CHART": {
      "DURCHSCHNITT_YEAR": "Betreuungsstunden {{jahr}}",
      "SOLL_STUNDEN": "Monatliche vertragliche Sollstunden",
      "EFFEKTIV_STUNDEN": "Monatlich effektiv betreute Stunden",
      "AVG_SOLL_STUNDEN": "{{count}}h vertragliche Sollstunden",
      "AVG_EFFEKTIV_STUNDEN": "{{count}}h effektiv betreute Stunden",
      "FORECAST_STUNDEN": "{{count}}h forecast Stunden"
    },

    "BETREUUNGSVEREINBARUNG": {
      "DOWNLOAD": "Betreuungsvereinbarung herunterladen",
      "CONFIG": "Betreuungsvereinbarungs Konfiguration",
      "CONFIGS": "Betreuungsvereinbarungs Konfigurationen",
      "NO_CONFIGS": "Keine Betreuungsvereinbarungs Konfigurationen",
      "EDIT_CONFIG": "Betreuungsvereinbarungs Konfiguration bearbeiten",
      "CREATE_CONFIG": "Betreuungsvereinbarungs Konfiguration erstellen",
      "ALTERNATIVE_NAME": "Alternativer Kinderort Name",

      "TYPE": {
        "NAME": "Betreuungsvereinbarungs Konfigurations Typ",
        "AUSWAHL": "Betreuungsvereinbarungs Konfigurations Typ auswählen",
        "CREATE": "Neuen Konfigurations Typ erstellen",
        "NO_TYPES": "Keine Konfigurations Typen vorhanden"
      }
    },

    "ADRESSE": "Adresse",
    "GLEICHE_ADRESSE_WIE_HAUPTKONTAKT": "gleiche Adresse wie die Hauptkontaktperson",
    "ADRESSE_MANDANT": "Mandant Adresse",
    "ADRESSE_KINDERORT": "Kinderort Adresse",
    "ADRESSE_CUSTOM": "Spezifische Adresse",
    "ADRESSE_NONE": "Keine Adresse",
    "ADRESSZUSATZ": "Zusatz",
    "ADRESS_CONFIRM": "Adress bestätigen",
    "ADDRESS_MODE": {
      "HAUPTKONTAKT": "Gleiche Adresse wie Hauptkontakt",
      "OWN": "Eigene Adresse",
      "NONE": " Keine Adresse"
    },

    "STRASSE": "Strasse",
    "NUMMER": "Nummer",
    "POSTFACH": "Adresszusatz",
    "PLZ": "PLZ",
    "ORT": "Ort",
    "GEMEINDE": "Gemeinde {{gemeinde}}",
    "GEMEINDE_NICHT_ERMITTELBAR": "Keine eindeutige politische Gemeinde ermittelbar",
    "LAND": "Land",
    "TELEFON": "Telefon",
    "MOBILE_NR": "Mobile",
    "GESCHAEFT_NR": "Telefon Geschäft",
    "ORGANISATION": "Organisation",
    "TELEFON_MIT_NUM": "Telefon {{num}}",
    "MOBILE_MIT_NUM": "Mobile {{num}}",
    "GESCHAEFT_MIT_NUM": "Geschäft {{num}}",
    "E-MAIL": "E-Mail",
    "WEBSITE": "Webseite",
    "REPORT_GENERIEREN": "Report generieren",
    "ALLE_KINDERORTE": "Alle Kinderorte",
    "ALLE_GRUPPEN": "Alle Gruppen",
    "KONTAKT": "Kontakt",
    "LOESCHEN": "Löschen",
    "RESTORE": "Wiederherstellen",
    "BEARBEITEN": "Bearbeiten",
    "ZUWEISEN": "Zuweisen",
    "ZUWEISUNG_AUFHEBEN": "Zuweisung aufheben",
    "IGNORIEREN": "Ignorieren",
    "UNIGNORIEREN": "Ignorieren aufheben",
    "DECLINE": "Ablehnen",
    "PENSUM": "Pensum {{pensum}}",
    "PENSUM_IN_PROZENT": "Pensum in %",
    "PENSUM_IN_SYMBOL_MF": "Pensum in{type, select, PERCENTAGE{ %} HOURS{ h} other{}}",
    "PENSUM_FAKTOR": "Pensum Faktor",
    "PENSUM_FAKTOR_ANPASSEN": "Pensum Faktor anpassen",
    "PROZENTPUNKTE": "Prozentpunkte",
    "WEEKLY_PLATZ_FREQUENCY": "Häufigkeit pro Woche",
    "SUBVENTIONIERTER_PLATZ": "Subventionierter Platz",
    "PRIVATER_PLATZ": "Privater Platz",
    "KINDERGARTEN": "Kindergarten / Schule",
    "BETREUUNGSWUNSCH": "Betreuungswunsch",
    "BETREUUNGSWUNSCH_AB": "Betreuungswunsch ab",
    "KEIN_WUNSCH_OFFEN": "Kein Wunsch offen",
    "BETREUUNG_MIT_DATUMPREP": "Betreuung {{preposition}}",
    "AKTUELLE_BELEGUNG": "Aktuelle Belegung",
    "AKTUELLE_BETREUUNG_MIT_DATUMPREP": "Aktuelle Betreuung {{preposition}}",
    "PROVISORISCHE_BETREUUNG_MIT_DATUMPREP": "Provisorische Betreuung {{preposition}}",
    "ANGEBOT_AB": "Angebot ab",
    "BEMERKUNG": "Bemerkung",
    "BESTAETIGEN": "ja",
    "ABBRECHEN": "abbrechen",
    "FORTFAHREN": "fortfahren",
    "ZURUECK": "zurück",
    "ALLE": "Alle",
    "OBJEKTE_PRO_SEITE": "Angezeigte Objekte pro Seite",
    "AKTUELLE_SEITE": "Seite {{page}}",
    "SEIT": "seit",
    "AB": "ab",
    "AND": "und",
    "OR": "oder",
    "TODAY": "heute",
    "DAY": "Tag",
    "WEEK": "Woche",
    "AKTUELLE_KAPAZITAET_MIT_DATUMPREP": "Aktuelle Kapazität {{preposition}}",
    "KAPAZITAET_MIT_DATUMPREP": "Kapazität {{preposition}}",
    "NEUE_KAPAZITAET": "Neue Kapazität definieren",
    "NEUE_KAPAZITAET_SPEICHERN": "Neue Kapazität speichern",
    "GUELTIG_AB": "Gültig ab",
    "GUELTIG_BIS": "Gültig bis",
    "DATE_RANGE": "Zeitraum",
    "SPEICHERN": "Speichern",
    "KONTINGENT": "Kontingent {{kontingente}}",
    "ANZAHL_PLAETZE": "Anzahl Plätze",
    "BEENDET_TITLE": "Beendet am: {{endedatum}}",
    "BEENDEN_REVERT": "Beenden rückgängig",
    "LAST_SENT_AT": "Versendet am",
    "ENDDATUM": "Enddatum",
    "BEENDEN": "Beenden",
    "BETREUUNGSFAKTOR_WERT": "Betreuungsfaktor ({{wert}})",
    "SPEZIFISCHER_BETREUUNGSFAKTOR_WERT": "Spezifischer Betreuungsfaktor ({{wert}})",
    "SHORT_KALENDERWOCHE_VON_BIS": "KW {{dateAb}} - {{dateBis}}",
    "KORRESPONDENZSPRACHE": "Korrespondenzsprache",
    "AKTUELLE_WOHNADRESSE_MIT_DATUMPREP": "Aktuelle Wohnadresse {{preposition}}",
    "WOHNADRESSE_MIT_DATUMPREP": "Wohnadresse {{preposition}}",
    "UMZUG_ERFASSEN": "Umzug erfassen",
    "TITLE_UMZUG": "Umzug erfassen",
    "BITTE_UMZUGSDATUM_ANGEBEN": "Bitte wählen Sie ein Datum aus",
    "ADRESSE_MITBEWOHNER": "Folgende Personen wohnen an derselben Adresse",
    "MITZUEGLER": "Folgende Personen werden auch umgezogen",
    "PARAMETERS": "Definierte Parameter",
    "BETRAG_VALUE": "CHF {{betrag}}",
    "DATEI_IMPORTIEREN": "Datei importieren",
    "DATEIEN_IMPORTIEREN": "Dateien importieren",
    "IMPORTIEREN": "Importieren",
    "PREPOSITION_DATUM_MF": "{isActive, select, true{Aktuell } other{}} {preposition} {isBeginOfTime, select, true{Eröffnung} other{{date}} }",
    "GUELTIGKEIT_VON_BIS": "{{dateAb}} - {{dateBis}}",
    "PERIOD": "{{dateAb}}/{{dateBis}}",
    "ZUGEWIESEN_PER": "Zugewiesen per {{date}}",
    "PROVISORISCH": "provisorisch",
    "VERRECHNET": "verrechnet",
    "VERRECHENBAR": "verrechenbar",
    "STORNIERT": "storniert",
    "GELOESCHT": "gelöscht",
    "NICHT_VERRECHENBAR": "nicht verrechenbar",
    "RECHNUNGSDIFFERENZ": "Rechnungsdifferenz vorhanden",
    "VON": "Von",
    "BIS": "bis",
    "STUNDEN": "{{hours}}h",
    "STUNDEN_BRACKETS": "({{hours}}h)",
    "HOURS_MINUTES": "{{hours}}:{{minutes}} h",
    "ADMINISTRATION": "Administration",
    "RECHNUNGS_KONFIGURATIONEN": "Rechnungskonfigurationen",
    "NEUE_KONFIGURATION": "Neue Konfiguration",
    "NEUE_KONFIGURATION_NAME": "Neue {{name}}-Konfiguration",
    "SMALLINVOICE": "SmallInvoice",
    "DVB": "DVB",
    "YES": "Ja",
    "NO": "Nein",
    "DATE_SWITCHER": {
      "PREV": "Zurück zu {{value}}",
      "NEXT": "Weiter zu {{value}}"
    },
    "TASKS": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Task} other{Tasks}}",
      "KINDERORT_TARIF_ZUWEISUNG": "Tarifzuweisung {{ZEITRAUM}} für {{entityDisplayName}} festlegen.",
      "KINDERORT_TARIF_ZUWEISUNG_ZEITRAUM": "Tarif {{ABRECHNUNG_TYP}} {{ZEITRAUM}} für {{entityDisplayName}} festlegen.",
      "GEBURTSTAG_KIND": "{{entityDisplayName}} braucht ein Geburtsdatum.",
      "KINDERORT_TARIF_FIRMENKONTINGENT": "Tarifzuweisung {{ZEITRAUM}} für Kontingent {{entityDisplayName}} festlegen.",
      "KIND_TARIFPARAMETER": "Parameter {{PARAMETERS}} für {{entityDisplayName}} {{ZEITRAUM}} festlegen.",
      "MANDATORY_CUSTOM_FIELD": "{{entityDisplayName}} hat folgende leere Pflichtfelder: {{PARAMETERS}}",
      "KIND_AUSTRITT_PROVISORISCH": "{{entityDisplayName}} hat einen provisorischen Austritt {{ZEITRAUM}}.",
      "KIND_BELEGUNG_PROVISORISCH": "{{entityDisplayName}} hat eine provisorische Zuteilung {{ZEITRAUM}}.",
      "KIND_BELEGUNG_ANGEBOT": "{{entityDisplayName}} hat ein offenes Angebot {{ZEITRAUM}}.",
      "KIND_RECHNUNGSDIFFERENZ": "{{entityDisplayName}} hat eine offene Rechnungsdifferenz {{ZEITRAUM}}.",
      "KINDERORT_LEISTUNGSBERECHNUNG_INTERNALERROR": "Ein interner Fehler in der Leistungsrechnung ist aufgetreten.",
      "RECHNUNGS_REVISION_DELIVERY_FAILURE": "Zustellung einer Revision der Rechnungs Nr. {{entityDisplayName}} ist fehlgeschlagen.",
      "KONTAKTPERSON_ADRESSE": "Der Rechnungskontakt {{entityDisplayName}} hat keine aktuelle Adresse.",
      "RECHNUNG_ZAHLUNGEN": "Die Rechnung Nr. {{entityDisplayName}} für {{KONTAKTPERSON}} ist überbezahlt.",
      "MONATSBLATT": "Das Monatsblatt {{ZEITRAUM}} für {{entityDisplayName}} ist noch nicht komplett ausgefüllt. Es fehlt: {{PARAMETERS}}",
      "ZEITRAUM_VON_BIS": "zu Zeitraum {{ZEITRAUM_VON}} - {{ZEITRAUM_BIS}}",
      "PERIODE": "{{VON}}/{{BIS}}",
      "LISTE_VON_MEHR_ALS_2": "{{liste}} und {{letztes}}",
      "AUSTRITT_DATUM": "am {{AUSTRITT}}",
      "ZEITRAUM_AB": "ab {{ZEITRAUM_VON}}",
      "COMMUNICATION_BRING_ABHOLZEITEN": "Bring- und Abholzeiten von {{entityDisplayName}} {{ZEITRAUM}} kontrollieren.",
      "KIBON_EXCHANGE_VERFUEGUNG": "KiBon Verfügungen {{entityDisplayName}} {{PERIODE}} zuweisen.",
      "KIBON_EXCHANGE_MUTATION": "Pendente KiBon Betreuungsmeldungen {{entityDisplayName}} {{PERIODE}} gefunden.",
      "CHECK_OUT_MISSING": "{{entityDisplayName}} wurde {{ZEITRAUM}} nicht abgemeldet.",
      "KIND_ABWEICHUNGS_MELDUNG": "Es gibt offene groupAdmin Anträge für {{entityDisplayName}}.",
      "PENDING_EXTERNAL_ANMELDUNG": "Es gibt offene externe Anmeldungen für {{entityDisplayName}}."
    },
    "LEISTUNGSRECHNUNG_WARNINGS": {
      "BG_PENSUM_MISMATCH": {
        "SHORT": "BG-Pensum",
        "LONG_MF": "{implicitlyKnown, select, KIND{Das Pensum von {kindName}} other{Das Pensum in {kinderOrtName}}} ist niedriger als das BG-Pensum. Bitte kontrollieren Sie das Pensum in kiBon und kitAdmin."
      },
      "PENDENTE_MUTATIONS_MELDUNG": {
        "SHORT": "Pendente kiBon Mutation",
        "LONG_MF": "Es gibt eine pendente kiBon Mutationsmeldung für {implicitlyKnown, select, KIND{{kinderOrtName}} other{{kindName}}}."
      },
      "PENDENTE_VERFUEGUNG_MELDUNG": {
        "SHORT": "Pendente Verfügung",
        "LONG_MF": "Für diesen Zeitraum wurde eine Platzbestätigung/Mutation für {implicitlyKnown, select, KIND{{kinderOrtName}} other{{kindName}}} an kiBon gesendet. Eine neue Verfügung ist ausstehend.",
        "BADGE_LOESCHEN": "Alle Warnung Badges für diesem Monat",
        "BADGES_OVERVIEW_TITLE": "Pendente Verfügungen",
        "DELETE_FOR_REF_NR": "Sollen die Warnhinweise für die Betreuung {{refNr}} gelöscht werden?",
        "DELETE_FOR_REF_NR_INFO": "kitAdmin hat keine Möglichkeit zu prüfen ob eine Platzbestätigung/Mutationsmeldung überhaupt jemals verfügt wird. Bitte überpfüfen Sie den Status der Betreuung direkt in kiBon.",
        "BADGE_MONAT_LOESCHEN": "Sollen die Hinweise für eine ausstehende Verfügung in diesem Monat entfernt werden?"
      },
      "PENDENTE_KIND_ABWEICHUNGS_MELDUNG": {
        "SHORT": "Pendenter groupAdmin Antrag",
        "LONG_MF": "Es gibt mindestens einen offenen groupAdmin Antrag für {kindName}."
      }
    },
    "EINZAHLUNGSSCHEIN": "Einzahlungsschein",
    "KONTO": "Konto",
    "RECHNUNGEN": "Rechnungen",
    "RECHNUNGSLAUF": "Rechnungslauf",
    "RECHNUNGSDATUM": "Rechnungsdatum",
    "FAELLIG_AM": "Fällig am",
    "ZAHLUNGSFRIST_PLACEHOLDER": "Fristdatum",
    "RECHNUNGS_TOTAL": "Rechnungstotal",
    "RECHNUNG_NR": "Nr. {{nummer}}",
    "RECHNUNG_PER_EMAIL_SENDEN": "Rechnung per E-Mail senden",
    "RECHNUNGEN_PER_EMAIL_SENDEN": "{{anzahl}} Rechnungen per E-Mail versenden?",
    "KORRESPONDENZ_PER_EMAIL": "Korrespondenz per E-Mail",
    "RECHNUNG_VERSENDEN_BESTAETIGEN": "Versand der Rechnung Nr. {{nummer}} bestätigen",
    "RECHNUNG_EMAIL_EMPFAENGER": "Empfänger-E-Mail: {{email}}",
    "VERSAND": "Versand",
    "EMAIL_AM": "E-Mail am: {{datum}}",
    "VERSAND_PER": "Versand per {{versandart}}",
    "VERSANDART": "Versandart",
    "VERSANDART_POST": "Post",
    "VERSANDART_EMAIL": "E-Mail",
    "ANTWORT_ADRESSE": "Antwort-E-Mail Adresse",
    "BCC_ADRESSE": "BCC-E-Mail Adresse",
    "BETRAG_CHF": "Betrag in CHF",
    "DATUM": "Datum",
    "UHRZEIT": "Uhrzeit",
    "MONTH": "Monat",
    "YEAR": "Jahr",
    "DATERANGE": "{{von}} - {{bis}}",
    "POSITION": "Position",
    "TOTAL": "Total",
    "ANZAHL": "Anzahl",
    "BEZEICHNUNG": "Bezeichnung",
    "TOTAL_CHF": "Total: CHF {{chf}}",
    "SOLL": "Soll",
    "HABEN": "Haben",
    "SALDO": "Saldo",
    "ZAHLUNG_TEXT": "{{dtype}} vom {{datum}} mit Betrag {{betrag}} CHF",
    "VORAUSZAHLUNG": "Vorauszahlung",
    "RUECKERSTATTUNG": "Rückerstattungsbetrag",
    "RUECKERSTATTUNGSBETRAG_AUSZAHLEN": "Rückerstattungsbetrag auszahlen.",
    "RUECKERSTATTUNG_AUSBEZAHLT": "Rückerstattung vom {{datum}} ausbezahlt",
    "RUECKERSTATTUNG_UND_AUSZAHLUNG_LOESCHEN": "Rückerstattung und Auszahlung löschen",
    "VORAUSZAHLUNG_VERBUCHT": "Vorauszahlung vom {{datum}} verbucht",
    "AUSZAHLEN": "Auszahlen",
    "AUSZAHLUNG_BESTAETIGEN": "Auszahlung bestätigen",
    "AUSZAHLUNGSDATUM": "Auszahlungsdatum",
    "AUSZAHLUNG_PAINFILE_GENERIEREN": "pain.001 Datei erstellen",
    "AUSZAHLUNG_ZAHLUNGSZWECK": "Zahlungszweck",
    "BETRAG": "Betrag",
    "CONFIRM_DELETE_ENTITY": "{{entity}} definitiv löschen?",
    "CONFIRM_DELETE": "Definitiv löschen?",
    "CONFIRM_RESTORE": "Definitiv wiederherstellen?",
    "AKTIONEN": "Aktionen",
    "WARN_AUSGLEICHSZAHLUNG_WIRD_STORNIERT": "Durch das Löschen der {{type}} wird die Ausgleichszahlung auf Rechnung Nr. {{rechnungsNummer}} storniert.",
    "OFFENE_POSTEN": "Offene Posten",
    "ERFASSEN": "Erfassen",
    "ZAHLUNGEN_BIS": "Zahlungen bis",
    "LEISTUNGSPERIODE_BIS": "Leistungsperiode bis",
    "BILD_HOCHLADEN": "Bild hochladen",
    "KEINE_ENTITY_VORHANDEN": "Keine {{entityName}} vorhanden",
    "KEINE_GRUPPEN": "Keine Gruppen",
    "KEINE_WEITEREN_GRUPPEN": "Keine weiteren Gruppen",
    "KEINE_LEISTUNGEN": "Keine Leistungen",
    "KEINE_OFFENE_POSTEN_VORHANDEN": "Keine offene Posten",
    "GEBUEHR": {
      "GEBUEHR": "Gebühr",
      "MITGLIEDER_BEITRAG": "Mitgliederbeitrag",
      "DEPOT": "Depot",
      "DEPOT_VERBUCHT": "Depot vom {{datum}} verbucht",
      "GEBUEHR_VERBUCHT": "Gebühr vom {{datum}} verbucht",
      "MITGLIEDER_BEITRAG_VERBUCHT": "Mitgliederbeitrag vom {{datum}} verbucht",
      "BEZEICHNUNG_TYPE": "Bezeichnung: {{type}}",
      "DEPOT_TO_RUECKERSTATTUNG": "In Rückerstattungsbetrag umwandeln",
      "DEPOT_TO_VORAUSZAHLUNG": "Als Vorauszahlung erfassen",
      "DEPOT_TO_RECHNUNG": "Als Vorauszahlung auf Rechnung verbuchen",
      "DEPOT_NON": "Keine Depots vorhanden",
      "DEPOT_MIT_RECHUNG_ERSTATTEN": "Depot von {{betrag | number:2}} CHF als Anzahlung einer Rechnung erstatten",
      "CONFIRM_DEPOT_MIT_RECHUNG_ERSTATTEN": "Depot von {{betrag | number:2}} CHF per Vorauszahlung auf die Rechnung vom {{rechnungPeriode}} erstatten?"
    },

    "FRAKTIONEN": {
      "KITA": {
        "PLURAL": "Gruppen",
        "WEITERE": "Weitere Gruppen",
        "ERSTELLEN": "Neue Gruppe erstellen",
        "ARCHIVIERTE_ANZEIGEN": "Archivierte Gruppen anzeigen",
        "ARCHIVIERTE_AUSBLENDEN": "Archivierte Gruppen ausblenden"
      },
      "TAGES_ELTERN": {
        "PLURAL": "Betreuungspersonen",
        "WEITERE": "Weitere Betreuungspersonen",
        "ERSTELLEN": "Neue Betreuungsperson erstellen",
        "ARCHIVIERTE_ANZEIGEN": "Archivierte Betreuungspersonen anzeigen",
        "ARCHIVIERTE_AUSBLENDEN": "Archivierte Betreuungspersonen ausblenden"
      }
    }
  },
  "BASE": {
    "NAVIGATION_OEFFNEN": "Navigation öffnen",
    "MENU_LOGOUT_USER": "Abmelden",
    "MENU_KIND_ANMELDEN": "Kind anmelden",
    "BENUTZER_PROFIL": "Profil"
  },
  "AUTHENTICATION": {
    "LOGIN_HEADER": "Anmelden",
    "LOGIN_EMAIL": "E-Mail",
    "LOGIN_PASSWORD": "Passwort",
    "LOGIN_LOGIN": "Login",
    "PRIVACY_TITLE": "Bitte akzeptieren Sie unsere Datenschutzrichtlinie um fortfahren zu können.",
    "PRIVACY_SUBTITLE": "<a href=\"https://blog.kitadmin.ch/privacy\" target=\"_blank\" rel=\"noopener\">Datenschutzrichtlinie</a>",
    "PRIVACY_CONFIRM": "Akzeptieren",
    "PRIVACY_CANCEL": "abmelden",
    "LOGIN_IDP": "Externes login",
    "IDP_HINT": "Keycloak IDP Hint",
    "OIDC_USER": "Externe Authentifizierung",
    "OIDC_USER_MANAGEMENT": "Keycloak Benutzerverwaltung",
    "OIDC_LOGIN_FAILED": "Anmeldung fehlgeschlagen"
  },
  "VERSION": {
    "VERSION_INFO": "Version {{frontendVersion}} / {{backendVersion}}",
    "VERSION_INFO_SINGLE": "Version {{version}}",
    "VERSION_MISMATCH": "Version {{version}} ist jetzt verfügbar.",
    "VERSION_TOOLTIP": "Frontend / Backend",
    "DO_UPDATE": "Wenn Sie das Update nicht ausführen läuft die Applikation möglicherweise nicht mehr korrekt.",
    "AKTUALISIEREN": "Aktualisieren"
  },
  "LANGUAGE": {
    "DE": "DE",
    "FR": "FR",
    "WIP": "Experimental",
    "NO_TEXT_DEFINED": "Kein Text definiert"
  },
  "FAKTURA": {
    "AKTUALISIERE": "aktualisiere...",
    "NAV_ZAHLUNGEN": "Zahlungen",
    "NAV_REVISIONEN": "Revisionen",
    "NAV_KONFIGURATION": "Konfiguration",
    "NAV_EINZAHLUNGSSCHEIN": "Einzahlungsschein",
    "NAV_KONTO": "Konto",
    "EMPFAENGER_LABEL": "Empfänger:",
    "KEINE_BEMERKUNG": "Keine Bemerkung",
    "HINWEIS": "Hinweis",
    "GUELTIG_BIS_OPTIONAL": "Gültig bis (optional)",
    "ACHTUNG": "Achtung",
    "FAKTURAEINSTELLUNGEN": "Fakturaeinstellungen",
    "RECHNUNGSDIFFERENZ": "Rechnungsdifferenz vorhanden",
    "RECHNUNGSUEBERSCHUSS": "Rechnungsüberschuss",
    "RECHNUNG_DELIVERY_FEHLER": "Übermittlungsfehler",
    "ES_GIBT_NEUE_LEISTUNGSRECHNUNG": "<strong>Rechnungsdifferenz:</strong> Die Leistungen haben sich verändert. Bitte erstellen Sie einen neuen Rechnungslauf um die Rechnung zu aktualisieren.",
    "RECHNUNG_IST_UEBERBEZAHLT": "<strong>Überbezahlt:</strong> Die Summe aller Zahlungseingänge übersteigen das Rechnungstotal.",
    "NEGATIVES_RECHNUNGSTOTAL": "Das Rechnungstotal ist negativ.",
    "RECHNUNGS_KONFIGURATION_DEAKTIVIERT": "<strong>Deaktiviert:</strong> Die Rechnungskonfiguration wurde deaktiviert. Diese Rechnung enthält keine aktuellen Zahlungsinformationen.",
    "RECHNUNGS_EMAIL_FEHLGESCHLAGEN": "<strong>Fehlgeschlagen:</strong> Der E-Mail-Versand an {{email}} am {{time}} ist fehlgeschlagen.",
    "CONFIRM_RECHNUNG_ZUWEISEN": "Überschüssigen Betrag von {{betrag | number:2}} CHF auf die Rechnung umbuchen?",
    "RECHNUNGSDATUM_DATUM": "Rechnungsdatum: {{datum}}",
    "ZAHLUNGSFRIST_AENDERN": "Zahlungsfrist ändern",
    "HERUNTERLADEN": "Herunterladen",
    "AKTUELLE_RECHNUNG_HERUNTERLADEN": "Aktuelle Rechnung herunterladen",
    "ORIGINAL_RECHNUNG_HERUNTERLADEN": "Original herunterladen",
    "STORNIEREN": "Stornieren",
    "RECHNUNGS_POSITIONEN": "Rechnungspositionen",
    "RECHNUNG_STORNIEREN": "Rechnung stornieren?",
    "RECHNUNGEN_STORNIEREN": "Rechnungen stornieren?",
    "RECHNUNGEN_STORNIEREN_LINK": "Rechnungen stornieren",
    "RECHNUNG_STORNIEREN_LINK": "Rechnung stornieren",
    "RECHNUNG_STORNIEREN_BETROFFENE_RECHNUNGEN": "Folgende Rechnungen werden gemeinsam storniert:",
    "RECHNUNGS_REVISION_DELIVERY_FAILURE_TASK_LOESCHEN": "Rechnungszustellungstask",
    "ERSETZT_RECHNUNG": "Ersetzt die Rechnung:",
    "IST_ERSETZTE_RECHNUNG": "Wird ersetzt durch die Rechnung:",
    "FAELLIG_AM_DATUM": "Fällig am {{datum}}",
    "FAELLIG_AM_DATUM_LABEL": "Fällig am: {{datum}}",
    "ZAHLUNGSFRIST_DATUM_LABEL": "Zahlungsfrist: {{datum}}",
    "MF_ZAHLUNGSFRIST_POPOVER_TEXT": "Die Zahlungsfrist ist seit {dateDiff, plural, one{einem Tag} other{# Tagen}} abgelaufen. Bitte erstellen Sie eine Mahnung um die Frist zu verlängern.",
    "ZAHLUNGSFRIST_POPOVER_TITLE": "Zahlungsfrist seit {{datum}} abgelaufen",
    "REVISIONEN_ANZAHL": "{{anzahl}} Revisionen",
    "RECHNUNGS_REVISIONEN": "Rechnungsrevisionen",
    "REVISION_ERSTELLT_AM_DATUM": "Erstellt am {{datum}}",
    "REVISION_STORNIERT_AM_DATUM": "Storniert am {{datum}}",
    "STATUS_AENDERN": "Status ändern",
    "VORAUSZAHLUNG_BETRAG_VORHANDEN": "Offene Vorauszahlungen vorhanden ({{betrag}} CHF)",
    "ABWEICHUNG_VORMONAT": "Betragsänderung zum Vormonat gefunden",
    "ABWEICHUNG_REVISIONS_TOTAL": "Betragsänderung zur letzten Rechnungsrevision gefunden",
    "ABWEICHUNG_VORMONAT_REVISIONS_TOTAL": "Betragsänderung zum Vormonat gefunden\nBetragsänderung zur letzten Rechnungsrevision gefunden",
    "RECHNUNGSGEBUEHREN_BEARBEITEN": "Rechnungsgebühren bearbeiten",
    "GEBUEHREN": "Gebühren",
    "GEBUEHR_LOSCHEN": "Gebühr löschen",
    "GEBUEHR_HINZUFUEGEN": "Gebühr hinzufügen",
    "KEINE_GEBUEHREN": "Keine Gebühren vorhanden",
    "VALUTA_DATE": "Valutadatum",
    "STATUS_VERLAUF": "Statusverlauf",
    "TASK_VERLAUF": "Taskverlauf",
    "STATUS_UPDATE": {
      "STATUS_UPDATE_RECHNUNGSLAUF": "Revision erstellt: {{benutzer}}",
      "STATUS_UPDATE_CUSTOM_POSITION_EDITED": "Rechnungsgebühren bearbeitet: {{benutzer}}",
      "STATUS_UPDATE_MAHNLAUF": "Mahnlauf: {{benutzer}}",
      "STATUS_UPDATE_ZAHLUNGS_EINGANG": "Zahlung erfasst: {{benutzer}}",
      "STATUS_UPDATE_ZAHLUNGS_STORNIERUNG": "Zahlung storniert: {{benutzer}}",
      "STATUS_UPDATE_EXTERN": "Statusaktualisierung durch Fremdsystem erkannt: {{benutzer}}",
      "STATUS_UPDATE_USER_UPDATE": "Statusaktualisierung: {{benutzer}}",
      "STATUS_UPDATE_KONFIGURATION_DEAKTIVIERT": "Rechnungskonfiguration deaktiviert: {{benutzer}}"
    },
    "EXT_STATE": {
      "EXT_STATE_GESENDET": "Gesendet",
      "EXT_STATE_BEZAHLT": "Bezahlt",
      "EXT_STATE_TEILBEZAHLT": "Teilbezahlt",
      "EXT_STATE_ERINNERT": "Zahlungserinnerung",
      "EXT_STATE_MAHNUNG1": "1. Mahnung",
      "EXT_STATE_MAHNUNG2": "2. Mahnung",
      "EXT_STATE_MAHNUNG3": "3. Mahnung",
      "EXT_STATE_STORNIERT": "Storniert",
      "EXT_STATE_SONSTIGES": "Sonstiges",
      "EXT_STATE_NICHT_VERFUEGBAR": "Nicht verfügbar",
      "EXT_STATE_BETRIEBEN": "Betreibung"
    },
    "RECHNUNG_FILTER": {
      "RECHNUNG_FILTER_GESENDET": "Gesendet",
      "RECHNUNG_FILTER_BEZAHLT": "Bezahlt",
      "RECHNUNG_FILTER_TEILBEZAHLT": "Teilbezahlt",
      "RECHNUNG_FILTER_ERINNERT": "Zahlungserinnert",
      "RECHNUNG_FILTER_MAHNUNG1": "1. Mahnung",
      "RECHNUNG_FILTER_MAHNUNG2": "2. Mahnung",
      "RECHNUNG_FILTER_MAHNUNG3": "3. Mahnung",
      "RECHNUNG_FILTER_BETRIEBEN": "Betreibung",
      "RECHNUNG_FILTER_STORNIERT": "Storniert",
      "RECHNUNG_FILTER_SONSTIGES": "Sonstiges",
      "RECHNUNG_FILTER_ABGELAUFEN": "Abgelaufen"
    },
    "RECHNUNG_PERDIODE_NR": "{{periode}} / Nr. {{nummer}}",
    "RECHNUNG_SEARCH_RESULT": "Rechnungs-Nr. {{nummer}}",
    "AUSSTEHEND_CHF": "Offen: CHF {{chf}}",
    "REFERENZNUMMER": "Referenznummer",
    "RECHNUNG_ANSEHEN": "Rechnung ansehen",
    "DETAILS": "Details",
    "BANKZAHLUNG": "Bankzahlung",
    "SKONTO": "Skonto",
    "ABSCHREIBUNG": "Abschreibung",
    "RUNDUNGSDIFFERENZ": "Rundungsdifferenz",
    "AUSGLEICHSZAHLUNG": "Ausgleichszahlung",
    "AUSGLEICH_VORAUSZAHLUNG_ANSEHEN": "Ausgleichsvorauszahlung ansehen",
    "AUSGLEICH_RUECKERSTATTUNG_ANSEHEN": "Ausgleichsrückerstattung ansehen",
    "ERFASSTE_ZAHLUNGEN": "Erfasste Zahlungen",
    "AUSSTEHENDER_BETRAG": "Ausstehender Betrag",
    "UEBERSCHUESSIGER_BETRAG": "Überschüssiger Betrag",
    "UEBERSCHUSS_AUSGLEICHEN": "Überschuss ausgleichen",
    "RECHNUNG_AUSGLEICHEN": "Überschuss von {{betrag}} CHF per {{type}} ausgleichen",
    "RECHNUNG_AUFTEILEN": "Überschuss von {{betrag | number:2}} CHF auf andere Rechnung umbuchen",
    "AUSGLEICHEN": "Ausgleichen",
    "ZAHLUNG_ERFASSEN": "Zahlung erfassen",
    "ZAHLUNG_TYP": "Zahlungstyp",
    "ZAHLUNG_STORNIEREN_CONFIRM": "Zahlung definitiv stornieren?",
    "DRUCKPOSITIONIERUNG": "Druckpositionierung",
    "QR-IBAN_KONTONUMMER": "QR-IBAN oder Kontonummer",
    "ESR_AUSLIEFERUNGSNUMMER": "Auslieferungsnummer",
    "EINZAHLUNGSSCHEIN_BANK": "Einzahlungsschein für eine Bank",
    "EINZAHLUNGSSCHEIN_PAGE": "Einzahlungsschein nicht auf Seite 1 drucken",
    "EINZAHLUNGSSCHEIN_PAGE_CONFIRM": "Der Einzahlungsschein wird nicht auf Seite 1 gedruckt",
    "NEW_EINZAHLUNGSSCHEIN": "Neuer Einzahlungsschein",
    "BANK_NAME": "Bank Name",
    "BANK_ADRESSZEILE": "Adresszeile",
    "VORSCHAU_ANZEIGEN": "Vorschau anzeigen",
    "NUR_DEFAULTS": "Es werden die Standardwerte angewendet",
    "SEITENRAENDER": "Seitenränder",
    "LOGO": "Logo",
    "EMPFAENGERADRESSE": "Empfängeradresse",
    "BREITE": "Breite",
    "ABSTAND_OBEN": "Abstand oben",
    "ABSTAND_UNTEN": "Abstand unten",
    "ABSTAND_LINKS": "Abstand links",
    "ABSTAND_RECHTS": "Abstand rechts",
    "ABSTAND_OBEN_MM": "Abstand oben (mm)",
    "ABSTAND_LINKS_MM": "Abstand links (mm)",
    "MM_VALUE": "{{value}} mm",
    "KEINE_ZAHLUNGEN_VORHANDEN": "Keine Zahlungen",
    "KEINE_EINZAHLUNGSSCHEINE": "Keine Einzahlungsscheine",
    "KEINE_VORSCHLAEGE": "Keine Vorschläge vorhanden",
    "KONTO": {
      "KONTEN": "Konten",
      "KEINE_KONTEN": "Keine Konten",
      "NEU": "Neues Konto",
      "BEZEICHNUNG": "Bezeichnung",
      "KONTOINHABER": "Kontoinhaber",
      "IBAN": "IBAN",
      "BIC": "BIC",
      "RUECKZAHLUNGSKONTO": "Rückzahlungskonto",
      "KEIN_KONTO": "Kein Konto",
      "MANDANT_DEFAULT": "Mandant standard"
    },
    "KONTENABGLEICH": {
      "KONTENABGLEICH": "Kontenabgleich",
      "NAV_TRANSAKTIONEN": "Transaktionen",
      "NAV_KONTOAUSZUEGE": "Kontoauszüge",
      "KONTOAUSZUEGE_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Kontoauszug} other{Kontoauszüge}}",
      "TRANSAKTIONEN_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Transaktion} other{Transaktionen}}",
      "TRANSAKTIONEN_AUTO_ASSIGN_MF": "{count, plural, =0{Es konnten keine Transaktionen zugewiesen werden} one{Folgende Transaktion wurde automatisch zugewiesen} other{Folgende # Transaktionen wurden automatisch zugewiesen}}",
      "TRANSAKTIONEN": "Transaktionen",
      "TRANSAKTIONEN_REFRESH": "Transaktionen aktualisieren",
      "TRANSAKTION_STATUS": "Status",
      "RECHNUNGS_STATUS": "Status",
      "STATUS_ZUGEWIESEN": "Zugewiesen",
      "STATUS_NICHT_ZUGEWIESEN": "Nicht zugewiesen",
      "STATUS_IGNORIERT": "Ignoriert",
      "DATEIEN_HOCHLADEN": "Dateien hochladen",
      "TRANSAKTION_ZUWEISEN": "Zuweisen",
      "ZUGEWIESEN": "Zugewiesen",
      "NICHT_ZUGEWIESEN": "Nicht zugewiesen",
      "IGNORIERT": "Ignoriert",
      "ZUWEISUNG_LOESCHEN": "Zuweisung löschen",
      "TRANSAKTION_LOESCHEN_IGNORIEREN": "Zuweisung löschen & ignorieren",
      "TRANSAKTIONEN_AUTOMATISCH_ZUWEISEN": "Automatisch zuweisen",
      "NICHT_IGNORIEREN": "Nicht ignorieren",
      "EINGELESEN_AM": "Eingelesen am",
      "EMPFAENGER": "Empfänger",
      "AUTOMATISCH_ZUGEWIESEN_DURCH": "Automatisch zugewiesen durch {{benutzer}}",
      "MANUELL_ZUGEWIESEN_DURCH": "Manuell zugewiesen durch {{benutzer}}",
      "KONTOAUSZUG_ERFOLGREICH_HOCHGELADEN": "Kontenabgleich erfolgreich",
      "KONTOAUSZUG_IMPORT_DETAILS": "Es wurden {{totalEntries}} Transaktionen hochgeladen. {{successfulAssigned}} wurden automatisch zugewiesen. {{duplicate}} Duplikate, {{notAssigned}} nicht zugewiesen und {{ignored}} ignoriert.",
      "BANK_STATEMENT_AUTOMATISCH_ZUGEWIESEN": "Automatisch zugewiesen",
      "BANK_STATEMENT_NICHT_ZUGEWIESEN": "Nicht zugewiesen",
      "BANK_STATEMENT_DUPLIKATE": "Duplikate",
      "BANK_STATEMENT_IGNORIERT": "Ignoriert",
      "BANK_STATEMENT_TOTAL": "Total",
      "BANK_STATEMENT_SUMME": "Summe",
      "NICHT_ZUGEWIESENE_ZUWEISEN": "Nicht zugewiesene zuweisen",
      "MF_BANK_STATEMENT_DUPLIKATE_ERKLAERUNG": "Es {duplicateCount, plural, one{wurde ein Duplikat} other{wurden # Duplikate}} hochgeladen. {duplicateCount, plural, one{Ist der Zahlungseingang} other{Sind die Zahlungseingänge}} wirklich mehrmals am selben Tag, mit dem selben Betrag und der selben Referenznummer eingegangen, kann dies hier manuell bestätigt werden.",
      "MARKIERTE_DUPLIKATE_IMPORTIEREN": "Markierte Duplikate importieren",
      "TRANSAKTIONEN_ANSEHEN": "Transaktionen ansehen",
      "TRANSAKTIONEN_LOESCHEN": "Datei und alle Zahlungen",
      "HERUNTERLADEN": "Herunterladen",
      "KONTOAUSZUG": "Kontoauszug",
      "ZEITRAUM": "Zeitraum",
      "EINLESEDATUM": "Einlesedatum",
      "DATEINAME": "Dateiname",
      "KONTOAUSZUG_VOM": "Kontoauszug vom {{datum}} ({{anzahlTransaktionen}})",
      "VORGESCHLAGEN": "Vorgeschlagen",
      "MATCH_BY_REFERENZNUMMER": "Referenznummer identisch",
      "MATCH_BY_KONTAKTPERSON": "Offene Rechnungen von {{name}}",
      "TRANSAKTION_DIREKT_ZUWEISEN": "Transaktion direkt zuweisen",
      "CONFIRM_TRANSAKTION_ZUWEISEN": "Transaktion dieser Rechnung definitiv zuweisen?",
      "TRANSAKTION_ZUWEISEN_WARNING": "Diese Zuweisung übersteigt den ausstehenden Rechnungsbetrag. Die Rechnung wird um CHF {{betrag}} überzahlt.",
      "CONFIRM_TRANSAKTION_IGNORIEREN": "Transaktion ignorieren?",
      "CONFIRM_TRANSAKTION_NICHT_IGNORIEREN": "Transaktion nicht mehr ignorieren?",
      "TRANSAKTION_ZUWEISUNG_LOESCHEN": "Transaktion Zuweisung löschen",
      "TRANSAKTIONEN_TOTAL_BETRAG_MF": "Totalbetrag {count, plural, one{der Transaktion} other{aller # Transaktionen}}",
      "NAME_NOT_FOUND": "(Name nicht vorhanden)"
    },
    "MASSENAKTIONEN": {
      "TITLE": "Massenaktionen",
      "EMAILS_VERSENDEN": "Rechnungen per E-Mail senden",
      "STORNIEREN": "Rechnungen stornieren",
      "ERROR_TITLE": "Folgende Fehler sind aufgetreten. Bei den restlichen Rechnungen wurde die Aktion erfolgreich durchgeführt.",
      "ERROR_RECH_NO": "Rechnungs Nr.: {{rechnr}}",
      "ERROR_MISSING_EMAIL": "Bei folgenden Personen ist keine E-Mail Adresse hinterlegt:",
      "ERROR_EMAIL_FAILED": "Der Versand ist wegen einem technischen Problem bei folgenden Rechnungen fehlgeschlagen:",
      "ERROR_EMAIL_SUCCESS_MF": "{count, plural, one{1 Rechnung konnte} other{# Rechnungen konnten}} versendet werden."
    }
  },
  "ADMINISTRATION": {
    "MAINTENANCE": "Wartung",
    "APP_CONFIG": "Konfiguration",
    "NAV_MANDANTEN_ABRECHNUNG": "Abrechnung",
    "NAV_MANDANTEN_UEBERSICHT": "Mandanten",
    "NAV_MANDANTEN_MAINTENANCE": "Wartung",
    "NAV_FEATURES": "Features",
    "NAV_USERS": "Aktive Benutzer",
    "NAV_NOTIFICATION": "Benachrichtigungen",
    "MTNC_ACTIONS": "Aktionen",
    "MTNC_BATCH_JOB_INFORMATION": "Batch Job Informationen abrufen",
    "MTNC_PURGE_BATCH_JOBS": " Batch Jobs löschen",
    "MTNC_LEISTUNGSRECHNUNG_STARTEN": "Leistungsrechnung starten",
    "MTNC_DELETE_SCHULUNGSMANDANTEN": "Schulungsmandanten löschen",
    "MTNC_ARCHIVE_KINDER": "Archive Kinder",
    "MTNC_UNARCHIVE_KINDER": "Unarchive Kinder",
    "MTNC_DVB_DEMO_DATA_INIT": "DV Bern Demodaten neu einspielen",
    "MTNC_WTC_DEMO_DATA_INIT": "Arbeitszeitkontrolle Demodaten erstellen",
    "MTNC_REINDEX": "Suchindex neu erstellen",
    "MTNC_CLEAR_CONTROLLING_DATA": "Controlling Daten löschen",
    "MTNC_RECALCULATE_CURRENT_CONTROLLING_DATA": "Controlling Daten des aktuellen Jahres neu berechnen",
    "MTNC_CONTROLLING_RECALCULATION": "KinderOrt Controlling Daten neu berechnen",
    "MTNC_CONTROLLING_RECALCULATION_CALC_ASYNC": "Asynchron berechnen",
    "MTNC_CONTROLLING_RECALCULATION_CALC_SYNC": "Synchron laden",
    "MTNC_CONTROLLING_RECALCULATION_DEL": "löschen",
    "MTNC_CLEAR_AUTH_CACHE": "Auth Cache löschen",
    "MTNC_CREATE_RECHUNUNGS_UEBERSCHUSS_TASK": "Rechnungs-Überschuss Tasks erstellen",
    "MTNC_DELETE_RECHUNGS_POSITIONEN": "Mandant Rechnungspositionen löschen",
    "MTNC_DELETE_ANGESTELLTE": "Angestellte löschen",
    "MTNC_MATCH_KIBON_EXCHANGE_DATA": "kiBon Exchange Zuweisung starten",
    "MTNC_SHIRO_TESTING_BASICS": "Shiro Basics",
    "MTNC_SHIRO_TESTING_SUPER_ADMIN_BLOCKING": "Shiro Super Admin (blocking)",
    "MTNC_SHIRO_TESTING_SUPER_ADMIN_NON_BLOCKING": "Shiro Super Admin (non-blocking)",
    "MTNC_CLEAN_UP_BLOB_DATA": "Veraltete BlobData löschen",
    "MTNC_RECALCULATE_MANDANT_RECHNUNG": "Mandantrechnung neu berechnen",
    "MTNC_RECALCULATE_ASYNC": "Asynchron neu berechnen",
    "MTNC_MIGRATE_KONTAKT_IBAN": "Kontaktperson IBAN Migration",
    "MTNC_MIGRATE_KONTAKT_IBAN_CUSTOM_FIELD": "Zusatzfeld Name",
    "MTNC_MIGRATE": "Migrieren",
    "MTNC_RELOAD_KIBON_EXCHANGE_INSTITUTIONEN": "kiBon Exchange Institutionen neu laden",
    "MANDANTEN": "Mandanten",
    "FILTER_BY_NAME": "Nach Namen filtern",
    "MONATLICHES_EINKOMMEN": "Monatliches Einkommen",
    "VERRECHENBARE_KINDERORTE": "Verrechenbare Kinderorte",
    "ACTIVE_TAGES_ELTERN": "Aktive Tageseltern",
    "CORONA_KINDER_ORT_FEES": "Corona Notverordnung",
    "KEINE_RECHNUNGEN_VORHANDEN": "Keine Rechnungen",
    "KEINE_RECHNUNGS_POSITIONEN": "Keine Rechnungspositionen",
    "VALUE_X": "{{count}}x",
    "CUSTOM_RECHNUNG_POSITION": "Korrektur",
    "CUSTOM_RECHNUNG_POSITION_LOESCHEN": "Korrektur löschen",
    "CUSTOM_RECHNUNG_POSITION_HINZUFUEGEN": "Korrektur hinzufügen",
    "CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN": "Korrekturen anpassen",
    "ERR_INVALID_CUSTOM_POSITION": "Korrektur vollständig ausfüllen (Position, Anzahl, Betrag).",
    "ERR_INVALID_MANDANT_RECHNUNG": "Mandant Rechnung nicht gefunden",
    "EMAIL_TEST": "E-Mail Test",
    "EMAIL_EMPFAENGER": "Empfänger",
    "EMAIL_BETREFF": "Betreff",
    "EMAIL_OPTIONALE_RECHNUNG": "Optionale Rechnung",
    "EMAIL_SENDEN": "E-Mail senden",
    "EMAIL_ERROR": "E-Mail konnte nicht an {{to}} gesendet werden.",
    "VERRECHENBAR_KINDERORT": {
      "SINGULAR": "Verrechenbarer Kinderort",
      "PLURAL": "Verrechenbare Kinderorte",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{verrechenbarer Kinderort} other{verrechenbare Kinderorte}}"
    },
    "NICHT_VERRECHENBAR_KINDERORT": {
      "SINGULAR": "Nicht verrechenbarer Kinderort",
      "PLURAL": "Nicht verrechenbare Kinderorte",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{nicht verrechenbarer Kinderort} other{nicht verrechenbare Kinderorte}}"
    },
    "ACTIVE_TOTAL": "{{active}} / {{total}}",
    "PIPEDRIVE_NAME": "Pipedrive Öffnen",
    "TIMEOUT_TESTING": {
      "TITLE": "Timeout Testing",
      "PROMISE": "Promise",
      "SEKUNDEN": "Sekunden",
      "SERVER_REQUEST_DURATION": "Server-Request-Dauer in Sekunden",
      "CLIENT_REQUEST_TIMEOUT": "Client-Request-Timeout in Sekunden",
      "REQUEST_START": "Request starten",
      "REQUEST_ABORT": "Request abbrechen"
    },
    "MANDANT_FEATURES": {
      "ENABLE_FOR_ALL": "Für alle Kinderorte aktivieren",
      "ENABLED_FOR_MF": "{forAll, select, true{Für alle Kinderorte aktiviert} other{ {count, plural, one{Für einen Kinderort aktiviert} other{Für {count} Kinderorte aktiviert:}} }}",
      "ENABLED_FOR_SELECTED": "Für ausgewählte Kinderorte aktiviert",
      "AVAILABLE": "Noch nicht vergebene Features:",
      "TYPES": {
        "CSV_EXPORT_RECHNUNGEN_HIRSLANDEN": "Hirslanden CSV Export im Rechnung ZIP",
        "CSV_EXPORT_RECHNUNGEN_KSA": "KSA CSV Export im Rechnungen ZIP",
        "GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL": "Langenthal GemoWin Export im Rechnungen ZIP",
        "XML_EXPORT_RECHNUNGEN_KIMI": "Kimi Rechnungs XML Export",
        "CSV_EXPORT_MONATSBLATT_LEOLEA": "Leolea CSV Export von Monatsblatt Tageseltern",
        "XML_EXPORT_MONATSBLATT_KIBEZ": "Kibez XML Export von Monatsblatt Tageseltern",
        "XML_EXPORT_MONATSBLATT_UNTERE_EMME": "XML Export von Monatsblatt Tageseltern Untere Emme",
        "KIBON_MELDUNGEN": "Daten an kiBon senden",
        "VERTRAGLICHES_PENSUM": "Vertragliches Pensum auf Belegung",
        "STUNDENERFASSUNG_TFO": "TFO Stundenerfassung für Tageseltern",
        "MONATSBLATT_API_KIBE_PLUS": "Monatsblatt API Kibe Plus",
        "MONATSBLATT_API_NANNY_KIBE_PLUS": "Monatsblatt API für Nannies Kibe Plus",
        "ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA": "Monatliches Anwesenheitssoll KSA Zobra",
        "ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS": "Jährliches Anwesenheitssoll KSA Zwärglihuus",
        "EXTERNAL_ANMELDUNG": "Externe Kinder Anmeldung",
        "DAUERAUFTRAG_ZAHLUNGSZUWEISUNG": "Dauerauftrag Zahlungszuweisung",
        "VORMONAT_RECHNUNGSDIFFERENZ": "Differenzen im Rechnungsbetrag zum Vormonat erkennen und angezeigen",
        "RECHNUNG_REVISION_RECHNUNGSDIFFERENZ": "Differenzen von Rechnungsrevisionen zur Vorgängerversion hervorheben",
        "CONTROLLING_REPORT_MONTHLY": "Monatlicher Controlling Report",
        "AUTOMATIC_ABWEICHUNGSMELDUNG_CONVERSION": "Automatisches Erstellen von Abweichungen aus Abweichungsmeldungen",
        "AUTOMATIC_ABWEICHUNGSMELDUNG_ABSENCE_CONVERSION": "Automatisches Erstellen von Absenzen aus Abweichungsmeldungen",
        "GRP_WEEK_CAPACITY": "Gruppenplätze ohne Auslastungszahlen"
      }
    },
    "USERS": {
      "REFRESH": "Aktualisieren"
    }
  },
  "KINDERORT": {
    "STAMMDATEN": "Stammdaten",
    "STRUKTUR_TITLE": "Kinderort-Struktur",
    "STRUKTUR": {
      "KITA": "Kinderort mit Gruppen",
      "TAGES_ELTERN": "Kinderort mit Tageseltern"
    },
    "DEFAULT_TITLE": {
      "KITA": "Kita",
      "TAGES_ELTERN": "Tageseltern"
    },
    "SUBVENTIONIERTE_KAPAZITAET": {
      "KITA": {
        "NAME": "Subventionierte Plätze",
        "ANZAHL": "Anzahl subventionierte Plätze",
        "WITH_VALUE": "{{anzahl}} subventionierte Plätze",
        "BEENDEN": "Subventionierte Plätze beenden",
        "BEENDEN_DIALOG_HEADING": "Subventionierte Plätze für {{kitaname}} werden beendet."
      },
      "TAGES_ELTERN": {
        "NAME": "Subventionierte Stunden",
        "ANZAHL": "Anzahl subventionierte Stunden",
        "WITH_VALUE": "{{anzahl}} subventionierte Stunden",
        "BEENDEN": "Subventionierte Stunden beenden",
        "BEENDEN_DIALOG_HEADING": "Subventionierte Stunden für {{kitaname}} werden beendet."
      }
    },

    "NAV_BELEGUNG": "Belegung",
    "NAV_WARTELISTE": "Warteliste",
    "NAV_TARIFE": "Tarife",
    "NAV_OFFENE_POSTEN_ANZEIGEN": "Alle Posten anzeigen",
    "NAV_RECHNUNGSKONFIGURATION": "Rechnungskonfiguration",
    "NAV_VEREINBARUNGSKONFIGURATION": "Vereinbarungskonfiguration",
    "NAV_KIBON_MUTATIONSLAUF": "kiBon Betreuungsmeldungen",
    "NAV_PERSONAL_KONFIGURATION": "Personalkonfiguration",
    "NAV_LOHN": "Lohn",
    "NAV_EXTERNAL_ANMELDUNGEN": "Externe Anmeldungen",
    "NAV_DIENST_ADMINISTRATION": "Dienstadministration",
    "NAV_KIND_ABWEICHUNGS_MELDUNGEN": "groupAdmin Anträge",
    "NAV_KINDERORT_GROUP_ADMIN_EINSTELLUNGEN": "groupAdmin Einstellungen",
    "FILTER_GRUPPEN": "Gruppen",
    "FILTER_PLAETZE": "Kontingente",
    "FILTER_STATUS_FRIST": "Frist",
    "FILTER_PRIVATE_PLAETZE": "Privat",
    "FILTER_SUBVENT_PLAETZE": "Subventioniert",
    "TITLE_FREIE_KAPAZITAET": "Freie Plätze",
    "TITLE_BELEGTE_PLAETZE": "Belegte Plätze",
    "PRIORITAET_SHORT": "Prio {{prioritaet}}",
    "KITA_TITLE_TARIF_PRIVAT": "Privater Tarif:",
    "KITA_TITLE_TARIF_SUBVENTIONIERT": "Subventionierter Tarif:",
    "KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN": "Betreuungsgutschein Tarif:",
    "KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG": "Betreuungsunabhängiger Tarif:",
    "KITA_TITLE_TARIF_PROVISORISCHER_BG": "Provisorischer BG Tarif:",
    "KITA_TITLE_TARIF_PRIVAT_AUSWAEHLEN": "Privater Tarif auswählen",
    "KITA_TITLE_TARIF_SUBVENTIONIERT_AUSWAEHLEN": "Subventionierter Tarif auswählen",
    "KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN_AUSWAEHLEN": "Betreuungsgutschein Tarif auswählen",
    "KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG_AUSWAEHLEN": "Betreuungsunabhängiger Tarif auswählen",
    "KITA_TITLE_TARIF_PROVISORISCHER_BG_AUSWAEHLEN": "Provisorischer BG Tarif auswählen",
    "NEUE_TARIF_ZUWEISUNG_SPEICHERN": "Tarife zuweisen",
    "NEUE_TARIF_ZUWEISUNG": "Neue Tarifzuweisung",
    "TARIF_ZUWEISUNG_BEENDEN": "Tarifzuweisung beenden",
    "PROFIL_ANSEHEN": "Profil ansehen",
    "PLAETZE": "Plätze",
    "ZEITRAUMFELD_TITLE_BELEGT": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}\nBelegte Plätze {{belegtePlaetze}}\nAnzahl Kinder {{kinder}}",
    "ZEITRAUMFELD_TITLE_CAPACITY": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}",
    "ZEITRAUMFELD_TITLE_BELEGT_LIMITED": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}\nBelegte Plätze {{belegtePlaetze}}\nAnzahl Kinder {{kinder}}\nVerfügbar bis {{verfuegbarBis}}",
    "ZEITRAUMFELD_TITLE_FREI": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}\nFreie Plätze {{freiePlaetze}}\nAnzahl Kinder {{kinder}}",
    "ZEITRAUMFELD_TITLE_FREI_CAPACITY": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}",
    "ZEITRAUMFELD_TITLE_FREI_LIMITED": "Max Plätze {{maxPlaetze}}\nAnzahl Plätze {{plaetze}}\nFreie Plätze {{freiePlaetze | roundTo:2}}\nAnzahl Kinder {{kinder}}\nVerfügbar bis {{verfuegbarBis}}",
    "ZEITRAUMFELD_MISSING_KAPAZITET": "Dieser Zeitraum kann nicht belegt werden, da zu diesem Datum keine Plätze im Gruppen-Profil definiert wurden.",
    "MEHR_ANZEIGEN": "Mehr anzeigen...",
    "WENIGER_ANZEIGEN": "Weniger anzeigen...",
    "TOTAL_KINDER_MF": "Total {anzahl} Kinder ({summenProzent}{vertraglichProzent, plural, =0{} other{, Vertraglich #%}})",
    "EROEFFNUNGSDATUM": "Eröffnungsdatum",
    "GUELTIGKEIT": "Gültigkeit",
    "BEWILLIGTE_PLAETZE": {
      "KITA": "Bewilligte Plätze",
      "TAGES_ELTERN": "Gültigkeit"
    },
    "BEWILLIGTE_PLAETZE_WITH_VALUE": "{{anzahl}} bewilligte Plätze",
    "MAXIMALE_PLAETZE": "Maximale Plätze",
    "MAXIMALE_PLAETZE_WITH_VALUE": "{{maximalePlaetze}} maximale Plätze",
    "PLAETZE_WITH_VALUE": "{{plaetze}} Plätze",
    "BELEGTE_PLAETZE_WITH_VALUE": "{{belegtePlaetze}} belegte Plätze",
    "AUSLASTUNG_WITH_VALUE": "{{auslastung}}% Auslastung",
    "AUSLASTUNG_SUBVENTIONIERTE_PLAETZE_WITH_VALUE": "{{auslastung}}% subventionierte Plätze",
    "ANZAHL_BEWILLIGTE_PLAETZE": "Anzahl bewilligte Plätze",
    "KITA_DELETE": "Kinderort löschen",
    "KITA_GUELTIGKEIT_INVALID": "Der Kinderort kann ausserhalb seiner Gültigkeit ({{gueltigAb}} - {{gueltigBis}}) nicht aufgerufen werden",
    "KONTINGENTE": "Kontingente {{kontingente}}",
    "ARCHIVIERTE_KONTINGENTE_ANZEIGEN": "Archivierte Kontingente anzeigen",
    "ARCHIVIERTE_KONTINGENTE_AUSBLENDEN": "Archivierte Kontingente ausblenden",
    "CONFIRM_KAPAZITAETEN_BEENDEN": "Es gibt noch Kapazitäten nach dem Enddatum. Wirklich beenden?",
    "KITA_BEENDEN_HEADING": "{{kitaname}} wird beendet.",
    "KITA_BEENDEN": "Kinderort beenden",
    "DURCHSCHNITT_WITH_VALUE": "Durchschnitt {{jahr}}",
    "KIND_ABWEICHUNGS_MELDUNGEN": {
      "TITLE": "Anträge",
      "NONE": "Keine Anträge vorhanden",
      "SINGULAR": "Antrag",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Antrag} other{Anträge}}",
      "ASSIGN": "Akzeptieren",
      "IGNORE": "Ignorieren",
      "SEE_DETAILS": "Details anpassen",
      "FILTER": {
        "TITLE": "Status",
        "OPEN": "Offen",
        "ASSIGNED": "Akzeptiert",
        "IGNORED": "Ignoriert"
      },
      "DETAIL": "zum Detail"
    },
    "SHORT_KALENDERWOCHE": "KW",
    "SHORT_KALENDERWOCHE_WITH_VALUE": "KW {{kalenderwoche}}",
    "VERFUEGBAR_AB": "Verfügbar ab",
    "ALTER": "Alter in Jahren",
    "TARIFE_AUSWAEHLEN": "Tarife auswählen",
    "TARIFE_ZUGEWIESEN": "Zugewiesene Tarife",
    "KITA_TARIF_AUSWAEHLEN": "{{tariftyp}} Tarif auswählen",
    "KITA_TARIFZUWEISUNG": "Tarifzuweisung",
    "TARIF_ZUWEISUNG_BEENDEN_HEADING": "Tarifzuweisung für {{kitaname}} beenden?",
    "CONFIRM_TARIF_ZUWEISUNG_BEENDEN": "Es gibt noch zugewiesene Tarife nach dem Enddatum. Wirklich beenden?",
    "KITA_KONFIGURATION_NAME": "{{name}} ({{type}}) {{deactivated ? '[Deaktiviert]' : ''}}",
    "KITA_RECHNUNGS_KONFIGURATION": "Kinderort-Rechnungskonfiguration",
    "ZAHLUNGSKONTO": "Zahlungskonto",
    "EINZAHLUNGSSCHEINE": "Einzahlungsscheine",
    "KEIN_KONTO": "Kein Konto",
    "KEIN_EINZAHLUNGSSCHEIN": "Kein Einzahlungsschein",
    "KEIN_LAYOUT": "Kein Layout",
    "LAYOUT": "Layout",
    "ZUERST_MANDANT_KONFIGURATION_ERSTELLEN": "Zuerst muss eine Mandant Konfiguration erstellt werden",
    "EINZAHLUNGSSCHEINE_AKTUALISIEREN": "Einzahlungsscheine aktualisieren",
    "ZAHLUNGSKONTO_AKTUALISIEREN": "Kontenliste aktualisieren",
    "LAYOUTS_AKTUALISIEREN": "Layoutliste aktualisieren",
    "RECHNUNGSLAEUFE": "Rechnungsläufe",
    "RECHNUNGSLAUF_ERSTELLEN": "Rechnungslauf erstellen",
    "MAHNLAUF": "Mahnlauf",
    "MAHNLAUF_STATUS_ERSTELLEN": "{{status}} erstellen",
    "MAHNLAUF_ERSTELLEN": "Mahnlauf erstellen",
    "KEINE_RECHNUNGEN_AUSGEWAEHLT": "Keine Rechnungen ausgewählt",
    "RECHNUNGSLAUF_STARTEN": "Rechnungslauf starten",
    "RECHNUNGSLAUF_BEENDEN": "Rechnungen bestätigen",
    "RECHNUNGSLAUF_SELECT_ALL_VERRECHENBAR": "Alle verrechenbaren auswählen",
    "RECHNUNGSLAUF_DESELECT_ALL": "Keine auswählen",
    "RECHNUNG_WITH_DIFF_PER_EMAIL_SENDEN": "nur mit Betragsänderung",
    "RECHNUNGSLAUF_PERIODE": "Rechnungslauf Leistungsperiode {{periode}}",
    "RECHNUNGSLAUF_PDF_ERSTELLEN": "Rechnungen herunterladen",
    "RECHNUNGSLAUF_ALLE_PDF_ERSTELLEN": "alle ",
    "RECHNUNGSLAUF_PDF_MIT_DIFF_ERSTELLEN": "nur mit Betragsänderung",
    "RECHNUNGSLAUF_SEND_ALL_EMAILS": "alle",
    "MAHNGEBUEHR": "Mahngebühr",
    "MAHNGEBUEHR_LOCKED": "Mahngebühren für Rechnungen vor dem {{date}} sind gesperrt.",
    "MAHNUNGEN_HERUNTERLADEN": "Mahnungen herunterladen",
    "ERSTELLEN_SIE_EINE_KITA_RECHNUNGSKONFIGURATION": "Um einen Rechnungslauf zu starten, müssen Sie zuerst eine gültige Kinderort-Rechnungskonfiguration erstellen.",
    "RECHNUNG_NICHT_VERRECHENBAR_TOOLTIP": "Rechnung kann nicht verrechnet werden weil...",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISEN": "Betreuungsfaktor-Regel zuweisen",
    "NEUE_KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG": "Neue Betreuungsfaktor-Regel zuweisen",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN": "Zuweisung beenden",
    "ZUGEWIESENE_KITA_BETREUUNGSFAKTOR_REGELN": "Zugewiesene Betreuungsfaktor-Regeln",
    "KITA_BETREUUNGSFAKTOR_REGEL": "Betreuungsfaktor-Regel-Zuweisung",
    "KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN_HEADING": "Betreuungsfaktor-Regel-Zuweisung für {{kitaname}} beenden?",
    "CONFIRM_KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN": "Es gibt noch zugewiesene Betreuungsfaktor-Regeln nach dem Enddatum. Wirklich beenden?",
    "BETREUUNGSFAKTOR_REGEL_AUSWAEHLEN": "Regel auswählen",
    "TITLE_BETREUUNGSFAKTOR_REGEL": "Regel {{regelname}} ",
    "MF_RECHNUNGEN_TOTAL_BETRAG": "Totalbetrag {count, plural, one{der Rechnung} other{aller # Rechnungen}}",
    "FAKE_KONTOAUSZUG": "Fake Kontoauszug herunterladen",
    "FAKE_ESR3_KONTOAUSZUG": "ESR3",
    "FAKE_ESR4_KONTOAUSZUG": "ESR4",
    "KEINE_ESR_RECHNUNGEN": "Unter diesen Rechnungen befinden sich keine nicht bezahlten Rechnungen mit DVB Einzahlungsscheinen.",
    "FAKTURA_STATUS": {
      "FAKTURIERT": "fakturiert",
      "TEILWEISE_FAKTURIERT": "teilweise fakturiert",
      "NICHT_FAKTURIERT": "nicht fakturiert",
      "KEINE_LEISTUNGEN": "Keine Leistungen"
    },
    "MASSENVERSAND": "Massenversand",
    "TEXT_MASSENVERSAND": "Text Massenversand",
    "ABSTAND_OBEN_SHORT": "Abstand oben",
    "ABSTAND_OBEN_ZAHL_MM": "{{hoehe}} mm",
    "ABSTAND_LINKS_SHORT": "Abstand links",
    "ABSTAND_LINKS_ZAHL_MM": "{{breite}} mm",
    "PP_FRANKIERUNG_HINZUFUEGEN": "PP-Frankierung hinzufügen",
    "ZAHLUNGS_ANGABEN": "Zahlungsangaben",
    "DEFINIERTE_TEXTE": "Definierte Texte",
    "NEUE_TEXTE": "Neue Texte",
    "EINFUEHRUNG": "Einführung",
    "KONDITIONEN": "Konditionen",
    "IS_GRUPPE_VERSTECKEN": "Gruppenname verstecken",
    "IS_PLATZART_VERSTECKEN": "Platzart verstecken",
    "IS_PENSUM_VERSTECKEN": "Pensum / vertragliche Stunden verstecken",
    "IS_FAKTOR_VERSTECKEN": "Betreuungsfaktor verstecken",
    "IS_BELEGUNG_ZEITEN_VERSTECKEN": "Belegungszeiten verstecken",
    "IS_KINDERORT_NAME_VERSTECKEN": "Kinderort Name verstecken",
    "SHOW_MODULE_NAMES": "Modulnamen anzeigen",
    "UNTERSCHRIFT_TITEL": "Unterschriften",
    "UNTERSCHRIFT_TITEL_VERSTECKEN": "Soll der Titel \"Unterschriften\" angezeigt werden?",
    "HAS_UNTERSCHRIFT1": "Unterschrift 1 Aktivieren",
    "UNTERSCHRIFT1": "Unterschrift 1",
    "HAS_UNTERSCHRIFT2": "Unterschrift 2 Aktivieren",
    "UNTERSCHRIFT2": "Unterschrift 2",
    "IS_UNTERSCHRIFT_TITLE_VERSTECKEN": "Überschrift verstecken",
    "PENSUM_DISPLAY_TYPE": {
      "TITLE": "Pensumsangabe in",
      "PCT": "Prozent",
      "TIME": "Zeit"
    },
    "IS_BETREUUNG_BEI_FAMILIE": "Betreuung bei der Familie",
    "RECHNUNG_STATUS_TEXT": {
      "RECHNUNG_STATUS_TEXT_STANDARD": "Standard",
      "RECHNUNG_STATUS_TEXT_ZAHLUNGSERINNERUNG": "Zahlungserinnerung",
      "RECHNUNG_STATUS_TEXT_MAHNUNG1": "1. Mahnung",
      "RECHNUNG_STATUS_TEXT_MAHNUNG2": "2. Mahnung",
      "RECHNUNG_STATUS_TEXT_MAHNUNG3": "3. Mahnung",
      "RECHNUNG_STATUS_TEXT_BETREIBUNG": "Betreibung"
    },
    "HEADER_KONFIGURATION": "Kopfzeileninformationen",
    "KONFIGURATION_EDIT_WARNUNG": "Nur neue Rechnungen werden von der Änderung der Rechnungskonfiguration betroffen sein.",
    "KONFIGURATION_EDIT_WARNUNG_COUNT": "Es wurden bereits {{anzahl}} Rechnungen mit dieser Konfiguration erstellt.",
    "KONFIGURATION_EDIT_EXCLUDED_RECHNUNGEN": "{{anzahl}} Rechnungen sind vorhanden, die nun keine Rechnungskonfiguration mehr hätten.<br>Die bisherige Konfiguration wird für diese Rechnungen beibehalten.",
    "KONFIGURATION_BCC_AKTIV": "BCC ist aktiv",
    "KONFIGURATION_BCC_AKTIVIEREN": "BCC aktivieren",
    "TARIF_POS_KONFIGURATION": "Gruppe / Betreuungsperson anzeigen",
    "KONFIGURATION_TARIF_POS_FRAKTION_SUFFIX_AUSGEBLENDET": "Name von Gruppe / Betreuungsperson ausgeblendet",
    "KONFIGURATION_SUMMARY_AUSBLENDEN": "Zusammenfassung ausblenden",
    "KONFIGURATION_SUMMARY_AUSGEBLENDET": "Zusammenfassung ausgeblendet",
    "KONFIGURATION_KUNDENNR_AUSBLENDEN": "Kundennummer ausblenden",
    "KONFIGURATION_KUNDENNR_AUSGEBLENDET": "Kundennummer ausgeblendet",
    "RECHNUNG_RUNDUNGSWERT": "Rundungsschritt des Rechnungstotal",
    "RECHNUNG_RUNDUNGSWERT_TOOLTIP": "Rundungsschritt des Betrages, z.B. 0.05 rundet 73.04 CHF auf 73.05 CHF auf",
    "DEFINIERTE_TEXTE_ERKLAERUNG": "Sie können für jeden Rechnungsstatus Einführung und Konditionen definieren. Der Standardtext wird übernommen falls für einen Status nichts definiert wird.",
    "KITA_SALDO": "Kinderort Saldo",
    "AKTUELLE_AENDERUNGEN": "Aktuelle Änderungen",
    "CONTROLLING_CHANGE_EINTRITT": "Eintritt von {{kind}}",
    "CONTROLLING_CHANGE_EINTRITT_ANGEBOT": "Eintrittsangebot von {{kind}}",
    "CONTROLLING_CHANGE_EINTRITT_PROVISORISCH": "Provisorischer Eintritt von {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE": "Neue Belegung für {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE_ANGEBOT": "Neues Belegungsangebot für {{kind}}",
    "CONTROLLING_CHANGE_BELEGUNG_CHANGE_PROVISORISCH": "Provisorische Belegung für {{kind}}",
    "CONTROLLING_CHANGE_BETREUUNGSFAKTOR_CHANGE": "{{kind}} erhält einen neuen Betreuungsfaktor ({{args}})",
    "CONTROLLING_CHANGE_AUSTRITT": "Austritt von {{kind}}",
    "CONTROLLING_CHANGE_AUSTRITT_PROVISORISCH": "Provisorischer Austritt von {{kind}}",
    "KEINE_AENDERUNGEN_VORHANDEN": "Keine Änderungen",
    "KEINE_KITA_RECHNUNGS_KONFIGURATION": "Keine Kinderort-Rechnungskonfiguration",
    "KEINE_NICHT_VERRECHNETEN_RECHNUNGEN_VORHANDEN": "Keine nicht verrechnete Rechnungen vorhanden",
    "KEINE_TARIFE_ZUGEWIESEN": "Keine zugewiesene Tarife",
    "KEINE_ZUGEWIESENE_KITA_BETREUUNGSFAKTOR_REGELN": "Keine zugewiesene Betreuungsfaktor-Regeln",
    "SCHLIESSTAGE": {
      "ZUWEISUNG": "Schliesstage-Zuweisung",
      "ZUWEISEN": "Schliesstage zuweisen",
      "ZUWEISUNG_BEENDEN": "Zuweisung beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Schliesstage-Zuweisung für {{kinderOrtName}} beenden?",
      "CONFIRM_BEENDEN": "Es gibt noch zugewiesene Schliesstage nach dem Enddatum. Wirklich beenden?",
      "NEUE_ZUWEISUNG": "Neue Schliesstage zuweisen",
      "ZUGEWIESENE": "Zugewiesene Schliesstage",
      "KEINE_ZUWEISUNG": "Keine zugewiesenen Schliesstage",
      "AUSWAEHLEN": "Schliesstage auswählen"
    },
    "ACCOUNTING_ID": "Buchhaltungs ID"
  },
  "DATA-INTEGRITY": {
    "NAV_MALFORMED_PHONE_NUMBERS": "Telefonnummern",
    "NAV_MISSING_GESCHLECHT_OR_SPRACHE": "Geschlecht & Sprache",
    "NO_MISSING_GESCHLECHT_OR_SPRACHE": "Keine fehlenden Geschlechter oder Sprachen",
    "DATA_INTEGRITY": "Datenintegrität",
    "NO_MALFORMED_PHONE_NUMBERS": "Alle Telefonnummern sind bereits gültig.",
    "MALFORMED_PHONE_NUMBERS_TEXT": "Laden Sie ein Dokument mit allen ungültigen Telefonnummern herunter:",
    "CSV_DOWNLOAD": "CSV herunterladen",
    "EMAIL_EXPORT_DOWNLOAD": "E-Mail Adressen exportieren",
    "REMOVE_COMPLETED": "Korrigierte Einträge automatisch ausblenden"
  },
  "DOKUMENT_LAYOUT": {
    "LOESCHEN": "Dokumentlayout löschen",
    "FUSSZEILE": "Fusszeile",
    "MODE_NORMAL": "Normal",
    "MODE_KOMPAKT": "Kompakt"
  },
  "BENUTZER": {
    "NAV_BENUTZERRECHTE": "Benutzerrechte",
    "PASSWORD_AENDERN": "Passwort ändern",
    "USERNAME_AENDERN": "Benutzername ändern",
    "USERNAME_AENDERN_CONFIRM": "Sie müssen sich neu anmelden wenn Sie Ihren Benutzernamen ändern.",
    "STAMMDATEN_BENUTZER": "Stammdaten Benutzer",
    "CURRENT_PASSWORD": "Login Passwort",
    "NEW_PASSWORD": "Neues Passwort",
    "CONFIRM_PASSWORD": "Passwort bestätigen",
    "SAVE_PASSWORD": "Passwort speichern",
    "USERNAME": "Benutzername ( E-Mail )",
    "RECHTE_ALLE_KINDERORTE": "Alle Kinderorte",
    "RECHTE_ZUSAETZLICHE_KINDERORTE": "Zusätzliche Kinderorte",
    "NEUES_KINDERORT_RECHT": "Neues Kinderort-Recht",
    "KINDERORT_PERMISSION": "Kinderort-Recht",
    "STANDARD": "Standard",
    "ROLE": "Benutzerrolle",
    "SUPER_ADMIN": "Super Admin",
    "MANDANT_ADMIN": "Mandant Admin",
    "SUPPORT_ADMIN": "Support Admin",
    "ADMIN": "Administrator",
    "USER": "Keine globale Berechtigung",
    "KITA_PERMISSION": {
      "TITLE": "Betreuungs Berechtigung",
      "BETREUUNG_ADMIN": "Betreuungs Administrator",
      "ADMINISTRATE": "Kinderort administrieren",
      "MANAGE": "Kinderort verwalten",
      "CONTROL": "Kontrollieren",
      "VIEW_PLUS": "Betreuen+",
      "VIEW": "Betreuen",
      "KEINE": "Keine"
    },
    "PERSONAL_PERMISSION": {
      "TITLE": "Personal Berechtigung",
      "ADMINISTRATE": "Personal administrieren",
      "MANAGE": "Personal verwalten",
      "VIEW": "Personal einsehen",
      "KEINE": "Keine"
    },
    "FUER_ALLE_KINDER_ORTE": "Für alle Kinderorte",
    "NEUES_RECHT_HINZUFUEGEN": "Neues Kinderort-Recht hinzufügen",
    "ROLLE_AUSWAEHLEN": "Rolle auswählen",
    "KITA_OR_PERSONAL_PERMISSION": "Betreuungs- oder Personal Berechtigung",
    "KITA_PERMISSION_AUSWAEHLEN": "Betreuungs Berechtigung auswählen",
    "PERSONAL_PERMISSION_AUSWAEHLEN": "Personal Berechtigung auswählen",
    "KEINE_KITA_RECHTE_VORHANDEN": "Keine Kinderort-Rechte",
    "ERR_INVALID_APPLICATION_ROLE": "Entweder eine Benutzerrolle oder eine Berechtigung muss ausgewählt werden!",
    "GRANULAR_PERMISSIONS": {
      "TITLE": "Spezifische Berechtigungen",
      "ADD": "Berechtigung hinzufügen",
      "NONE": "Keine spezifischen Berechtigungen vorhanden",
      "FOR_ALL": "Für alle Kinderorte",
      "TYPE": {
        "FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE": "Rechnungssperre ignorieren"
      }
    }
  },
  "BETREUUNGSFAKTORREGEL": {
    "KEIN_FAKTOR_DEFINIERT": "Kein Faktor definiert",
    "TITLE_ALTERSBEDINGTE_BETREUUNGSFAKTOREN": "Altersbedingte Betreuungsfaktoren",
    "AB_MONATE_DISPLAY": "ab {{display}} Monaten",
    "AB_GEBURT": "ab Geburt",
    "FAKTOR_DISPLAY": "Faktor: {{faktor}}",
    "KINDERGARTEN_FAKTOR_DISPLAY": "Faktor KG {{type}}: {{faktor}}",
    "SCHULE_FAKTOR_DISPLAY": "Faktor Schule: {{faktor}}",
    "TITLE_KINDERGARTENBEDINGTER_BETREUUNGSFAKTOR": "Kindergartenbedingter Betreuungsfaktor",
    "KINDERGARTEN_BETREUUNGSFAKTOR_HINZUFUEGEN": "Kindergartenfaktor hinzufügen",
    "ALTERS_BEDINGTER_BETREUUNGSFAKTOR_HINZUFUEGEN": "altersbedingten Betreuungsfaktor hinzufügen",
    "FAKTOR": "Faktor",
    "KINDERGARTEN_1_FAKTOR": "Faktor Kindergarten I",
    "KINDERGARTEN_2_FAKTOR": "Faktor Kindergarten II",
    "SCHULE_FAKTOR": "Faktor Schule",
    "VON_MONATEN_LABEL": "Älter als (Monate)",
    "BETREUUNGSFAKTOR_REGEL_LOESCHEN": "Betreuungsfaktorregel löschen"
  },
  "KIBON_ABZUG": {
    "ABZUG_DISPLAY": "kiBon Abzugswert: {{abzug}}%",
    "TITLE": "kiBon Kindergartenbedingter Pensum-Abzug pro Tag",
    "HINZUFUEGEN": "kiBon Pensum-Abzug hinzufügen",
    "INFO_BETREUUNGSFAKTOR_REGEL": "Kinder, welche den Kindergarten besuchen, brauchen einen Korrekturwert für die automatisierte Betreuungsmeldung über die kiBon Schnittstelle, da das Kind während den Kindergarten-Besuchszeiten nicht durch den Kinderort betreuut wird.\nHier kann ein Abzug definiert werden, welcher pro Betreuungstag vom Pensum abgezogen wird.\nStandardmässig wird der Wert nur dann abgezogen, wenn ein Vormittag belegt ist. Über die Zusätzliche Checkbox kann dies geändert werden.\nFalls individuelle Abzüge benötigt werden, kann dies über eine neue Platzart mit spezifischem Abzug gemacht werden.",
    "INFO_PLATZ_ART": "Kinder, welche den Kindergarten besuchen und diese Platzart belegen kann das tägliche Pensum reduziert werden. Somit kann das Pensum korrigiert werden, welches durch den Besuch im Kindergarten entsteht.",
    "NACHMITTAGS_ABZUG": "Abzug auch an Nachmittagen berücksichtigen",
    "KEIN_ABZUG_DEFINIERT": "Kein kiBon-Abzugswert definiert",
    "KIBON_ABZUG_PRO_TAG": "Abzug pro Tag",
    "INFO_PAUSCHAL_ABZUG": "Genereller Pauschalabzug für Kindergartenkinder, welche einen internen Kindergarten besuchen.",
    "KIBON_INTERN_KG_PAUSCHAL_ABZUG": "kiBon Pauschalabzug interner Kindergarten"
  },
  "SEARCH": {
    "GLOBAL_SEARCH": "Suche",
    "TOGGLE_SEARCH_MODE": "Suchmodus umschalten",
    "ALLE_RESULTATE": "Alle Resultate",
    "SUCHERGEBNISSE": "Suchergebnisse für '{{suchtext}}'",
    "KEINE_SUCHERGEBNISSE": "Keine Suchergebnisse.",
    "ENTITY_TITLE_MF": "{entity, select, KINDERORT{} KINDERORT_FRAKTION{Gruppe / Betreuungsperson} MANDANT{Mandant} SCHLIESSTAGE{Schliesstage} BENUTZER{Benutzer} ANGESTELLTE{Angestellte} BETREUUNGSFAKTOR_REGEL{Betreuungsfaktorregel} BETREUUNGS_PERSON{Betreuungsperson} DOKUMENT_LAYOUT{Dokumentlayout} PLATZART{Platzart} KONTINGENT{Kontingent} GRUPPE{Gruppe} KIND{Kind} KITA{} KONTAKTPERSON{Kontaktperson} RECHNUNG{Rechnung} TAGES_ELTERN{} TARIF{Tarif} other{}} {text}",
    "ARCHIVE_MODE_WAEHLEN": "Archivmodus wählen",
    "ARCHIVE_MODE": {
      "ARCHIVED": "Nur archivierte",
      "NON_ARCHIVED": "Nicht archivierte",
      "ANY": "Archiv unabhängig"
    }
  },
  "KONTAKTPERSON": {
    "TITLE_KINDER": "Kinder",
    "KUNDEN_NUMMER": "Kunden-Nr. {{nummer}}",
    "KONTAKTPERSON_STAMMDATEN": "Kontaktperson Stammdaten",
    "HAUPTKONTAKT": "Hauptkontakt",
    "ERZIEHUNGSBERECHTIGTER": "Erziehungsberechtigt",
    "RECHNUNGSEMPFAENGER": "Rechnungskontakt",
    "SONSTIGER": "Sonstiger Kontakt",
    "ABHOLBERECHTIGT": "Abholberechtigt",
    "WOHNADRESSE": "Wohnadresse",
    "RECHNUNGSUEBERSICHT": "Rechnungsübersicht",
    "RECHNUNG_LABEL_PERIODE": "Rechnung {{periode}}",
    "VORAUSZAHLUNG_ERFASSEN": "Vorauszahlung erfassen",
    "RUECKERSTATTUNG_ERFASSEN": "Rückerstattungsbetrag erfassen",
    "GEBUEHR_ERFASSEN": "Gebühr erfassen",
    "VORAUSZAHLUNG_ERFASSEN_LINK": "Vorauszahlung",
    "RUECKERSTATTUNG_ERFASSEN_LINK": "Rückerstattungsbetrag",
    "ZAHLUNG_BANKZAHLUNG": "Bankzahlung auf Rechnung {{periode}}",
    "ZAHLUNG_SKONTO": "Skonto auf Rechnung {{periode}}",
    "ZAHLUNG_ABSCHREIBUNG": "Abschreibung auf Rechnung {{periode}}",
    "ZAHLUNG_RUNDUNGSDIFFERENZ": "Rundungsdifferenz auf Rechnung {{periode}}",
    "ZAHLUNG_AUSGLEICHSZAHLUNG": "Ausgleichszahlung auf überschüssigen Betrag",
    "ABGEWICKELTE_POSTEN_ANZEIGEN": "Abgewickelte Posten anzeigen",
    "STEUERBESCHEINIGUNG_ERSTELLEN": "Steuerbescheinigung erstellen",
    "MAHNSPERRE": "Mahnsperre",
    "CORRESPONDENCE": {
      "PAST": "Vergangene Korrespondenz",
      "CREATE_NOTE": "Notiz erstellen"
    },
    "GEBUEHR_TYPE_TITLE": "Gebühr Typ",
    "GEBUEHR_TYPE_AUSWAEHLEN": "Gebühr Typ auswählen",
    "GEBUEHR_TYPE": {
      "DEPOT": "Depot",
      "MITGLIEDER_BEITRAG": "Mitgliederbeitrag",
      "GEBUEHR": "Gebühr"
    },
    "DEPOT_BEREITS_EINGEFORDERT": "Dieses Depot wurde bereits eingefordert. Nicht auf eine Rechnung übernehmen",
    "IBAN": "IBAN"
  },
  "KIND": {
    "KIND_ERFASSEN": "Neues Kind erfassen",
    "KIND_HINZUFUEGEN": "Erfasstes Kind hinzufügen",
    "KIND_ERFASSEN_LINK": "Kind erfassen",
    "PRIORITAET_TITEL": "Priorität",
    "KITA_HINZUFUEGEN": "Kinderort hinzufügen",
    "BETREUUNGSGUTSCHEINE": "Betreuungsgutscheine {{kitaname}}",
    "BETREUUNGSGUTSCHEIN": "Betreuungsgutschein {{kind}}",
    "KIND_DIREKTZUWEISUNG_LINK": "Kind direkt zuweisen",
    "AUSTRITT": "Austritt",
    "ANMELDUNG": "Anmeldung",
    "ANMELDUNG_AM": "Anmeldung am",
    "ANHAENGE": "Anhänge",
    "ANHAENGE_VERTRAULICH": "Vertrauliche Anhänge",
    "ANHAENGE_ALLGEMEIN": "Allgemeine Anhänge",
    "ANHANG_HOCHLADEN": "Anhang hochladen",
    "KIND_STAMMDATEN": "Kind Stammdaten",
    "KIND_WURDE_BEREITS_EINMAL_ERFASST": "Ein Kind mit diesem Namen und Geburtstag existiert bereits. Fortfahren?",
    "GEBURTSTERMIN": "Voraussichtlicher Geburtstermin",
    "ANMELDEDATUM": "Anmeldedatum",
    "GESCHWISTER": "Geschwister",
    "GESCHWISTER_ANMELDEN": "Neues Geschwister anmelden",
    "GESCHWISTER_HINZUFUEGEN": "Geschwister hinzufügen",
    "GESCHWISTER_AUSWAEHLEN": "Geschwister auswählen",
    "KONTAKTPERSON_ERFASSEN_LINK": "Kontaktperson erfassen",
    "KONTAKTPERSON_ERFASSEN": "Neuen Kontakt erfassen",
    "KONTAKTPERSON_HINZUFUEGEN": "Bestehenden Kontakt hinzufügen",
    "KONTAKT_ENTFERNEN": "Kontakt entfernen",
    "DIESEN_KONTAKT_ALS_HAUPTKONTAKT_DEFINIEREN": "Diesen Kontakt als Hauptkontakt definieren",
    "WOHNADRESSE_KIND": "Wohnadresse Kind",
    "KIND_WOHNADRESSE_GLEICH_WIE_HAUPTKONTAKT": "Das Kind ist am gleichen Ort wohnhaft wie die Hauptkontaktperson",
    "RECHNUNGSANTEIL": "Rechnungsanteil",
    "RECHNUNGSAUFTEILUNG_FIXBETRAG": "in CHF",
    "RECHNUNGSAUFTEILUNG_PROZENT": "in Prozent %",
    "KONTAKTE": "Kontakte",
    "EINTRITTSDATUM_AB": "Eintrittsdatum ab",
    "BETREUUNG_AB": "Gewünschte Betreuung ab",
    "BETREUUNGSWUNSCH_HINZUFUEGEN": "Neuen Betreuungswunsch erfassen",
    "BETREUUNGSGRUND": "Betreuungsgrund",
    "BETREUUNGSVERLAUF": "Betreuungsverlauf",
    "BETREUUNG": "Betreuung",
    "KEINE_BELEGUNG": "Keine Belegung vorhanden",
    "KIND_ANMELDEN": "Kind anmelden",
    "KIND_AUSTRITT_HEADING": "Das Kind tritt aus dem Kinderort aus.",
    "KIND_AUSTRITT_AUSTRITTSDATUM": "Austrittsdatum festlegen",
    "KIND_AUSTRITT_CONFIRM_BELEGUNGEN": "Es gibt noch Belegungen nach dem Austrittsdatum. Wirklich austreten?",
    "KIND_AUSTRITT_VERLAUF_TITLE": "Austritt am:",
    "KIND_AUSTRITT_PROVISORISCH_VERLAUF_TITLE": "Provisorischer Austritt am:",
    "KIND_AUSTRITT_REVERT": "Austritt rückgängig",
    "KIND_AUSTRITT_DEFINITIV": "Austritt bestätigen",
    "AUSTRITT_MONATSBELEGUNG": "Eine Belegung mit Anwesenheits-Zeiten kann nicht automatisch für den nächsten Monat übernommen werden.",
    "DELETE_KIBON_DATEN": "<p>Es wurden bereits Daten an kiBon gesendet.</p><p>Bitte stellen Sie sicher das die Daten in kiBon korrekt sind.</p>",
    "KIND_DIREKT_ZUWEISEN": "Kind direkt zuweisen",
    "MUTTERSPRACHE": "Muttersprache",
    "SOZIALVERSICHERUNGSNUMMER": "Sozialversicherungsnummer",
    "KINDKONTAKT_RECHNUNGBETRAG_PROZENT": "({{betrag}}%)",
    "KINDKONTAKT_RECHNUNGBETRAG_FIXBETRAG": "({{betrag}} CHF)",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG": "Rechnungsaufteilung",
    "KIND_RECHNUNGSAUFTEILUNG_PROZENT": "in Prozent %",
    "KIND_RECHNUNGSAUFTEILUNG_FIXBETRAG": "in CHF",
    "KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_PROZENT": "%",
    "KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_FIXBETRAG": "CHF",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG_SPEICHERN": "Rechnungsaufteilung anwenden",
    "KINDKONTAKT_RECHNUNGSAUFTEILUNG_ERROR_PROZENT_TOTAL": "Die Summe der Prozentwerte ist nicht 100% sondern {{total}}%. Es müssen noch {{remainder}}% verteilt werden.",
    "NEUE_PARAMETER_ERFASSEN": "Neue Parameter",
    "NEUE_PARAMETER_ERFASSEN_TITLE": "Neue Parameter erfassen",
    "PARAMETER": "Parameter",
    "IRRELEVANT_PARAMETERS": "Aktuell irrelevante Parameter",
    "IMPORTED_PARAMETER": "importierter kiBon-Parameter",
    "BG_PENSUM_PROZENT": "BG-Pensum (%)",
    "BG_PENSUM": "BG-Pensum",
    "BG_PENSUM_PROZENT_VALUE": "BG-Pensum: {{value}}%",
    "BETRAG_GUTSCHEIN": "Gutschein (CHF)",
    "BETRAG_VERGUENSTIGT_SHORT": "Vergünstigung",
    "ELTERNBEITRAG_SHORT": "Elternbeitrag",
    "BETRAG_GUTSCHEIN_SHORT": "Gutschein",
    "VOLLKOSTEN_GUTSCHEIN": "Vollkosten (CHF)",
    "VOLLKOSTEN_GUTSCHEIN_SHORT": "Vollkosten",
    "BETRAG_GUTSCHEIN_VALUE": "Gutschein: {{value}} CHF",
    "BETRAG_VOLLKOSTEN_VALUE": "Vollkosten: {{value}} CHF",
    "BG_ANSPRUCH": "Anspruch",
    "BG_PENSUM_ZEITEINHEIT_MF": "{pensumUnit, select, HOURS{ {zeiteinheiten, plural, one{1h} other{#h} }} other{ {prozent}% }}",
    "BG_PENSUM_ZEITEINHEIT_TOOLTIP_MF": "{pensumUnit, select, DAYS{ {zeiteinheiten, plural, one{1 Tag} other{# Tage} }} other{ {prozent}% }}",
    "NEUER_GUTSCHEIN_ERFASSEN": "Neuer Betreuungsgutschein",
    "NEUER_GUTSCHEIN_ERFASSEN_TITLE": "Neuer Betreuungsgutschein erfassen",
    "MANUELLER_BETREUUNGSGUTSCHEIN": "Manueller Betreuungsgutschein",
    "STUNDENKONTINGENT": "Stundenguthaben",
    "STUNDENKONTINGENTE": "Stundenguthaben {{kitaname}}: ",
    "NEUE_STUNDENKONTINGENTE_ERFASSEN": "Neues Stundenguthaben",
    "NEUE_STUNDENKONTINGENTE_ERFASSEN_TITLE": "Neues Stundenguthaben erfassen",
    "STUNDENTKONTINGENTE_STUNDEN": "Anzahl Stunden",
    "KEIN_KONTINGENT": "Keine Kontingente vorhanden",
    "PLATZBESTAETIGUNG_DEAKTIVIEREN": "Automatische kiBon Platzbestätigung/Mutationsmeldung deaktivieren",

    "KITAX": {
      "KITAX_KIBON_VERFUEGUNG": "Ki-Tax / kiBon Verfügung",
      "MF_VERFUEGUNG": "{type, select, kiTax{Ki-Tax} kiBon{kiBon} other{Ki-Tax / kiBon}} Verfügung",
      "MF_VERFUEGUNG_VOM": "{type, select, kiTax{Ki-Tax} kiBon{kiBon} other{Ki-Tax / kiBon}} Verfügung vom {date}",
      "KITAX_VERFUEGUNG_IMPORT": "Ki-Tax / kiBon Verfügung importieren",
      "KITAX_IMPORT_UEBERSCHREIBT_BETREUUNGSGUTSCHEINE": "Es existieren bereits {{anzahl}} Betreuungsgutscheine zu diesen Daten. Diese werden womöglich überschrieben. Fortfahren?",
      "DATEN_UEBERPRUEFEN": "Daten überprüfen",
      "AUSZAHLUNG_AN_ELTERN": "Gutschein wird direkt von kiBon den Eltern ausbezahlt"
    },

    "LEISTUNGEN": {
      "LEISTUNGEN": "Leistungen",
      "KIND_PARAMETER_DISPLAY": "{{name}}: {{value}}",
      "KIND_TARIF_PARAMETER_HISTORY_ENTRY": "Parameterzuweisung",
      "KIND_TARIF_PARAMETER_HISTORY_ENTRY_TERMINATE_HEADING": "Parameterzuweisung beenden",
      "KIND_PARAMETER_BEENDEN": "Parameterzuweisung beenden",
      "CONFIRM_TARIFE_PARAMETER_BEENDEN": "Es gibt noch Parameter nach dem Enddatum. Wirklich beenden?",
      "LEISTUNGEN_TOTAL": "Total Leistungen",
      "LEISTUNGSRECHNUNG_NICHT_VORHANDEN": "Keine verrechenbaren Leistungen",
      "LEISTUNGSRECHNUNG_NICHT_VERRECHENBAR_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Die Leistung kann nicht verrechnet werden weil...",
      "LEISTUNGSRECHNUNG_VERRECHENBAR_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Die Leistung kann verrechnet werden.",
      "LEISTUNGSRECHNUNG_STORNIERT_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Es gibt stornierte Rechnungen für die Leistung.",
      "LEISTUNGSRECHNUNG_RECHNUNGSDIFFERENZ_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Die Leistung wurde verrechnet, hat sich aber inzwischen verändert.",
      "LEISTUNGSRECHNUNG_VERRECHNET_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Die Leistung wurde verrechnet.",
      "LEISTUNGSRECHNUNG_GELOESCHT_TOOLTIP": "{{kitaName ? kitaName + ': ' : ''}}Die Leistung wurde gelöscht.",
      "TARIFRECHNUNG_RESULT_DOWNLOAD_LABEL": "Berechnung herunterladen",
      "PLATZ_CONFIRMATION_DOWNLOAD_LABEL": "Platzbestätigung herunterladen"
    },

    "RECHNUNGEN_PERIODE": "Rechnungen {{periode}}",
    "IDENTIFIKATIONSNUMMER": "Identifikationsnummer",
    "GUTSCHRIFTEN_UND_GEBUEHREN": "Gutschriften und Gebühren",
    "FIX_POSITION_TYP_GEBUEHR": "Gebühr",
    "FIX_POSITION_TYP_GUTSCHRIFT": "Gutschrift",
    "FIX_POSITION_TYP_CORONA_REFUND": "Corona Gutschrift",
    "EINGEWOEHNUNG_POSITION": {
      "NAME": "Eingewöhnung",
      "ERFASSEN": "Eingewöhnung erfassen",
      "DELETE_TITLE": "Eingewöhnung vom {{gueltigAb}} bis {{gueltigBis}}",
      "ANZAHL_TAGE": "Anzahl Tage",
      "VON": "Eingewöhnung von",
      "BIS": "Eingewöhnung bis"
    },
    "GEBUEHR_ERFASSEN": "Gebühr erfassen",
    "GUTSCHRIFT_ERFASSEN": "Gutschrift erfassen",
    "SELECT_LEISTUNGSART": "Leistungsart auswählen",
    "FIX_POSITION_TEXT": "{{type}} vom {{datum}}",
    "LEISTUNGSART": {
      "LEISTUNGSART_BETREUUNG": "Betreuung",
      "LEISTUNGSART_FERIENBETREUUNG": "Ferienbetreuung",
      "LEISTUNGSART_MITGLIEDERBEITRAG": "Mitgliederbeitrag",
      "LEISTUNGSART_RABATT": "Rabatt",
      "LEISTUNGSART_SCHULKIND": "Schulkind",
      "LEISTUNGSART_SONSTIGES": "Sonstiges",
      "LEISTUNGSART_SUBVENTION": "Subvention",
      "LEISTUNGSART_VERPFLEGUNG": "Verpflegung",
      "LEISTUNGSART_AUSSERORDENTLICHER_BETREUUNGSAUFWAND": "Ausserordentlicher Betreuungsaufwand"
    },
    "KEINE_ANHAENGE": "Keine Anhänge",
    "KEINE_BETREUUNGSGUTSCHEINE": "Keine Betreuungsgutscheine",
    "KEINE_GESCHWISTER": "Keine Geschwister",
    "KEINE_KIND_TARIF_PARAMETER_HISTORY_ENTRIES": "Keine Parameter",
    "KEINE_STUNDENKONTINGENTE": "Keine Stundenguthaben",
    "KEINE_WEITEREN_KONTAKTE": "Keine weiteren Kontakte",

    "ZUWEISUNG": {
      "ZUWEISUNG_AB": "Zuweisung ab:",
      "ZUWEISEN_PROVISORISCH": "Provisorisch zuweisen",
      "ZUWEISEN_ANGEBOT_ERSTELLEN": "Angebot machen",
      "ZUWEISEN_DEFINITIV": "Definitiv zuweisen",
      "KINDERGARTEN": {
        "ONE": "Kindergarten I",
        "ONE_SHORT": "KG I",
        "TWO": "Kindergarten II",
        "TWO_SHORT": "KG II",
        "SCHULE": "Schule",
        "SCHULE_SHORT": "Schule",
        "INTERN": "intern",
        "EXTERN": "extern",
        "SCHULSTUFE": "Schulstufe / Klasse",
        "BETREUUNG_IN_FERIENZEIT_AUSWAEHLEN": "Betreuung in Ferienzeit auswählen",
        "BETREUUNG_IN_FERIENZEIT": {
          "KEINE_ANGABE": "Keine Angabe",
          "SCHULZEIT": "Schulzeit",
          "FERIENZEIT": "Ferienzeit"
        }
      },
      "OPTIONEN": "Optionen",
      "ZUWEISUNG_SUCCESS": "Das Kind wurde erfolgreich zugeteilt.",
      "ZUWEISUNG_DELETE_BETREUUNGSWUNSCH": "Von der Warteliste entfernen",
      "ZUWEISUNG_KEEP_BETREUUNGSWUNSCH": "Auf der Warteliste lassen",
      "ZUWEISUNG_MAX_PLAETZE_WARNING": "Diese Belegung überschreitet die maximal verfügbaren Plätze. Zuweisung abbrechen?",
      "ZUWEISUNG_CONFIRM_ADJUSTED_GUELTIGKEIT": "Diese Belegung wird per {{gueltigbis}} automatisch beendet.",
      "NEUE_BETREUUNG_FESTLEGEN": "Neue Betreuung festlegen",
      "ZUWEISUNGSDATUM": "Zuweisungsdatum",
      "KEIN_ZUWEISUNGSDATUM_AUSGEWAEHLT": "Kein Zuweisungsdatum ausgewählt",
      "ZUWEISUNG": "Zuweisung",
      "AB_DATEVALUE": "Ab {{date}}",
      "SPEZ_BETREUUNGSFAKTOR": "Spezifischer Betreuungsfaktor",
      "EINGEWOEHNUNG_PHASE": "Eingewöhnung",
      "VERTRAGLICHE_BEMERKUNG": "vertragliche Bemerkungen",
      "BEMERKUNG_IN_BETREUUNGSVEREINBARUNG": "Bemerkung in Betreuungsvereinbarung drucken",
      "NO_FLEXIBLE_PLAETZE": "Fixer Betreuungsplatz",
      "BELEGUNG_ZEIT_ADD": "Bring- & Abholzeiten",
      "BELEGUNG_ZEIT_FROM": "Anwesenheit von",
      "BELEGUNG_ZEIT_TO": "Anwesenheit bis",
      "BELEGUNG_ZEIT_MORE": "Bring- & Abholzeiten hinzufügen",
      "VERTRAGLICHE_STUNDEN": {
        "PRIVAT": "Vertragliche Stunden: Privat",
        "DISPLAY_PRIVAT": "Privat: {{value}}h",
        "SUBVENTIONERT": "Vertragliche Stunden: Subventioniert",
        "DISPLAY_SUBVENTIONIERT": "Subventioniert: {{value}}h",
        "FIRMA": "Vertragliche Stunden: {{name}}",
        "DISPLAY_FIRMA": "{{name}}: {{value}}h",
        "PERCENTAGE": {
          "TITLE": "Vertragliches Pensum",
          "PRIVAT": "Vertragliches Pensum: Privat",
          "DISPLAY_PRIVAT": "Privat: {{value}}%",
          "SUBVENTIONERT": "Vertragliches Pensum: Subventioniert",
          "DISPLAY_SUBVENTIONIERT": "Subventioniert: {{value}}%",
          "FIRMA": "Vertragliches Pensum: {{name}}",
          "DISPLAY_FIRMA": "{{name}}: {{value}}%"
        }
      }
    },

    "MONATSBELEGUNG": {
      "MONATSPLAN": "Monatsplan",
      "ANWESENHEIT_ERFASSEN": "Anwesenheit erfassen",
      "TAG": "Tag",
      "PLATZART": "Platzart",
      "VON": "von",
      "BIS": "bis",
      "ZEITEN_LEEREN": "Alle Zeiten leeren",
      "BELEGUNG_FUER": "Monatsbelegung für {{name}}",
      "PRINT": "Drucken",
      "AVAILABLE_ANWESENHEITSZEITEN": "Einsehbare Anwesenheitszeiten",
      "COMPLETE_UNTIL": "Eintragen bis: {{date}}",
      "DEADLINE_EXPIRED": "<strong>Die Frist ist abgelaufen.</strong> Der Monatsplan ist noch einsehbar bis {{date}}",
      "RELEASE_FOR_MAIN_CONTACT": "Für Hauptkontakt freigeben",
      "RELEASE_HEADER_MF": "Anwesenheitszeiten {count, plural, one{von {name}} other{}} freigeben?",
      "READ_UNTIL": "Zum Lesen freigegeben bis <strong>{{date}}</date>",
      "WRITE_UNTIL": "Zum Bearbeiten freigegeben bis <strong>{{date}}</date>",
      "RESEND": "Erneut senden",
      "RESEND_HEADER_MF": "{count, plural, one{E-Mail} other{E-Mails}} erneut senden {count, plural, one{für {name}} other{}}",
      "ADJUST_DEADLINE": "Frist anpassen",
      "ADJUST_DEADLINE_HEADER_MF": "{count, plural, one{Fristanpassung für {name}} other{Fristanpassungen}}",
      "AFFECTED_MONTH": "Monat: {{month}}",
      "PREVIOUS_DEADLINE": "Bisherige Frist: {{date}}",
      "WITHDRAW": "Aufheben",
      "WITHDRAW_HEADER": "Freigabe aufheben",
      "WITHDRAW_WRITE": "Schreibberechtigung aufheben",
      "WITHDRAW_ALL": "Gesamte Freigabe für Monat {{month}} aufheben",
      "ENTER_NEW_DEADLINE": "Neue Frist eingeben",
      "ENTER_DEADLINE": "Frist eingeben",
      "CONFIRM_HEADER": "E-Mail Bestätigung senden?",
      "CONFIRM_WITHOUT_EMAIL": "Zuweisen ohne Bestätigungsmail",
      "ERROR_INVALID_TIMES": "Die Zeiten vom {{date}} {{name}} sind ungültig",
      "MONATSBELEGUNG_PROVISORISCH_SAVED": "Die provisorische Monatbelegung wurde erfolgreich gespeichert.",
      "ANWESENHEITS_ZEIT": "Anwesenheits Zeit",
      "SETTINGS": "Anwesenheitszeit Einstellungen",
      "MAX_DAILY_HOURS": "Maximal Tägliche Stunden: {{stunden}}",
      "MAX_DAILY_HOURS_WARNING": "Die maximal empfohlenen täglichen Stunden von {{stunden}} wurden überschritten.",
      "ALLOWED_ARRIVAL_TIMES": "Erlaubte Bringzeiten",
      "ALLOWED_PICK_UP_TIMES": "Erlaubte Abholzeiten",
      "ALLOWED_TIMES_PLACEHOLDER": "hh:mm-hh:mm, ...",
      "ALLOWED_TIMES": "Erlaubte Zeiten sind:\n{{constraints}}",
      "PARENT_DEFAULT_VALUES": "Standard für Eltern",
      "PARENT_DEFAULT_VALUES_VERTRAGLICHES_PENSUM": "Standard Vertragliches Pensum",
      "TAGESBASIERT": "Tagesbasiert"
    },
    "EXTRA_DAY": {
      "TITLE": "Abweichungen",
      "EDIT_MF": "Abweichungen {count, plural, =0{erfassen} other{bearbeiten}}",
      "CREATED_DAYS": "Erfasste Abweichungen",
      "CURRENT": "Aktuelle Abweichungen seit {{von}}",
      "FUTURE": "Abweichungen ab {{von}}",
      "WITH_RANGE": "Abweichungen {{von}} - {{bis}}",
      "NO_EXTRA_DAYS": "Keine Abweichungen erfasst",
      "NO_PERMISSION_FOR_KINDER_ORT": "Sie sind nicht berechtigt zum Erfassen von Abweichungen für den aktuellen Kinderort.",
      "CATEGORY": {
        "TYP_AUSWAEHLEN": "Abweichungstyp auswählen",
        "TYPES": {
          "STANDARD": "Standard",
          "KOMPENSATION": "Kompensation",
          "KRANKHEIT": "Krankheit",
          "AUSFALL": "Ausfall",
          "FERIEN": "Ferien",
          "EINGEWOEHNUNG": "Eingewöhnung",
          "UNBEKANNT": "Unbekannt"
        }
      },
      "TYPE": {
        "TITLE": "Art",
        "ABSENCE": "Abwesenheit",
        "ADDITIONAL": "Zusatztag"
      }
    },

    "ANWESENHEITS_SOLL": {
      "TITLE": "Anwesenheitssoll",
      "NONE": "Kein Anwesenheitssoll vorhanden",
      "NEUE_ZUWEISUNG": "Anwesenheitssoll erfassen",
      "ZUWEISEN": "Soll zuweisen",
      "ZUWEISUNG": "Anwesenheitssoll",
      "ZUWEISUNG_BEENDEN": "Soll beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Anwesenheitssoll beenden",
      "TAGE": "Tage",
      "HALBTAGE": "Halbtage",
      "FRUEHBETREUUNGEN": "Frühbetreuungen",
      "MITTAGE": "Mittagsbetreuungen",
      "NACHMITTAGE": "Halbe Nachmittage",
      "SUMMARY_MF": "{tage, plural, =0{} one{# Tag} other{# Tage}} {halbtage, plural, =0{} one{# Halbtag} other{# Halbtage}} {frueh, plural, =0{} one{# Frühbetreuung} other{# Frühbetreuungen}} {mittage, plural, =0{} one{# Mittag} other{# Mittage}} {nachmittage, plural, =0{} one{# Halbe Nachmittage} other{# Halbe Nachmittage}}",
      "PERIOD": "Periode {{von}} - {{bis}}",
      "VERBRAUCH": "{{ist}}/{{soll}}",
      "WARNING": "VORSICHT: Der Verbrauch berücksichtigt nur die bereits verrechenbaren Tage."
    }
  },
  "DASHBOARD": {
    "TITLE": "Dashboard",
    "GOOD_MORNING": "Guten Morgen {{vorname}}!",
    "GOOD_AFTERNOON": "Guten Nachmittag {{vorname}}!",
    "GOOD_EVENING": "Guten Abend {{vorname}}!",
    "FAVORIT_SUCHEN": "Favorit suchen...",
    "FAVORIT_HINZUFUEGEN": "Favorit hinzufügen",
    "AUFGABENLISTE": "Aufgabenliste",
    "MEHR_AUFGABEN_ANZEIGEN": "Mehr Aufgaben anzeigen",
    "KEINE_AUFGABEN_VORHANDEN": "Keine offenen Aufgaben zu den gesetzten Favoriten vorhanden",
    "KEINE_AUFGABEN_OHNE_FAVORITEN": "Ohne Favoriten gibt es keine Aufgaben",
    "KEINE_FAVORITEN": "Fügen Sie Favoriten hinzu!",
    "ORT_HINZUFUEGEN": "Betreuungsort Filter",
    "TASK_FILTER_TITLE": "Aufgabentyp Filter",
    "TASK_FILTER": {
      "BELEGUNG": "Belegung",
      "BRING_ABHOLZEITEN": "Bring- und Abholzeiten",
      "CUSTOM_FIELD": "Zusatzfelder",
      "KIBON": "Kibon",
      "LEISTUNGSRECHNUNG": "Leistungsrechnung",
      "GROUP_ADMIN_KONTROLLE": "groupAdmin Kontrolle",
      "EXTERNAL_ANMELDUNG_KONTROLLE": "externe Anmeldung Kontrolle"
    }
  },
  "FRAKTION": {
    "NAV": {
      "PROFIL": "Profil",
      "MONATSBLATT": "Monatsblatt",
      "BETREUUNGS_SCHLUESSEL": "Betreuungsschlüssel"
    },
    "KONFIGURATION": "Konfiguration",
    "GRUPPE": {
      "LOESCHEN": "Gruppe löschen",
      "BEENDEN": "Gruppe beenden"
    },
    "BETREUUNGS_PERSON": {
      "LOESCHEN": "Betreuungsperson löschen",
      "BEENDEN": "Betreuungsperson beenden",
      "KINDER_MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{Keine Kinder in diesem Monat} one{Betreutes Kind} other{Betreute Kinder}}"
    },
    "PLAETZE_NEU_FESTLEGEN": "Neue Plätze festlegen",
    "ANZAHL_MAX_PLAETZE": "Anzahl max. Plätze",
    "MAXIMALE_PLAETZE_FESTLEGEN": "Maximale Plätze festlegen",
    "NAME": "Name",
    "KAPAZITAET": "Kapazität",
    "BEENDEN_HEADING": "{{name}} wird beendet",

    "MONATSBLATT": {
      "RECHNUNGSMONAT": "Rechnungsmonat",
      "KEINE_EINTRAEGE": "Keine Einträge vorhanden",
      "LEISTUNGSRECHNUNG_AKTUALISIEREN": "Leistungsrechnung aktualsieren",
      "STUNDEN": "Stunden",
      "STUNDEN_ANZAHL": "{{anzahl}} Stunden",
      "PERIODE": "Periode {{von}} - {{bis}}",
      "VERBUCHTE_STUNDEN_ANZAHL": "{{verbucht}} / {{gesamt}}",
      "STUNDEN_VERFUEGBAR": "Stunden verfügbar",
      "ZUSAETZLICHE_BELEGUNG": "Zusätzlich: ",
      "CREATE_ZUSAETZLICHE_BELEGUNG": "Zusätzliche private Belegung erfassen",
      "DELETE_ZUSAETZLICHE_BELEGUNG": "Zusätzliche private Belegung löschen",
      "CONFIRM_DELETE_ZUSAETZLICHE_BELEGUNG": "Wollen Sie die zusätzlichen privaten Belegungen",

      "API": {
        "SEND": "An die Lohnschnittstelle senden",
        "SEND_TITLE": "{{monthYear}} an die Lohnschnittstelle senden?",
        "SEND_INFO_KINDERORT": "Dadurch werden die erfassten Stunden und Spesen aller Betreuungspersonen von {{name}} an die Lohnschnittstelle gesandt - auch bereits versendete.",
        "SEND_INFO_BETREUUNGSPERSON": "Dadurch werden die erfassten Stunden und Spesen von {{name}} an die Lohnschnittstelle gesandt.",
        "VERAENDERT_INFO_MF": "{status, select, VERAENDERT{Wurde an die Lohnschnittstelle versandt, aber seither in kitAdmin verändert.} FAILED{Bei der Datenübermittlung ist ein technisches Problem aufgetreten} other{}}",
        "STATUS": {
          "VERSANDT": "Versandt",
          "VERAENDERT": "Verändert",
          "FAILED": "Übermittlungsfehler"
        }
      }
    },

    "LEISTUNGS_TYP": {
      "PRIVAT": "Private Belegung",
      "SUBVENTIONIERTES_KONTINGENT": "Subventionierte Belegung",
      "KONTINGENT": "Kontingent Belegung",
      "BETREUUNGS_GUTSCHEIN": "Betreuungsgutschein Belegung",
      "BETREUUNGS_UNABHAENGIG": "Betreuungsunabhängige Belegung"
    },
    "BETREUUNGS_SCHLUESSEL": {
      "ZUWEISUNG": "Betreuungsschlüssel-Zuweisung",
      "ZUWEISEN": "Betreuungsschlüssel zuweisen",
      "ZUWEISUNG_BEENDEN": "Betreuungsschlüssel beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Betreuungsschlüssel-Zuweisung für {{assignedEntity}} beenden?",
      "CONFIRM_BEENDEN": "Es gibt noch zugewiesene Betreuungsschlüssel nach dem {{date}}. Wie soll damit umgegangen werden?",
      "NEUE_ZUWEISUNG": "Neuen Betreuungsschlüssel zuweisen",
      "ZUGEWIESENE": "Zugewiesene Betreuungsschlüssel",
      "KEINE_ZUWEISUNG": "Keine zugewiesenen Betreuungsschlüssel",
      "AUSWAEHLEN": "Betreuungsschlüssel auswählen",
      "NONE_FOR_FRAKTION": "Es gibt keine Betreuungsschlüssel für den Wochenplan dieser Gruppe.",
      "TERMINATION_MODE": {
        "TERMINATE": "Betreuungsschlüssel am {{date}} beenden. Künftige Betreuungsschlüssel bleiben erhalten.",
        "DELETE_TILL_NEXT_GAP": "Den Betreuungsschlüssel am {{date}} beenden und direkt anschliessende Betreuungsschlüssel löschen.",
        "DELETE_FOLLOWING": "Den Betreuungsschlüssel am {{date}} beenden und alle künftigen Betreuungsschlüssel löschen."
      }
    }
  },
  "BETREUUNGS_PERSON": {
    "STAMMDATEN": "Stammdaten",
    "WOHNADRESSE": "Wohnadresse",
    "KORRESPONDENZSPRACHE": "Korrespondenzsprache"
  },
  "FIRMA": {
    "TITLE_TARIF": "Tarif {{tarifname}}",
    "ANZAHL_PLAETZE": "Anzahl Plätze",
    "NEUES_KONTINGENT": "Neues Kontingent erstellen",
    "KONTINGENT_NEUE_PLAETZE": "Neue Plätze",
    "KONTINGENT_PLAETZE": "Plätze",
    "KONTINGENT_DEFINIEREN": "Kontingent definieren",
    "BEZEICHNUNG_KONTINGENT": "Bezeichnung Kontingent",
    "FIRMA_LOESCHEN": "Platzart löschen",
    "FIRMA_DELETE": "Platzart löschen",
    "KONTINGENT_BEENDEN": "Kontingent beenden",
    "FIRMEN_KONTINGENT_BEENDEN_HEADING": "Plätze für das Kontingent {{name}} werden beendet.",
    "CONFIRM_FIRMEN_KONTINGENT_BEENDEN": "Es gibt noch plätze nach dem Enddatum. Wirklich beenden?",
    "KEINE_KONTINGENTE": "Keine Kontingente"
  },
  "CUSTOM_FIELD": {
    "BEZEICHNUNG": "Bezeichnung",
    "TYP_AUSWAEHLEN": "Feldtyp auswählen",
    "HINZUFUEGEN": "Feld hinzufügen",
    "SORTIEREN": "Felder sortieren",
    "MANDATORY": "Pflichtfeld",
    "SELECT_VALUES": "Auswahl werte",
    "TYPE": {
      "BOOLEAN": "Ein / Aus",
      "STRING": "Text",
      "SELECT": "Auswahl"
    }
  },
  "SCHULUNG": {
    "SCHULUNG_ABSOLVIEREN": "Schulung absolvieren",
    "SCHULUNG_AUSWAEHLEN": "Schulung auswählen",
    "SCHULUNG": "Schulung",
    "INHALT": "Inhalt",
    "ZIEL": "Ziel",
    "ABLAUF": "Ablauf",
    "BESCHREIBUNG": "Pro Schulung wird ein Schulungsmandant mit Demodaten erstellt.",
    "BENUTZERNAME_VALUE": "Benutzername: <strong>{{value}}</strong>",
    "PASSWORT_VALUE": "Passwort: <strong>{{value}}</strong>",
    "SCHULUNGSBENUTZER_CREATED_AND_DATA_INITIALIZED": "Ihr Benutzer wurde erstellt und mit den entsprechenden Daten initialisiert.\nViel Spass bei der Schulung!",
    "UNTERLAGEN_ANSEHEN": "Unterlagen ansehen",
    "SCHULUNG_DURCHFUEHREN": "Schulung durchführen",
    "SCHULUNG_ERSTELLEN": "Schulung erstellen",
    "WIE_FUNKTIONIERTS": "Wie funktioniert's?",

    "EINTRITT_BIS_AUSTRITT": {
      "TITLE": "Von der Anmeldung bis zum Austritt",
      "SUBJECT": "Stammdaten werden erfasst und verwaltet anhand von Anmeldungen, Umzügen, Belegungsänderungen und Austritten. Die Auslastung wird anhand der Belegung ersichtlich und Benutzerrechte werden durchgespielt.",
      "GOAL": "Ziel dieser Schulung ist, dass der Benutzer die Grundlagen von kitAdmin kennt. Es ist ihm möglich die entsprechenden Daten schnell zu finden und zwischen diesen Daten zu navigieren. Der Benutzer kann die Kinder verwalten und die Kinderorte administrieren.",
      "STEPS": {
        "NEUER_BENUTZER_ANLEGEN": "Neuen Benutzer erfassen und Benutzerrechte vergeben",
        "ABLOESUNG_MIGRATION": "Ablösen alter Excellisten (Migration)",
        "ARBEITEN_MIT_SUCHE": "Arbeiten mit der Suche",
        "FAVORITEN_AUFGABENLISTE": "Favoriten und Aufgabenliste",
        "KIND_PROFIL_ANSEHEN": "Kind ansehen / verwalten",
        "KITA_PROFIL_ANSEHEN": "Kinderort ansehen / verwalten",
        "ARBEITEN_MIT_BELEGUNGSLISTE": "Arbeiten mit der Warteliste / Belegungsliste",
        "KINDER_ZUWEISEN": "Kinder zuweisen / Zuweisungen ändern",
        "BERECHTIGUNGEN_BETREUER_VERWALTER": "Berechtigungen als Betreuer / Verwalter"
      }
    },
    "MODELLIERUNG_KITA": {
      "TITLE": "Modellierung Kinderort",
      "SUBJECT": "Komplette Eröffnung eines Kinderortes. Bewilligte Plätze, Kontingente und Betreuungsfaktoren werden hinterlegt. Einstellungen zur Vereinbarungskonfiguration werden getätigt.",
      "GOAL": "Am Ende der Schulung kann der Benutzer neue Kinderorte, Gruppen und Firmen(kontingente) selbststätig über kitAdmin erstellen und verwalten.",
      "STEPS": {
        "KITA_EROEFFNEN": "Kinderort eröffnen",
        "STAMMDATEN_BEARBEITEN": "Stammdaten bearbeiten",
        "GRUPPE_ERSTELLEN_ANPASSEN": "Gruppe erstellen und anpassen",
        "PLATZART_ERSTELLEN": "Platzart erstellen",
        "KITA_KONFIGURIEREN": "Kinderort konfigurieren",
        "BETREUUNGSFAKTOREN_ERSTELLEN": "Betreuungsfaktoren erstellen",
        "VEREINBARUNGSKONFIG_LAYOUT_ANPASSEN": "Vereinbarungskonfiguration und Dokumentenlayout anpassen"
      }
    },
    "TARIFE_LEISTUNGSRECHNUNG": {
      "TITLE": "Tarife / Leistungsrechnung",
      "SUBJECT": "Tarife werden anhand von Vorlagen erstellt und bearbeitet. Die Tarife werden einem Kinderort oder einer Firma zugewiesen. Die Leistungsberechnung der Kinder wird mit einem Parameter und einem Betreuungsgutschein erweitert. Aufgabentasks werden erledigt.",
      "GOAL": "Der Benutzer weiss welche Komponenten benötigt werden, damit eine Leistungsberechnung durchgeführt werden kann. Der Benutzer kann nötige Parametereinstellungen beim Kind vornehmen oder die Angaben mit einem Betreuungsgutschein erweitern. Aufgabentasks können selbstständig erledigt werden.",
      "STEPS": {
        "TARIF_ERSTELLEN": "Tarif erstellen",
        "TARIF_BEARBEITEN": "Tarif bearbeiten",
        "TARIF_KITA_ZUWEISEN": "Tarif einem Kinderort zuweisen",
        "TARIF_FIRMA_ZUWEISEN": "Tarif einer Firma zuweisen",
        "KIND_PARAMETER_SETZEN": "Kind mit Parameter erweitern",
        "KIND_BETREUUNGS_GUTSCHEIN_SETZEN": "Kind mit Betreuungsgutschein erweitern",
        "FAVORIT_ERSTELLEN": "Favoriten erstellen",
        "AUFGABEN_ERLEDIGEN": "Aufgaben erledigen"
      }
    },
    "FAKTURA": {
      "TITLE": "Faktura",
      "SUBJECT": "Einstellungen zur Rechnungskonfiguration werden getätigt. Rechnungsläufe werden erstellt und via E-Mail / Post versendet. Rechnungskorrekturen werden vorgenommen. Vorauszahlungen und Rückerstattung bei überbezahlten Rechnungen werden getätigt. Mahnläufe werden erstellt und durchgeführt.",
      "GOAL": "Der Benutzer versteht, wie der Mandant und die Kinderorte konfiguriert werden müssen, damit fakturiert werden kann. Der Benutzer kennt die Faktura Prozesse und Reihenfolge. Rechnungs- und Mahnuläufe können selbständig vorgenommen werden. Korrekturen und Vorauszahlungen können korrekt verbucht werden.",
      "STEPS": {
        "EINRICHTUNG_DVB_FAKTURA": "Einrichtung DVB Faktura",
        "RECHNUNGSKONFIGURATION_EINER_KITA_ZUWEISEN": "Rechnungskonfiguration einem Kinderort zuweisen",
        "RECHNUNGSLAUF_ERSTELLEN": "Rechnungslauf erstellen",
        "RECHNUNGSKORREKTUR_ERSTELLEN": "Rechnungskorrektur erstellen",
        "RECHNUNG_STORNIEREN": "Rechnung stornieren",
        "RECHNUNG_BEZAHLEN": "Rechnung bezahlen",
        "RECHNUNG_MAHNEN": "Rechnung mahnen",
        "OFFENE_POSTEN_VORAUSZAHLUNGEN_UND_RUECKERSTATTUNGEN": "Offene Posten (Vorauszahlungen und Rückerstattungen)",
        "UEBERSCHUESSIG_BEZAHLTE_RECHNUNGEN": "Überschüssig bezahlte Rechnungen",
        "DER_KONTENABGLEICH": "Der Kontenabgleich"
      }
    },
    "PERSONAL": {
      "TITLE": "Personal",
      "SUBJECT": "Verwalten von Betreuungsschlüsseln und Anstellungen. Zuweisen von Personal basierend auf dem belegungsbedingten Bedarf.",
      "GOAL": "Der Benutzer versteht, wie Personal erfasst und optimal zugewiesen werden kann."
    },
    "SUPPORT": {
      "TITLE": "Support",
      "SUBJECT": "Durchführen von gängigen Support Fällen",
      "GOAL": "Der Benutzer kann gängige Supportfälle lösen."
    }
  },
  "ORGANISATION": {
    "ORGANISATION": "Organisation"
  },
  "VOREINSTELLUNGEN": {
    "VOREINSTELLUNGEN": "Voreinstellungen",
    "PARAMETER_SORTIERUNG": "Parameter-Sortierung",
    "ARCHIVIERTE_TARIFE": "Archivierte Tarife anzeigen"
  },
  "MANDANT": {
    "DISABLED": "Deaktiviert",
    "READ_ONLY": "Nur lesbar",
    "ACTIVE": "Aktiv",
    "ABGERECHNET_BIS": "Abgerechnet bis",
    "ABRECHNUNG_VORAUS_ZUSATZ_MONATE": "Monate der Abrechnung im Voraus",
    "ABRECHNUNG_VORAUS_ZUSATZ_MONATE_VALUE": "Abrechnung im Voraus: {{monate}} Monate",
    "MANDANT_MIT_DEMODATEN": "Mandant mit Demodaten initialisieren",
    "MIT_STANDARD_WOCHENPLAN": "Mit Standard-Wochenplan",
    "WARNING_INTERVAL_IN_DAYS": "Platz-Verfügbarkeit Warnung: {{tage}} Tage",
    "FAKTURA": "Faktura",
    "MODULES": "Module",
    "NO_MODULES": "Keine",
    "RECHNUNGS_KONFIGURATION": "Rechnungskonfiguration",
    "CONFIRM_DEACTIVATE_RECHNUNGS_KONFIGURATION": "Wenn Sie die Rechnungskonfiguration deaktivieren, erhalten alle betroffenen Rechnungen, die nicht storniert sind, den Status Bezahlt.",
    "API_TOKEN": "API-Token",
    "API_TOKEN_LEER_LASSEN": "API-Token (leer lassen um den Token nicht zu ändern)",
    "KONFIGURATION": "Konfiguration",
    "KEINE_RECHNUNGS_KONFIGURATIONEN": "Keine Rechnungskonfigurationen",
    "AUTOMATIC_PAYMENTS": "Automatische Zahlungen",
    "EINZAHLUNGSSCHEIN_ALL_PAGES": "Auf jeder Seite einen Einzahlungsschein hinzufügen",
    "INDIVIDUAL_FILES": "pro Rechnungsempfänger 1 PDF",
    "RECHNUNGSLAUF_LOCKED_BEFORE": "Rechnungssperre bis",
    "KORRESPONDENZ_ADRESSE": "Korrespondenz Adresse",
    "LANGUAGES": "Sprachen",
    "DEFAULT_LANGUAGE": "Standard Sprache",
    "SUPPORTED_LANGUAGE": "Unterstützte Sprache",
    "FR": "Französisch",
    "DE": "Deutsch",

    "MODULE": {
      "BASIC": "Basis",
      "FAKTURA": "Faktura",
      "ANWESENHEIT": "Flexible Plätze",
      "TAGESELTERN": "Tageseltern",
      "DYNAMISCHER_BELEGUNGSPLAN": "Dynamischer Belegungsplan",
      "KOMMUNIKATION": "Kommunikation",
      "BSV": "BSV",
      "GROUP_ADMIN": "Group Admin",
      "PERSONALPLANUNG": "Personalplanung",
      "WORK_TIME_CONTROLLING": "Arbeitszeit Controlling"
    },

    "SUBSCRIPTION_MODEL": {
      "LIGHT": "Light",
      "LIGHT_ERKLAERT": "(50% Rabatt)",
      "PREMIUM": "Premium",
      "PREMIUM_ERKLAERT": "(inklusive Support, Beratung, Schulung)",
      "FREE": "Free"
    },

    "PREISE": {
      "KITA": "CHF pro Kita",
      "BETREUUNGS_PERSON": "CHF pro Betreuungsperson"
    },
    "CONFIG": {
      "TITLE": "Standards"
    },
    "RECHNUNG_CONFIG": {
      "TITLE": "Abrechnungs Konfiguration",
      "POSITIONS_TITLE": "Spezifische Korrekturen",
      "VERRECHNUNG_AB": "Verrechnen ab",
      "ANZAHL_RABATT": "{{count}} Gratis Kinderorte",
      "KINDERORT_RABATT_PCT": "{{count}}% Rabatt",
      "PAUSCHAL_PREIS": "Pauschalpreis CHF {{count}}",
      "PAUSCHAL_PREIS_BIS": "Pauschalpreis Enddatum"
    }
  },
  "REPORT": {
    "TITLE": "Reports",
    "REPORT_AUSWAEHLEN": "Report auswählen",
    "PURPOSE": "Wofür wird der Report gebraucht?",
    "STRUCTURE": "Wie ist der Report aufgebaut?",

    "GESPEICHERTE": "Gespeicherte Reports",
    "GESPEICHERTE_AKTUALISIEREN": "Liste Aktualisieren",

    "ALLE_PLAETZE": "Alle Plätze",
    "STICHTAG": "Stichtag",
    "STICHTAGBELEGUNG": "Stichtag Belegung",
    "STICHTAGADRESSE": "Stichtag Adresse",
    "ALLE_KONTAKTE": "Alle Kontakte",

    "MIT_BELEGUNG": "Mit Belegung",
    "AUF_WARTELISTE": "Auf Warteliste",

    "TEMPLATE": {
      "ERSTELLTE_KONFIGURATIONEN": "Erstellte Templatekonfigurationen",
      "KONFIGURATION": "Templatekonfiguration",
      "DEFAULT_HERUNTERLADEN": "Standardtemplate herunterladen",
      "HOCHLADEN": "Template hochladen",
      "HERUNTERLADEN": "Herunterladen",
      "LOESCHEN": "Löschen",
      "TEMPLATE_VALUE": "Individuelles Template {{name}}",
      "TEMPLATE": "Individuelles Template",
      "KEINE": "Keine individuellen Templates für diesen Report erstellt.",
      "ERR_INCOMPLETE": "Das Template ist noch nicht vollständig ausgefüllt.",
      "DEFAULT": "Standard Template",
      "OUTDATED": "Es ist eine neue Templateversion verfügbar.\nBitte aktualisieren sie die Templateversion:\nAktuelle Version: {{current}} \nNeue Version: {{new}}"
    },

    "CUSTOM_FIELD_CONFIGURATION": {
      "TITLE": "Zusatzfeldkonfiguration",
      "KIND": "Alle Kinderzusätze",
      "BELEGUNG": "Alle Belegungszusätze",
      "ERSTELLT": "Erstellte Zusatzfeldkonfigurationen",
      "KEINE": "Keine Zusatzfeldkonfigurationen erstellt.",
      "KONFIGURATION_NAME": "Zusatzfeldkonfiguration {{name}}",
      "LOESCHEN": "Zusatzfeldkonfiguration löschen",
      "DEFAULT": "Alle Zusatzfelder"
    },

    "BETRIEB": {
      "TITLE": "Betrieb"
    },
    "ADRESSLISTE_KINDER_KONTAKTE": {
      "TITLE": "Adressliste",
      "PURPOSE": "<ul><li>Serienbriefe pro Haushalt</li><li>Adress-Anschriften (Etiketten, Briefe, usw.)</li><li>Anzahl Kinder / Geschwister pro Haushalt erkennen</li></ul>",
      "STRUCTURE": "<p>Pro Adresse wird eine Zeile erzeugt. Dabei werden pro Adresse alle Kontaktpersonen und Kinder aufgezeigt, die dort wohnen.</p>"
    },
    "AKTUELLE_AENDERUNGEN": {
      "TITLE": "Aktuelle Änderungen",
      "PURPOSE": "Information an Betreuer über fixe oder geplante Belegungsänderungen in des Kinderortes.",
      "STRUCTURE": "<p>Änderungen sind zeitlich geordnet. Die Änderungen werden unterschieden nach Betreuungsfaktoränderung, Eintritt, Mutation und Austritt. Zudem wird angegeben um wie viel sich das Pensum reduziert oder erhöht. Die betreffenden Gruppen sind mit einem X gekennzeichnet.</p>"
    },
    "BELEGUNGSPLAN": {
      "TITLE": "Belegungsplan",
      "PURPOSE": "<ul><li>Information an Betreuerinnen und Betreuer über Kinderortbelegung</li><li>Anwesenheitskontrolle (Tipp: Tatsächliche Belegung kann direkt im Report angegeben werden)</li></ul>",
      "STRUCTURE": "<p>Im Report wird für jede Kalenderwoche im ausgewählten Zeitraum ein einzelnes Tabellenblatt erstellt. Die Kinder werden in den jeweiligen Gruppen aufgelistet. Deren Belegung ist grau markiert. Die Platzart ist nicht ersichtlich.</p>",
      "INCLUDE_EXTRA_PLAETZE": "Zusatztage und Abwesenheiten berücksichtigen"
    },
    "BELEGUNGSPLAN_ANWESENHEITSZEITEN": {
      "TITLE": "Belegungsplan mit Anwesenheitszeiten",
      "PURPOSE": "Information an Betreuerinnen und Betreuer über Kinderortbelegung und Anwesenheitszeiten.",
      "STRUCTURE": "<p>Im Report wird für jede Kalenderwoche im ausgewählten Zeitraum ein einzelnes Tabellenblatt erstellt. Die Kinder werden in den jeweiligen Gruppen aufgelistet. Die Anwesenheitszeit wird pro Kind für jeden Tag eingetragen.</p>"
    },
    "BELEGUNGSPLAN_MONTHLY": {
      "TITLE": "Belegungsplan mit Monatsansicht",
      "PURPOSE": "Information an Betreuerinnen und Betreuer über Kinderortbelegung und Belegungänderungen.",
      "STRUCTURE": "<p>Im Report wird für eine Kalenderwoche im ausgewählten Monat ein einzelnes Tabellenblatt erstellt. Die Kinder werden in den jeweiligen Gruppen aufgelistet. Belegungsänderungen wie Eintritt, Austritt und Mutation im jeweiligen Monat werden aufgelistet.</p>"
    },
    "BELEGUNGSPLAN_DAY_BASED": {
      "TITLE": "Tagesbasierter Belegungsplan",
      "PURPOSE": "Information an Betreuerinnen und Betreuer über Kinderortbelegung.",
      "STRUCTURE": "<p>Im Report wird für jeden Tag einer Kalenderwoche ein einzelnes Tabellenblatt erstellt. Die Kinder werden in den jeweiligen Gruppen aufgelistet.</p>",
      "WEEK_OF": "Woche vom"
    },
    "GEBURTSTAGSLISTE_KINDER": {
      "TITLE": "Geburtstagsliste",
      "PURPOSE": "Information an die Betreuerinnen und Betreuer über alle Kindergeburtstage im Jahr.",
      "STRUCTURE": "<p>Die Geburtstage aller Kinder in dem Kinderort werden pro Monat dargestellt. Nebst dem Geburtsdatum berechnet der Report auch das zukünftige Alter des Kindes.</p>"
    },
    "TELEFON_EMAIL_ADRESSLISTE": {
      "TITLE": "Telefon-, E-Mail- und Adressliste",
      "PURPOSE": "<ul><li>Telefonliste im Kinderort</li><li>Massen-E-Mail senden an Kontaktpersonen</li><li>Kontaktpersonen mit Adresse anschreiben</li></ul>",
      "STRUCTURE": "<p>Der Report beinhaltet pro Kind und Kontaktperson Telefonnummern, E-Mail-Adresse und Wohnadresse der Kontaktperson. Jede Kommunikationsart wird in einem separaten Tabellenblatt geführt. Das Tabellenblatt \"Gesamt\" listet alles auf.</p>"
    },
    "KINDERGARTENKINDER": {
      "TITLE": "Kindergarten Kinder",
      "PURPOSE": "Report über Kindergartenkinder für Stadt Bern",
      "STRUCTURE": "<p>Der Report listet pro Zeile ein Kindergartenkind mit der entsprechenden Wohnadresse des Kindes auf. Zusätzlich ist deklariert, ob die Belegungsoptionen KG 1 oder KG 2. und die Angabe intern oder extern angegeben sind.</p>"
    },
    "FRUEH_SPAET_DIENST": {
      "TITLE": "Früh- und Spätdienst",
      "PURPOSE": "Report zur Erleichterung der Personaleinteilung mit Kinder-Anwesenheitszeiten",
      "STRUCTURE": "<p>Der Report listet in der linken Spalte die Zeit auf, in der die Kinder ankommen, und in der rechten Spalte zu welcher Zeit die Kinder wieder abgeholt werden. Die Einträge werden sortiert aufgelistet und nach Halbstundenintervallen getrennt. Kinder mit Betreuungsfaktor grösser als 1 werden fett markiert, Kinder mit niedrigerem Betreuungsfaktor als 1 werden kursiv markiert. Unten werden für alle Zeiträume Zeilen generiert, in welchen das Personal zugeteilt werden kann.</p>",
      "FRUEH_BIS": "Frühdienst-Bis-Zeit",
      "SPAET_VON": "Spätdienst-Von-Zeit"
    },
    "ABWEICHUNGEN_KINDER": {
      "TITLE": "Abweichungen",
      "PURPOSE": "Übersicht über die Belegungs-Abweichungen, Tauschtage eines Kindes.",
      "STRUCTURE": "Zusatztage und Abwesenheiten werden pro Kind und Gruppe/Betreuungsperson aufgelistet."
    },
    "CONTROLLING": {
      "TITLE": "Controlling",
      "PURPOSE": "<ul><li>Kontrolle über die Auslastung des Kinderortes</li><li>Überprüfung der Kontingentauslastung</li><li>Export der Daten, die in dem Kinderort im Tab Übersicht als Graphik dargestellt werden</li></ul>",
      "STRUCTURE": "<p>Jede Änderung wird zeitlich geordnet. Die Änderungen werden nach Betreuungsfaktoränderung, Betreuungsänderung (Eintritt, Mutation und Austritt) und Korrekturen in der Folgewoche unterschieden. Zudem zeigt die Liste einen Übertrag aus dem letzten Jahr und einen Jahresabschluss. Pro Woche werden die Auslastung des Kinderortes, das subventionierte Kontingent und die Kontingente berechnet. Für die Kontingente wird ein Jahresschnitt berechnet.</p>"
    },
    "CONTROLLING_MONTHLY": {
      "TITLE": "Monatliches Controlling",
      "PURPOSE": "<ul><li>Kontrolle über die monatliche Auslastung des Kinderortes</li><li>Export der Daten, die in dem Kinderort im Tab Übersicht als Graphik dargestellt werden</li></ul>",
      "STRUCTURE": "<p>Pro Kinderort werden in einem eigenen Sheet die monatlichen Controlling Daten pro Fraktion aufgelistet.</p>"
    },
    "STUNDENBASIERTES_CONTROLLING": {
      "TITLE": "Stundenbasiertes Controlling",
      "PURPOSE": "<ul><li>Kontrolle über die monatliche Auslastung der Tageseltern</li><li>Export der Daten, die bei den Tageseltern im Tab Übersicht als Graphik dargestellt werden</li></ul>",
      "STRUCTURE": "<p>Pro Tageseltern und Kind werden die monatlichen Controlling Daten aufgelistet.</p>"
    },
    "BELEGUNGSPLAN_PLATZART": {
      "TITLE": "Belegungsplan (mit Platzart)",
      "PURPOSE": "Export der Belegungen",
      "STRUCTURE": "<p>Im Report wird für jede Kalenderwoche im ausgewählten Zeitraum ein einzelnes Blatt erstellt. Die Kinder werden in den jeweiligen Gruppen aufgelistet. Deren Belegung ist je nach Platzart mit einem P (privat), S (subventioniert) oder F (Firmenplatz) gekennzeichnet.</p>",
      "PLATZART": "Mit Platzart",
      "ANWESENHEIT": "Mit Anwesenheitszeit"
    },
    "KENNZAHLEN": {
      "TITLE": "Kennzahlen",
      "PURPOSE": "<ul><li>Ermitteln von individuellen Kennzahlen über einen Kinderort oder den Mandanten (z.B. beliebtestes Pensum oder Anzahl Kinder pro Jahrgang, Gemeinde, Geschlecht, usw.)</li><li>Auswertung von Tarifparametern</li></ul>",
      "STRUCTURE": "<p>Pro Kind und Platzart, welche am eingegebenen Stichtag ein Pensum in dem Kinderort belegen, wird eine Zeile generiert.</p>"
    },
    "EXTERNAL_ANMELDUNGEN": {
      "TITLE": "Externe Anmeldungen",
      "PURPOSE": "<ul><li>Ermitteln von individuellen Kennzahlen über einen Kinderort oder den Mandanten (z.B. beliebtestes Pensum oder Anzahl Kinder pro Jahrgang, Gemeinde, Geschlecht, usw.)</li><li>Auswertung von Tarifparametern</li></ul>",
      "STRUCTURE": "<p>Pro Kind und Platzart, welche am eingegebenen Stichtag ein Pensum in dem Kinderort belegen, wird eine Zeile generiert.</p>"
    },
    "WARTELISTE": {
      "TITLE": "Warteliste",
      "PURPOSE": "Export der Warteliste pro Kinderort oder über alle Kinderorte.",
      "STRUCTURE": "<p>Jede Zeile im Report entspricht einem Kind mit einem Betreuungswunsch. Der Betreuungswunsch wird mit folgenden Angaben ergänzt: Betreuungsgründe, aktuelle Belegung, Hauptkontakt und Bemerkung.</p>"
    },
    "BETREUUNGSGUTSCHEINE": {
      "TITLE": "Betreuungsgutscheine",
      "PURPOSE": "Export der erfassten Betreuungsgutscheine.",
      "STRUCTURE": "<p>Jede Zeile im Report entspricht einem Betreuungsgutschein. Die Betreuungsgutscheine sind mit dem Hauptkontakt und einer weiteren erziehungsberechtigten Person des Kindes ergänzt.</p>"
    },
    "KIBON_REPORT": {
      "TITLE": "kiBon",
      "PURPOSE": "<p>Der Report ist dazu gedacht Eltern zu kontaktieren, welche voraussichtlich für die nächste Gutscheinperiode einen BG beantragen werden.</p>",
      "STRUCTURE": "<p>Im Report wird jedes Kind, welches zum angegebenen Zeitraum noch über eine Belegung verfügt, auf einer Zeile dargestellt. Ausserdem werden auch Daten wie bspw. Platzanfrage, Platzbestätigung, Mutation, Verfügung ersichtlich.</p>"
    },
    "LEISTUNGEN_KALKULIERT": {
      "TITLE": "Kalkulierte Leistungen",
      "PURPOSE": "<ul><li>Prognose der Einnahmen und Kostenstellen</li><li>Tarifevaluierung</li></ul>",
      "STRUCTURE": "<p>Während im Tabellenblatt \"Leistungsjournal\" alle kalkulierten Leistungen vorhanden sind, gilt dieses Blatt auch als Datenbasis für die anderen im Report integrierten Tabellenblätter mit Pivottabellen. Die Tabellen zeigen den prognostizierten Verlauf der kalkulierten Leistungen über alle Monate im entsprechenden Jahr. Die Leistungen sind nach Platz und Leistungsart gruppiert. Damit können Tarifänderungen spielerisch leicht angepasst und somit analysiert werden. Hinweis: Die Daten werden jeweils über Nacht neu berechnet. Die Ergebnisse einer Tarifänderung sind so beispielsweise erst am nächsten Tag vollständig ersichtlich.</p>"
    },
    "FINANZLASTENAUSGLEICH": {
      "TITLE": "Finanzlastenausgleich (FILAG)",
      "PURPOSE": "Information für Behörden, welche Kinder aus welcher Gemeinde einen subventionierten Platz in Anspruch nimmt.",
      "STRUCTURE": "<p>Alle Kinder einem subventionierten Platz werden mit Ihrer politischen Gemeinde und dem Betreuungspensum angezeigt. Über eine Pivottabelle ist ersichtlich, welche Gemeinde wie viele subventionierte Plätze bei dem Kinderort bezieht.</p>"
    },
    "BELEGTE_PROZENT_PUNKTE": {
      "TITLE": "Belegte Prozentpunkte pro Tag",
      "PURPOSE": "Um geleistete Tagesschul-Stunden pro Betreuungsfaktor Ende Schuljahr dem Kanton mitzuteilen.",
      "STRUCTURE": "<p>Der Report hat 3 Tabs: \"Prozentpunktsummen\", \"Parameter\" und \"Geschlossene Tage\".</p><p>Unter Prozentpunktsummen kann die Summe der Betreuungsstunden pro Betreuungsfaktor herausgelesen werden. Damit die korrekte Zahl berechnet werden kann, muss unter dem Tab \"Parameter\" die \"geöffneten Tage pro Woche\" als auch die \"geöffneten Stunden pro Tag\" eingetragen werden.</p><p>Damit geschlossene Tage (Ferien oder Feiertage) berücksichtigt werden, können diese im 3. Arbeitsblatt mit einem \"x\" gekennzeichnet werden.</p>"
    },
    "TARIFRECHNUNG_REPORTING_KALKULIERT": {
      "TITLE": "Tarifkennzahlen (Reportingpositionen)",
      "PURPOSE": "<ul><li>Tarif und damit benutzerdefinierte Auswertungen, die über den Tarif gesteuert werden können</li><li>Um geleistete Tagesschul-Stunden pro Betreuungsfaktor Ende Schuljahr dem Kanton mitzuteilen. (Formular 1 und 2)</li></ul>",
      "STRUCTURE": "<p>Pro Tarifabschnitt wird im Report eine Zeile geführt. In dieser Zeile sind alle Reportingpositionen aufgeführt, wobei die Spalten durch die Bezeichnungen definiert sind. Zusätzlich wird im Report falls möglich eine Gruppe/Betreuungsperson mitgegeben, sowie der Betreuungsfaktor. Die betroffene Platzart (privat, subventioniert, ...) sowie der entsprechende Zeitabschnitt lassen ebenfalls weitere Auswertungsmöglichkeiten zu.</p>"
    },
    "STUNDENBLATT": {
      "TITLE": "Stundenblatt",
      "PURPOSE": "Übertragung der verrechneten Stunden in die externe Lohnbuchhaltungssoftware.",
      "STRUCTURE": "<p>Der Report hat drei Tabellenblätter: \"Effektive-Stunden\", \"Filag\" und \"StundenblattData\".</p><p>Im Tabellenblatt \"Effektive-Stunden\" werden via Pivot-Tabelle die Anzahl erfasster Stunden pro Betreuungsperson angezeigt.<br>Das Tabellenblatt \"Filag\" listet pro Gemeinde die Anzahl subventionierter sowie privater Stunden auf.<br> Als Daten-Basis dient das Tabellenblatt \"StundenblattData\".</p>"
    },
    "MONATSBLATT_AUSZUG": {
      "TITLE": "Monatsblatt Auszug (PDF)",
      "PURPOSE": "<ul><li>Dient als Beilageblatt zur Lohnabrechnung der Tagesfamilien</li><li>Aufschlüsseln der Betreuungsstunden pro Kind nach Betreuungsfaktor</li><li>Anzeigen der erfassten monatsbasierten Tarifparameter im Monatsblatt</li></ul>",
      "STRUCTURE": "<p>Der Report erstellt einen Ordner, worin die Monatsblätter als PDF pro Tagesfamilie angezeigt werden. Das Layout kann angepasst werden. Durch das hinzufügen einer Absenderadresse erscheint auch der Ort in der Datumszeile. Ansonsten wird der Ort mit \"Datum\" ersetzt.</p>"
    },
    "CORONA_REFUNDS": {
      "TITLE": "Notverordnung Rückerstattung Bern für öffentliche Kitas",
      "PURPOSE": "<p>Dient dem Berechnen der Ausfälle auf Grund von Corona pro Kind.</p><p>Berücksichtigt nur den Zeitraum 17. März - 16. Mai 2020.</p>",
      "STRUCTURE": "<p>Listet Pro Kind die Anzahl Tage welche normalerweise monatlich die Kita besucht wird, sowie die Anzahl Tage, während welchen auf Grund von Corona die Kita nicht besucht wurde.</p><p>Es werden die Betreuungskosten berechnet, welche für Corona bedingte Ausfälle rückerstattet werden können.</p>",
      "DOWNLOAD_PDFS": "Elternbriefe herunterladen",
      "PDF_INTRODUCTION": "Freitext Einleitung für Elternbrief",
      "RETURN_DATE": "Gewünschtes Rücksendedatum",
      "DEFAULTS": {
        "PDF_INTRODUCTION_MF": "{reportType, select, CORONA_REFUNDS{Liebe Familie\n\nGemäss der Notverordnung des Kantons Bern vom 22. April 2020 übernimmt die Gesundheits-, Sozial- und Integrationsdirektion die Elterngebühren für Betreuungsplätze, die zwischen dem 17. März und dem 16. Mai aufgrund Corona-Abwesenheiten nicht genutzt wurden.} other{Liebe Familie\n\nGemäss der Notverordnung des Bundes vom 20. Mai 2020 übernehmen die Kantone die Elterngebühren für Betreuungsplätze, die zwischen dem 17. März und dem 17. Juni 2020 aufgrund Corona-Abwesenheiten nicht genutzt wurden.}}",
        "PDF_ADDITIONAL_TXT_MF": "Bitte bestätigen Sie uns, dass es sich bei diesen Abwesenheiten um coronabedingte Abwesenheiten handelte, damit wir Ihnen die Elterngebühren dieser Abwesenheiten erlassen und sie beim Kanton zurückfordern können.\n\nBitte senden Sie uns ein unterschriebenes Exemplar dieser Bestätigung mit dem beigelegten Antwortcouvert { email, select, null{} other{oder per E-Mail an {email}} } bis am {returnDate} zurück. Erst danach werden wir die Rechnungen entsprechend korrigieren und an Sie versenden. Sollten wir von Ihnen bis dahin keine Rückmeldung erhalten, können wir auf Ihren Rechnungen die entsprechenden Elterngebühren nicht in Abzug bringen.\n\nMelden Sie Abweichungen der Corona-abwesenden Tage schriftlich bei { email, select, null{{sender}} other{{email}} }.\n\nFreundliche Grüsse\n{sender}",
        "PDF_ADDITIONAL_TXT_NO_ABSENCES_MF": "Bitte senden Sie uns ein unterschriebenes Exemplar dieser Bestätigung mit dem beigelegten Antwortcouvert { email, select, null{} other{oder per E-Mail an {email}} } bis am {returnDate} zurück.\n\nMelden Sie Abweichungen der Corona-abwesenden Tage schriftlich bei { email, select, null{{sender}} other{{email}} }.\n\nFreundliche Grüsse\n{sender}"
      },
      "PDF_ADDITIONAL_TEXT": "Zusätzlicher Freitext für Elternbrief (mit Abwesenheiten)",
      "PDF_ADDITIONAL_TEXT_NO_ABSENCES": "Zusätzlicher Freitext für Elternbrief (ohne Abwesenheiten)",
      "CREATE_REFUNDS": "Gutschriften erstellen",
      "VERPFLEGUNG_REFUND_PCT": "Rückerstattung durch die Kita der Verpflegungskosten",
      "ADD_SIGNATURES": "Unterschriftszeile für Erzeihungsberechtigte erstellen",
      "FEE_APPROVAL": "Wenn Sie diese Funktion nutzen entstehen Ihnen pro Kinderort einmalige Kosten von CHF 150.-, welche Ihnen mit der August Rechnung verrechnet werden.",
      "ERR_GENERATION_FAILED": "Das Erstellen des Reports ist fehlgeschlagen. Sind alle vom Lockdown betroffenen Monate verrechenbar?"
    },
    "CORONA_REFUNDS_BERN_PRIVAT": {
      "TITLE": "Notverordnung Rückerstattung Bern für private Kitas",
      "PURPOSE": "<p>Dient dem Berechnen der Ausfälle auf Grund von Corona pro Kind.</p><p>Berücksichtigt den Zeitraum 17. März - 17. Juni 2020.</p>",
      "STRUCTURE": "<p>Listet Pro Kind die Anzahl Tage welche normalerweise monatlich die Kita besucht wird, sowie die Anzhal Tage, während welchen auf Grund von Corona die Kita nicht besucht wurde.</p><p>Es werden die Betreuungskosten berechnet, welche für Corona bedingte Ausfälle rückerstattet werden können.</p>"
    },
    "CORONA_REFUNDS_LUZERN": {
      "TITLE": "Notverordnung Rückerstattung Luzern",
      "PURPOSE": "<p>Dient dem Berechnen der Ausfälle auf Grund von Corona pro Kind.</p>",
      "STRUCTURE": "<p>Listet Pro Kind die Anzahl Tage welche normalerweise monatlich die Kita besucht wird, sowie die Anzhal Tage, während welchen auf Grund von Corona die Kita nicht besucht wurde.</p><p>Es werden die Betreuungskosten berechnet, welche für Corona bedingte Ausfälle rückerstattet werden können.</p>"
    },
    "BSV_PRAESENZKONTROLLE": {
      "TITLE": "BSV (Präsenzkontrolle für Kita)",
      "PURPOSE": "<p>Präsenzkontrolle für das Bundesamt für Sozialversicherungen für Kindertagesstätten.</p><p>Das Formular wird vom BSV für die Finanzierung von neuen Betreuungsplätzen benötigt.</p>",
      "STRUCTURE": "<p>Füllt direkt die offizielle Excel Vorlage für die Präsenzkontrolle aus.</p><p>Feiertage und Schliesstage müssen noch manuell ausgefüllt werden.</p><p>Belegungen mit Kindergarten werden nicht berücksichtigt.</p><p>Zusatztage und Abwesenheiten werden nicht berücksichtigt.</p>",
      "FULL_DAY_BETREUUNG_HOURS": "Anzahl Stunden ab welcher eine Betreuung als ganztags gilt"
    },
    "BSV_PRAESENZKONTROLLE_KG": {
      "TITLE": "BSV (Präsenzkontrolle für schulergänzende Betreuung)",
      "PURPOSE": "<p>Präsenzkontrolle für das Bundesamt für Sozialversicherungen für schulergänzende Betreuung.</p><p>Das Formular wird vom BSV für die Finanzierung von neuen Betreuungsplätzen benötigt.</p>",
      "STRUCTURE": "<p>Füllt direkt die offizielle Excel Vorlage für die Präsenzkontrolle aus.</p><p>Feiertage, Schliesstage sowie Ferien müssen noch manuell ausgefüllt werden.</p><p>Es werden nur Belegungen mit Kindergarten berücksichtigt.</p><p>Zusatztage und Abwesenheiten werden nicht berücksichtigt.</p>"
    },
    "ANWESENHEITSSOLL_VERBRAUCH": {
      "TITLE": "Anwesenheitssoll",
      "PURPOSE": "Prüfung des Anwesenheitssolls sowie des Verbrauchs, zur Verrechnung des nicht verbrauchten Solls.",
      "STRUCTURE": "Listet die Summe des Anwesenheitssolls, des Verbrauchs und der Differenz pro Kind für den ausgewählten Zeitraum auf."
    },
    "STUNDENVERGLEICH_SOLL_IST": {
      "TITLE": "Stundenvergleich Ist/Soll",
      "PURPOSE": "<p>Kontrolle über die täglichen Soll- und Ist-Zeiten pro Kind.</p><p>Report kann den Eltern zur Kontrolle ausgehändigt werden</p>",
      "STRUCTURE": "Der Report kann pro Kind für einen bestimmten Monat generiert werden. Darin ist jeder Tag mit den angemeldeten Bring und Abholzeiten (Soll) sowie den effektiven Anwesenheitszeiten (Ist) aufgelistet."
    },
    "BETREUUNGS_STUNDEN_KONTROLLE": {
      "TITLE": "Betreuungsstunden Kontrolle",
      "PURPOSE": "Auflistung aller erfassten Stunden in Monatsblatt & Stundenblatt, Stundenguthaben und Tarifparametern bzw. Spesen.",
      "STRUCTURE": "<p>Der Report kann für einen Tageseltern Region und einen Zeitraum generiert werden.</p><p>Generiert wird eine CSV Datei, mit einer Zeile pro Kind, Zeitraum, Betreuungsperson und Stundenguthaben. Daraus lässt sich z.B. eine Pivot Tabelle generieren, um die Summe aller Stunden des Monatsblattes, des Stundenblattes und des Guthabens pro Kind zu vergleichen.</p>"
    },
    "FAKTURA": {
      "TITLE": "Faktura"
    },
    "ZAHLUNGS_EINGAENGE": {
      "TITLE": "Zahlungseingänge",
      "PURPOSE": "<ul><li>Export der erfassten Zahlungen</li><li>Abgleich in der Buchhaltung</li></ul>",
      "STRUCTURE": "<p>Jede erfasste Zahlung erscheint als separate Zeile in diesem Report. Die Zahlung ist, falls möglich, einem Kinderort und einem Rechnungskontakt zugewiesen. Die Zahlungstypen werden unterschieden nach:</p><ul><li>Abschreibung</li><li>Ausgleichszahlung für Vorauszahlung</li><li>Ausgleichszahlung für Rückerstattung</li><li>Bankzahlung</li><li>Bankzahlung aus Vorauszahlung</li><li>Bankzahlung aus Kontenabgleich</li><li>Rundungsdifferenz</li><li>Rückerstattung</li><li>Rückerstattung aus Ausgleichszahlung</li><li>Skonto</li><li>Vorauszahlung</li><li>Vorauszahlung aus Ausgleichszahlung</li></ul>"
    },
    "LEISTUNGEN_FAKTURIERT": {
      "TITLE": "Fakturierte Leistungen",
      "PURPOSE": "<ul><li>Abgleich mit der Buchhaltung</li><li>Export der fakturierten Leistungen</li></ul>",
      "STRUCTURE": "<p>Das Tabellenblatt \"Fakturajournal\" listet alle fakturierten Leistungen auf. Es gilt zudem als Datenbasis für die anderen im Report integrierten Tabellenblätter mit Pivottabellen. Die Tabellen zeigen den Verlauf der bereits fakturierten Leistungen im entsprechenden Jahr. Die Leistungen sind nach Platz und Leistungsart gruppiert.</p>"
    },
    "KONTAKTPERSONEN_ZAHLUNGEN": {
      "TITLE": "Offene Vorauszahlungen & Rückerstattungsbeträge",
      "PURPOSE": "Sicherheit, damit offene Vorauszahlungen und Rückererstattungsbeträge nicht vergessen werden.",
      "STRUCTURE": "<p>Es werden alle Vorauszahlungen und Rückerstattungsbeträge im Zusammenhang mit dem Kinderort und der Kontaktperson dargestellt.</p>",
      "INCLUDE_PAYED_RUECKERSTATTUNGEN": "Inklusive ausgezahlte Rückerstattungen"
    },
    "OFFENE_POSTEN": {
      "TITLE": "Offene Posten",
      "PURPOSE": "<ul><li>Export der Offenen Posten Liste des gesamten Mandanten oder des Kinderortes oder/und der Kontaktperson</li><li>Monats- / Quartal- und Jahresabschluss</li></ul>",
      "STRUCTURE": "<p>Die Offenen Posten werden pro Kontaktperson/Kinderort gruppiert. Es sind nur Kinderorte/Kontaktpersonen dargestellt, die noch offene Posten aufweisen.</p>"
    },
    "AKTUELLE_OFFENE_POSTEN": {
      "TITLE": "Offene Posten (ohne Abgeschlossene)",
      "PURPOSE": "<ul><li>Export der Offenen Posten Liste des gesamten Mandanten oder des Kinderortes oder/und der Kontaktperson</li><li>Monats- / Quartal- und Jahresabschluss</li></ul><p>Zahlungen, welche mittlerweile einer Rechnung zugewiesen und somit abgeschlossen sind, werden unabhängig von der angegebenen \"Leistungsperiode bis\" von diesem Report ignoriert.</p>",
      "STRUCTURE": "<p>Die Offenen Posten werden pro Kontaktperson/Kinderort gruppiert. Es sind nur Kinderorte/Kontaktpersonen dargestellt, die noch offene Posten aufweisen.</p>"
    },
    "RECHNUNGS_GEBUEHREN": {
      "TITLE": "Rechnungsgebühren",
      "PURPOSE": "<ul><li>Export aller Rechnungsgebühren ausserhalb der Leistungsrechnung</li><li>Monats- / Quartal- und Jahresabschluss</li></ul>",
      "STRUCTURE": "<p>Es werden alle verrechneten Rechnungsgebühren dargestellt.</p>"
    },
    "STEUERBESCHEINIGUNGEN": {
      "TITLE": "Steuerbescheinigungen (PDF)",
      "PURPOSE": "Bescheinigt die fakturierten Leistungen einer Kontaktperson pro Kalenderjahr.",
      "STRUCTURE": "<p>Der Report erstellt einen Ordner, worin die Steuerbestätigungen als PDF pro Rechnungskontakt angezeigt werden. Das Layout kann angepasst werden. Durch das hinzufügen einer Absenderadresse erscheint auch der Ort in der Datumszeile. Ansonsten wird der Ort mit \"Datum\" ersetzt.</p>",
      "STRUCTURE_TITLE": "Gruppierung",
      "STRUCTURE_LEISTUNGSART": "Nach Leistungsart",
      "STRUCTURE_MONTHLY": "Nach Monat"
    },
    "DEPOTS": {
      "TITLE": "Depots",
      "PURPOSE": "Übersicht über erfasste Depots",
      "STRUCTURE": "<p>Für die ausgewählten Kinderorte werden alle offenen Depots aufgelistet.<br /> Offen bedeutet, dass sie noch nicht per Vorauszahlung oder Rückerstattung erstattet wurden.</p><p>Der Stichtag wird verwendet für die Ermittlung des Rechnungsstatus der Rechnungen, auf welchen die Depots verrechnet wurden.</p><p>Wenn zusätzlich auch erstattete Depots aufgelistet werden sollen, können diese optional nach Erstattungsdatum limitiert werden.</p>",
      "INCLUDE_ERSTATTET": "Inklusive erstattete",
      "ERSTATTET_VON": "Erstattet von",
      "ERSTATTET_BIS": "Erstattet bis"
    },
    "PERSONAL": {
      "TITLE": "Personal"
    },
    "PERSONALPLANUNG": {
      "TITLE": "Personalplanung",
      "PURPOSE": "Geplantes Personal inklusive Pausen und Termine.",
      "STRUCTURE": "Im Report wird für jede Kalenderwoche im ausgewählten Zeitraum ein einzelnes Tabellenblatt erstellt. Die Angestellten werden in den jeweiligen Kinderortfraktion aufgelistet. Die Zuweisung ist mit der dazugehörenden Dienstfarbe markiert, wenn verfügbar.</p>",
      "INCLUDE_TERMINE": "mit Terminen",
      "INCLUDE_KINDERORT_SCHLUESSEL": "mit Kinderort-Schlüssel Zuweisungen",
      "INCLUDE_TAGESINFOS": "mit Tagesinfos",
      "INCLUDE_PAUSEN": "mit Pausenzeiten",
      "EVENT_TOOLTIP_NOT_AVAILABLE": "nicht verfügbar von {{von}} bis {{bis}}"
    },
    "PERSONALPLANUNG_MONTHLY": {
      "TITLE": "Monatliche Personalplanung",
      "PURPOSE": "Geplantes Personal inklusive Termine.",
      "STRUCTURE": "Im Report werden für den ausgewählten Monat die Angestellten in den zugewiesenen Kinderortfraktionen aufgelistet. Die Zuweisung ist mit der dazugehörenden Dienstfarbe und dem Dienst Kürzel markiert, wenn verfügbar.</p>"
    }
  },
  "COMMUNICATION": {
    "TITLE": "Kommunikation",
    "SELECT_RECIPIENTS": "Empfänger auswählen",
    "PROBLEM": "Problem",
    "INCOMPLETE_RECIPIENTS": "{{count}} unvollständige Empfänger",
    "POST_RECIPIENTS": "{{count}} Post-Empfänger",
    "READY_TO_SEND": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{versandbereite E-Mail} other{versandbereite E-Mails}}"
    },
    "SENT": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{versendete E-Mail} other{versendete E-Mails}}"
    },
    "DEADLINE": "Frist",
    "EXPIRED": "abgelaufen",
    "CONTACT_USER_MUTIERT": "Letzte Anpassung der Eltern",
    "SEND_EMAILS": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{E-Mail versenden} one{E-Mail versenden} other{E-Mails versenden}}"
    },
    "RESEND_EMAILS": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{E-Mail erneut versenden} one{E-Mail erneut versenden} other{E-Mails erneut versenden}}"
    },
    "CHECK_COMPLETENESS": "Vollständigkeit prüfen",
    "KONTAKT_MISMATCH": "Der dargestellte Kontakt für welchen eine E-Mail versendet wurde, entspricht nicht dem aktuellen Hauptkontakt.",
    "BRING_ABHOLZEITEN": {
      "RELEASE": "Freigabe der Bring- und Abholzeiten",
      "CONFIRMATION": "Bestätigung der Bring- und Abholzeiten",
      "TITLE": "Bring- und Abholzeiten {{date}}",
      "PURPOSE": "E-Mails auslösen für die Einladung zum Eintragen der Bring- und Abholzeiten",
      "STRUCTURE": "<p>Versenden und Verwaltung von Einladungsmails für die Hauptkontakte von Kindern einer ausgewählten Kita.</p><p>Um bereits versandte E-Mails zu verwalten, wählen sie den betroffenen Monat nochmals aus.</p>",
      "PRESENCE": "Anwesenheit",
      "NO_FLEXIBLE_PLAETZE": "Mit fixem Betreuungsplatz",
      "NO_FLEXIBLE_PLAETZE_EXISTING_MONATSBELEGUNG": "Mit fixem Betreuungsplatz. Bring- und Abholzeiten vorhanden",
      "CREATE_MONATSBELEGUNG_MF": "{showcount, select, true{{count} } other {}}{count, plural, one{Standard Anwesenheitszeit erfassen} other {Standard Anwesenheitszeiten erfassen}}"
    },
    "STEUERBESCHEINIGUNG": {
      "NAME": "Steuerbescheinigung",
      "TITLE": "Steuerbescheinigungen {{date}} versenden",
      "PURPOSE": "E-Mails auslösen mit den Steuerbescheinigungen fürs entsprechende Jahr",
      "STRUCTURE": "<p>Prozess für den Massenversand von Steuerbescheinigung via E-Mail und Post.</p><p>Um bereits versandte E-Mails zu verwalten, wählen sie das betroffene Jahr nochmals aus.</p>"
    },
    "BULK_EMAILS": {
      "NAME": "E-Mail",
      "TITLE": "E-Mail {{date}} schreiben",
      "PURPOSE": "E-Mail an ausgewählte Kontaktpersonen versenden",
      "STRUCTURE": "<p>Empfänger auswählen, E-Mail schreiben und versenden.</p>",
      "SEND_EMAIL": "E-Mail an ausgewählte Emfänger senden",
      "DISPOSITION_NOTIFICATION": "Empfangsbestätigung anfordern",
      "TERMINATION_MAIL": "Kündigungsbestätigung via Mail",
      "HISTORY": {
        "TITLE": "Massenmail Verlauf",
        "MF": "{showcount, select, true{{count} } other{}} Massenmails",
        "SEARCH_TEXT_SHORT": "E-Mail Suche",
        "SEARCH_TEXT": "Betreff und Inhalt Suche",
        "SENT_DATE": "Versanddatum",
        "SENT_BY_USER": "Versandt von Benutzer",
        "ABSENDER_INFO": "Absender Adresse und Name",
        "RECIPIENTS": "Massenmail Empfänger",
        "SEE_RECIPIENTS": "Empfänger Auflistung",
        "SEND_MAIL": "Erneut senden"
      }
    },
    "EMAIL_TEMPLATE": {
      "TITLE": "E-Mail-Templates",
      "SINGULAR": "E-Mail-Template",
      "PLURAL": "E-Mail-Templates",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{E-Mail-Template} other{E-Mail-Templates}}",
      "CREATE": "E-Mail-Template erstellen",
      "EDIT": "E-Mail-Template editieren",
      "TEMPLATE_NAME": "Name",
      "CONTENT": "Inhalt"
    },
    "EMAIL": {
      "ABSENDER_ADRESSE": "Absenderadresse",
      "TEST_RECIPIENT": "Test E-Mail Empfänger",
      "SEND_TEST_EMAIL": "Test E-Mail senden",
      "TEST_MAIL_SUCCESS": "Das Test E-Mail wurde erfolgreich versendet",
      "SUBJECT": "Betreff",
      "CONTENT": "E-Mail Nachricht",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, =0{E-Mail} one{E-Mail} other{E-Mails}}",
      "HEADER_INFO": "An: {{to}}\nAntwort an: {{replyToName}} <{{replyToAddr}}>\nGesendet von: {{sentBy}}"
    },
    "FOR": {
      "BRING_ABHOLZEITEN": "Für {{text}}",
      "BRING_ABHOLZEITEN_CONFIRM": "Bestätigt für {{text}}"
    },
    "ABSENDER_NAME": "Absender Name",
    "NONE_AVAILABLE": "Sie haben keine Kommunikationsmodule freigeschaltet."
  },
  "ERRORS": {
    "VALUE_REQUIRED": "Gültigen Wert eingeben.",

    "ERR_ADRESSE_VALIDATION_UNAVAILABLE": "Die Adresse kann momentan leider nicht überprüft werden.",
    "ERR_KITA_ODER_GRUPPE": "Mindestens einen Kinderort oder eine Gruppe auswählen.",
    "ERR_WARTELISTE_MIT_GRUPPEN": "Wenn \"Auf Warteliste\" ausgewählt wurde, dürfen keine Gruppen ausgewählt werden.",
    "ERR_WARTELISTE_BELEGUNG": "Von den Optionen \"Auf Warteliste\" und \"Mit Belegung\" muss mindestens eine ausgewählt werden.",
    "ERR_PLATZ": "Mindestens eine Platzart auswählen.",
    "ERR_ONE_KONTAKT_REQUIRED": "Mit den Filtereinstellungen wurde kein Empfänger gefunden.",
    "ERR_BELEGUNG_NOT_EMTPY": "Ein Kind mit Belegungen kann nicht gelöscht werden. Löschen Sie vorerst alle Belegungen, um das das Kind zu löschen.",
    "ERR_INCOMPLETE_ANMELDUNG": "Anmeldung vollständig ausfüllen.",
    "ERR_KIND_NOT_FIND": "Kein Kind für zuweisung ausgewählt",
    "ERR_KONTAKT_NOT_FIND": "Kein Kontakt für zuweisung ausgewählt",
    "ERR_INCOMPLETE_FORM": "Formular vollständig ausfüllen.",
    "ERR_INVALID_HOURS": "Die Summe der täglichen Sollzeiten stimmen nicht mit dem Arbeitszeitmodell überein.",
    "ERR_KITA_UND_GRUPPE": "Wählen Sie einen Kinderort oder eine Gruppe aus.",
    "ERR_ALTERS_BEDINGTE_BETREUUNGSFAKTOREN_FORM": "Es darf kein Monat kleiner als 1 oder doppelt definiert sein. Ein Faktor darf nicht kleiner als 0.01 sein. Ein Faktor darf maximal 2 Kommastellen haben.",
    "ERR_NO_BETREUUNGSFAKTOR_SET": "Es muss mindestens ein Betreuungsfaktor definiert werden.",
    "ERR_KINDERGARTENFAKTOR_FORM": "Der Kindergartenfaktor darf maximum 2 Kommastellen haben.",
    "ERR_FAKTOR": "Ein Faktor darf nicht kleiner als 0.01 sein. Ein Faktor darf maximal 2 Kommastellen haben.",
    "ERR_DOWNLOAD": "Die Datei konnte nicht heruntergeladen werden.",
    "ERR_DOWNLOAD_MALWARE": "Es wurde Malware in der Datei gefunden. Wenn Sie die Datei trotzdem herunterladen wollen, melden Sie sich bitte beim Support.",
    "ERR_EINZAHLUNGSSCHEIN_KONTO": "Teilnehmer Nummer (Oranger Einzahlungsschein) oder QR-IBAN (QR Rechnung) muss gesetzt sein.",
    "ERR_DIFFERENT_PASSWORD": "Eindeutiges Passwort eingeben.",

    "ERR_SCHLIESSTAG_FORM": "Es gibt einen Fehler beim Namen oder den Zeiträumen.",

    "ERR_REQUIRED_GESCHLECHT": "Bitte ein Geschlecht auswählen.",
    "ERR_REQUIRED_KONTAKTPERSON": "Mindestens eine Kontaktperson angeben.",
    "ERR_REQUIRED_KORRESPONDENZ_ADRESSE": "Korrespondenzaddresse ungültig. Überprüfen Sie, ob alle Adressfelder gesetzt sind.",
    "ERR_REQUIRED_KITA": "Mindestens einen Kinderort auswählen.",
    "ERR_REQUIRED_PLATZ": "Wählen Sie einen gewünschten Platz (Privater, Subventioniert oder Firmenplatz).",
    "ERR_REQUIRED_PLATZ_DYNAMIC": "Wählen Sie einen gewünschten Platz ({{plaetze}}).",
    "ERR_REQUIRED_BELEGUNGSEINHEIT": "Mindestens eine korrekte Belegungseinheit (Tag + Morgen / Mittag / Nachmittag) auswählen.",
    "ERR_REQUIRED_BELEGUNGSEINHEIT_FOR": "Mindestens eine korrekte Belegungseinheit (Tag + Morgen / Mittag / Nachmittag) für {{name}} auswählen.",
    "ERR_REQUIRED_ANMELDEDATUM": "Anmeldedatum eingeben.",
    "ERR_INVALID_RECHNUNGSAUFTEILUNG": "Die Rechnungsaufteilung ist ungültig. Die Summe aller Prozentwerte muss 100% ergeben. Der Betrag / Prozentwert muss grösser als 0 sein.",
    "ERR_INVALID_ERZIEHUNGSBERECHTIGTE": "Ein Kind kann höchstens 2 erziehungsberechtigte Personen haben.",
    "ERR_INVALID_DATE": "Gültiges Datum eingeben (z.B. 24.12.2018).",
    "ERR_INVALID_JAHR": "Gültiges Jahr eingeben (z.B. 2018).",
    "ERR_INVALID_DATES": "Datum gültig ab darf nicht nach dem Datum gültig bis liegen.",
    "ERR_INVALID_TIME_RANGE": "Bis muss nach von sein.",
    "ERR_DUPLICATE_TIME_RANGE": "Module mit gleichem Zeitraum gefunden.",
    "ERR_INVALID_REPEAT_COUNT": "ungültige Wiederholungszahl",
    "ERR_TECHNICAL": "Upps! Ein unerwarteter Fehler ist aufgetretern.",
    "ERR_REQUIRED_NAME": "Name darf nicht leer sein.",
    "ERR_REQUIRED_FAMILIENNAME": "Name des Kindes angebeben.",
    "ERR_REQUIRED_GRUPPENNAME": "Name der Gruppe angebeben.",
    "ERR_REQUIRED_BETREUUNG_VON": "Datum für die gewünschte Betreuung eingeben.",
    "ERR_REQUIRED_PENSUM": "Gewünschtes Pensum eingeben.",

    "ERR_INCOMPLETE_BEWERBUNG": "Betreuungswunsch vollständig ausfüllen.",
    "ERR_INCOMPLETE_KONTAKTPERSON": "Kontaktperson vollständig ausfüllen.",
    "ERR_REQUIRED_BIRTHDAY": "Bitte geben Sie entweder den voraussichtlichen Geburtstermin oder das Geburtsdatum an.",
    "ERR_KEINE_BENUTZER": "Es gibt keine non-FREE Benutzer.",
    "ERR_INTERNAL": "Upps! Ein Fehler ist aufgetreten.",
    "ERR_BADREQUEST": "Der Request war ungültig.",
    "ERR_REQUEST_ABORTED": "Timeout: Der Request wurde abgebrochen.",
    "ERR_SERVICE_UNAVAILABLE": "kitAdmin ist momentan nicht verfügbar. Wir sind gleich zurück.",
    "ERR_FORBIDDEN": "Zugriff verweigert.",
    "ERR_BELEGUNGSEINHEIT_CONFLICT": "Die Belegung der Gruppe {{gruppe}} am {{wochentag}} ist ungültig.",
    "ERR_BELEGUNG_BEFORE_BIRTHDAY": "Es wurde versucht eine Zuweisung zu einem Zeitpunkt zu erfassen, an welchem das Kind noch nicht geboren ist.",
    "ERR_INVALID_BELEGUNGSEINHEIT": "Die Zeitraum Auswahl am {{wochentag}} ist ungültig.",
    "ERR_NO_PLATZ_FOR_ZEITRAUMFELDER": "Upps, die Belegung der Gruppe {{gruppe}} konnte nicht erstellt werden.",
    "ERR_LOGIN": "Anmeldung fehlgeschlagen. Versuchen Sie es erneut oder melden Sie sich über \"Externes Login\" an.",
    "ERR_INCOMPLETE_LOGIN": "Gültige E-Mail-Adresse und Passwort eingeben.",
    "ERR_UNKNOWN_WOCHENTAG": "Upps, der Wochentag {{wochentag}} ist unbekannt.",
    "ERR_KIND_AUSTRITT_FORM": "Austritt vollständig ausfüllen.",
    "ERR_IDENTICAL_PASSWORD": "Das neue Passwort darf nicht mit dem alten Passwort übereinstimmen.",
    "ERR_STRONG_PASSWORD": "Das neue Passwort muss mindestens 8 Stellen lang sein und mindestens einen Buchstaben, eine Ziffer und ein Sonderzeichen enthalten.",
    "ERR_MAX_KLEINER_ALS_ANZAHL": "Anzahl max. Plätze darf nicht leer, ungültig oder kleiner als Anzahl Plätze sein.",
    "ERR_ANZ_PLAETZE_VALUE_UNGUELTIG": "Der Wert der Anzahl Plätze kann nicht korrekt berechnet werden, da mind. ein Feld einen ungültigen Wert enthält.",
    "ERR_MAX_PLAETZE_VALUE_UNGUELTIG": "Der Wert der max. Anzahl Plätze kann nicht korrekt berechnet werden, da mind. ein Feld einen ungültigen Wert enthält.",
    "ERR_ANZ_PLAETZE_CALCULATION_FAILED": "Der Wert der Anzahl Plätze kann nicht berechnet werden, da die Berechnung ungültig ist.",
    "ERR_MAX_PLAETZE_CALCULATION_FAILED": "Der Wert der max. Anzahl Plätze kann nicht berechnet werden, da die Berechnung ungültig ist.",
    "ERR_ANZ_EMPTY_MAX_NOT": "Es können keine maximalen Plätze vergeben werden, wenn die dazugehörige Anzahl Plätze leer oder ungültig ist.",
    "ERR_INCOMPLETE_FIRMA": "Firma vollständig ausfüllen.",
    "ERR_KONTAKT_NOT_REMOVED": "Der Kontakt konnte nicht entfernt werden.",
    "ERR_HAUPTKONTAKT_REQUIRES_TELEPHONE": "Ein Hauptkontakt benötigt eine Telefonnummer.",
    "ERR_ERZIEHUNGSBERECHTIGTER_REQUIRES_ADDRESS": "Erziehungberechtigte benötigen eine Adresse.",
    "ERR_RECHNUNGSEMPFAENGER_REQUIRES_ADDRESS": "Ein Rechnungskontakt benötigt eine Adresse.",
    "ERR_HAUPTKONTAKT_REQUIRES_ADDRESS": "Ein Hauptkontakt benötigt eine Adresse.",
    "ERR_DUPLICATE_NAME": "Namen müssen eindeutig sein.",
    "ERR_EMAIL_REQUIRES_SUBJECT_AND_CONTENT": "E-Mail benötigt einen Betreff und Inhalt",
    "ERR_EMAIL_REQUIRES_ABSENDER_ADRESSE": "E-Mail benötigt Adresse und Name des Absenders",
    "ERR_EMAIL_REQUIRES_EMPFAENGER_ADRESSE": "E-Mail benötigt eine gültige Emfängeradresse",
    "ERR_NOTIZ_REQUIRES_SUBJECT_AND_CONTENT": "Notiz benötigt einen Betreff und Inhalt",
    "ERR_FIRMA_REQUIES_ADDRESS_NAME_AND_TELEFON": "Eine Firma benötigt einen Namen, eine Adresse und eine Telefonnummer",
    "ERR_INVALID_KINDERORT_ANGESTELLTE": "Mindestens einen Kinderort oder eine Angestellte auswählen.",
    "ERR_DUPLICATE_EXLUSION_RANGE": "Eine identische Ausnahme ist bereits erfasst",
    "ERR_EXCLUSION_INVALID": "Die erfasste Ausnahme ist ungültig",

    "ERR_FILE_TOO_LARGE": "Eine Datei ist zu gross.",
    "ERR_FILES_TOO_LARGE": "Die Dateien sind zu gross.",
    "ERR_UPLOAD_FAILED": "Upload fehlgeschlagen.",
    "ERR_UPLOAD_FAILED_MULTI_FILE": "Upload fehlgeschlagen. Bitte die Fehlgeschlagene Datei entfernen.",
    "ERR_FILE_NOT_VALID": "Datei ist nicht erlaubt.",
    "ERR_FILE_FORMAT_NOT_ALLOWED": "Dateiformat ist nicht erlaubt (erlaubt ist {{value}}).",
    "ERR_IMAGE_SMALL_HEIGHT": "Bild ist nicht hoch genug (mind. {{value}}px).",
    "ERR_IMAGE_LARGE_HEIGHT": "Bild ist zu hoch (max. {{value}}px).",
    "ERR_IMAGE_SMALL_WIDTH": "Bild ist nicht hoch genug (mind. {{value}}px).",
    "ERR_IMAGE_LARGE_WIDTH": "Bild ist zu hoch (max. {{value}}px).",
    "ERR_IMAGE_RATIO": "Bildverhältnis ist nicht erlaubt ({{value}}).",
    "ERR_IMAGE_SMALL_RATIO": "Bildverhältnis ist zu klein (> {{value}}).",
    "ERR_IMAGE_LARGE_RATIO": "Bildverhältnis ist zu gross (< {{value}}).",
    "ERR_IMAGE_DIMENSIONS": "Bildgrösse ist nicht erlaubt.",

    "ERR_TARIFPARAMETER_WITHOUT_NAMES": "Parameter müssen einen Namen haben.",
    "ERR_UNABHAENGIG_TARIF_MIT_MONATS_BASIERTE_PARAM": "Ein betreuungsunabhängiger Tarif kann keine monatsbasierte Parameter haben.",
    "ERR_ABRECHUNG_VORAUS_ZUSATZ_MONATE": "Bitte eine ganze Zahl für die Monate der Abrechnung im Voraus eingeben.",
    "ERR_INTEGER": "Bitte eine ganze Zahl eingeben.",
    "ERR_SMALLINVOICE_FORM": "Token muss exakt 32 Zeichen lang sein.",
    "ERR_KITA_RECHNUNGSKONFIGURATION_GUELTIG_BIS_NACH_GUELTIG_AB": "\"Gültig bis\" muss nach \"Gültig ab\" sein.",
    "ERR_KITA_RECHNUNGSLAUF_FAELLIG_AM_NACH_RECHNUNGS_DATUM": "\"Fällig am\" muss nach \"Rechnungsdatum\" sein.",
    "ERR_REVERT_AUSTRITT": "Der Austritt konnte nicht rückgängig gemacht werden.",
    "ERR_REVERT_BEENDEN": "Die Beendigung konnte nicht rückgängig gemacht werden.",
    "ERR_ENTITY_EXISTS": "Es gibt bereits eine Entität des Typs {{entityClassName}} mit {{constraintName}} {{duplicateValue}}.",
    "ERR_ENTITY_NOT_FOUND": "Die Entität des Typs {{entityClassName}} mit ID {{entityId}} wurde nicht gefunden.",
    "ERR_RECHNUNGSLAUF": "Es ist ein Fehler bei der Verarbeitung des Rechnungslauf aufgetreten.",
    "ERR_KINDERGARTEN_CHOICE": "Bitte eine gültige Auswahl für Kindergarten / Schule treffen.",
    "ERR_KITA_RECHTE_WERDEN_GELOESCHT": "Diese Standard-Rolle schliesst Kinderort-Rechte aus. Sie gilt über alle Kinderorte. Kinderort-spezifische Berechtigungen werden deshalb gelöscht.",
    "ERR_AUTHORIZATION": "Zugriff verweigert.",
    "ERR_EINGEWOEHNUNG_VON_BIS": "Bitte Beginn und Ende der Eingewöhnungsphase eingeben.",
    "ERROR": "Fehler",
    "ERR_RULE_VIOLATION": {
      "ERR_INVALID_TEMPLATE_REPORT_TYPE": "ReportTyp des Templates stimmt nicht überein. Erwartet: \"{{expectedValue}}\"; aus Template gelesen: \"{{actualValue}}\""
    },
    "PW_VIOLATION_EMPTY_PASSWORD": "Ein leeres Passwort ist nicht erlaubt.",
    "PW_VIOLATION_TOO_LONG": "Das Passwort ist zu lang.",
    "PW_VIOLATION_EMPTY_SALT": "Das Speichern des Passwortes ist fehlgeschlagen.",
    "PW_VIOLATION_CONFIRMATION_FAILURE": "Die Passwörter stimmen nicht überein.",

    "ERR_LOGIN_TOKEN_EXPIRED": "Sorry, diese Anmeldungs-Methode ist nicht mehr gültig.",

    "SI_ERR_CLIENT_DOES_NOT_EXIST": "Der Rechnungskontakt {{rechnungsEmpfaengerDisplayName}}, Kunden-Nr. {{kundenNummer}, existiert nicht mehr auf SmallInvoice. Wenden Sie sich an den Support.",
    "SI_ERR_CLIENT_IS_DELETED": "Die Rechnung {{rechnungsNummer}} kann nicht erstellt werden, da der Rechnungskontakt {{rechnungsEmpfaengerDisplayName}} auf SmallInvoice gelöscht wurde.",
    "SI_ERR_CLIENT_NUMBER_DUPLICATE": "Es gibt bereits einen Rechnungskontakt auf SmallInvoice mit der Kunden-Nr. {{kundenNummer}}.",
    "SI_ERR_COULD_NOT_GET_PDF": "Das PDF konnte nicht von SmallInvoice heruntergeladen werden.",
    "SI_ERR_CONTACT_OF_INVALID_CLIENT": "Der Rechnungskontakt ist ungültig. Wenden Sie sich an den Support.",
    "SI_ERR_CLIENT_ADDRESS_DOES_NOT_EXIST": "Die Kundenadresse existiert nicht mehr auf SmallInvoice. Wenden Sie sich an den Support.",
    "SI_ERR_ESR_DOES_NOT_EXIST": "Die Einzahlungsschein Konfiguration existiert nicht mehr auf SmallInvoice. Wenden Sie sich an den Support.",
    "SI_ERR_EMAIL_ADDRESS": "Die E-Mail Adresse von {{rechnungsEmpfaengerDisplayName}} wurde von SmallInvoice nicht akzeptiert.",
    "SI_ERR_INVALID_CONTACT_ID": "Die Kontakte von {{rechnungsEmpfaengerDisplayName}} konnten nicht aktualisiert werden. Wenden Sie sich an den Support.",
    "SI_ERR_ESR_NUMBER_DUPLICATE": "Es gibt bereits eine Rechnung auf SmallInvoice mit der ESR-Nr. {{esrNummer}}.",
    "SI_ERR_MISSING_DOCUMENT_TYPE": "Die Rechnung {{rechnungsNummer}} konnte nicht übermittelt werden. Wenden Sie sich an den Support.",
    "SI_ERR_INVOICE_DOES_NOT_EXIST": "Die Rechnung {{rechnungsNummer}} existiert nicht mehr auf SmallInvoice.",
    "SI_ERR_INVOICE_NUMBER_DUPLICATE": "Es gibt bereits ein Rechnung auf SmallInvoice mit der Rechnungs-Nr. {{rechnungsNummer}}.",
    "SI_ERR_OBJECT_DOES_NOT_EXIST": "Ein Objekt konnte nicht mehr auf SmallInvoice gefunden werden.",
    "SI_ERR_OVERDUE_PAYMENT": "Die Rechnung von SmallInvoice wurde nicht rechtzeitig bezahlt. SmallInvoice hat den Account deswegen blockiert.",
    "SI_ERR_PAYDATE": "Das Rechnungsdatum muss vor dem Zahlungsdatum liegen.",
    "SI_ERR_PAYMENTS_TOO_LARGE_AMOUNT": "Eine Zahlung konnte nicht übermittelt werden, da kein höherer Betrag als das Rechnungstotal erlaubt ist.",
    "SI_ERR_RATE_LIMIT": "SmallInvoice ist überlastet. Versuchen Sie es in einigen Minuten nochmals und wenden Sie sich an den Support.",
    "SI_ERR_TOKEN_EXPIRED": "Der API-Token der Rechnungskonfigurationen ist ungültig.",
    "SI_ERR_NO_TOKEN": "Der API-Token der Rechnungskonfigurationen fehlt.",
    "SI_ERR_NO_ACCESS_RIGHTS": "Fehlende Rechte für mindestens ein angeforderdetes Objekt.",
    "SI_ERR_UNKNOWN": "Die Kommunikation mit SmallInvoice ist wegen eines unbekannten Fehlers fehlgeschlagen: {{message}}.",
    "SI_ERR_USER_DELETED": "Die Kommunikation mit SmallInvoice schlägt fehl, weil der Benutzer auf SmallInvoice gelöscht wurde.",
    "SI_SERVICE_UNAVAILABLE": "SmallInvoice ist momentan leider nicht verfügbar. Versuchen Sie es später nochmal.",
    "SI_ERR_ZAHLUNG_STORNIEREN": "Eine SmallInvoice Zahlung kann nur auf SmallInvoice storniert werden.",
    "CONFIGURATION_ERROR": "Die Faktura Konfiguration ist fehlerhaft: {{message}}.",

    "ERR_FAKTURA_ENDPOINT_ESR": "Die Einzahlungsschein-Optionen können momentan nicht von {{provider}} geladen werden.",
    "ERR_FAKTURA_ENDPOINT_INVOICE": "Rechnungen können momentan nicht von {{provider}} geladen werden.",
    "ERR_FAKTURA_ENDPOINT_ACCOUNT": "Die Zahlungskonten können momentan nicht von {{provider}} geladen werden.",
    "ERR_FAKTURA_ENDPOINT_PAYMENT": "Zahlungen können momentan nicht von {{provider}} geladen werden.",
    "ERR_FAKTURA_ENDPOINT_LAYOUT": "Die Layout-Optionen können momentan nicht von {{provider}} geladen werden.",
    "ERR_FAKTURA_ENDPOINT_CLIENT": "Kunden können momentan nicht von {{provider}} geladen werden.",
    "ERR_STORNIEREN": "Die Rechnungen konnten aufgrund einer technischen Störung nicht storniert werden.",
    "ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL": "Die Kontaktperson hat keine E-Mail Adresse hinterlegt.",
    "ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL_SHORT": "Keine E-Mail Adresse hinterlegt.",
    "CLOSE_ERRORBAR": "Schliessen",
    "CLOSE_SUCCESSBAR": "Schliessen",

    "PRIORITIZE_JIRA_TASK": "Um diesen Fehler nicht mehr zu sehen, erhöhe die Priorität von {{task}}.",
    "KITAX_IMPORT_ERROR": "Die hochgeladene Datei konnte nicht gelesen werden.",

    "ERR_CHF": "Betrag (CHF) muss grösser 0 sein.",
    "ERR_ANZAHL": "Anzahl muss grösser 0 sein und auf maximum 3 Nachkommastellen gerundet werden.",
    "ERR_VON_BIS_SAME_MONTH": "Von und Bis müssen im selben Monat sein.",

    "ERR_ACCEPT_AGB": "Um fortzufahren müssen die allgemeinen Geschäftsbedingungen akzeptiert werden.",

    "ERR_INVALID_ARBEITSZEIT": "Bis-Zeit darf nicht vor Von-Zeit sein.",

    "SPF": {
      "TITLE": "Warnung",
      "TEXT": "Der SPF Eintrag ist nicht korrekt.<br>E-Mails werden vermutlich nicht korrekt ankommen.<br>Bitte wenden Sie sich an den Support.",
      "LINK_TEXT": "Blogbeitrag zu SPF",
      "LINK_URL": "https://blog.kitadmin.ch/e-mails-aus-kitadmin-verschicken-e-mail-werden-von-empfaengern-zurueckgewiesen"
    }
  },
  "TARIF": {
    "TYPE": {
      "BETREUUNGS_ABHAENGIG": "Betreuungsabhängig",
      "BETREUUNGS_UNABHAENGIG": "Betreuungsunabhängig",
      "BETREUUNGS_STUNDEN": "Betreuungsstunden",
      "PROVISORISCHER_BG": "Provisorische Gutscheinkorrektur",
      "UNMODIFIABLE": "Der Typ des Tarifs kann nachträglich nicht mehr geändert werden"
    },

    "PARAM_TYPE": {
      "GLOBAL_PARAM": "Global",
      "MONATS_BASIERT": "Monatsbasiert"
    },

    "PARAM_DESCRIPTION": {
      "GLOBAL_PARAM": "Global: Auf dem Kind werden die Tarifparameter-Werte mit einer Gültigkeit hinterlegt und mit einem Kinderort verknüpft",
      "MONATS_BASIERT": "Monatsbasiert: Auf der Fraktion (Gruppe/Betreuungsperson) werden im Monatsblatt die Tarifparameter-Werte pro Monat und Kind hinterlegt und mit der Fraktion verknüpft."
    },

    "ABRECHNUNG_TYP": {
      "MF": "{name, select, PRIVAT{Privater Tarif} SUBVENTIONIERT{Subventionierter Tarif} BETREUUNGSGUTSCHEIN{Betreuungsgutschein Tarif} BETREUUNGS_UNABHAENGIG{Betreuungsunabhängiger Tarif} PROVISORISCHER_BG{Provisorische Gutscheinkorrektur Tarif} other{}}"
    },

    "PARAMETER_HINZUFUEGEN": "Parameter definieren",
    "NAME_PARAMETER": "Name Parameter",
    "AKTUELLER_TARIF_VALUE_MIT_DATUMPREP": "Aktuelle Tarifversion {{preposition}}",
    "TARIF_VALUE_MIT_DATUMPREP": "Tarifversion {{preposition}}",
    "TARIF_VALUE": "Tarifversion",
    "TARIF_VALUE_VERSION": "V{{version}}",
    "TARIF_HOCHLADEN_LABEL": "Excel-Tarifrechner hochladen",
    "TARIF_DOWNLOAD_LABEL": "Excel-Tarifrechner herunterladen",
    "TARIF_VORLAGE_LABEL": "Tarif Vorlage",
    "TARIF_STAMMDATEN": "Tarif Stammdaten",
    "VORLAGEN": "Vorlagen",
    "TARIF_NEUER_VALUE": "Neue Tarifversion hochladen",
    "TARIF_BEENDEN": "Tarif beenden",
    "TARIF_DELETE": "Tarif löschen",
    "EROEFFNUNG": "Eröffnung",
    "POSITIONS_TOTAL_MANUAL": "Positionstotal aus Totalbetrag-Spalte lesen",
    "POSITIONS_TOTAL_AUTO": "Positionstotal aus Anzahl und Betrag ausrechnen",
    "BETREUUNGS_UNABHAENGIG_TARIF": "Betreuungsunabhängiger Tarif",
    "TARIF_TYPE_WAEHLEN": "1. Typ wählen",
    "NAME_EINGEBEN": "2. Name eingeben",
    "TARIF_PARAMETER": "3. Parameter",
    "EXCEL_TEMPLATE_HERUNTERLADEN": "4. Vorbereitetes Excel Template herunterladen",
    "EXCEL_DATEI_HOCHLADEN": " 5. Excel Datei hochladen",
    "OPTIONEN": "6. Optionen",
    "TARIF_TYP": "Typ: {{type}} ",
    "PARAM_TYP": "Param Typ",
    "USED_BY": "Verwendet von",
    "NULL_TARIF": "Nulltarif",

    "ERRORS": {
      "ERR_INCOMPLETE_TARIF": "Tarif vollständig ausfüllen (mit Tarif-File uploaden).",
      "ERR_DUPLICATED_TARIFPARAMETER_NAMES": "Die Parameter Namen müssen eindeutig sein.",
      "ERR_INCOMPLETE_PARAMETER_HISTORY_ENTRY": "\"Gültig ab\" ausfüllen und nur Zahlen als Parameterwerte eingeben.",
      "ERR_BETREUUNGSGUTSCHEIN_VERGUENSTIGTER_BETRAG": "Gutschein (CHF) muss grösser 0 sein.",
      "ERR_BETREUUNGSGUTSCHEIN_VOLLKOSTEN": "Vollkosten (CHF) muss grösser 0 sein.",
      "ERR_BETREUUNGSGUTSCHEIN_GUTSCHEIN_LARGER_VOLLKOSTEN": "Der Betrag des Betreuungsgutscheins darf nicht grösser sein als Vollkosten.",
      "ERR_BETREUUNGSGUTSCHEIN_BG_PENSUM_PROZENT": "BG-Pensum (%) muss grösser 0% sein.",
      "ERR_BETREUUNGSGUTSCHEIN_KITA": "Bitte einen Kinderort auswählen.",
      "ERR_BETREUUNGSGUTSCHEIN_WERTE": "Der Betrag des Betreuungsgutscheins darf nicht grösser sein als Vollkosten."
    }
  },
  "WOCHENPLAN": {
    "SHOW_ZEITRAUM_NAME": "Namen der Zeiträume anzeigen?",
    "WOCHENTAGE": "Wochentage: ",
    "BETREUUNGSZEITRAEUME": "Betreuungszeiträume: ",
    "PROZENTPUNKTE": "{{prozent}}%",
    "PRIO": "Prio: {{prio}}",
    "CREATE_BELEGUNGS_EINHEIT": "Belegungseinheit erstellen",
    "BETREUUNGS_ZEITRAUM": {
      "VERPFLEGUNG": "Verpflegung",
      "VERPFLEGUNG_OHNE": "keine",
      "VERPFLEGUNGS_TYP": {
        "FRUEHSTUECK": "Frühstück",
        "ZWISCHENVERPFLEGUNG_VORMITTAG": "Znüni",
        "MITTAGESSEN": "Mittagessen",
        "ZWISCHENVERPFLEGUNG_NACHMITTAG": "Zvieri",
        "ABENDESSEN": "Abendessen"
      }
    }
  },
  "GROUP_ADMIN": {
    "EINSTELLUNGEN": {
      "TITLE": "groupAdmin Module",
      "SUBTITLE": "Module",
      "NONE": "Keine Module verfügbar",
      "MODULE_ERSTELLEN": "Module erstellen",
      "MODULE_BEARBEITEN": "Module bearbeiten",
      "MODULE_LOESCHEN": "Modul löschen",
      "ADD_TIME_RANGE": "+ Zeitraum hinzufügen",
      "REMOVE_TIME_RANGE": "Löschen",
      "DELETE_ROW": "Zeitraum löschen {{von}} - {{bis}}"
    }
  },
  "PERSONAL": {
    "TITLE": "Personal",
    "PERSONAL_SETTINGS": "Personaleinstellungen",
    "BEDARF": "Personalbedarf",
    "ZUWEISUNGEN": "Zuweisungen",
    "STAFF_COUNT": "Total {{count}} Personen",
    "ANGESTELLTE": {
      "SINGULAR": "Angestellte",
      "PLURAL": "Angestellte",
      "MF": "{showcount, select, true{{count} } other{}} Angestellte",
      "NONE": "Keine Angestellte",
      "ALLE": "Alle Angestellten",
      "TITLE": "Angestellte Stammdaten",
      "CREATE": "Angestellte erfassen",
      "ADD_ADDRESS": "Adresse hinzufügen",
      "REMOVE_ADDRESS": "Adresse entfernen",
      "ASSIGN_USER": "Benutzer zuweisen",
      "AUSWAEHLEN": "Angestellte auswählen",
      "EXCLUDED": "Ausgeschlossene Angestellte:",
      "EXCLUDED_AUSWAEHLEN": "ausgeschlossene Angestellte auswählen"
    },
    "ANSTELLUNG": {
      "TITLE": "Anstellung",
      "VERLAUF": "Anstellungsverlauf",
      "NO_ANSTELLUNG": "Keine Anstellung vorhanden",
      "CREATE": "Neue Anstellung erstellen",
      "EDIT": "Anstellung bearbeiten",
      "FROM": "Anstellung ab {{date}}",
      "CURRENT": "Aktuelle Anstellung",
      "TO": "Anstellung bis {{date}}",
      "WEEKLY_HOURS": "Stunden / Woche",
      "PENSUM": "Anstellungspensum",
      "NICHT_VERFUEGBAR": "Nicht verfügbar",
      "ARBEITSPENSUM_PROZENT": "Prozent",
      "WEEKLY_HOURS_COUNT": "{{hours}} Stunden / Woche",
      "ARBEITSPENSUM_PROZENT_COUNT": "{{prozent}}%",
      "DAILY_HOURS": "Tägliche Anstellungszeiten",
      "ZEIT_FROM_TO": "Anstellungszeit {{from}} - {{to}}",
      "ZEIT_FROM_TO_EMPTY": "neue Anstellungszeit",
      "DAILY_AVAILABILITY": "Tägliche Verfügbarkeit",
      "DAILY_SOLLZEIT": "Tägliche Sollzeit",
      "SOLLZEIT": "Sollzeit",
      "SOLLZEIT_WITH_DAY": "Sollzeit {{day}}",
      "SOLLZEIT_SUM": "Ergibt {{sum}} Stunden pro Woche",
      "ZEIT_FROM": "Anstellungszeit von",
      "ZEIT_TO": "Anstellungszeit bis",
      "ADD_AVAILABILITY": "Verfügbarkeit hinzufügen",
      "REMOVE_AVAILABILITY": "Verfügbarkeit entfernen",
      "EDUCATION": "Ausbildungsstand",
      "ADDITIONAL": "Weiteres",
      "VERTRETUNGSPERSON": "Vertretungsperson",
      "NO_DAILY_ANSTELLUNG": "Keine vordefinierten Verfügbarkeiten",
      "ZUWEISUNG": "Anstellung",
      "NEUE_ZUWEISUNG": "Neue Anstellung zuweisen",
      "ZUWEISUNG_BEENDEN": "Anstellung beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Anstellung für {{assignedEntity}} beenden?",
      "PENSUM_UND_VERFUEGBARKEIT": "Pensum und Verfügbarkeit",
      "ADD_STANDORT": "+ Anstellungsstandort hinzufügen",
      "CONFIRM_BEENDEN": "Es gibt noch zugewiesene Anstellungen nach dem {{date}}. Wie soll damit umgegangen werden?",
      "TERMINATION_MODE": {
        "TERMINATE": "Anstellung am {{date}} beenden. Künftige Anstellungen bleiben erhalten.",
        "DELETE_TILL_NEXT_GAP": "Die Anstellung am {{date}} beenden und direkt anschliessende Anstellungen löschen.",
        "DELETE_FOLLOWING": "Die Anstellung am {{date}} beenden und alle künftigen Anstellungen löschen."
      }
    },
    "PLANUNG": {
      "KINDER_ORT_SCHLUESSEL": "Kinderort-Schlüssel",
      "OTHER_ANGESTELLTE": "Weitere Angestellte",
      "ADDITIONAL_ANGESTELLTE": "+ Mitarbeitende hinzufügen",
      "SEARCH_ANGESTELLTE": "Mitarbeitende suchen",
      "DAY_INFO": "Tagesinfo"
    },
    "ANGESTELLTE_ZUWEISUNG": {
      "TITLE": "Zuweisung"
    },
    "SORT": {
      "TITLE": "Sortierung",
      "SORTED": "Sortiert",
      "UNSORTED": "Unsortiert",
      "NO_SORTABLE_ITEMS": "Keine Sortierung möglich"
    },
    "ZUWEISUNG": {
      "PLURAL": "Deinstzuweisungen",
      "TITLE": "Dienstzuweisung",
      "VERLAUF": "Zuweisungsverlauf",
      "FROM": "Zuweisung ab {{date}}",
      "OPTIONAL_BIS": "Bis (optional)",
      "NONE": "Keine Zuweisung vorhanden",
      "EDIT": "Zuweisung bearbeiten",
      "ZUWEISUNG": "Dienstzuweisung",
      "ZUWEISBARE_DIENSTE": "Zuweisbare Dienste",
      "DIENST_BESCHREIBUNG": "{{name}}: {{von}} - {{bis}}",
      "UEBERNEHMEN": "Zuweisung übernehmen",
      "KOPIE_MIT_ZUSATZ": "Neu mit zusätzlichen Diensten",
      "KOPIE_OHNE": "Dienste entfernen",
      "COPY": "Kopieren",
      "EMAIL": "E-Mail",
      "EMAIL_TITLE": "Personalplan per E-Mail versenden",
      "EMAIL_RECIPIENT_COUNT_MF": "{count} versendbare {count, plural, one{E-Mail} other{E-Mails}}",
      "ANGESTELLTE_EMAIL_MISSING": "Bei den folgenden Angestellten ist keine E-Mail Adresse hinterlegt:",
      "SEND_EMAIL": "Personalplan versenden",
      "COPY_TITLE": "Zuweisungen kopieren",
      "COPY_SOURCE": "Zu kopierende Wochen",
      "COPY_TARGET": "Einfügen in Wochen",
      "COPY_CONFLICT_TITLE": "Konflikt beim Kopieren",
      "COPY_CONFLICT_SUBTITLE": "Im Einfügebereich sind bereits Zuweisungen vorhanden.<br> Sollen diese gelöscht oder beibehalten werden?",
      "COPY_CONFLICT_DELETE": "löschen",
      "COPY_CONFLICT_KEEP": "beibehalten",
      "ZUWEISUNG_BEENDEN": "Dienstzuweisung beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Dienstzuweisung für {{assignedEntity}} beenden?",
      "NEUE_ZUWEISUNG": "Neue Dienstzuweisung",
      "CONFIRM_BEENDEN": "Es gibt noch zugewiesene Dienste nach dem {{date}}. Wie soll damit umgegangen werden?",
      "VON_BIS_TOOLTIP": "{{von}}-{{bis}}\nDienstzuweisung ",
      "TERMINATION_MODE": {
        "TERMINATE": "Dienstzuweisung am {{date}} beenden. Künftige Dienstzuweisungen bleiben erhalten.",
        "DELETE_TILL_NEXT_GAP": "Die Dienstzuweisung am {{date}} beenden und direkt anschliessende Dienstzuweisungen löschen.",
        "DELETE_FOLLOWING": "Die Dienstzuweisung am {{date}} beenden und alle künftigen Dienstzuweisungen löschen."
      },
      "PERSONALPLAN_GENERIEREN": "Report",
      "ALLE_AN_ABWAEHLEN": "Alle An-/Abwählen",
      "VERTRETUNGSPERSONAL_AN_ABWAEHLEN": "An-/Abwählen"
    },
    "ERROR": {
      "PERSONAL_SELECTION_REQUIRED": "Mindestens eine Person muss ausgewählt werden.",
      "ZEITRAUM_SELECTION_REQUIRED": "Mindestens ein Zeitraum muss ausgewählt werden.",
      "AUSBILDUNG_REQUIRED": "Mindestens eine Ausbildungsstufe muss ausgewählt werden.",
      "DUPLICATE_AUSBILDUNGSNAME": "Ausbildungsnamen müssen eindeutig sein.",
      "ZUWEISUNG_GUELTIG_BIS": "Ungültiges bis Datum.",
      "DIENST_OVERLAP": "Es gibt Überschneidungen mit bereits zugewiesenen Diensten.",
      "DIENST_OVERLAP_SUBTITLE": "<p>Damit die zugewiesenen Personen nicht in zwei zeitlich überschneidenden Diensten arbeiten, werden bereits zugewiesene, überschneidende Dienste in den anderen Gruppen entfernt.</p><p>Betrifft: {{displayNames}}<p><p>Möchten Sie fortfahren?<p>",
      "DIENST_OVERLAP_LIST": "Dienste überschneiden sich.\n {{conflicts}}"
    },
    "BETREUUNGS_SCHLUESSEL": {
      "SINGULAR": "Betreuungsschlüssel",
      "PLURAL": "Betreuungsschlüssel",
      "MF": "{showcount, select, true{{count} } other{}}Betreuungsschlüssel",
      "SCHLUESSEL": "Schlüssel",
      "TYPE": {
        "SINGULAR": "Betreuungsschlüssel Typ",
        "FRAKTION": "Fraktions-Schlüssel",
        "KINDER_ORT": "Kinderort-Schlüssel"
      },
      "KEIN_BETREUUNGS_SCHLUESSEL": "Kein Betreuungsschlüssel",
      "CREATE": "Betreuungsschlüssel erstellen",
      "DEFAULT_TAG": "Tagesunabhängiger Schlüssel",
      "TAG": "Wochentag",
      "ADD_RULE": "Schlüssel hinzufügen",
      "DELETE_RULE": "Schlüssel löschen",
      "ADD_AUSBILDUNGS_BEDARF": "Ausbildungsstand hinzufügen",
      "ADD_BEDARF": "Zusätzlicher Bedarf",
      "BEDARF_MF": "{type, select, BOTH{ab {plaetze, plural, =1{# Platz} other{# Plätze}}, ab {kinder, plural, =1{# Kind} other{# Kinder}}} KINDER{ab {kinder, plural, =1{# Kind} other{# Kinder}}} PLAETZE{ab {plaetze, plural, =1{# Platz} other{# Plätze}}} other{}}",
      "VON_PLAETZE": "ab Anzahl Plätze",
      "VON_KINDER": "ab Anzahl Kinder",
      "CHILD_COUNTS_MF": "{plaetze, plural, =1{# Platz} other{# Plätze}}, {children, plural, =1{# Kind} other{# Kinder}}",
      "BEDARF": "Personalbedarf"
    },
    "STUNDENERFASSUNG": {
      "TITLE": "Stundenerfassung",
      "MONAT": "Monat",
      "KEINE_EINTRAEGE": "Für den ausgewählten Tag gibt es keine zugewiesenen Kinder",
      "CALENDAR_TOOLTIP": "Betreuung Tag",
      "STUNDEN": "Total Stunden",
      "ADD_TIMES": "Zusätzliche Zeiten erfassen",
      "REOPEN": "Erneut eröffnen",
      "FREIGEBEN": "Freigeben",
      "FREIGEBEN_CONFIRM": "Nach der Freigabe kann der Monat nicht mehr bearbeitet werden. Wirklich freigeben?",
      "KORRIGIERT": "Korrektur abgeschlossen",
      "BESTAETIGEN": "Bestätigen",
      "NAV_TO_MONATSBLATT": "zum Monatsblatt",
      "ABWEICHUNG": "Abweichung hinzufügen",
      "MONTH_SUMMARY": "Monatsübersicht",
      "ORIGINAL_MF": "Ursprünglich {original, select, null{leer} other{{original}}}",
      "ORIGINAL_ZEITEN": "Ursprüngliche Zeiten",
      "NO_DOWNLOAD_DATA": "Es sind noch keine Daten vorhanden.",
      "ERFASSUNGS_BEMERKUNG": "Bemerkung",
      "KORREKTUR_BEMERKUNG": "Bemerkung Admin",
      "VERTRAGLICHE_STUNDEN": "Vertragliche Stunden: {{stunden}}h",
      "VERTRAGLICHE_STUNDEN_MISMATCH": "Die betreuten Stunden sind tiefer als die vertraglichen Stunden"
    },
    "STUNDENBLAETTER": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Stundenblatt} other{Stundenblätter}}",
      "PLURAL": "Stundenblätter",
      "STATE": {
        "NONE": "Keine Eingabe",
        "OFFEN": "Offen",
        "FREIGEGEBEN": "Freigegeben",
        "KORRIGIERT": "Korrigiert",
        "BESTAETIGT": "Bestätigt"
      },
      "DESCRIPTION": {
        "NONE": "Es wurden noch keine Daten erfasst, jedoch können diese durch die Betreuungsperson erfasst werden.",
        "OFFEN": "Es wurden Daten erfasst und die Stundenerfassung liegt bei der Betreuungsperson.",
        "FREIGEGEBEN": "Die Stundenerfassung wurde von der Betreuungsperson freigegeben zur Kontrolle durch den Administrator.",
        "KORRIGIERT": "Die Stundenerfassung wurde durch den Administrator Kontrolliert.",
        "BESTAETIGT": "Die Stundenerfassung wurde durch den Administrator Bestätigt. Die Daten wurden vom Monatsblatt übernommen"
      }
    },
    "AUSBILDUNGEN": {
      "SINGULAR": "Ausbildung",
      "PLURAL": "Ausbildungen",
      "NONE": "Keine Ausbildungen",
      "HINZUFUEGEN": "Ausbildung hinzufügen",
      "REQUIRED": "{{ausbildungName}}: {{assigned}}/{{required}}"
    },
    "CHART": {
      "BEDARF": "Personalbedarf als Anzahl Vollzeit Anstellungen",
      "BEDARF_FTE": "{{fte}} FTE Bedarf",
      "ASSIGNED": "Zugewiesene Anzahl Vollzeit Anstellungen",
      "ASSIGNED_FTE": "{{fte}} FTE zugewiesen",
      "EMPLOYED": "Vollzeit Anstellungen",
      "EMPLOYED_COUNT": "{{count}} Vollzeit Anstellungen",
      "FTE_EXPLANATION": "FTE = Full Time Equivalent\nAnzahl Vollzeit Anstellungen"
    },
    "FILTER": {
      "ANSTELLUNG_OHNE": "Anstellung ohne",
      "ANSTELLUNG_MIT": "Anstellung mit",
      "ANSTELLUNG_ZUWEISUNG_PENSUM": "Anstellungspensum / Zuweisung",
      "DIENST_ZUWEISUNG": {
        "TITLE": "Zuweisung",
        "ASSIGNED": "Zugewiesen",
        "NOT_ASSIGNED": "Nicht zugewiesen"
      },
      "TERMIN": {
        "HIDE": "Nur Anwesende",
        "ONLY_ABSENT": "Nur Abwesende",
        "BEDARFSRELEVANT": "Nur Bedarfsrelevante Termine"
      },
      "BEZUGS_PERSON": "Nur Bezugspersonen"
    },
    "KONFIGURATION": {
      "SINGULAR": "Personalkonfiguration",
      "PLURAL": "Personalkonfigurationen",
      "ZUWEISUNG": "Personalkonfiguration",
      "TO_MANDANT_CONFIG": "Zur Organisations-Konfiguration",
      "WEEKLY_HOURS": "Wöchentliche Arbeitsstunden",
      "FTE_HOURS": "Wöchentliche FTE Stunden",
      "WEEKLY_HOURS_VAL": "{{hours}} h/Woche",
      "FTE_HOURS_VAL": "{{hours}} FTE h/Woche",
      "KEINE": "Keine Konfiguration vorhanden",
      "NEUE_ZUWEISUNG": "Neue Konfiguration",
      "ZUWEISEN": "Konfiguration zuweisen",
      "ZUWEISUNG_BEENDEN": "beenden",
      "ZUWEISUNG_BEENDEN_HEADING": "Personalkonfiguration beenden",
      "CONFIRM_BEENDEN": "Es gibt noch zugewiesene Konfigurationen nach dem {{date}}. Wie soll damit umgegangen werden?",
      "TERMINATION_MODE": {
        "TERMINATE": "Konfiguration am {{date}} beenden. Künftige Konfigurationen bleiben erhalten.",
        "DELETE_TILL_NEXT_GAP": "Die Konfiguration am {{date}} beenden und direkt anschliessende Konfigurationen löschen.",
        "DELETE_FOLLOWING": "Die Konfiguration am {{date}} beenden und alle künftigen Konfigurationen löschen."
      },
      "ARBEITSZEIT": {
        "VON_BIS": "Arbeitszeit {{von}} - {{bis}}",
        "VON": "Arbeitszeit von",
        "BIS": "Arbeitszeit bis"
      }
    },
    "TERMIN": {
      "SINGULAR": "Termin",
      "PLURAL": "Termine",
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Termin} other{Termine}}",
      "TITLE": "Termine",
      "NONE": "Keine Termine erfasst",
      "ERSTELLEN": "{{entity}} erfassen",
      "EDIT": "{{entity}} bearbeiten",
      "VON": "Zeit von (optional)",
      "BIS": "Zeit bis (optional)",
      "NAME": "Titel",
      "ABWESENHEIT": "Abwesenheit",
      "DELETE_TITLE": "{{entity}} definitiv löschen?",
      "DELETE_TITLE_ALL": "{{entity}} definitiv löschen?",
      "DELETE_ALL_SUBTITLE": "{{entity}} wird bei allen Angestellten gelöscht.",
      "DELETE_SOME_SUBTITLE": "{{entity}} wird bei folgenden Personen gelöscht:",
      "DELETE_IN_KITA_MF": "{entity} wird in {count} {count, plural, one{Kinderort} other{Kinderorten}} gelöscht.",
      "DELETE_IN_ALL_KITAS": "{{entity}} wird in allen betroffenen Kinderorte gelöscht.",
      "DELETE_UPDATE_MODE": "Alle oder nur bestimmte Elemente der Terminserie löschen?",
      "EDIT_UPDATE_MODE": "Alle oder nur bestimmte Elemente der Terminserie editieren?",
      "UPDATE_MODE": {
        "ALL": "Alle",
        "ALL_FUTURE": "Alle zukünftigen",
        "SINGLE": "Dieses Serienelement"
      },
      "BEMERKUNG": "Bemerkung",
      "TYPE": {
        "TITLE": "Termintypen",
        "SINGULAR": "Termintyp",
        "NONE": "Keine Termintypen vorhanden",
        "DEFAULT": "Standard Termintyp",
        "NO_DEFAULT": "Kein standard Termintyp",
        "NEW": "Neuen Termintypen erstellen",
        "EDIT": "Termintyp bearbeiten",
        "NAME": "Name",
        "DEDUCT_FROM_BEDARFSRECHNUNG": "Angestellte während Termin nicht zum Betreuungsschlüssel dazurechnen",
        "WORK_TIME_TYPE_TITLE": "Arbeitszeit",
        "WORK_TIME_TYPE": {
          "NO_WORK_TIME": "Termine sind keine Arbeitszeit",
          "WORK_TIME": "Termine sind Arbeitszeit",
          "DEDUCT_FROM_PLANNED_TIME": "Termine unterbrechen Arbeitszeit"
        },
        "BEDARFSRECHNUNG_TITLE": "Bedarfsrechnung"
      },
      "DEDUCT_FROM_BEDARF_RECHNUNG": "von der Bedarfsrechnung abziehen",
      "DEDUCT_FROM_ARBEITSZEIT": "von der Arbeitszeit abziehen",
      "KINDERORT_TERMIN": "Personal Termine",
      "FOR_KINDER_ORT": "Kinderort Termin",
      "FOR_ANGESTELLTE": "Angestellte Termin",
      "WIEDERKEHRENDE": "wiederkehrend",
      "WIEDERHOLUNGS_TYP": "Wiederholungstyp",
      "INTERVAL": "Intervall",
      "FREQUENZ_PREFIX": "Alle",
      "FREQUENZ_TYP": {
        "DAILY": {
          "TITLE": "Täglich",
          "INTERVAL": "Tage"
        },
        "WEEKLY": {
          "TITLE": "Wöchentlich",
          "INTERVAL": "Wochen"
        },
        "MONTHLY": {
          "TITLE": "Monatlich",
          "INTERVAL": "Monate"
        }
      },
      "BIS_TITLE": "Wiederholen bis",
      "BIS_TYPE": {
        "TITLE": "Endet",
        "BIS_EWIG": "Nie",
        "BIS_DATUM": "Am",
        "ANZAHL": "Nach"
      },
      "INVALID_WIEDERHOLUNG_END": "Wiederholungen können nicht vor dem Start des Termins enden.",
      "WIEDERHOLUNGEN": "Wiederholungen",
      "EXCLUSIONS": "Wiederholungsausnahmen",
      "ADD_EXCLUSION": "Ausnahme hinzufügen",
      "RRULE": {
        "EVERY": "alle",
        "UNTIL": "bis",
        "DAY": "Tag",
        "DAYS": "Tage",
        "WEEK": "Woche",
        "WEEKS": "Wochen",
        "ON": "am",
        "IN": "in",
        "ON THE": "am",
        "FOR": "für",
        "AND": "und",
        "OR": "oder",
        "AT": "um",
        "LAST": "zuletzt",
        "ST": ".,",
        "ND": ".,",
        "RD": ".,",
        "TH": ".,",
        "(~ APPROXIMATE)": "",
        "TIMES": "mal",
        "TIME": "Zeit",
        "MINUTES": "Minuten",
        "HOURS": "Stunden",
        "WEEKDAYS": "Wochentag",
        "WEEKDAY": "Wochentag",
        "MONTHS": "Monate",
        "MONTH": "Monat",
        "YEARS": "Jahre",
        "YEAR": "Jahr"
      },
      "BACKGROUND_COLOR": "Farbe",
      "TEXT_COLOR": "Textfarbe",
      "OWN": "Eigene"
    },
    "BEZUGSPERSON": {
      "PLURAL": "Bezugspersonen",
      "SEARCH": "Bezugsperson suchen",
      "FOR_CHILDREN": "Bezugsperson für:\n{{names}}",
      "MISSING_MF": "Fehlende {count, plural, one{Bezugsperson} other{Bezugspersonen}}:\n{names}"
    },
    "DIENSTKONFIGURATION": {
      "TITLE": "Dienstadministration",
      "SINGULAR": "Dienst",
      "NONE": "Keine Dienste vorhanden",
      "NEW": "Neuen Dienst erstellen",
      "EDIT": "Dienst bearbeiten",
      "NAME": "Name",
      "KUERZEL": "Kürzel",
      "BACKGROUND_COLOR": "Farbe",
      "TEXT_COLOR": "Textfarbe",
      "PAUSE": "Pause",
      "PAUSE_VON": "Pause von",
      "PAUSE_BIS": "Pause bis"
    },
    "KORRESPONDENZ": {
      "TITLE": "Korrespondenz",
      "PAST": "Vergangene Korrespondenz"
    }
  },
  "WORK_TIME_CONTROLLING": {
    "TITLE": "Arbeitszeitkontrolle",
    "CONTROL": {
      "TITLE": "Kontrolle"
    },
    "SETTINGS": {
      "TITLE": "Einstellungen"
    },
    "WORK_TIME_MODEL": {
      "TITLE": "Arbeitszeitmodell",
      "SINGULAR": "Arbeitszeitmodell",
      "DEFAULT": "Standard Arbeitszeitmodell",
      "NO_DEFAULT": "Kein standard Arbeitszeitmodell",
      "NONE": "Keine Arbeitszeitmodelle vorhanden",
      "WITHOUT": "Ohne Arbeitszeitmodell",
      "SELECT": "Bitte wählen Sie ein Arbeitszeitmodell",
      "NEW": "Arbeitszeitmodell erstellen",
      "NAME": "Name",
      "WEEKLY_HOURS": "Wochenstunden",
      "EDIT": "Arbeitszeitmodell bearbeiten",
      "SOFT_DELETE": "Deaktivieren",
      "RESTORE": "Aktivieren",
      "SOFT_DELETED": "deaktiviert",
      "MANDANT_DEFAULT": "Mandant standard"
    },
    "WORK_TIME_TABLE": {
      "HEADER": {
        "EMPLOYEE": "Mitarbeitende",
        "DATE": "Datum",
        "TARGET_HOURS": "Soll Arbeitszeit",
        "PLANNED_HOURS": "Geplante Arbeitszeit",
        "IST": "Ist Arbeitszeit",
        "ABWESENHEIT": "Abwesenheit",
        "FERIENKONTINGIENT": "Ferienkontingent",
        "EXPAND_EMPLOYEE": "Tagesansicht einblenden",
        "COLLAPSE_EMPLOYEE": "Tagesansicht ausblenden",
        "EXPAND_DAY_DETAILS": "Detailansicht einblenden",
        "COLLAPSE_DAY_DETAILS": "Detailansicht ausblenden"
      }
    }
  },
  "KIBON": {
    "KIBON": "kiBon",
    "KIND_NAME_FILTER": "Kind Name",
    "NAV_VERFUEGUNGEN": "Verfügungen",
    "NAV_ALLE_VERFUEGUNGEN": "Gesamte Verfügungen",
    "NAV_ANFRAGEN": "Platzbestätigungen",
    "NAV_INSTITUTIONEN": "Institutionen",
    "NAV_TAGESSCHULE_ANMELDUNGEN": "Anmeldungen",
    "REFERENZ_NUMMER": "Referenznummer",
    "OHNE_INSTITUTION": "Ohne zugewiesene Institution",
    "IMPORTIERT_AM": "Importiert am {{date}}",
    "PERIODE": "Periode",
    "MUTATION": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Betreuungsmeldung} other{Betreuungsmeldungen}}",
      "PENDENTE_MELDUNGEN": "Pendente Betreuungsmeldungen",
      "PENDENTE_MELDUNGEN_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Pendente Betreuungsmeldung} other{Pendente Betreuungsmeldungen}}",
      "GESENDETE_MELDUNGEN": "Gesendete Betreuungsmeldungen",
      "IGNORIERTE_MELDUNGEN": "Ignorierte Betreuungsmeldungen",
      "GESENDET_UM": "{{time}} durch {{benutzer}}",
      "IGNORED_UM": "{{time}} durch {{benutzer}}",
      "KEINE_PENDENTE_MELDUNGEN": "Es gibt keine pendenten Betreuungsmeldungen.",
      "KEINE_GESENDETE_MELDUNGEN": "Es gibt keine gesendeten Betreuungsmeldungen.",
      "KEINE_IGNORIERTE_MELDUNGEN": "Es gibt keine ignorierten Betreuungsmeldungen.",
      "MELDEN": "Betreuungen an kiBon senden",
      "IGNORIEREN": "Betreuung ignorieren",
      "MELDEN_CONFIRM_TITLE_MF": "{count, plural, one{Betreuung an kiBon senden?} other{# Betreuungen an kiBon senden?}}",
      "MELDEN_CONFIRM_TEXT": "Für jede Betreuung, die an kiBon gesendet wird, wird eine Mutationsmeldung erstellt. Diese Mutationsmeldungen können nicht zurückgezogen werden<br><br>Im Gegensatz zum Rechnungslauf werden die Daten nicht automatisch aktualisiert. D.h. wenn sich z.B. eine Belegung oder ein Tarif ändert, müssen die Daten manuell neu berechnet werden.",
      "MELDEN_IGNORE_TITLE_MF": "{count, plural, one{Betreuung ignorieren?} other{# Betreuungen ignorieren?}}",
      "MELDEN_IGNORE_TEXT": "Die ausgewählten Betreuungen werden ignoriert und können nicht an kiBon gesendet werden.",
      "AUSSERORDENTLICHER_BETREUUNGSAUFWAND": "Ausserordentlicher Betreuungsaufwand",
      "VON": "Von",
      "BIS": "Bis",
      "BETREUUNGS_KOSTEN": "Betreuungskosten CHF",
      "BETREUUNGS_PENSUM": "Pensum",
      "BETREUUNGS_PENSUM_MF": "{pensumUnit, select, DAYS{ {betreuungspensum} Tage } HOURS{ {betreuungspensum} Stunden } other{ {betreuungspensum}% }}",
      "BETREUUNGS_PENSUM_MONTLY_MF": "{pensumUnit, select, DAYS{ {betreuungspensum} ({monthly})<sup>1</sup> Tage } HOURS{ {betreuungspensum} ({monthly})<sup>1</sup> Stunden } other{ {betreuungspensum}% }}",
      "HAUPT_MAHLZEITEN": "Hauptmahlzeiten\nAnzahl CHF",
      "NEBEN_MAHLZEITEN": "Nebenmahlzeiten\nAnzahl CHF",
      "NEU_GENERIEREN": "Daten für aktive Perioden neu berechnen",
      "EINGEWOEHNUNG": "Eingewöhnung\nPeriode CHF",
      "NEU_GENERIEREN_CONFIRM_TEXT": "Bei Kinderorten mit vielen Kindern dauert es bis zu einer Stunde, bis die Berechnungen abgeschlossen sind. In dieser Zeit sollten die Belegungen nicht verändert werden.",
      "IGNORIERTE_REF_NR_VERWALTEN": "Ignorierte Referenznummern verwalten",
      "MONTHLY_INFO": "\u00B9 Für kiBon müssen die Werte (in Klammern) für einen ganzen Monat eingegeben werden.",
      "COMPARISON": {
        "DIFFERENT": "Es wurden Änderungen gefunden",
        "UNDEFINED": "Die Werte lassen sich nicht vergleichen"
      },
      "TRIGGER": {
        "BETREUUNG_ANFRAGE": "Platzbestätigung",
        "ERNEUTE_BETREUUNG_ANFRAGE": "Platzbestätigung",
        "MUTATION": "Mutation"
      },
      "NICHT_IGNORIEREN": "Betreuungsmeldung nicht mehr ignorieren?",
      "STATUS_TOOLTIP_MF": "{provisorisch, select, true{Diese Betreuungsmeldung kann nicht an kiBon gesendet werden, da es in der Periode eine nicht-definitive Belegung oder einen provisorischen Austritt hat.} other{{status, select, MUTIERBAR{Betreuungsmeldung kann an kiBon gesendet werden} NICHT_MUTIERBAR{Diese Betreuungsmeldung ist vermutlich unvollständig und kann deshalb nicht an kiBon gesendet werden.} other{???}}}}",
      "KIBON_GENERATION_VIOLATION_MF": "Es konnte keine Betreuungsmeldung für Referenznummer {refNr} generiert werden weil: <ul>\n{KIND_ENABLED, select, true{<li>Beim Kind das Häkchen \"Platzbestätigung/Mutationsmeldung deaktivieren\" gesetzt wurde</li>} other{}} \n{PRIVATE_PLAETZE, select, true{<li>Das Kind keine Privaten Plätze innerhalb der Periode hat</li>} other{}}\n{MODUL_DYNAMISCHER_BELEGUNGSPLAN, select, true{<li>Das Modul dynamischer Belegungsplan fehlt</li>} other{}}\n{FEATURE_KIBON_MELDUNGEN, select, true{<li>Die KinderOrt-spezifische KiBon Berechtigung fehlt</li>} other{}}</ul>",
      "GENERATION_RESULTS": "Aktuelle Berechnung",
      "GENERTION_RESULT_MF": "{success, select, true{Meldung generiert} other{Meldung nicht generiert}}",
      "BETREUUNGSMELDUNG_GENERIERT": "Es konnte eine Betreuungsmeldung generiert werden.",
      "BETREUUNGSMELDUNG_IGNORIERT": "Das Kind hat bereits eine neuere Verfügung. Die Betreuungsmeldung ist deshalb überflüssig und wurde ignoriert."
    },
    "VERFUEGUNGEN": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Verfügung} other{Verfügungen}}",
      "HEADER": "Verfügungen",
      "STATE": {
        "OPEN": "Nicht zugewiesen",
        "MATCHED": "Automatisch Zugewiesen",
        "SKIPPED": "Überflüssig",
        "MANUALLY_ASSIGNED": "Manuell Zugewiesen",
        "REMOVED": "Zuweisung Entfernt",
        "IGNORED": "Ignoriert"
      },
      "STATE_INFO": {
        "OPEN": "Nicht zugewiesen",
        "MATCHED": "Automatisch Zugewiesen",
        "MANUALLY_ASSIGNED": "Manuell Zugewiesen",
        "REMOVED": "Zuweisung Entfernt",
        "IGNORED": "Ignoriert"
      },
      "VERFUEGUNG_VOM": "Verfügung vom {{date}}",
      "VERSION_MF": "{version, plural, =0{1. Verfügung} other{#. Mutation}}",
      "MATCHING_HEADER": "Automatische Zuweisung",
      "MATCHING_TEXT": "Damit eine Verfügung automatisch zugewiesen wird, müssen folgende Punkte übereinstimmen:<br>Name, Vorname(n) und das Geburtsdatum des Kindes.<br>Name und Vorname(n) von Kontakt und Gesuchsteller.<br>Zusätzlich darf die Verfügung keine Gutscheine für Zeiträume enthalten, in welchen das Kind in kitAdmin bereits von einer anderen Verfügung Gutscheine hat.",
      "ASSIGN_CONFIRMATION_HEADER": "Verfügung diesem Kind definitiv zuweisen?",
      "NOT_ASSIGNED": "Nicht zugewiesene Verfügungen",
      "ASSIGN_DIRECTLY": "Verfügung direkt zuweisen",
      "IGNORE_TITLE": "Verfügung ignorieren",
      "UN_IGNORE_TITLE": "Verfügung ignorieren",
      "IGNORE_SUBTITLE": "Sind Sie sicher, dass Sie die Verfügung ignorieren und keinem Kind zuweisen wollen?",
      "GESUCHSTELLER": "Gesuchsteller",
      "INSTITUTION_TRAEGERSCHAFT": "Kinderort und Trägerschaft",
      "VERFUEGUNGS_DATEN": "Verfügungs Daten",
      "BULK_IGNORE": "Massen-Ignorieren",
      "BULK_IGNORE_CONFIRM_ALLE": "Dadurch werden alle Verfügungen ignoriert - auch bereits zugewiesene.",
      "BULK_IGNORE_CONFIRM_PERIODE": "Dadurch werden alle Verfügungen welche vollständig in der ausgewählten Perioden enthalten sind ignoriert - auch bereits zugewiesene.",
      "BULK_UN_IGNORE": "Massen-Ignorieren rückgängig machen",
      "BULK_UN_IGNORE_CONFIRM_PERIODE": "Dadurch wird für alle ignorierten Verfügungen welche vollständig in der ausgewählten Periode enthalten sind der Status zu \"nicht zugewiesen\" geändert",
      "BULK_UN_IGNORE_CONFIRM_ALLE": "Dadurch wird für alle ignorierten Verfügungen der Status zu \"nicht zugewiesen\" geändert"
    },
    "KIND_MATCHING": {
      "KINDER_NAME": "Name Kind",
      "GEBURTSDATUM_KIND": "Geburtsdatum Kind",
      "GESUCHSTELLER_NAME": "Name Gesuchsteller",
      "KONTAKT_NAME_MF": "{count, plural, one{Name Kontaktperson} other {Namen Kontaktpersonen}}",
      "KINDERORT_DATEN": "kitAdmin Daten",
      "VALUES_NOT_EQUAL": "Werte welche nicht übereinstimmen (kiBon- und kitAdmin Daten) sind Rot markiert."
    },
    "INSTITUTION_MATCHING": {
      "INSTITUTIONS": "Institutionen",
      "INSTITUTIONS_MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Institution} other{Institutionen}}",
      "ZUWEISEN": "Institution <strong>{{institution}}</strong> dem Kinderort <strong>{{kinderOrt}}</strong> zuweisen?",
      "ZUWEISUNG_ENTFERNEN": "Soll die zugewiesene Institution <strong>{{institution}}</strong> von dem Kinderort <strong>{{kinderOrt}}</strong> gelöst werden?",
      "ASSIGNED_KINDER_ORT": "Zugewiesener Kinderort:",
      "MATCHING_STATE": "Status",
      "MATCHING_STATE_OPEN": "Nicht zugewiesen",
      "MATCHING_STATE_REMOVED": "Entfernt",
      "MATCHING_STATE_MATCHED": "Zugewiesen",
      "MATCHING_STATE_SKIPPED": "Übersprungen",
      "MATCHING_STATE_MANUALLY_ASSIGNED": "Manuell zugewiesen",
      "MATCHING_STATE_IGNORED": "Ignoriert"
    },
    "ANFRAGEN": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Platzbestätigung} other{Platzbestätigungen}}",
      "HEADER": "Platzbestätigungen",
      "STATE": {
        "PENDING": "Nicht zugewiesen",
        "ASSIGNED": "Zugewiesen",
        "PREPARED": "Versandbereit",
        "COMPLETED": "Gesendet",
        "IGNORED": "Ignoriert"
      },
      "STATE_INFO": {
        "PENDING": "Zeigt Platzbestätigungsanfragen an, welche nicht automatisch einem Kind zugewiesen werden konnten.\n\nMit dem Zuweisen-Button kann man diese dem Kind zuweisen und anschliessend auch gleich\ndie Platzbestätigung senden, sofern es eine Betreuungsmeldung zum Versenden findet.",
        "ASSIGNED": "Platzbestätigungsanfragen, welche einem Kind zugewiesen wurden,\naber für welche keine Meldung generiert werden konnte.\n\nDie Belegung und das Häkli\n«Automatische kiBon Platzbestätigung/Mutationsmeldung deaktivieren» überprüfen.",
        "PREPARED": "Platzbestätigungsanfragen, für welche eine noch nicht versendete Betreuungsmeldung generiert wurde.\n\nVia Kind -> kiBon oder KinderOrt -> kiBon Betreuungsmeldungen versenden.",
        "COMPLETED": "Die Betreuungsmeldung/Platzbestätigung wurde an kiBon gesendet.",
        "IGNORED": "Die Platzbestätigung wurde ignoriert und wird für\ndie aktuelle Periode nicht mehr berücksichtigt."
      },
      "ANFRAGE_VOM": "Anfrage vom {{date}}",
      "MATCHING_HEADER": "Automatische Zuweisung",
      "MATCHING_TEXT": "Damit eine Platzbestätigung automatisch erfolgt, müssen folgende Punkte übereinstimmen:<br>Name, Vorname(n) und das Geburtsdatum des Kindes.<br>Name und Vorname(n) von Kontakt und Gesuchsteller.",
      "ACHTUNG_HEADER": "Achtung",
      "ACHTUNG_TEXT": "Wenn es keine Betreuungsmeldung zum Versenden findet, kann es daran liegen, dass die Betreuungsmeldung bereits versendet wurde, oder dass das Häkli «Automatische kiBon Platzbestätigung/Mutationsmeldung deaktivieren» aktiviert ist, oder dass das Kind vielleicht gar keine private Betreuung hat. ",
      "ZUWEISUNG_AUFHEBEN": "Zuweisung aufheben?",
      "IGNORIEREN": "Platzbestätigung ignorieren?",
      "NICHT_IGNORIEREN": "Platzbestätigung nicht mehr ignorieren?",
      "ASSIGN_CONFIRMATION_HEADER": "Platz mit diesem Kind definitiv bestätigen?",
      "ASSIGN_DATEN": "Daten aus Anfrage"
    },
    "TAGESSCHULE_ANMELDUNG": {
      "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Tagesschule Anmeldung} other{Tagesschule Anmeldungen}}",
      "HEADER": "Tagesschule Anmeldungen",
      "KIBON_ANMELDUNG": "kiBon Anmeldung",
      "CONFIRMED_KIBON_ANMELDUNG": "Bestätigte kiBon Anmeldung",
      "STATE": {
        "TITLE": "Status",
        "PENDING": "Nicht zugewiesen",
        "ASSIGNED": "Zugewiesen",
        "COMPLETED": "Gesendet",
        "IGNORED": "Ignoriert",
        "ABGELEHNT": "Abgelehnt"
      },
      "STATE_INFO": {
        "PENDING": "Zeigt Tagesschule Anmeldungen an, welche noch nicht einem Kind zugewiesen wurden.\n\nMit dem Zuweisen-Button kann man diese einem Kind zuweisen.",
        "ASSIGNED": "Tagesschule Anmeldungen, welche einem Kind zugewiesen wurden.",
        "COMPLETED": "Eine Bestätigung für die Tagesschul Anmeldung wurde an kiBon gesandt.",
        "IGNORED": "Die Tagesschule Anmeldung wurde ignoriert.",
        "ABGELEHNT": "Die Tagesschule Anmeldung wurde abgelehent.",
        "ASSIGN_CONFIRMATION_HEADER": "Anmeldung diesem Kind definitiv zuweisen?"
      },
      "KIBON_STATE": {
        "SCHULAMT_ANMELDUNG_ERFASST": "Erfasst",
        "SCHULAMT_ANMELDUNG_AUSGELOEST": "Ausgelöst",
        "SCHULAMT_MODULE_AKZEPTIERT": "Akzeptiert",
        "SCHULAMT_ANMELDUNG_UEBERNOMMEN": "Übernommen",
        "SCHULAMT_ANMELDUNG_ABGELEHNT": "Abgelehnt",
        "SCHULAMT_FALSCHE_INSTITUTION": "Falsche Institution",
        "SCHULAMT_ANMELDUNG_STORNIERT": "Storniert"
      },
      "PROFILE": "Profil",
      "DECLINE": "Anmeldung ablehnen?",
      "ANGEMELDET_AM": "Angemeldet am {{date}}",
      "ZUWEISUNG_AUFHEBEN": "Zuweisung aufheben?",
      "IGNORIEREN": "Anmeldung ignorieren?",
      "NICHT_IGNORIEREN": "Anmeldung nicht mehr ignorieren?",
      "ABLEHEN": "Anmeldung ablehen?",
      "ANMELDEN": "Anmeldung übernehmen",
      "ASSIGN_CONFIRMATION_HEADER": "Anmeldung diesem Kind zuweisen?",
      "ASSIGN_DATEN": "Daten aus Anmeldung",
      "BESTAETIGEN": "Bestätigen",
      "MODIFY": "Angepasste Bestätigung für kiBon",
      "INTERVALL": "Intervall",
      "WEEKLY": "Wöchentlich",
      "ALTERNATING_MODULE": "Alle 2 Wochen",
      "EINTRITTSDATUM": "Eintrittsdatum:",
      "PLAN_KLASSE": "(Plan) Klasse",
      "ABWEICHUNG_SEMESTER_2": "Das Kind benötigt im 2. Semester andere Betreuungszeiten, weil sich der Stundenplan ändert.",
      "CONFIRM": "Anmeldung bestätigen?",
      "CONFIRM_SUCCESS": "Die Anmeldung wurde erfolgreich bestätigt.",
      "UEBERNEHMEN": {
        "ABGELEHNT": "Diese Anmeldung wurde abgelehnt.",
        "IGNORED": "Diese Anmeldung wurde ignoriert.",
        "MATCHES": "Anmeldung importieren",
        "MATCHES_INFO_MF": "{count, plural, =0{Es wurden keine Haushalte} one{Es wurde ein Haushalt} other{Es wurden # Haushalte}} an derselben Adresse gefunden. Bitte {count, plural, =0{} other{einen auswählen,}} selber nach einem bereits erfassen Kind suchen oder ein neues Kind erfassen.",
        "NO_MATCHES": "Es wurden keine Kinder mit ähnlichen Namen und Adresse gefunden."
      }
    },

    "REFERENZ_NUMMER_INFO": {
      "PERIOD": "Periode: {{dateAb}}/{{dateBis}}",
      "FALL_NR": "Fall Nr.: {{fallNr}}",
      "GEMEINDE": "Gemeinde: {{gemeinde}}",
      "IGNORIERT": "Referenznummer wurde ignoriert"
    },

    "REFERENZ_NUMMER_VERWALTEN": {
      "HEADER": "Ignorierte Referenznummern verwalten",
      "ZUGEWIESEN": "Zugewiesen",
      "IGNORIERT": "Ignoriert",
      "NO_DATA": "Keine Referenznummer gefunden.",
      "REF_NR_IGNORIEREN_AUFHEBEN": "aufheben"
    },

    "MAINTENANCE": {
      "QUERY_API": "Daten von kiBon importieren",
      "LIMIT": "limit",
      "AFTER_ID": "after_id",
      "INSTITUTION": "Institution"
    }
  },
  "NOTIZ": {
    "NAME": "Notiz",
    "PLURAL": "Notizen",
    "TITLE": "Notiz {{date}} schreiben",
    "CREATE_NOTIZ": "Notiz erstellen",
    "CREATE_NOTIZ_CONFIRM": "Notiz erstellen?",
    "UPDATE_NOTIZ": "Notiz aktualisieren",
    "UPDATE_NOTIZ_CONFIRM": "Notiz aktualisieren?",
    "CONTENT": "Notiz Nachricht",
    "HEADER_INFO": "Erstellt von: {{createdBy}}"
  },
  "NOTIFICATION": {
    "TITLE": "Benachrichtigungen",
    "SINGULAR": "Benachrichtigung",
    "MF": "{showcount, select, true{{count} } other{}}{count, plural, one{Benachrichtigung} other{Benachrichtigungen}}",
    "CREATE": "Benachrichtigung erstellen",
    "DUE_TIME": "Startzeit (optional)"
  },
  "EXTERNAL_ANMELDUNG": {
    "NAME": "Anmeldung",
    "TITLE": "Kinder Anmeldung",
    "KIND_SAVED": "Die Anmeldung wurde erfolgreich gespeichert",
    "BETREUUNG_AB": "Betreuung ab: {{date}}",
    "ANMELDE_DATUM": "Anmeldedatum: {{date}}",
    "SUBVENTIONIERTER_PLATZ": "Subventionierter Platz oder Betreuungsgutschein",
    "ACCEPT_AGB": "Ich akzeptiere die",
    "ACCEPT_AGB_LINK_PART": "allgemeinen Geschäftsbedingungen",
    "ACCEPT_PRIVACY_POLICY": "Bitte akzeptieren Sie unsere ",
    "ADD_CHILD": "Zusätzliches Kind anmelden",
    "REMOVE_CHILD": "Kind {{name}} entfernen",
    "ADD_KONTAKT": "Zusätzlichen Kontakt erfassen",
    "STAMMDATEN_KIND": "Stammdaten {{name}}",
    "ADDRESS_KIND": "Wohnadresse {{name}}",
    "BETREUUNG_KIND": "Betreuungswunsch {{name}}",
    "ANMELDEN": "Anmelden",
    "ASSIGNED": "Zugewiesene",
    "UNASSIGNED": "Offene",
    "GESCHLECHT": {
      "MAENNLICH": "Männlich",
      "WEIBLICH": "Weiblich",
      "UNBEKANNT": "Unbekannt",
      "DIVERS": "Divers"
    },
    "GESCHLECHT_KIND": {
      "MAENNLICH": "Junge",
      "WEIBLICH": "Mädchen",
      "UNBEKANNT": "Unbekannt",
      "DIVERS": "Divers"
    },
    "DETAIL": {
      "TITLE": "Anmeldung übernehmen",
      "NAME": "Details",
      "MATCHES": "Anmeldung importieren",
      "MATCHES_INFO_MF": "{count, plural, =0{Es wurden keine Haushalte} one{Es wurde ein Haushalt} other{Es wurden # Haushalte}} an derselben Adresse gefunden. Bitte {count, plural, =0{} other{einen auswählen,}} selber nach einem bereits erfassen Kind suchen oder ein neues Kind erfassen."
    },
    "BEWERBUNG": {
      "DUPLICATE": "Es gibt bereits einen offenen Betreuungswunsch für das zugewiesene Kind",
      "BEWERBUNG_UEBERNEHMEN": "Neuen Betreuungswunsch erstellen",
      "BEWERBUNG_NICHT_UEBERNEHMEN": "Neuen Betreuungswunsch ignorieren",
      "BERECHNETES_PENSUM": "Berechnetes Pensum {{pensum}}%"
    },
    "WIZARD": {
      "BACK": "Zurück",
      "NEXT": "Weiter",
      "CONCLUSION": "Abschluss",
      "SUMMARY": "Zusammenfassung",
      "NAV_TO_ERROR": "Die Anmeldung ist noch nicht vollständig ausgefüllt und kann deshalb nicht gespeichert werden.",
      "NAV_TO_ERROR_BTN": "Hier klicken, um zum unvollständigen Teil zu gelangen."
    },
    "CONFIG": {
      "TITLE": "Externe Anmeldung",
      "TAG_MANAGER_CONTAINER": "Google Tag Manager Container Id",
      "REDIRECT_URL": "URL zur Weiterleitung nach Absenden",
      "AGB_URL": "URL zu den allgemeinen Geschäftsbedingungen",
      "REPLY_TO_EMAIL": "Absender E-Mail für Bestätigungsmail",
      "INFO_EMAIL": "E-Mail Adresse für Info-Mail",
      "CUSTOM_STYLE": "Eigenes CSS",
      "SHOW_PENSUM": "Pensum anzeigen",
      "SHOW_PRIVATER_PLATZ": "Privater Platz anzeigen",
      "SHOW_SUBVENTIONIERTER_PLATZ": "Subventionierter Platz anzeigen",
      "BEMERKUNG": "Bemerkung",
      "WOCHENPLAENE": "Wochenpläne",
      "ADD_WOCHENPLAN": "Wochenplan hinzufügen",
      "WOCHENPLAN_NAME": "Name",
      "WOCHENPLAN_BEMERKUNG": "Bemerkung",
      "WOCHENPLAN_DISPLAY_TYPE": {
        "TIMES": "Modulzeiten anzeigen",
        "NAMES": "Modulnamen anzeigen"
      },
      "SELECT_WOCHENPLAN": "Wochenplan auswählen"
    },
    "CUSTOM_FIELDS": {
      "TITLE": "Externe Anmeldung",
      "SELECT_NAMED_ENTITY_TYPE": "Zugehörigkeit",
      "NAMED_ENTITY_TYPE": {
        "KIND": "Kind",
        "KONTAKTPERSON": "Kontakt"
      }
    },
    "DELETE": {
      "ZUGEWIESENE_KIND_TEXT": "Der externen Anmeldung ist bereits ein Kind zugewiesen",
      "DELETE_CHILD": "Zugewiesenes Kind auch löschen",
      "DELETE_ASSIGNMENT": "Nur Anmeldung löschen"
    }
  },
  "CHECK_IN": {
    "TITLE": "Übersicht",
    "EVENT": "Eintrag",
    "CUSTOM_FIELD": {
      "DAILY": "Täglich",
      "DISPLAYED_ON_OVERVIEW": "Auf Übersicht anzeigen",
      "DISPLAYED_ON_DAY_OVERVIEW": "Auf Tagesübersicht",
      "INTERN_ONLY": "Nur intern",
      "EDITABLE": "Änderbar",
      "CHECK-IN": "Check-In",
      "YES": "Ja",
      "NO": "Nein",
      "LINK": "Verknüpftes Feld",
      "LINK_KIND_FIELD": "Verknüpftes Kind Feld",
      "LINK_CHECK_IN_FIELD": "Verknüpftes Check-In Feld",
      "LINK_FIELD": "verknüpfen",
      "LINKED_TO": "Verknüpft mit: {{name}}",
      "NO_LINK_FIELD_AVAILABLE": "Es wurde kein verknüpfbares Feld gefunden",
      "CREATE": "Erstellen"
    },
    "FILTER": {
      "LABEL": "Filter",
      "RESET": "Zurücksetzen",
      "INVALID": "Keine Daten",
      "LABEL_COUNT": "{{label}} ({{count}})",
      "SHOW": "Anzeigen",
      "CLOSE": "Ausblenden",
      "SUCHE": {
        "PLACEHOLDER": "Namenssuche",
        "CLEAR": "Zurücksetzen",
        "GLOBAL": "Ganze Kita nach Kind durchsuchen"
      }
    },
    "STATUS": {
      "TIME": "Zeitpunkt",
      "VALUES": {
        "ANGEKOMMEN": "Eingecheckt",
        "ABGEHOLT": "Abgemeldet",
        "ABWESEND": "Absenz",
        "PAUSIERT": "Pause",
        "EINGEPLANT": "Eingeplant"
      },
      "VALUES_COUNT": {
        "ANGEKOMMEN": "Eingecheckt ({{count}})",
        "ABGEHOLT": "Abgemeldet ({{count}})",
        "ABWESEND": "Absenz ({{count}})",
        "PAUSIERT": "Pause ({{count}})",
        "EINGEPLANT": "Eingeplant ({{count}})"
      }
    },
    "NAVIGATION": {
      "KINDERORT": "Wechseln zu: {{kinderOrtDisplayName}}"
    },
    "FORM": {
      "REQUIRED": "{{field}} ist erforderlich",
      "SPEICHERN": "Speichern",
      "ABBRECHEN": "Abbrechen"
    },
    "BUTTON": {
      "ABSENZ": "Absenz",
      "CHECK-IN": "Check-In",
      "CHECK-OUT": "Abmelden",
      "PAUSIEREN": "pausieren",
      "ANTRAG_ERFASSEN": "Antrag erfassen"
    },
    "LISTE": {
      "WURDE_ABGEMELDET": "Wurde abgemeldet",
      "GEPLANT": "Geplant",
      "WIRD_ABGEHOLT": "Wird abgeholt um",
      "BETREUT": "Betreut",
      "ZEITEN": "{{von}} - {{bis}}",
      "ZEITEN_BIS": "{{bis}}",
      "CHECK_IN_SUCCESS": "{{kindDisplayName}} wurde eingecheckt",
      "CHECK_OUT_SUCCESS": "{{kindDisplayName}} wurde abgemeldet",
      "NAVIGATE_TO_TAGESINFO": "Zu den Tagesinfos",
      "KEINE_KINDER_EINGEPLANT": "Heute sind keine Kinder eingeplant.",
      "KEINE_RESULTATE": "Keine Resultate."
    },
    "TAGESINFO": {
      "TITLE": "Tagesinfo: {{kindDisplayName}}",
      "NAV": "Tagesinfo öffnen",
      "ANTRAG_ERFASSEN": "Antrag erfassen",
      "SECTION": {
        "TAGESINFO": "Tagesinfo",
        "WEITERE": "Weitere Infos",
        "LISTS": "Listen",
        "BAZ": "Geplante Zeiten"
      },
      "LISTS": {
        "LAST_STATUS_CHANGES": "Letzte Änderungen",
        "LAST_STATUS_CHANGES_EMPTY": "Keine Änderungen vorhanden",
        "OPEN_DEVIATION_REQUESTS": "Offene Anträge",
        "OPEN_DEVIATION_REQUESTS_EMPTY": "Keine offenen Anträge vorhanden",
        "STATUS_DELETED": "Status {{status}} {{zeit}} wurde gelöscht",
        "STATUS_RESTORED": "Status {{status}} {{zeit}} wurde wiederhergestellt",
        "DEVIATION_DELETED": "Antrag {{antrag}} {{zeitraum}} wurde gelöscht",
        "DEVIATION_RESTORED": "Antrag {{antrag}} {{zeitraum}} wurde wiederhergestellt",
        "RESTORE_STATUS": "Rückgängig",
        "RESTORE_DEVIATION": "Rückgängig"
      },
      "BAZ": {
        "PLANNED": "Geplant",
        "OVERRIDE": "Angepasst",
        "ADD_OVERRIDE": "+ Angepassten Zeitraum hinzufügen",
        "VON": "von",
        "BIS": "bis"
      }
    },
    "KONTAKT": {
      "TITLE": "Kontakt: {{kindDisplayName}}"
    },
    "ABWEICHUNG": {
      "TITLE": "Abweichung: {{kindDisplayName}}",
      "ADD_ABWEICHUNG": "Abweichung hinzufügen",
      "REMOVE_ABWEICHUNG": "Entfernen",
      "FORM": {
        "ALL_GROUPS": "Alle Gruppen",
        "GROUP": "Gruppe",
        "CATEGORY": "Kategorie",
        "TYPE": "Typ",
        "FROM_DATE": "Von Datum",
        "TO_DATE": "Bis Datum",
        "FROM_TIME": "Von Zeit",
        "TO_TIME": "Bis Zeit",
        "REMARK": "Bemerkung",
        "SAVE": "Antrag Speichern",
        "ABORT": "Abbrechen",
        "VALIDATION": {
          "ALL_GROUP_WITH_ADDITIONAL_EXTRA_PLATZ_TYPE_WARN": "Es kann kein Zusatztag über alle Gruppen erstellt werden."
        },
        "TITLE": "Antrag erfassen für {{kinderOrtDisplayName}}"
      }
    }
  },
  "TAGESUEBERSICHT": {
    "TITLE": "Tagesübersicht",
    "SECTION_CUSTOM_FIELDS": "Kinderinfos",
    "NO_CUSTOM_FIELD_VALUES": "Keine Tagesübersichts Infos für den {{date}} vorhanden.",
    "SECTION_PERSONAL_TAESINFO": "Personalinfos",
    "NO_PERSONAL_TAGESINFO": "Keine Personalplanung Tagesinfos für den {{date}} vorhanden.",
    "SECTION_ABWEICHUNGEN": "Abweichungen",
    "NO_ABWEICHUNGEN": "Keine Abweichungen vorhanden."
  },
  "BETREUUNGS_ANGEBOT": {
    "NAME": "Betreuungsangebot",
    "NONE": "Kein Betreuungsangebot",
    "HORT": "Hort",
    "KRIPPE": "Krippe",
    "HORT_AND_KRIPPE": "Hort und Krippe"
  }
}
