/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import {hasOwnPropertyGuarded} from '../../util';
import {FakturaEndpointErrorEndpoints} from './FakturaEndpointErrorEndpoints';
import type {RechnungsLaufError} from './RechnungsLaufError';

type FakturaEndpointPayload = {
    endpoint: typeof FakturaEndpointErrorEndpoints;
    provider: typeof RechnungsKonfigurationType;
};

export type FakturaError<T> = Record<'args', Record<'fakturaError', T>>;
export type FakturaRechnungsLaufError = FakturaError<Record<keyof RechnungsLaufError, unknown>>;
export type FakturaEndpointError = FakturaError<FakturaEndpointPayload>;
export type FakturaStornierenError = FakturaError<Record<'stornierenError', unknown>>;

export function isFakturaError<T extends string>(error: unknown): error is FakturaError<T> {
    return hasOwnPropertyGuarded(error, 'args') && hasOwnPropertyGuarded(error.args, 'fakturaError');
}

export function isRechnungslaufError(error: unknown): error is FakturaRechnungsLaufError {
    return isFakturaError(error)
        && hasOwnPropertyGuarded(error.args.fakturaError, 'failedRechnungsUebermittlungsEinheiten');
}

export function isFakturaEndpointError(error: unknown): error is FakturaEndpointError {
    const strings: string[] = Object.values(FakturaEndpointErrorEndpoints);

    return isFakturaError(error) &&
        hasOwnPropertyGuarded(error.args.fakturaError, 'endpoint') &&
        strings.includes(String(error.args.fakturaError.endpoint));
}

export function isFakturaStornierenError(error: unknown): error is FakturaStornierenError {
    return isFakturaError(error)
        && hasOwnPropertyGuarded(error.args.fakturaError, 'stornierenError');
}
