/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil, isNullish} from '@dv/shared/code';

export function isValidGueltigAbGueltigBis(gueltigAb: any, gueltigBis: any): boolean {
    const ab = DvbDateUtil.toLocalDate(gueltigAb);
    const bis = DvbDateUtil.toLocalDate(gueltigBis);

    if (isNullish(ab) || isNullish(bis)) {
        return true;
    }

    return ab.isSameOrBefore(bis);
}
