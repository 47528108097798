/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {BenutzerType} from '@dv/shared/backend/model/benutzer-type';
import type {JaxAuthAccessElement} from '@dv/shared/backend/model/jax-auth-access-element';
import type {JaxSearchResultEntry} from '@dv/shared/backend/model/jax-search-result-entry';
import type {UserRole} from '@dv/shared/roles';

export interface IPrincipal {
    username: string | null;
    userId: string | null;
    mandantName: string | null;
    displayName: string | null;
    userType: BenutzerType | null;
    roles: UserRole[];
    permissions: string[];
    privacyConsentGiven: BackendLocalDate | null;
    angestellteId: string | null;
    hasStundenErfassung: boolean;
    idpHint: string | null;
    preSelectableKinderOrt: JaxSearchResultEntry | null;
}

export class Principal implements IPrincipal {

    public constructor(
        public username: string | null = null,
        public userId: string | null = null,
        public mandantName: string | null = null,
        public displayName: string | null = null,
        public userType: BenutzerType | null = null,
        public roles: UserRole[] = [],
        public permissions: string[] = [],
        public privacyConsentGiven: BackendLocalDate | null = null,
        public angestellteId: string | null = null,
        public hasStundenErfassung: boolean = false,
        public idpHint: string | null = null,
        public preSelectableKinderOrt: JaxSearchResultEntry | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxAuthAccessElement): IPrincipal {
        return new Principal(
            data.username,
            data.userId,
            data.mandantName,
            data.displayName,
            data.userType,
            data.roles as UserRole[],
            data.permissions,
            data.privacyConsentGiven,
            data.angestellteId,
            data.hasStundenErfassung,
            data.idpHint,
            data.preSelectableKinderOrt,
        );
    }
}
