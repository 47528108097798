/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type KitadminModul = 'FAKTURA' | 'ANWESENHEIT' | 'TAGESELTERN' | 'BASIC' | 'DYNAMISCHER_BELEGUNGSPLAN' | 'KOMMUNIKATION' | 'BSV' | 'GROUP_ADMIN' | 'PERSONALPLANUNG' | 'WORK_TIME_CONTROLLING';

export const KitadminModul = {
    FAKTURA: 'FAKTURA' as KitadminModul,
    ANWESENHEIT: 'ANWESENHEIT' as KitadminModul,
    TAGESELTERN: 'TAGESELTERN' as KitadminModul,
    BASIC: 'BASIC' as KitadminModul,
    DYNAMISCHER_BELEGUNGSPLAN: 'DYNAMISCHER_BELEGUNGSPLAN' as KitadminModul,
    KOMMUNIKATION: 'KOMMUNIKATION' as KitadminModul,
    BSV: 'BSV' as KitadminModul,
    GROUP_ADMIN: 'GROUP_ADMIN' as KitadminModul,
    PERSONALPLANUNG: 'PERSONALPLANUNG' as KitadminModul,
    WORK_TIME_CONTROLLING: 'WORK_TIME_CONTROLLING' as KitadminModul
};

