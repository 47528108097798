/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
import {isSeverityEnabled, parseSeverity} from './logging-helpers';
import {LogSeverity} from './LogSeverity';

export class Log {
    public constructor(
        private readonly name: string,
        private readonly fgColor: string,
        private readonly bgColor: string,
        private readonly loggerEnabled: (loggerName: string, severity: LogSeverity) => boolean,
    ) {
        // nop
    }

    public info(message: unknown, ...args: unknown[]): void {
        this.doApply(console.info, LogSeverity.INFO, message, args);
    }

    public warn(message: unknown, ...args: unknown[]): void {
        this.doApply(console.warn, LogSeverity.WARN, message, args);
    }

    public error(message: unknown, ...args: unknown[]): void {
        this.doApply(console.error, LogSeverity.ERROR, message, args);
    }

    public debug(message: unknown, ...args: unknown[]): void {
        this.doApply(console.debug, LogSeverity.DEBUG, message, args);
    }

    /**
     * Trace must be explicitly enabled in environment.ts for specific loggers.
     */
    public trace(message: unknown, ...args: unknown[]): void {
        this.doApply(console.debug, LogSeverity.TRACE, message, args);
    }

    private doApply(
        logFunc: (message?: unknown, ...optionalParams: unknown[]) => unknown,
        severity: LogSeverity,
        message: unknown,
        args: unknown[],
    ): void {
        if (!this.loggerEnabled(this.name, severity)) {
            return;
        }

        const callee = logFunc || console.log;
        if (!callee) {
            return;
        }

        const params = [
            `%c${this.name}`,
            `color: ${this.fgColor}; background-color: ${this.bgColor}`,
            severity,
            message,
            ...args,
        ];

        try {
            callee(...params);
        } catch (ignored) {
            // probably IE with a closed console
        }
    }
}

export class LogFactory {
    private static minSeverity: LogSeverity = LogSeverity.INFO;
    private static traceEnabledFor?: string[];

    public static init(
        logSeverity: LogSeverity,
        traceEnabledFor?: string[],
    ): void {
        LogFactory.minSeverity = parseSeverity(logSeverity);
        LogFactory.traceEnabledFor = traceEnabledFor;
    }

    /**
     * Usage: const LOG = LogFactory.createLog("MyFooCompoment");
     */
    public static createLog(loggerName: string): Log {
        const bgColor = 'white';
        const fgColor = 'black';

        return new Log(
            loggerName,
            fgColor,
            bgColor,
            (name: string, severity: LogSeverity) => this.isLoggerEnabled(name, severity),
        );
    }

    private static isLoggerEnabled(loggerName: string, severity: LogSeverity): boolean {
        return this.isTraceEnabled(loggerName) || this.isSeverityEnabled(severity);
    }

    private static isSeverityEnabled(severity: LogSeverity): boolean {
        return isSeverityEnabled(severity, LogFactory.minSeverity);
    }

    private static isTraceEnabled(loggerName: string): boolean {
        return this.traceEnabledFor?.includes('*')
            ?? this.traceEnabledFor?.includes(loggerName)
            ?? false;
    }

}
