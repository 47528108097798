/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from './DvbUtil';

export class DvbUriUtil {
    public static plainObjectToUriParam(obj: { [key: string]: any }): string {
        let s = '(';

        Object.keys(obj).forEach((key: string, index, keys) => {
            s += `${key}:${DvbUriUtil.valueToUriParam(obj[key]) as string}`;

            if (index < keys.length - 1) {
                s += ';';
            }
        });

        return `${s})`;
    }

    public static booleanMapToUriParam<T extends string>(map: { [k in T]?: boolean }): string {
        let s = '';

        DvbUtil.keys(map).forEach(key => {
            if (map[key]) {
                s += `${key},`;
            }
        });

        if (s.length > 0) {
            // cut off last semicolon
            s = s.substring(0, s.length - 1);
        }

        return s;
    }

    public static paramToBooleanMap<T extends string>(param: string): { [k in T]?: boolean } {
        const map: { [k in T]?: boolean } = {};

        if (param.length === 0) {
            return map;
        }

        param.split(',').forEach(key => {
            map[key as T] = true;
        });

        return map;
    }

    public static arrayToUriParam(arr: string[]): string {
        return arr.join(',');
    }

    public static paramToArray(param: string): string[] {
        if (param.length === 0) {
            return [];
        }

        return param.split(',');
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    public static applyUriToObject<T extends object>(params: string | undefined, obj: T): T {
        if (!params) {
            return obj;
        }

        let depth = 0;
        let lastIndex = 0;
        const pairs = [];

        for (let i = 0; i < params.length; i++) {
            const c = params.charAt(i);

            if (c === '(') {
                depth++;
                if (depth === 1) {
                    lastIndex = i;
                }
            } else if (c === ')') {
                depth--;
                if (depth === 0 && i - lastIndex - 1 > 0) {
                    pairs.push(params.substring(lastIndex + 1, i));
                }
            }

            if (depth !== 1) {
                continue;
            }

            if (c === ';') {
                pairs.push(params.substring(lastIndex + 1, i));
                lastIndex = i;
            }
        }

        DvbUriUtil.applyPairsToObject(pairs, obj);

        return obj;
    }

    private static valueToUriParam<T>(value: T): string | number | boolean | null | undefined | T {
        switch (typeof value) {
            case 'string':
                return `'${value}'`;
            case 'number':
            case 'boolean':
            case 'object': // typoef null === 'object'
            case 'undefined':
                return value;
            default:
                throw new Error(`Type cannot directly be converted to uri param: ${typeof value}`);
        }
    }

    private static applyPairsToObject(pairs: string[], obj: { [key: string]: any }): void {
        pairs.forEach(keyValue => {
            const colonPosition: number = keyValue.indexOf(':');
            const key = keyValue.substring(0, colonPosition);

            obj[key] = DvbUriUtil.extractValue(keyValue.substring(colonPosition + 1));
        });
    }

    private static extractValue(paramValue: any): any {
        if (paramValue.length === 0) {
            return undefined;
        }

        const firstChar = paramValue.charAt(0);
        const lastChar = paramValue.charAt(paramValue.length - 1);

        if (firstChar === '(' && lastChar === ')') {
            return paramValue;
        }

        if (firstChar === '\'' && lastChar === '\'') {
            return String(paramValue.substring(1, paramValue.length - 1));
        }

        try {
            return JSON.parse(paramValue);
        } catch (err) {
            console.warn('Could not parse', paramValue, err);

            return undefined;
        }
    }
}
