import {Directive, inject, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NgForm} from '@angular/forms';
import {debounceTime} from 'rxjs';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'form',
    standalone: true,
})
export class FormValueChangesDirective {

    private readonly ngForm = inject(NgForm, {self: true});

    @Output() public readonly formValueChange = this.ngForm.form.valueChanges.pipe(
        debounceTime(0),
        takeUntilDestroyed(),
    );
}
