/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type FeatureType = 'CSV_EXPORT_RECHNUNGEN_HIRSLANDEN' | 'CSV_EXPORT_RECHNUNGEN_KSA' | 'GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL' | 'XML_EXPORT_RECHNUNGEN_KIMI' | 'CSV_EXPORT_MONATSBLATT_LEOLEA' | 'XML_EXPORT_MONATSBLATT_KIBEZ' | 'XML_EXPORT_MONATSBLATT_UNTERE_EMME' | 'KIBON_MELDUNGEN' | 'VERTRAGLICHES_PENSUM' | 'STUNDENERFASSUNG_TFO' | 'MONATSBLATT_API_KIBE_PLUS' | 'MONATSBLATT_API_NANNY_KIBE_PLUS' | 'ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA' | 'ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS' | 'EXTERNAL_ANMELDUNG' | 'DAUERAUFTRAG_ZAHLUNGSZUWEISUNG' | 'VORMONAT_RECHNUNGSDIFFERENZ' | 'RECHNUNG_REVISION_RECHNUNGSDIFFERENZ' | 'CONTROLLING_REPORT_MONTHLY' | 'AUTOMATIC_ABWEICHUNGSMELDUNG_CONVERSION' | 'AUTOMATIC_ABWEICHUNGSMELDUNG_ABSENCE_CONVERSION' | 'GRP_WEEK_CAPACITY';

export const FeatureType = {
    CSV_EXPORT_RECHNUNGEN_HIRSLANDEN: 'CSV_EXPORT_RECHNUNGEN_HIRSLANDEN' as FeatureType,
    CSV_EXPORT_RECHNUNGEN_KSA: 'CSV_EXPORT_RECHNUNGEN_KSA' as FeatureType,
    GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL: 'GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL' as FeatureType,
    XML_EXPORT_RECHNUNGEN_KIMI: 'XML_EXPORT_RECHNUNGEN_KIMI' as FeatureType,
    CSV_EXPORT_MONATSBLATT_LEOLEA: 'CSV_EXPORT_MONATSBLATT_LEOLEA' as FeatureType,
    XML_EXPORT_MONATSBLATT_KIBEZ: 'XML_EXPORT_MONATSBLATT_KIBEZ' as FeatureType,
    XML_EXPORT_MONATSBLATT_UNTERE_EMME: 'XML_EXPORT_MONATSBLATT_UNTERE_EMME' as FeatureType,
    KIBON_MELDUNGEN: 'KIBON_MELDUNGEN' as FeatureType,
    VERTRAGLICHES_PENSUM: 'VERTRAGLICHES_PENSUM' as FeatureType,
    STUNDENERFASSUNG_TFO: 'STUNDENERFASSUNG_TFO' as FeatureType,
    MONATSBLATT_API_KIBE_PLUS: 'MONATSBLATT_API_KIBE_PLUS' as FeatureType,
    MONATSBLATT_API_NANNY_KIBE_PLUS: 'MONATSBLATT_API_NANNY_KIBE_PLUS' as FeatureType,
    ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA: 'ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA' as FeatureType,
    ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS: 'ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS' as FeatureType,
    EXTERNAL_ANMELDUNG: 'EXTERNAL_ANMELDUNG' as FeatureType,
    DAUERAUFTRAG_ZAHLUNGSZUWEISUNG: 'DAUERAUFTRAG_ZAHLUNGSZUWEISUNG' as FeatureType,
    VORMONAT_RECHNUNGSDIFFERENZ: 'VORMONAT_RECHNUNGSDIFFERENZ' as FeatureType,
    RECHNUNG_REVISION_RECHNUNGSDIFFERENZ: 'RECHNUNG_REVISION_RECHNUNGSDIFFERENZ' as FeatureType,
    CONTROLLING_REPORT_MONTHLY: 'CONTROLLING_REPORT_MONTHLY' as FeatureType,
    AUTOMATIC_ABWEICHUNGSMELDUNG_CONVERSION: 'AUTOMATIC_ABWEICHUNGSMELDUNG_CONVERSION' as FeatureType,
    AUTOMATIC_ABWEICHUNGSMELDUNG_ABSENCE_CONVERSION: 'AUTOMATIC_ABWEICHUNGSMELDUNG_ABSENCE_CONVERSION' as FeatureType,
    GRP_WEEK_CAPACITY: 'GRP_WEEK_CAPACITY' as FeatureType
};

