import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SpinnerComponent} from '../../../spinner/spinner.component';

@Component({
    selector: 'dvlib-page',
    standalone: true,
    templateUrl: './page.component.html',
    styles: [':host {display: block;}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SpinnerComponent, TranslateModule],
})
export class PageComponent {
    @Input({required: true}) public itemsOnPageCount!: number;
    @Input() public isLoading: boolean = false;
    @Input({required: true}) public entityName: string = '';
}
