/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {isNullish} from '@dv/shared/code';

@Component({
    selector: 'dvlib-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class SpinnerComponent implements OnInit {

    @Input() public show?: boolean | null;

    /**
     * @param value if set to true, the spinner will have a fixed width & height - even when it's not visible.
     */
    @HostBinding('class.icon-size-spinner') @Input({transform: booleanAttribute}) private fillSpace: boolean = false;

    public ngOnInit(): void {
        if (isNullish(this.show)) {
            this.show = true;
        }
    }
}
