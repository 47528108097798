/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '../types';

// Please also extend the translations in key SEARCH.ENTITY_TITLE_MF
// These are the types, that SearchEntity provides in the backend.
export const ENTITY_SEARCH_TYPE = stringUnion(
    'ANGESTELLTE',
    'BENUTZER',
    'BETREUUNGS_PERSON',
    'BETREUUNGSFAKTOR_REGEL',
    'DOKUMENT_LAYOUT',
    'FIRMA',
    'FIRMENKONTINGENT',
    'GRUPPE',
    'KIND',
    'ANMELDUNG_KIND',
    'KITA',
    'KONTAKTPERSON',
    'MANDANT',
    'RECHNUNG',
    'SCHLIESSTAGE',
    'TAGES_ELTERN',
    'TARIF',
);

export type EntitySearchType = typeof ENTITY_SEARCH_TYPE.type;

// Entities like KINDERORT or KINDERORT_FRAKTION are generalised: they can be used as search type, but their return type
// will be the concrete ENTITY_SEARCH_TYPE (the nested type)
export type AggragatedEntitySearchTypes = EntitySearchType | 'KINDERORT' | 'KINDERORT_FRAKTION' | 'TASK_ORT';

type EntitySearchTypes = { [K in AggragatedEntitySearchTypes]: EntitySearchType[] };

export const ENTITY_TO_SEARCH: EntitySearchTypes = {
    // Aggregated
    KINDERORT: ['KITA', 'TAGES_ELTERN'],
    KINDERORT_FRAKTION: ['GRUPPE', 'BETREUUNGS_PERSON'],
    TASK_ORT: ['KITA', 'TAGES_ELTERN', 'GRUPPE', 'BETREUUNGS_PERSON'],

    // Single
    ANGESTELLTE: ['ANGESTELLTE'],
    BENUTZER: ['BENUTZER'],
    BETREUUNGS_PERSON: ['BETREUUNGS_PERSON'],
    BETREUUNGSFAKTOR_REGEL: ['BETREUUNGSFAKTOR_REGEL'],
    DOKUMENT_LAYOUT: ['DOKUMENT_LAYOUT'],
    FIRMA: ['FIRMA'],
    FIRMENKONTINGENT: ['FIRMENKONTINGENT'],
    GRUPPE: ['GRUPPE'],
    KIND: ['KIND'],
    ANMELDUNG_KIND: ['ANMELDUNG_KIND'],
    KITA: ['KITA'],
    KONTAKTPERSON: ['KONTAKTPERSON'],
    MANDANT: ['MANDANT'],
    RECHNUNG: ['RECHNUNG'],
    SCHLIESSTAGE: ['SCHLIESSTAGE'],
    TAGES_ELTERN: ['TAGES_ELTERN'],
    TARIF: ['TARIF'],
};
