/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, ISortable} from '../../types';
import {orderValueComparator} from '../../types';
import {DvbUtil} from '../DvbUtil';

type CustomField = ISortable & Partial<IPersistable>;

export function sortCustomFields(a: CustomField, b: CustomField): number {
    return orderValueComparator(a, b) || sortById(a, b);
}

function sortById(a: Partial<IPersistable>, b: Partial<IPersistable>): number {
    return DvbUtil.persistableComparatorAsc({id: a.id ?? ''}, {id: b.id ?? ''});
}
