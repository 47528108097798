/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
export class RechnungsRevisionErrorType {
    public constructor(public name: string, public msgKey: string) {
    }
}

const RECHNUNGS_REVISION_ERROR_TYPES: { [index: string]: RechnungsRevisionErrorType } = {
    CLIENT_ADDRESS_DOES_NOT_EXIST: new RechnungsRevisionErrorType('CLIENT_ADDRESS_DOES_NOT_EXIST',
        'ERRORS.SI_ERR_CLIENT_ADDRESS_DOES_NOT_EXIST'),
    CLIENT_DOES_NOT_EXIST: new RechnungsRevisionErrorType('CLIENT_DOES_NOT_EXIST',
        'ERRORS.SI_ERR_CLIENT_DOES_NOT_EXIST'),
    CLIENT_IS_DELETED: new RechnungsRevisionErrorType('CLIENT_IS_DELETED', 'ERRORS.SI_ERR_CLIENT_IS_DELETED'),
    CLIENT_NUMBER_DUPLICATE: new RechnungsRevisionErrorType('CLIENT_NUMBER_DUPLICATE',
        'ERRORS.SI_ERR_CLIENT_NUMBER_DUPLICATE'),
    COULD_NOT_GET_PDF: new RechnungsRevisionErrorType('COULD_NOT_GET_PDF', 'ERRORS.SI_ERR_COULD_NOT_GET_PDF'),
    CONTACT_OF_INVALID_CLIENT: new RechnungsRevisionErrorType('CONTACT_OF_INVALID_CLIENT',
        'ERRORS.SI_ERR_CONTACT_OF_INVALID_CLIENT'),
    ESR_DOES_NOT_EXIST: new RechnungsRevisionErrorType('ESR_DOES_NOT_EXIST', 'ERRORS.SI_ERR_ESR_DOES_NOT_EXIST'),
    ESR_NUMBER_DUPLICATE: new RechnungsRevisionErrorType('ESR_NUMBER_DUPLICATE', 'ERRORS.SI_ERR_ESR_NUMBER_DUPLICATE'),
    INVALID_CONTACT_ID: new RechnungsRevisionErrorType('INVALID_CONTACT_ID', 'ERRORS.SI_ERR_INVALID_CONTACT_ID'),
    INVALID_EMAIL_ADDRESS: new RechnungsRevisionErrorType('INVALID_EMAIL_ADDRESS', 'ERRORS.SI_ERR_EMAIL_ADDRESS'),
    INVOICE_DOES_NOT_EXIST: new RechnungsRevisionErrorType('INVOICE_DOES_NOT_EXIST',
        'ERRORS.SI_ERR_INVOICE_DOES_NOT_EXIST'),
    INVOICE_NUMBER_DUPLICATE: new RechnungsRevisionErrorType('INVOICE_NUMBER_DUPLICATE',
        'ERRORS.SI_ERR_INVOICE_NUMBER_DUPLICATE'),
    MISSING_DOCUMENT_TYPE: new RechnungsRevisionErrorType('MISSING_DOCUMENT_TYPE',
        'ERRORS.SI_ERR_MISSING_DOCUMENT_TYPE'),
    NO_ACCESS_RIGHTS: new RechnungsRevisionErrorType('NO_ACCESS_RIGHTS', 'ERRORS.SI_ERR_NO_ACCESS_RIGHTS'),
    // TODO OBJECT_DOES_NOT_EXIST ist nicht sehr hilfreich. Wäre gut zu wissen, welches Objekt fehlt...
    OBJECT_DOES_NOT_EXIST: new RechnungsRevisionErrorType('OBJECT_DOES_NOT_EXIST',
        'ERRORS.SI_ERR_OBJECT_DOES_NOT_EXIST'),
    OVERDUE_PAYMENT: new RechnungsRevisionErrorType('OVERDUE_PAYMENT', 'ERRORS.SI_ERR_OVERDUE_PAYMENT'),
    PAYDATE: new RechnungsRevisionErrorType('PAYDATE', 'ERRORS.SI_ERR_PAYDATE'),
    PAYMENTS_TOO_LARGE_AMOUNT: new RechnungsRevisionErrorType('PAYMENTS_TOO_LARGE_AMOUNT',
        'ERRORS.SI_ERR_PAYMENTS_TOO_LARGE_AMOUNT'),
    RATE_LIMIT: new RechnungsRevisionErrorType('RATE_LIMIT', 'ERRORS.SI_ERR_RATE_LIMIT'),
    SERVICE_UNAVAILABLE: new RechnungsRevisionErrorType('SERVICE_UNAVAILABLE', 'ERRORS.SI_SERVICE_UNAVAILABLE'),
    TOKEN_EXPIRED: new RechnungsRevisionErrorType('TOKEN_EXPIRED', 'ERRORS.SI_ERR_TOKEN_EXPIRED'),
    NO_TOKEN: new RechnungsRevisionErrorType('NO_TOKEN', 'ERRORS.SI_NO_TOKEN'),
    UNKNOWN: new RechnungsRevisionErrorType('UNKNOWN', 'ERRORS.SI_ERR_UNKNOWN'),
    USER_DELETED: new RechnungsRevisionErrorType('USER_DELETED', 'ERRORS.SI_ERR_USER_DELETED'),
    CONFIGURATION_ERROR: new RechnungsRevisionErrorType('CONFIGURATION_ERROR', 'ERRORS.CONFIGURATION_ERROR'),
};

export class RechnungsRevisionError {
    public constructor(
        public rechnungsRevisionId: string,
        public rechnungsId: string,
        public rechnungsNummer: number,
        public kundenNummer: number,
        public esrNummer: number | null,
        public rechnungsEmpfaengerDisplayName: string,
        public message: string | null,
        public errorType: RechnungsRevisionErrorType,
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsRevisionError {

        const rechnungsRevisionId = data.rechnungsRevisionId;
        const rechnungsId = data.rechnungsId;
        const rechnungsNummer = parseInt(data.rechnungsNummer, 10);
        const kundenNummer = parseInt(data.kundenNummer, 10);
        const esrNummer = data.esrNummer ? parseInt(data.esrNummer, 10) : null;
        const rechnungsEmpfaengerDisplayName = data.rechnungsEmpfaengerDisplayName;
        const message = data.message;
        const errorType = RechnungsRevisionError.findErrorType(data.errorType);

        return new RechnungsRevisionError(rechnungsRevisionId,
            rechnungsId,
            rechnungsNummer,
            kundenNummer,
            esrNummer,
            rechnungsEmpfaengerDisplayName,
            message,
            errorType);
    }

    private static findErrorType(errorType: any): RechnungsRevisionErrorType {
        const found = Object.keys(RECHNUNGS_REVISION_ERROR_TYPES).filter(
            key => RECHNUNGS_REVISION_ERROR_TYPES[key].name === errorType);

        if (found.length !== 1) {
            throw new Error(`RechnungsRevisionErrorType not found for input:${String(errorType)}`);
        }

        return RECHNUNGS_REVISION_ERROR_TYPES[found[0]];
    }
}
