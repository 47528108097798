/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {stringUnion} from '../types';

export const BETRIEB = stringUnion(
    'ABWEICHUNGEN_KINDER',
    'ADRESSLISTE_KINDER_KONTAKTE',
    'AKTUELLE_AENDERUNGEN',
    'BELEGUNGSPLAN',
    'BELEGUNGSPLAN_ANWESENHEITSZEITEN',
    'BELEGUNGSPLAN_DAY_BASED',
    'FRUEH_SPAET_DIENST',
    'GEBURTSTAGSLISTE_KINDER',
    'KINDERGARTENKINDER',
    'TELEFON_EMAIL_ADRESSLISTE',
);

export type Betrieb = typeof BETRIEB.type;

export const CONTROLLING = stringUnion(
    'ANWESENHEITSSOLL_VERBRAUCH',
    'BELEGTE_PROZENT_PUNKTE',
    'BELEGUNGSPLAN_MONTHLY',
    'BELEGUNGSPLAN_PLATZART',
    'BETREUUNGS_STUNDEN_KONTROLLE',
    'BETREUUNGSGUTSCHEINE',
    'BSV_PRAESENZKONTROLLE',
    'BSV_PRAESENZKONTROLLE_KG',
    'CONTROLLING',
    'CONTROLLING_MONTHLY',
    'CORONA_REFUNDS',
    'CORONA_REFUNDS_BERN_PRIVAT',
    'CORONA_REFUNDS_LUZERN',
    'EXTERNAL_ANMELDUNGEN',
    'FINANZLASTENAUSGLEICH',
    'KENNZAHLEN',
    'KIBON_REPORT',
    'LEISTUNGEN_KALKULIERT',
    'MONATSBLATT_AUSZUG',
    'STUNDENBASIERTES_CONTROLLING',
    'STUNDENBLATT',
    'STUNDENVERGLEICH_SOLL_IST',
    'TARIFRECHNUNG_REPORTING_KALKULIERT',
    'WARTELISTE',
);

export type Controlling = typeof CONTROLLING.type;

export const FAKTURA = stringUnion(
    'DEPOTS',
    'KONTAKTPERSONEN_ZAHLUNGEN',
    'LEISTUNGEN_FAKTURIERT',
    'OFFENE_POSTEN',
    'RECHNUNGS_GEBUEHREN',
    'STEUERBESCHEINIGUNGEN',
    'ZAHLUNGS_EINGAENGE',
);

export type Faktura = typeof FAKTURA.type;

export const PERSONAL = stringUnion(
    'PERSONALPLANUNG',
    'PERSONALPLANUNG_MONTHLY',
);

export type Personal = typeof PERSONAL.type;

export const REPORT_TYPE = stringUnion(...BETRIEB.values, ...CONTROLLING.values, ...FAKTURA.values, ...PERSONAL.values);

export type ReportType = typeof REPORT_TYPE.type;

export type FrontendOnly = 'AKTUELLE_OFFENE_POSTEN';
export type FrontendReportType = ReportType | FrontendOnly;
