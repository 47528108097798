import type {Signal, WritableSignal} from '@angular/core';
import {computed, Injectable, signal} from '@angular/core';
import type {DateDisplayMode, Nullish} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, START_OF_TYPE} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import moment from 'moment';
import type {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import type {BsDatepickerViewMode} from 'ngx-bootstrap/datepicker/models';
import {getDefaultConfig} from '../../forms/datepicker';

const bsMinMode: Record<DateDisplayMode, BsDatepickerViewMode> = {
    year: 'year',
    month: 'month',
    week: 'day',
    day: 'day',
};

const equalMoment = {equal: DvbDateUtil.isMomentEquals};
const equalDate = {equal: (a: Date | undefined, b: Date | undefined) => a?.getTime() === b?.getTime()};

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class DateSwitcherStore {

    // input state
    public gueltigAb = signal<moment.Moment | Nullish>(undefined, equalMoment);
    public gueltigBis = signal<moment.Moment | Nullish>(undefined, equalMoment);
    public isDisabled = signal(false);
    public mode = signal<DateDisplayMode>('day');
    public convertToFirstOfMode = signal(true);

    // derived state
    private startOfType = computed(() => START_OF_TYPE[this.mode()]);
    private bsMinMode = computed(() => bsMinMode[this.mode()]);

    // model of the date switcher
    public selectedDate: WritableSignal<Date | undefined> = signal(undefined, equalDate);

    private selectedMoment = computed(() => {
        return this.selectedDate() ? moment(this.selectedDate()) : undefined;
    }, equalMoment);
    public selectedInMode = computed(() => this.convertToFirstOfMode()
        ? this.toFirstDateOfMode(this.selectedMoment()) : this.toBeginOfDay(this.selectedMoment()), equalMoment);

    private minOfMode = computed(() => this.toFirstDateOfMode(this.gueltigAb()), equalMoment);
    private maxOfMode = computed(() => this.toFirstDateOfMode(this.gueltigBis()), equalMoment);

    public bsConfig: Signal<Partial<BsDatepickerConfig>> = computed(() => ({
        ...getDefaultConfig(),
        minDate: this.gueltigAb()?.toDate(),
        maxDate: this.gueltigBis()?.toDate(),
        minMode: this.bsMinMode(),
    }));

    public hasPrevious: Signal<boolean> = computed(() => {
        const minOfMode = this.minOfMode();
        const selected = this.selectedInMode();
        const granularity = this.startOfType();

        return !minOfMode || !selected || minOfMode.isBefore(selected, granularity);
    });

    public hasNext: Signal<boolean> = computed(() => {
        const maxOfMode = this.maxOfMode();
        const selected = this.selectedInMode();
        const granularity = this.startOfType();

        return !maxOfMode || !selected || maxOfMode.isAfter(selected, granularity);
    });

    public dateInDisplayFormat: Signal<string> = computed((): string => {
        const date = this.selectedInMode();
        const mode = this.mode();

        return this.format(date, mode);
    });

    private previousValue = computed(() => {
        const firstOfModeDate = this.toFirstDateOfMode(this.selectedDate());
        const mode = this.mode();

        return firstOfModeDate ? firstOfModeDate.subtract(1, mode) : undefined;
    });

    private nextValue = computed(() => {
        const firstOfModeDate = this.toFirstDateOfMode(this.selectedDate());
        const mode = this.mode();

        return firstOfModeDate ? firstOfModeDate.add(1, mode) : undefined;
    });

    public previousInDisplayFormat = computed(() => this.format(this.previousValue(), this.mode()));
    public nextInDisplayFormat = computed(() => this.format(this.nextValue(), this.mode()));

    public constructor(
        public readonly translator: Translator,
    ) {
    }

    public setPreviousValue(): void {
        this.selectedDate.set(checkPresent(this.previousValue()).toDate());
    }

    public setNextValue(): void {
        this.selectedDate.set(checkPresent(this.nextValue()).toDate());
    }

    public setValue(value: Date | undefined): void {
        this.selectedDate.set(value);
    }

    /**
     * Converts the input depending on the display mode to the 'firstDateOfMode' date.
     * For mode 'week' this returns startOf isoWeek
     * For mode 'year' this returns the first of January
     */
    private toFirstDateOfMode(aMoment: moment.Moment | Date | Nullish): moment.Moment | undefined {
        if (!aMoment) {
            return undefined;
        }

        return moment(aMoment).startOf(this.startOfType()).startOf('day');
    }

    /**
     * Returns the input at its startOfDay or undefined if it was undefined
     */
    private toBeginOfDay(aMoment: moment.Moment | Date | Nullish): moment.Moment | undefined {
        if (!aMoment) {
            return undefined;
        }

        return DvbDateUtil.adjustToBeginOfDay(moment(aMoment));
    }

    private format(date: undefined | moment.Moment, mode: DateDisplayMode): string {
        if (!DvbDateUtil.isValidMoment(date)) {
            return '';
        }

        switch (mode) {
            case 'year':
                return date.format('YYYY');
            case 'month':
                return date.format('MMMM YYYY');
            case 'week':
                return this.translator.instant('COMMON.SHORT_KALENDERWOCHE_VON_BIS', {
                    dateAb: moment(date).startOf('isoWeek').format('W[, ]DD. MMM'),
                    dateBis: moment(date).endOf('isoWeek').format('DD. MMM YYYY'),
                });
            case 'day':
                return date.format('dd, DD. MMM YYYY');
        }
    }
}
