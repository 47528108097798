/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import type {AfterViewInit} from '@angular/core';
import {Directive, HostBinding, inject, Input} from '@angular/core';

@Directive({
    selector: 'textarea[dvlibElasticTextInput]',
    standalone: true,
    hostDirectives: [
        CdkTextareaAutosize,
    ],
})
export class ElasticTextInputDirective implements AfterViewInit {
    @HostBinding('class.form-control') private readonly bsStyles = true;
    @HostBinding('style.resize') private readonly resize = 'none';
    @HostBinding('style.overflow') private readonly overflow = 'hidden';

    @HostBinding()
    @Input()
    public rows: number = 2;

    private material = inject(CdkTextareaAutosize, {self: true});

    public ngAfterViewInit(): void {
        this.material.minRows = this.rows;
    }
}
