/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */
import type {Provider} from '@angular/core';
import {Optional} from '@angular/core';
import {ControlContainer, NgForm, NgModelGroup} from '@angular/forms';

/**
 * Allows a child component to extend the parents NgForm or NgModelGroup.
 */
export const formViewProvider: Provider = {
    provide: ControlContainer,
    useFactory: formViewProviderFactory,
    deps: [
        [new Optional(), NgForm],
        [new Optional(), NgModelGroup],
    ],
};

function formViewProviderFactory(
    ngForm: NgForm, ngModelGroup: NgModelGroup,
): NgForm | NgModelGroup | null {
    return ngModelGroup ?? ngForm ?? null;
}
