import type {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

const DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    containerClass: 'dvb-calendar',
    showWeekNumbers: false,
    customTodayClass: 'today',
    selectFromOtherMonth: true,
    dateInputFormat: 'D.M.YYYY',
    showTodayButton: true,
    initCurrentTime: false,
};

export function initDatepickerConfig(todayButtonLabel: string): void {
    DATEPICKER_CONFIG.todayButtonLabel = todayButtonLabel;
}

export function getDefaultConfig(): Partial<BsDatepickerConfig> {
    return JSON.parse(JSON.stringify(DATEPICKER_CONFIG));
}
