import {booleanAttribute, Directive, inject, Input} from '@angular/core';
import {NgForm} from '@angular/forms';

@Directive({
    selector: 'form[dvlibFormDisabled]',
    standalone: true,
})
export class FormDisabledDirective {

    private readonly ngForm = inject(NgForm, {self: true});

    @Input({required: true, transform: booleanAttribute, alias: 'dvlibFormDisabled'})
    public set disabled(value: unknown) {
        // the ngForm is initiaially not having any conotrols associatiated with itself, thus changing the disabled
        // state doesn't seem to do anyhting. adding a setTimeout will trigger the state change in the next change
        // detection cycle, where controles are associated.
        setTimeout(() => {
            if (value) {
                this.ngForm.form.disable();
            } else {
                this.ngForm.form.enable();
            }
        }, 0);
    }
}
