/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * Type-assertions <strong>in an AngularJS compliant way</strong>.
 */
export class TypeUtil {
    // Using FunctionType does not make sense here, because then tsc reduces type inference to `unknown`.
    // eslint-disable-next-line @typescript-eslint/ban-types
    public static isFunction(value: unknown): value is Function {
        return typeof value === 'function';
    }

    public static isString(value: unknown): value is string {
        return typeof value === 'string';
    }

    public static isNumber(value: unknown): value is number {
        return typeof value === 'number';
    }
}
