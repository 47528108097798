/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {Translator} from '@dv/shared/translator';
import type {IPersistable, IRestModel} from '../types';
import type {ParamValueBaseType, ParamValueDisplayType} from './';
import {DvbRestUtil} from './';
import {ParamValueDType} from './ParamValueDType';

export class ParamValue implements IPersistable, IRestModel {

    public static readonly DTYPE: typeof ParamValueDType = ParamValueDType;

    public constructor(
        public id: string | null = null,
        public dtype: ParamValueDType,
        public value: ParamValueBaseType | ParamValue[] | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): ParamValue {
        let value = data.value;

        if (data.dtype === ParamValue.DTYPE.LOCAL_DATE) {
            value = DvbRestUtil.localDateToMoment(data.value);
        } else if (data.dtype === ParamValue.DTYPE.COLLECTION) {
            value = value.map((val: any) => ParamValue.apiResponseTransformer(val));
        }

        return new ParamValue(data.id, data.dtype, value);
    }

    public getDisplayValue(translator: Translator): ParamValueDisplayType {
        // eslint-disable-next-line no-invalid-this
        return this.toDisplayValue(translator, this.value, this.dtype);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            collectionValue: this.dtype === ParamValueDType.COLLECTION ?
                (this.value as ParamValue[]).map(value => value.toRestObject()) :
                undefined,
            localDateValue: this.dtype === ParamValueDType.LOCAL_DATE ? this.value : undefined,
            abrechnungTypeValue: this.dtype === ParamValueDType.ABRECHNUNG_TYP ? this.value : undefined,
            stringValue: this.dtype === ParamValueDType.STRING ? this.value : undefined,
            longValue: this.dtype === ParamValueDType.LONG ? this.value : undefined,
            bigDecimalValue: this.dtype === ParamValueDType.BIG_DECIMAL ? this.value : undefined,
            booleanValue: this.dtype === ParamValueDType.BOOLEAN ? this.value : undefined,
        };
    }

    private toDisplayValue(
        translator: Translator,
        value: ParamValueBaseType | ParamValue[] | null,
        dtype: ParamValueDType,
    ): ParamValueDisplayType {

        switch (dtype) {
            case ParamValueDType.COLLECTION:
                return this.formatArray(translator, value as ParamValue[]);
            case ParamValueDType.LOCAL_DATE:
                return (value as moment.Moment).format('D.M.YYYY');
            case ParamValueDType.ABRECHNUNG_TYP:
                return translator.instant(`COMMON.${String(value)}`);
            case ParamValueDType.STRING:
                return value as string;
            case ParamValueDType.LONG:
                return parseInt(value as string, 10);
            case ParamValueDType.BIG_DECIMAL:
                return parseFloat(value as string);
            case ParamValueDType.BOOLEAN:
                return translator.instant(`COMMON.${value ? 'YES' : 'NO'}`);
            default:
                throw new Error(`no displayValue defined for paramValue with dtype ${JSON.stringify(dtype)}`);
        }
    }

    private formatArray(translator: Translator, array: ParamValue[]): ParamValueDisplayType {
        if (array.length === 1) {
            return this.toDisplayValue(translator, array[0].value, array[0].dtype);
        }

        const mappedValues = array.map(i => i.value);

        if (mappedValues.length >= 2) {
            return translator.instant('COMMON.TASKS.LISTE_VON_MEHR_ALS_2', {
                liste: mappedValues.slice(0, mappedValues.length - 1).join(', '),
                letztes: mappedValues[mappedValues.length - 1],
            });
        }

        return '';
    }
}
