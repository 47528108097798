/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * @example
 * const myMemoizer = new Memoizer(myExpensiveFunction);
 * myMemoizer.get(10);
 * myMemoizer.get(10); // cache hit
 */
export class Memoizer<T> {

    private cache: { [key: string]: T } = {};

    /**
     * @param func some expensive function
     */
    public constructor(private func: MemoizeFunction<T>) {
    }

    public reset(): void {
        this.cache = {};
    }

    /**
     * removes the cache value for property 'p' - thus the memoized function is called upon next 'get(p)'
     *
     * @param p the cache key
     */
    public clear(p: string): void {
        delete this.cache[p];
    }

    /**
     * Either gets the cached value for properperty 'p' or computes and caches it
     *
     * @param p the cache key
     */
    public get(p: string): T {
        return memoize(this.func, this.cache)(p);
    }

    /**
     * Allows updating/overriding the cache
     */
    public set(p: string, value: T): void {
        this.cache[p] = value;
    }
}

function memoize<T>(func: MemoizeFunction<T>, cache: { [key: string]: T }): MemoizeFunction<T> {
    return function memoizeFunctionCall(this: any): T {
        // eslint-disable-next-line prefer-rest-params
        const args: any = Array.prototype.slice.call(arguments);

        // check if we can grab it from the cache
        if (!(args in cache)) {
            cache[args] = func.apply(this, args);
        }

        return cache[args];
    };
}

export type MemoizeFunction<T> = (p: string) => T;
