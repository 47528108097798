/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FeatureType} from '@dv/shared/backend/model/feature-type';
import type {GranularKinderOrtPermissionType} from '@dv/shared/backend/model/granular-kinder-ort-permission-type';
import type {KitaPermissionType} from '@dv/shared/backend/model/kita-permission-type';
import {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {PersonalPermissionType} from '@dv/shared/backend/model/personal-permission-type';
import type {SharedPermissionType} from '@dv/shared/roles';

type KindPermission =
    'CARE' |
    'CARE_ANY' |
    'VIEW' |
    'VIEW_ANY';

type CrudPermission = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

const CRUD_PERMISSIONS = {
    CREATE: 'CREATE' as CrudPermission,
    READ: 'READ' as CrudPermission,
    UPDATE: 'UPDATE' as CrudPermission,
    DELETE: 'DELETE' as CrudPermission,
};

function toCrud<T extends string>(prefix: T): Crud<T> {
    return Object.fromEntries(Object.values(CRUD_PERMISSIONS)
        .map(permission => [permission, `${prefix}:${permission.toLowerCase()}:`] as const)) as Crud<T>;
}

type MODULES = { [k in KitadminModul]: `module:${Lowercase<k>}` };

function toModules(): MODULES {
    return Object.fromEntries(Object.values(KitadminModul)
        .map(modul => [modul, `module:${modul.toLowerCase()}`] as const)) as MODULES;
}

type FEATURES = { [k in FeatureType]: `feature:${Lowercase<k>}` };

function toFeatures(): FEATURES {
    return Object.fromEntries(Object.values(FeatureType)
        .map(feature => [feature, `feature:${feature.toLowerCase()}`] as const)) as FEATURES;
}

type ExcludeAnySuffix<T extends string> = T extends `${infer Prefix}_ANY` ? `${Prefix}` : never;

type ForIdOrGeneral<Prefix extends string, T extends string> =
    | { [k in T]: `${Prefix}:${Lowercase<k>}:` }
    | { [k in `${T}_ANY`]: `${Prefix}:${Lowercase<ExcludeAnySuffix<k>>}:general` }
    ;

type Crud<Prefix extends string> =
    | { [k in CrudPermission]: `${Prefix}:${Lowercase<k>}:` }
    ;

// uses a custom permission string
const GRANULAR_KINDER_ORT_PERMISSION_STRINGS = {
    FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE: 'granular_kinder_ort:faktura:rechnungslauf:lock_override',
} as const;

type GranularKinderOrtPermissions =
    | { [k in GranularKinderOrtPermissionType]: `${typeof GRANULAR_KINDER_ORT_PERMISSION_STRINGS[k]}:` }
    | { [k in `${GranularKinderOrtPermissionType}_ANY`]: `${typeof GRANULAR_KINDER_ORT_PERMISSION_STRINGS[ExcludeAnySuffix<k>]}:general` }
    ;

export const PERMISSION = {
    KITA: {
        BETREUUNG_ADMIN: 'kita:betreuung_admin:',
        BETREUUNG_ADMIN_ANY: 'kita:betreuung_admin:general',
        ADMINISTRATE: 'kita:administrate:',
        ADMINISTRATE_ANY: 'kita:administrate:general',
        MANAGE: 'kita:manage:',
        MANAGE_ANY: 'kita:manage:general',
        CONTROL: 'kita:control:',
        CONTROL_ANY: 'kita:control:general',
        VIEW_PLUS: 'kita:view_plus:',
        VIEW_PLUS_ANY: 'kita:view_plus:general',
        VIEW: 'kita:view:',
        VIEW_ANY: 'kita:view:general',
        CREATE_BELEGUNG: 'kita:create_belegung:',
        CREATE_BELEGUNG_ANY: 'kita:create_belegung:general',
        VIEW_PLATZ_KONTINGENT_TYPE: 'kita:view_platz_kontingent_type:',
        VIEW_PLATZ_KONTINGENT_TYPE_ANY: 'kita:view_platz_kontingent_type:general',
        MODIFY_EXTRA_PLATZ: 'kita:modify_extra_platz:',
        MODIFY_EXTRA_PLATZ_ANY: 'kita:modify_extra_platz:general',
        CHECK_IN_CHECK_OUT: 'kita:check_in_check_out:',
        CHECK_IN_CHECK_OUT_ANY: 'kita:check_in_check_out:general',
    },
    GRANULAR_KINDER_ORT: {
        FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE: 'granular_kinder_ort:faktura:rechnungslauf:lock_override:',
        FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE_ANY: 'granular_kinder_ort:faktura:rechnungslauf:lock_override:general',
    },
    PERSONAL: {
        ADMINISTRATE: 'personal:administrate:',
        ADMINISTRATE_ANY: 'personal:administrate:general',
        MANAGE: 'personal:manage:',
        MANAGE_ANY: 'personal:manage:general',
        VIEW: 'personal:view:',
        VIEW_ANY: 'personal:view:general',
    } as const,
    ANGESTELLTE: toCrud('angestellte'),
    SHARED: {
        MANAGE_USERS_ANY: 'shared:manage_users:general',
        MANAGE_USERS: 'shared:manage_users:',
        EMPLOYEE_VIEW: 'shared:employee_view:',
        EMPLOYEE_VIEW_ANY: 'shared:employee_view:general',
    },
    MODULE: toModules(),
    MANDANT: toCrud('mandant'),
    KIND: {
        CARE: 'kind:care:',
        CARE_ANY: 'kind:care:general',
        VIEW: 'kind:view:',
        VIEW_ANY: 'kind:view:general',
    },
    FEATURE: toFeatures(),
} satisfies  {
    MODULE: MODULES;
    KITA: ForIdOrGeneral<'kita', KitaPermissionType>;
    GRANULAR_KINDER_ORT: GranularKinderOrtPermissions;
    PERSONAL: ForIdOrGeneral<'personal', PersonalPermissionType>;
    ANGESTELLTE: Crud<'angestellte'>;
    SHARED: ForIdOrGeneral<'shared', SharedPermissionType>;
    MANDANT: Crud<'mandant'>;
    KIND: { [k in KindPermission]: string };
    FEATURE: FEATURES;
};
