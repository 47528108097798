/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, HostBinding, Input} from '@angular/core';

export type SupportedType = 'text';

@Directive({
    selector: 'input[dvlibTextInput]',
    standalone: true,
})
export class TextInputDirective {
    @HostBinding('type') @Input() public type: SupportedType = 'text';
    @HostBinding('class.form-control') private readonly bsStyles = true;
}
