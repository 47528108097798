/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';

export const BEGIN_OF_TIME = moment('1900-01-01', 'YYYY-MM-DD', true);
export const END_OF_TIME = moment('9999-12-31', 'YYYY-MM-DD', true);
export const DOKUMENT_LAYOUT_MAX_MM = 300;
export const DEBOUNCE_TIME = 1000;
export const SHORT_DEBOUNCE_TIME = 300;
export const DECIMAL_PCT_FACTOR = 100;

export const DB_MAX_LENGTH = 1000;
export const DB_SHORT_LENGTH = 100;

export const COLOR_PALETTE: string[] = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#9E9E9E',
    '#607D8B',
];

export const TEXT_COLOR_PALETTE: string[] = [
    '#FFFFFF',
    '#38424c',
];
