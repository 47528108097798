<dvlib-dialog [open]="open" (dialogClose)="cancel.emit()">
    <ng-template>
        <div class="modal-body">
            <h1 [innerHTML]="title | translate"></h1>
            @if (subtitle) {
                <h2 [innerHTML]="subtitle | translate"></h2>
            }
        </div>

        <div class="modal-footer big-abstand-top">
            <dvlib-button-list>
                @for (button of inverseButtons ? ['cancel', 'confirm'] : ['confirm', 'cancel']; track $index) {
                    @if (button === 'confirm') {
                        <dvlib-loading-button
                            (clickEvent)="confirm.emit()"
                            [type]="'submit'"
                            [isLoading]="isLoading"
                            [spinnerRight]="inverseButtons"
                            [asLink]="inverseButtons">
                            <span [translate]="confirmActionText || 'COMMON.FORTFAHREN'"></span>
                        </dvlib-loading-button>
                    } @else if (button === 'cancel') {
                        <button type="button"
                                class="btn"
                                [ngClass]="{
                                      'btn-primary': inverseButtons,
                                      'btn-link': !inverseButtons
                                    }"
                                [translate]="cancelActionText || 'COMMON.ABBRECHEN'"
                                (click)="cancel.emit()">
                        </button>
                    }
                }
            </dvlib-button-list>
        </div>
    </ng-template>
</dvlib-dialog>
