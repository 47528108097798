/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Translator} from '@dv/shared/translator';
import {DvbErrorUtil, IDvbError} from '@dv/shared/code';

@Component({
    selector: 'dvlib-error',
    templateUrl: './error.html',
    styleUrls: ['./error.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class ErrorComponent {
    public useHtml: boolean = false;
    public errorMessage: string = 'not-set';

    public constructor(
        private readonly translator: Translator,
    ) {
    }

    @Input()
    public set error(error: IDvbError) {
        this.useHtml = error.useHtml;
        this.errorMessage = DvbErrorUtil.getErrorMessage(error, this.translator);
    }
}
