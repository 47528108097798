/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RequiredPermission} from '../authorization/authorisation-types';

export abstract class StateConfiguration<T> {

    public key: T;
    public titleKey: string;
    public purposeKey: string;
    public structureKey: string;
    public state: string;
    public permission: RequiredPermission;

    protected constructor(
        keyPrefix: string,
        statePrefix: string,
        key: T,
        state: string,
        permission: RequiredPermission = 'module:basic',
    ) {
        this.key = key;
        const prefix = `${keyPrefix}${String(key)}`;
        this.titleKey = `${prefix}.TITLE`;
        this.purposeKey = `${prefix}.PURPOSE`;
        this.structureKey = `${prefix}.STRUCTURE`;
        this.state = statePrefix + state;
        this.permission = permission;
    }
}
