/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Subject} from 'rxjs';
import {runInZone} from '../rxjs/run-in-zone';
import type {BroadcastMessage} from './broadcast-message';

/**
 * Adoptation of https://www.acagroup.be/en/blog/how-to-use-the-broadcastchannel-api-angular/
 */
export class BroadcastService<T> {

    private channel: BroadcastChannel;
    private onMessage$ = new Subject<BroadcastMessage<T>>();

    public messages$ = this.onMessage$.pipe(
        // make sure changes in the channel trigger angular change detection
        runInZone(),
    );

    public constructor(
        channelName: string,
    ) {
        this.channel = new BroadcastChannel(channelName);
        this.channel.onmessage = message => this.onMessage$.next(message.data);
    }

    public postMessage(message: BroadcastMessage<T>): void {
        this.channel.postMessage(message);
    }
}
