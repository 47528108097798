/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, Input, Output} from '@angular/core';
import type {Nullish} from '@dv/shared/code';
import {debounceUntilIdle} from '@dv/shared/code';
import {Subject} from 'rxjs';
import {SpinnerComponent} from '../../spinner';

@Component({
    selector: 'dvlib-loading-button',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        SpinnerComponent,
    ],
})
export class LoadingButtonComponent {

    private static readonly DOUBLE_CLICK_DELAY: number = 300;

    public readonly click$ = new Subject<Event>();

    @Input({transform: booleanAttribute}) public isLoading: boolean | Nullish = false;
    @Input({transform: booleanAttribute}) public asLink: boolean = false;
    @Input({transform: booleanAttribute}) public dropdown: boolean = false;
    @Input({transform: booleanAttribute}) public margin: boolean = false;
    @Input({transform: booleanAttribute}) public spinnerRight: boolean = false;
    @Input() public type: string = 'button';
    @Input({transform: booleanAttribute}) public disabled: boolean = false;

    @Output() public readonly clickEvent = this.click$.pipe(
        debounceUntilIdle(LoadingButtonComponent.DOUBLE_CLICK_DELAY),
    );
}

