/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, NgZone} from '@angular/core';
import type {OperatorFunction} from 'rxjs';
import {Observable} from 'rxjs';

/**
 * Custom OperatorFunction that makes sure that all lifecycle hooks of an Observable
 * are running in the NgZone.
 * See https://www.acagroup.be/en/blog/how-to-use-the-broadcastchannel-api-angular/
 */
export function runInZone<T>(): OperatorFunction<T, T> {
    const zone = inject(NgZone);

    return source$ => new Observable(observer =>
        source$.subscribe({
            next: (value: T) => zone.run(() => observer.next(value)),
            error: (e: unknown) => zone.run(() => observer.error(e)),
            complete: () => zone.run(() => observer.complete()),
        }));
}
