<div class="button"
     [class.button-icon-left]="iconPosition === 'left'">
    <span [class.truncate]="truncate">
        <ng-content/>
    </span>
    @if (_icon) {
        <dvlib-icon class="button-icon" [iconName]="_icon" iconWidth="fixed"/>
    }

    @if (badgeCounter > 0) {
        <span class="button-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
            {{ badgeCounter }}
        </span>
    }

    @if (isLoading) {
        <dvlib-spinner class="button-spinner"/>
    }
</div>
