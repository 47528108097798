/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IValidable} from '../types';
import {stringUnion} from '../types';
import {DvbUtil, hasOwnPropertyGuarded} from '../util';
import {ErrorCode} from './ErrorCode';

/**
 * Collection of Warnings from Backend which aren't just shown, but the frontend does something with them.
 */
export const WARNING_RULE_NAME = stringUnion(
    'MaxValuesExceeded',
    'KindHasBetreuungsMeldung',
    'DienstOverlap',
);

export enum ErrorType {
    VALIDATION = 'VALIDATION_ERROR',
    INTERNAL = 'INTERNAL_ERROR',
    BADREQUEST = 'BAD_REQUEST',
    ABORT = 'ABORT',
    /**
     * Used for success messages in green.
     */
    SUCCESS = 'SUCCESS',
    SUCCESS_TRANSLATED = 'SUCCESS_TRANSLATED',
}

export enum ErrorLevel {
    SEVERE = 'A',
    WARNING = 'B',
    INFO = 'C',
}

export const ERROR_CODE = {
    ENTITY_EXISTS: new ErrorCode('ENTITY_EXISTS', ['entityClassName', 'constraintName', 'duplicateValue']),
    ENTITY_NOT_FOUND: new ErrorCode('ENTITY_NOT_FOUND', ['entityClassName', 'entityId']),
    FAKTURA_ERROR: new ErrorCode('FAKTURA_ERROR', ['fakturaError']),
    ILLEGAL_VALUE: new ErrorCode('ILLEGAL_VALUE', ['valueName', 'entityClassName']),
    MISSING_VALUE: new ErrorCode('MISSING_VALUE', ['valueName']),
    PASSWORD_RULE_VALIDATION: new ErrorCode('PASSWORD_RULE_VALIDATION', ['violation']),
    RULE_VIOLATION: new ErrorCode('RULE_VIOLATION', ['ruleName', 'expectedValue', 'actualValue']),
    KITAX_IMPORT_ERROR: new ErrorCode('KITAX_IMPORT_ERROR'),
    EMAIL_ERROR: new ErrorCode('EMAIL_ERROR', ['to']),
    // Frontend only codes:
    AUTHORIZATION: new ErrorCode('AUTHORIZATION'),
};

export interface IDvbError {
    readonly type: ErrorType;
    readonly severity: ErrorLevel;
    readonly msgKey: string;
    readonly errorCode: ErrorCode | null;
    readonly args: Record<string, unknown>;
    readonly useMessageformat: boolean;
    readonly useHtml: boolean;
}

// FIXME: lich: must this really implement IValidable?
//   If this is /not/ needed, DvbError can be transformed into a utility class.
//   Alls Instance-Usages may then be refactored to IDvbError.
export class DvbError implements IDvbError, IValidable {

    public constructor(
        public readonly type: ErrorType,
        public readonly severity: ErrorLevel,
        public readonly msgKey: string,
        public readonly errorCode: ErrorCode | null = null,
        public readonly args: Record<string, unknown> = {},
        public readonly useMessageformat: boolean = false,
        public readonly useHtml: boolean = false,
    ) {
    }

    public static validationError(
        msgKey: string,
        args: any,
        useMessageformat: boolean = false,
        useHtml: boolean = false,
    ): DvbError {
        return new DvbError(ErrorType.VALIDATION, ErrorLevel.SEVERE, msgKey, null, args, useMessageformat, useHtml);
    }

    /**
     * Creates a new DvbError used for displaying success messages.
     */
    public static success(msgKey: string, args: any, type: ErrorType = ErrorType.SUCCESS): DvbError {
        return new DvbError(type, ErrorLevel.INFO, msgKey, null, args);
    }

    public static findErrorCode(errorCode: string): ErrorCode | null {
        const firstOrNull = DvbUtil.findFirst(DvbUtil.keys(ERROR_CODE),
            (key): boolean => !!key && ERROR_CODE[key].code === errorCode);

        return firstOrNull === null ? null : ERROR_CODE[firstOrNull];
    }

    public static checkArgs(error: IDvbError): boolean {
        return !!error.errorCode &&
            error.errorCode.args.every(arg => hasOwnPropertyGuarded(error.args, arg) && error.args[arg] !== undefined);
    }

    public static isValid(error: unknown): error is IDvbError {
        if (!isDvbError(error)) {
            return false;
        }

        const validType = Object.values(ErrorType).includes(error.type);
        const validSeverity = Object.values(ErrorLevel).includes(error.severity);
        const validMsgKey = DvbUtil.isNotEmptyString(error.msgKey);

        return validType && validSeverity && validMsgKey;
    }

    public static isSuccess(error: IDvbError): boolean {
        switch (error.type) {
            case ErrorType.SUCCESS:
            case ErrorType.SUCCESS_TRANSLATED:
                return true;
            default:
                return false;
        }
    }

    public isValid(): boolean {
        return DvbError.isValid(this);
    }

}

export function isDvbError(errorObj: unknown): errorObj is IDvbError {
    return hasOwnPropertyGuarded(errorObj, 'type')
        && hasOwnPropertyGuarded(errorObj, 'severity')
        && hasOwnPropertyGuarded(errorObj, 'msgKey');
}

export const STANDARD_ERRORS = {
    usernameChanged: new DvbError(ErrorType.ABORT, ErrorLevel.INFO, 'username changed'),
};
