/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {BackendLocalDateTime} from '@dv/shared/backend/model/backend-local-date-time';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

@Pipe({
    name: 'dvBackendLocalDateTimeFormat',
    standalone: true,
})
export class DvBackendLocalDateTimeFormatPipe implements PipeTransform {
    public transform(input: BackendLocalDateTime | undefined, format: string): string {
        const momentDateTime: moment.Moment | null = DvbRestUtil.localDateTimeToMoment(input);

        return momentDateTime === null ? '' : momentDateTime.format(format);
    }
}
