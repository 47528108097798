/**
 * Checks if the given value is null or undefined and throws an error. If the value is non null, it is returned.
 *
 * @example
 * const val1: string | null = 'foo';
 * const nonNullVal1 = checkPresent(val1); // ok, type is now `string`
 *
 * const val2: string | null = null;
 * const nonNullVal2 = checkPresent(val1); // throws error
 */
export type Nullish = null | undefined;
// much more understandable compiler errors than NonNullable<T>
export type NonNullish<T> = Exclude<T, Nullish>;

export function checkPresent<T>(val: T, description?: string | (() => string)): NonNullish<T> {
    if (isPresent(val)) {
        return val;
    }

    if (description) {
        const text = typeof description === 'function'
            ? description()
            : description;
        throw new Error(text);
    }

    // Angular sometimes shortens the *logged* stacktrace on service initialization,
    // so gather the real trace here and log it.
    const stack: string | undefined = new Error().stack;

    const desc = `value expected but none given: ${String(val)}, stack: ${stack}`;
    throw new Error(desc);
}

export function isNullish<T>(val: T | Nullish): val is Nullish {
    return val === undefined || val === null;
}

export function isPresent<T>(val: T | Nullish): val is NonNullish<T> {
    return !isNullish(val);
}
