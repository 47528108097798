/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IconName} from './icons';

/* eslint-disable sonarjs/no-duplicate-string */
const iconMap: Record<IconName, string> = {
    abgeholt: 'fa-arrow-circle-left',
    hamburger: 'fa-bars',
    calendar: 'fa-calendar',
    ok: 'fa-check',
    angekommen: 'fa-check-circle',
    aufgeklappt: 'fa-chevron-down',
    dropdown: 'fa-chevron-down',
    zurueck: 'fa-chevron-left',
    zugeklappt: 'fa-chevron-right',
    kind: 'fa-child',
    achtung: 'fa-exclamation-circle',
    filter: 'fa-sliders',
    kita: 'fa-home',
    info: 'fa-info',
    pausiert: 'fa-pause-circle',
    plus: 'fa-plus',
    reset: 'fa-share',
    search: 'fa-search',
    design: 'fa-snowflake-o ',
    close: 'fa-times',
    abwesend: 'fa-times-circle',
    clear: 'fa-times-circle',
    trash: 'fa-trash',
    edit: 'fa-pencil',
    user: 'fa-user',
    geburtstag: 'fa-birthday-cake',
    'offene-tagesfelder': 'fa-file-text-o',
    mail: 'fa-envelope',
    dateien: 'fa-paperclip',
    'no-icon': '',
    eingeplant: '',
    share: 'fa-share-alt',
    copy: 'fa-copy',
    download: 'fa-download',
    mailOpen: 'fa-envelope-o',
    sort: 'fa-sort',
    asc: 'fa-sort-asc',
    desc: 'fa-sort-desc',
};

/* eslint-enable sonarjs/no-duplicate-string */

export function getFontawesomeIcon(iconName: IconName): string {
    return iconMap[iconName];
}
