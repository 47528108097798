/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {RechnungsRevisionError} from './RechnungsRevisionError';

export class RechnungsUebermittlungsEinheitError {
    public constructor(
        public rechnungsUebermittlungsEinheitId: string,
        public causes: RechnungsRevisionError[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): RechnungsUebermittlungsEinheitError {
        const answer = new RechnungsUebermittlungsEinheitError(data.rechnungsUebermittlungsEinheitId);

        if (Array.isArray(data.causes)) {
            answer.causes = data.causes.map((c: any) => RechnungsRevisionError.apiResponseTransformer(c));
        }

        return answer;
    }
}
