/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {Translator} from '@dv/shared/translator';
import type {unitOfTime} from 'moment';
import moment from 'moment';
import {BEGIN_OF_TIME, DayOfWeek, END_OF_TIME} from '../../constants';
import {LogFactory} from '../../logging/LogFactory';
import type {Nullish} from '../../types/nullish';
import {isNullish} from '../../types/nullish';
import {DvbUtil} from '../DvbUtil';
import {DvbRestUtil} from '../rest';
import type {DateDisplayMode} from './date-display-mode';
import type {IDateRangeWithOptionalTime} from './IDateRangeWithOptionalTime';
import type {ILimited} from './ILimited';
import type {SupportedDateTypes} from './local-date-converter';

type MomentAdjuster = (input: moment.Moment) => moment.Moment;

const LOG = LogFactory.createLog('DvbDateUtil');

export const START_OF_TYPE: Readonly<Record<DateDisplayMode, unitOfTime.StartOf>> = {
    year: 'year',
    month: 'month',
    week: 'isoWeek',
    day: 'day',
} as const;

/* eslint-disable max-lines */
export class DvbDateUtil {

    public static readonly DATE_FORMAT: string = 'D.M.YYYY';

    public static readonly NUMBER_OF_MONTHS: number = 12;
    public static readonly MINUTES_PER_HOUR: number = 60;

    /**
     * Returns a new moment() instance set to today at midnight (0h).
     */
    public static today(): moment.Moment {
        return moment().startOf('day');
    }

    public static todayAsLocalDate(): BackendLocalDate {
        return DvbRestUtil.momentToLocalDateChecked(DvbDateUtil.today());
    }

    /**
     * Start of Day of the given moment or of the current Day
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static startOfDay(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.startOf(aMoment, 'day') : DvbDateUtil.startOf(moment(), 'day');
    }

    /**
     * Start of ISO week of the given moment or of the current week
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static startOfWeek(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.startOf(aMoment, 'isoWeek') : DvbDateUtil.startOf(moment(), 'isoWeek');
    }

    /**
     * End of ISO week of the given moment or of the current week
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static endOfWeek(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.endOf(aMoment, 'isoWeek') : DvbDateUtil.endOf(moment(), 'isoWeek');
    }

    /**
     * Start of Month of the given moment or of the current Month
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static startOfMonth(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.startOf(aMoment, 'month') : DvbDateUtil.startOf(moment(), 'month');
    }

    /**
     * End of Month of the given moment or of the current Month
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static endOfMonth(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.endOf(aMoment, 'month') : DvbDateUtil.endOf(moment(), 'month');
    }

    /**
     * Start of year of the given moment or of the current year
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static startOfYear(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.startOf(aMoment, 'year') : DvbDateUtil.startOf(moment(), 'year');
    }

    /**
     * End of year of the given moment or of the current Month
     *
     * @param aMoment the parameter is mutated. Call with moment(aMoment) if you need a copy
     */
    public static endOfYear(aMoment?: moment.Moment): moment.Moment {
        return aMoment ? DvbDateUtil.endOf(aMoment, 'year') : DvbDateUtil.endOf(moment(), 'year');
    }

    /**
     * E.g. Monday -> 1, Tuesday -> 2, Friday -> 5, Sunday 7
     *
     * @return the number of days between the given dayOfWeek and the first day of the week.
     */
    public static getIsoWeekDayNumber(dayOfWeek: DayOfWeek): number {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        switch (dayOfWeek) {
            case DayOfWeek.MO:
                return 1;
            case DayOfWeek.TU:
                return 2;
            case DayOfWeek.WE:
                return 3;
            case DayOfWeek.TH:
                return 4;
            case DayOfWeek.FR:
                return 5;
            case DayOfWeek.SA:
                return 6;
            case DayOfWeek.SU:
                return 7;
            default:
                throw new Error(`No valid DayOfWeek: ${JSON.stringify(dayOfWeek)}`);
        }
    }

    public static getDayOfWeek(isoWeekDayNumber: number): DayOfWeek {
        switch (isoWeekDayNumber) {
            case 1:
                return DayOfWeek.MO;
            case 2:
                return DayOfWeek.TU;
            case 3:
                return DayOfWeek.WE;
            case 4:
                return DayOfWeek.TH;
            case 5:
                return DayOfWeek.FR;
            case 6:
                return DayOfWeek.SA;
            case 7:
                return DayOfWeek.SU;
            default:
                throw new Error(`No valid isoWeekDayNumber: ${isoWeekDayNumber}`);
        }
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    }

    /**
     * @param dayOfWeek
     * @param aMoment reference date
     * @return a moment based on aMoment but where the weekday is set to dayOfWeek
     */
    public static getDayOfWeekMoment(dayOfWeek: DayOfWeek, aMoment: moment.Moment): moment.Moment {
        if (!aMoment) {
            // legacy behaviour
            return null as any;
        }

        return moment(aMoment).isoWeekday(DvbDateUtil.getIsoWeekDayNumber(dayOfWeek)).startOf('day');
    }

    public static getDayOfWeekLocalized(dayOfWeek: DayOfWeek): string {
        return DvbDateUtil.getDayOfWeekMoment(dayOfWeek, moment()).format('dddd');
    }

    public static isSameDayOfWeek(dayOfWeek: DayOfWeek, aMoment: moment.Moment): boolean {
        return DvbDateUtil.getIsoWeekDayNumber(dayOfWeek) === aMoment.isoWeekday();
    }

    public static isSameMonthAndDay(momentA: moment.Moment, momentB: moment.Moment): boolean {
        return momentA.month() === momentB.month() && momentA.date() === momentB.date();
    }

    public static isSameYearAndMonth(momentA: moment.Moment, momentB: moment.Moment): boolean {
        return momentA.year() === momentB.year() && momentA.month() === momentB.month();
    }

    public static isSameHour(momentA: moment.Moment, momentB: moment.Moment): boolean {
        return momentA.isSame(momentB, 'hour');
    }

    public static isEndOfTime(aMoment: moment.MomentInput): boolean {
        return END_OF_TIME.isSame(aMoment);
    }

    public static isBeginOfTime(aMoment: moment.MomentInput): boolean {
        return BEGIN_OF_TIME.isSame(aMoment);
    }

    public static isMomentEquals(momentA?: unknown, momentB?: unknown): boolean {
        if (!moment.isMoment(momentA) || !moment.isMoment(momentB)) {
            return false;
        }

        return momentA.isSame(momentB);
    }

    public static isMomentBefore(momentA: moment.Moment, momentB: moment.Moment): boolean {
        return momentA.isBefore(momentB);
    }

    public static isValidMoment(aMoment?: unknown): aMoment is moment.Moment {
        if (!aMoment || !moment.isMoment(aMoment)) {
            return false;
        }

        return aMoment.isValid();
    }

    public static isValidBackendLocalDate(value: unknown): value is BackendLocalDate {
        return typeof value === 'string' ?
            DvbDateUtil.isValidMoment(DvbRestUtil.localDateToMoment(value)) :
            false;
    }

    public static isValidDate(value: unknown): value is Date {
        return value instanceof Date ?
            DvbDateUtil.isValidMoment(moment(value)) :
            false;
    }

    public static toDate(value: SupportedDateTypes | Nullish): Date | undefined {
        const momentValue = DvbDateUtil.toMoment(value);
        if (DvbDateUtil.isValidMoment(momentValue)) {
            return momentValue.toDate();
        }

        return undefined;
    }

    public static toMoment(value: SupportedDateTypes | Nullish): moment.Moment | null {
        if (value instanceof Date) {
            return moment(value);
        }

        if (value === '') {
            return null;
        }

        if (DvbUtil.isNotEmptyString(value)) {
            return DvbRestUtil.localDateToMoment(value);
        }

        return value ?? null;
    }

    public static toLocalDate(value: SupportedDateTypes | Nullish): moment.Moment | null {
        const toMoment = DvbDateUtil.toMoment(value);

        return toMoment?.isValid() ? toMoment : null;
    }

    public static toHHMMTime(value: Date | moment.Moment | BackendLocalTimeHHMM | Nullish): moment.Moment | null {
        if (value instanceof Date) {
            return DvbDateUtil.toValidHHMMMoment(moment(value));
        }
        if (DvbUtil.isNotEmptyString(value)) {
            return DvbRestUtil.localeHHMMTimeToMoment(value);
        }
        if (DvbUtil.isEmptyStringType(value)) {
            return null;
        }

        return DvbDateUtil.toValidHHMMMoment(value);
    }

    /**
     * @return a moment of today's date with the hour and minute from the given moment.
     */
    public static toHHMMMoment(a: moment.Moment): moment.Moment {
        return DvbDateUtil.today().set({hour: a.hour(), minute: a.minute()});
    }

    /**
     * @return a moment of today's date with the hour and minute from the given moment.
     */
    public static toValidHHMMMoment(a: moment.Moment | Nullish): moment.Moment | null {
        return a?.isValid() ? DvbDateUtil.today().set({hour: a.hour(), minute: a.minute()}) : null;
    }

    public static getEarlierTime(a: moment.Moment, b: moment.Moment): moment.Moment {
        const earlier: moment.Moment = DvbDateUtil.isTimeBefore(a, b) ? a : b;

        return DvbDateUtil.toHHMMMoment(earlier);
    }

    public static getLaterTime(a: moment.Moment, b: moment.Moment): moment.Moment {
        const later: moment.Moment = DvbDateUtil.isTimeBefore(a, b) ? b : a;

        return DvbDateUtil.toHHMMMoment(later);
    }

    /**
     * Compares times (hours and minutes) of two moments. If you're using #isBefore, then the date will also be
     * compared.
     */
    public static isTimeBefore(a: moment.Moment, b: moment.Moment): boolean {
        return DvbDateUtil.getTimeDiff(a, b) > 0;
    }

    /**
     * Compares times (hours and minutes) of two moments. If you're using #isSame, then the date will also be
     * compared.
     */
    public static isTimeEqual(a: moment.Moment, b: moment.Moment): boolean {
        return DvbDateUtil.getTimeDiff(a, b) === 0;
    }

    /**
     * Calculates the time difference in minutes between two moments, ignoring their date.
     */
    public static getTimeDiff(start: moment.Moment, end: moment.Moment): number {
        return DvbDateUtil.getMinutesSinceMidnight(end) - DvbDateUtil.getMinutesSinceMidnight(start);
    }

    /**
     * Calculates the difference between start and end in minutes
     */
    public static diff(start: moment.Moment, end: moment.Moment): number {
        return end.diff(start, 'minutes');
    }

    /**
     * Calculates the difference between start and end in days
     */
    public static dayDiff(start: moment.Moment, end: moment.Moment): number {
        return moment(end).startOf('day').diff(moment(start).startOf('day'), 'days');
    }

    public static getMinutesSinceMidnight(aMoment: moment.Moment): number {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const hours: number = aMoment.get('hour') * 60;
        const minutes: number = aMoment.get('minute');

        return hours + minutes;
    }

    /**
     * @return Each Monday of the year, includng 1.1.xxxx and 31.12.xxxx, ascending
     */
    public static createMondaysForYear(year: number): moment.Moment[] {
        const points = [];
        const yearMoment = moment().startOf('day').year(year);
        const startOfYear = moment(yearMoment).startOf('year').startOf('day');
        const endOfYear = moment(yearMoment).endOf('year').startOf('day');
        const monday = moment(startOfYear).add(1, 'weeks').startOf('isoWeek'); // first monday of the year

        if (monday.isAfter(startOfYear)) {
            points.push(startOfYear);
        }

        while (monday.year() === year) {
            points.push(moment(monday));
            monday.add(1, 'week');
        }

        if (points[points.length - 1].isBefore(endOfYear)) {
            points.push(endOfYear);
        }

        return points;
    }

    /**
     * @return Each first of month of the year, ascending
     */
    public static createMonthsForYear(year: number): moment.Moment[] {
        const results = [];

        const january = DvbDateUtil.startOfYear(moment().year(year));
        results.push(january);

        for (let i = 1; i < DvbDateUtil.NUMBER_OF_MONTHS; i++) {
            results.push(moment(january).add(i, 'months'));
        }

        return results;
    }

    public static adjustToBeginOfDay(value: moment.Moment): moment.Moment {
        return DvbDateUtil.adjustTo(value, DvbDateUtil.startOfDay);
    }

    public static adjustToEndOfMonth(value: moment.Moment): moment.Moment {
        return DvbDateUtil.adjustTo(value, DvbDateUtil.endOfMonth);
    }

    public static adjustToBeginOfWeek(value: moment.Moment): moment.Moment {
        return DvbDateUtil.adjustTo(value, DvbDateUtil.startOfWeek);
    }

    public static adjustToEndOfWeek(value: moment.Moment): moment.Moment {
        return DvbDateUtil.adjustTo(value, DvbDateUtil.endOfWeek);
    }

    /**
     * @return an array of moments for each date between start and end, including start and end
     */
    public static createDatesFromRange(start: moment.Moment, end: moment.Moment): moment.Moment[] {
        const dates: moment.Moment[] = [];
        const current = moment(start).startOf('day');
        while (current.isSameOrBefore(end)) {
            dates.push(moment(current));
            current.add(1, 'day');
        }

        return dates;
    }

    /**
     * Sets the given values hours minutes, second and millisecond to the ones from timeValue
     */
    public static setTime(value: moment.Moment, timeValue: moment.Moment): moment.Moment {
        value.set('hour', timeValue.get('hour'));
        value.set('minute', timeValue.get('minute'));
        value.set('second', timeValue.get('second'));
        value.set('millisecond', timeValue.get('millisecond'));

        return value;
    }

    public static hasValidMoments<T extends ILimited>(limitedEntity?: T): limitedEntity is T {
        if (!limitedEntity) {
            return false;
        }

        return DvbDateUtil.isValidMoment(limitedEntity.gueltigAb)
            && DvbDateUtil.isValidMoment(limitedEntity.gueltigBis);
    }

    public static isGueltigOn(entity: ILimited, aMoment: moment.Moment): boolean {
        const gueltigAb = moment.isMoment(entity.gueltigAb) ? entity.gueltigAb : moment(entity.gueltigAb);
        const gueltigBis = moment.isMoment(entity.gueltigBis) ? entity.gueltigBis : moment(entity.gueltigBis);
        const isValid = gueltigAb.isValid() && gueltigBis.isValid();

        return isValid &&
            (aMoment.isSame(gueltigAb) || aMoment.isBetween(gueltigAb, gueltigBis) || aMoment.isSame(gueltigBis));
    }

    /**
     * @param entityCollection an array of entities with 'gueltigAb', 'gueltigBis' properties
     * @param aMoment the time of interest
     * @return the entity that matches the time of interest, if it exists.
     */
    public static getEntityOn<T extends ILimited>(entityCollection: T[], aMoment: moment.Moment): T | null {
        const entities = DvbDateUtil.getEntitiesOn<T>(entityCollection, aMoment);
        if (entities.length > 1) {
            const msg: string = aMoment.toISOString();
            throw new Error(`More than one active entity at: ${msg}`);
        }

        return entities.length === 1 ? entities[0] : null;
    }

    /**
     * @param entityCollection an array of entities with 'gueltigAb', 'gueltigBis' properties
     * @param aMoment the time of interest
     * @return all entities that match the time of interest.
     */
    public static getEntitiesOn<T extends ILimited>(
        entityCollection: T[],
        aMoment: moment.Moment,
    ): T[] {
        if (!Array.isArray(entityCollection) || !DvbDateUtil.isValidMoment(aMoment)) {
            return [];
        }

        return entityCollection.filter(entity => DvbDateUtil.isGueltigOn(entity, aMoment));
    }

    /**
     * @param entityCollection an array of entities with 'gueltigAb', 'gueltigBis' properties
     * @param from the start time of interest
     * @param to the end time of interest
     * @return all entities that are within the time of interest
     */
    public static getEntitiesIn<T extends ILimited>(
        entityCollection: T[],
        from: moment.Moment,
        to: moment.Moment,
    ): T[] {
        if (!Array.isArray(entityCollection) || !DvbDateUtil.isValidMoment(from) || !DvbDateUtil.isValidMoment(to)) {
            throw new Error('invalid input');
        }

        return entityCollection
            .filter(entity => entity.gueltigAb!.isSameOrBefore(to) && entity.gueltigBis!.isSameOrAfter(from));
    }

    /**
     * @param entityCollection an array of entities with 'gueltigAb', 'gueltigBis' properties
     * @param aMoment the start of the search
     */
    public static findGueltigBis<T extends ILimited>(
        entityCollection: T[],
        aMoment: moment.Moment,
    ): moment.Moment {
        const entityOn = DvbDateUtil.getEntityOn<T>(entityCollection, aMoment);
        if (entityOn) {
            return entityOn.gueltigBis!;
        }

        const sortedEntities = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(
            entityCollection.filter(entity => !entity.gueltigAb!.isBefore(aMoment)));

        if (sortedEntities.length === 0) {
            return END_OF_TIME;
        }

        return moment(sortedEntities[0].gueltigAb).subtract(1, 'day');
    }

    /**
     * @return LimitedEntity mit dem overlap-Zeitraum. Wenn invalid oder kein Overlap wird null zurueckgegeben
     */
    public static getOverlap<T extends ILimited>(limitedEntity1: T, limitedEntity2: T): ILimited | null {
        if (!DvbDateUtil.hasValidMoments(limitedEntity1) || !DvbDateUtil.hasValidMoments(limitedEntity2)) {
            return null;
        }

        if (limitedEntity1.gueltigAb!.isAfter(limitedEntity2.gueltigBis) ||
            limitedEntity1.gueltigBis!.isBefore(limitedEntity2.gueltigAb)) {
            return null;
        }

        const isAfter = limitedEntity2.gueltigAb!.isAfter(limitedEntity1.gueltigAb);
        const ab = isAfter ? limitedEntity2.gueltigAb : limitedEntity1.gueltigAb;
        const isBefore = limitedEntity2.gueltigBis!.isBefore(limitedEntity1.gueltigBis);
        const bis = isBefore ? limitedEntity2.gueltigBis : limitedEntity1.gueltigBis;

        return {
            gueltigAb: ab,
            gueltigBis: bis,
        };
    }

    /**
     * @return TRUE when limitedEntity1.gueltigBis + 1 day = limitedEntity2.gueltigAb, FALSE otherwise.
     */
    public static isGueltigBisDayBefore<T extends ILimited>(limitedEntity1: T, limitedEntity2: T): boolean {
        return moment(limitedEntity1.gueltigBis).add(1, 'days').isSame(limitedEntity2.gueltigAb);
    }

    /**
     * @param limitedEntity
     * @param stichtag the time of interest
     */
    public static getTemporalPrepositionKey<T extends ILimited>(
        limitedEntity: T,
        stichtag: moment.Moment,
    ): string | null {

        if (!limitedEntity
            || !DvbDateUtil.isValidMoment(limitedEntity.gueltigAb!)
            || !DvbDateUtil.isValidMoment(stichtag)
        ) {
            return null;
        }

        return limitedEntity.gueltigAb.isAfter(stichtag) ? 'COMMON.AB' : 'COMMON.SEIT';
    }

    public static getGueltigkeitText<T extends ILimited>(
        limitedEntity: T,
        stichtag: moment.Moment,
        translate: Translator,
    ): string {
        return DvbDateUtil.getGueltigkeitTextWithOptionalTime(limitedEntity, stichtag, translate);
    }

    public static getBackendLimitedDateText(
        translate: Translator,
        gueltigAb: BackendLocalDate | undefined,
        gueltigBis: BackendLocalDate | undefined,
    ): string {
        const limitedEntity = {gueltigAb, gueltigBis, von: undefined, bis: undefined};

        return DvbDateUtil.getGueltigkeitTextWithOptionalTime(limitedEntity, DvbDateUtil.today(), translate);
    }

    public static getGueltigkeitTextWithOptionalTime<T extends IDateRangeWithOptionalTime>(
        limitedEntity: T,
        stichtag: moment.Moment,
        translate: Translator,
        config: {
            alwaysShowDate: boolean;
        } = {
            alwaysShowDate: false,
        },
    ): string {
        const {
            gueltigAb,
            gueltigAbTxt,
            gueltigBis,
            gueltigBisTxt,
            vonTxt,
            bisTxt,
            isSingleDay,
            hasRange,
            rangeSeparator,
        } = DvbDateUtil.parse(limitedEntity);

        if (isNullish(gueltigAb)) {
            return '';
        }

        if (this.hideGueltigBis(gueltigBis)) {
            const txt = DvbUtil.joinNonEmpty([gueltigAbTxt, vonTxt]);

            return this.getGueltigAbWithPreposition({gueltigAb, gueltigBis}, stichtag, translate, txt);
        }

        const vonPrefix = !hasRange && vonTxt ? `${translate.instant('COMMON.AB')}` : '';
        const bisPrefix = !hasRange && bisTxt ? `${translate.instant('COMMON.BIS')}` : '';

        if (isSingleDay) {
            if (config.alwaysShowDate) {
                return DvbUtil.joinNonEmpty([
                    gueltigAbTxt,
                    vonPrefix,
                    vonTxt,
                    rangeSeparator,
                    bisPrefix,
                    bisTxt,
                ]);
            }

            const result = DvbUtil.joinNonEmpty([
                vonPrefix,
                vonTxt,
                rangeSeparator,
                bisPrefix,
                bisTxt,
            ]);

            return result ? result : gueltigAbTxt;
        }

        return DvbUtil.joinNonEmpty([
            gueltigAbTxt,
            vonPrefix,
            vonTxt,
            rangeSeparator,
            gueltigBisTxt,
            bisPrefix,
            bisTxt,
        ]);
    }

    public static sortLimitedEntitiesByGueltigAbAsc<T extends ILimited>(limitedEntities: T[]): T[] {
        return limitedEntities.sort((a, b) => DvbDateUtil.limitedEntityComparatorAsc(a, b));
    }

    public static sortLimitedEntitiesByGueltigAbDesc<T extends ILimited>(limitedEntities: T[]): T[] {
        return limitedEntities.sort((a, b) => DvbDateUtil.limitedEntityComparatorDesc(a, b));
    }

    public static sortByStichtagAsc<T>(elements: T[], mapper: (o: T) => moment.Moment): T[] {
        return elements.sort((a, b) => DvbDateUtil.stichtagComparatorAsc(mapper(a), mapper(b)));
    }

    /**
     * @return < 0 wenn a < b, ==0 wenn a==b, >0 wenn a > b
     */
    public static stichtagComparatorAsc(a: moment.Moment | null, b: moment.Moment | null): number {
        if (!DvbDateUtil.isValidMoment(a)) {
            return DvbDateUtil.isValidMoment(b) ? -1 : 0;
        }

        if (a.isBefore(b)) {
            return -1;
        }

        return a.isSame(b) ? 0 : 1;
    }

    public static intersects(
        a: Required<ILimited>,
        b: Required<ILimited>,
    ): boolean {
        const aAb = moment(a.gueltigAb, true);
        const aBis = moment(a.gueltigBis, true);
        const bAb = moment(b.gueltigAb, true);
        const bBis = moment(b.gueltigBis, true);

        return !aAb.isAfter(bBis) && !aBis.isBefore(bAb);
    }

    public static intersectsAny(
        a: Required<ILimited>,
        b: Required<ILimited[]>,
    ): boolean {
        return b.some(bb => DvbDateUtil.intersects(a, bb));
    }

    public static isEntityGueltigOn(
        entity: { gueltigAb?: BackendLocalDate; gueltigBis?: BackendLocalDate },
        aMoment: moment.Moment,
    ): boolean {
        const gueltigAb = moment(entity.gueltigAb);
        const gueltigBis = moment(entity.gueltigBis);

        return this.isGueltigOn({gueltigAb, gueltigBis}, aMoment);
    }

    /**
     * @return < 0 wenn a.gueltigAb < b.gueltigAb, ==0 wenn a.gueltigAb==b.gueltigAb, >0 wenn a.gueltigAb > b.gueltigAb
     */
    private static limitedEntityComparatorAsc<T extends ILimited>(a: T, b: T): number {
        return DvbDateUtil.stichtagComparatorAsc(a.gueltigAb, b.gueltigAb);
    }

    /**
     * @return < 0 wenn b.gueltigAb < a.gueltigAb, ==0 wenn a.gueltigAb==b.gueltigAb, >0 wenn b.gueltigAb > a.gueltigAb
     */
    private static limitedEntityComparatorDesc<T extends ILimited>(a: T, b: T): number {
        return DvbDateUtil.limitedEntityComparatorAsc(a, b) * -1;
    }

    private static startOf(aMoment: moment.Moment, type: unitOfTime.StartOf): moment.Moment {
        if (DvbDateUtil.isValidMoment(aMoment)) {
            return aMoment.startOf(type).startOf('day');
        }

        const msg: string = JSON.stringify(aMoment);

        throw new Error(`Invalid Moment ${msg}`);
    }

    private static endOf(aMoment: moment.Moment, type: unitOfTime.StartOf): moment.Moment {
        if (DvbDateUtil.isValidMoment(aMoment)) {
            return aMoment.endOf(type).startOf('day');
        }
        const msg: string = JSON.stringify(aMoment);

        throw new Error(`Invalid Moment ${msg}`);
    }

    // mutates the input value if necessary and returns the same instance
    private static adjustTo(value: moment.Moment, adjustFunction: MomentAdjuster): moment.Moment {
        if (DvbDateUtil.isValidMoment(value)) {
            const adjustedCopy = adjustFunction(moment(value));
            if (adjustedCopy.isSame(value)) {
                return value;
            }

            adjustFunction(value);
        }

        return value;
    }

    private static getGueltigAbWithPreposition(
        limitedEntity: ILimited,
        stichtag: moment.Moment,
        translate: Translator,
        gueltigAb: string,
    ): string {
        const prepositionKey = this.getTemporalPrepositionKey(limitedEntity, stichtag);
        if (isNullish(prepositionKey)) {
            LOG.warn('prepositionKey is nullish: ', limitedEntity, prepositionKey);
        }
        const preposition: string = DvbUtil.capitalize(translate.instant(prepositionKey));

        return `${preposition} ${gueltigAb}`;
    }

    private static parse<T extends IDateRangeWithOptionalTime>(limitedEntity: T): {
        gueltigAb: moment.Moment | null;
        gueltigAbTxt: string;
        gueltigBis: moment.Moment | null;
        gueltigBisTxt: string;
        von: moment.Moment | null;
        vonTxt: string;
        bis: moment.Moment | null;
        bisTxt: string;
        isSingleDay: boolean;
        hasRange: boolean;
        rangeSeparator: string;
    } {
        const gueltigAb: moment.Moment | null = DvbRestUtil.localDateToMoment(limitedEntity.gueltigAb);
        const gueltigAbTxt = gueltigAb ? gueltigAb.format('l') : '';
        const gueltigBis: moment.Moment | null = DvbRestUtil.localDateToMoment(limitedEntity.gueltigBis);
        const gueltigBisTxt = gueltigBis ? gueltigBis.format('l') : '';
        const von: moment.Moment | null = DvbRestUtil.localeHHMMTimeToMoment(limitedEntity.von);
        const vonFormat = von ? von.format('HH:mm') : '';
        const vonTxt = vonFormat === '00:00' ? '' : vonFormat;
        const bis: moment.Moment | null = DvbRestUtil.localeHHMMTimeToMoment(limitedEntity.bis);
        const bisFormat = bis ? bis.format('HH:mm') : '';
        const bisTxt = bisFormat === '23:59' ? '' : bisFormat;

        const isSingleDay = gueltigAbTxt === gueltigBisTxt;
        const hasRange = DvbUtil.isNotEmptyString(vonTxt) && DvbUtil.isNotEmptyString(bisTxt) || !isSingleDay;
        const rangeSeparator: string = hasRange ? '-' : '';

        return {
            gueltigAb,
            gueltigAbTxt,
            gueltigBis,
            gueltigBisTxt,
            von,
            vonTxt,
            bis,
            bisTxt,
            isSingleDay,
            hasRange,
            rangeSeparator,
        };
    }

    private static hideGueltigBis(gueltigBis: moment.Moment | null): boolean {
        return isNullish(gueltigBis) || this.isEndOfTime(gueltigBis);
    }
}
