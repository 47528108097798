/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TrackByFunction} from '@angular/core';
import type {Nullish} from '@dv/shared/code';

/* eslint-disable @typescript-eslint/naming-convention */
export const trackByIdentity = (_index: number, item: unknown): unknown => item;
export const trackByStringify = (_index: number, item: unknown): unknown => JSON.stringify(item);
export const trackById = <T extends { id?: PropertyKey | Nullish}>(_index: number, item: T): unknown => item?.id;
export const trackByIndex = (index: number, _item: unknown): unknown => index;

export function trackByProperty<T>(name: keyof T): TrackByFunction<T> {
    return (_index, item) => item[name];
}
