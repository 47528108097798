/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
import type {EmbeddedViewRef} from '@angular/core';
import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

export class DvlibLetLetContext<T = unknown> {
    public $implicit: T = null!;
    public dvlibLet: T = null!;
}

/**
 * Convenience: local template variables from component values, mainly for easier handling of async pipes.
 *
 * This is a slight variation of *ngIf which does <strong>not</strong> but always renders the content,
 * regardles of the value provided. *ngIf only renders the content if the value is truthy.
 *
 * Usage example:
 * ```
 * <ng-container *dvlibLet="(foo$ | async) as foo"><div>{{foo}}</div></ng-container>
 * ```
 */
@Directive({
    selector: '[dvlibLet]',
    standalone: true,
})
export class LetDirective<T = unknown> {
    private _context: DvlibLetLetContext<T> = new DvlibLetLetContext<T>();

    private _viewRef: EmbeddedViewRef<DvlibLetLetContext<T>> | null = null;

    public constructor(
        private readonly _viewContainer: ViewContainerRef,
        private readonly templateRef: TemplateRef<DvlibLetLetContext<T>>,
    ) {
    }

    @Input()
    public set dvlibLet(value: T) {
        // do not create a new instance since the EmbeddedView does not get notified about this.
        this._context.dvlibLet = value;
        this._context.$implicit = value;

        if (!this._viewRef) {
            this._viewRef = this.createView();
        }

        this._viewRef.markForCheck();
    }

    private createView(): EmbeddedViewRef<DvlibLetLetContext<T>> {
        this._viewContainer.clear();

        return this._viewContainer.createEmbeddedView(this.templateRef, this._context);
    }
}
