/* eslint-disable @typescript-eslint/no-magic-numbers */

import {ChangeDetectionStrategy, Component, computed, input, model} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ITEMS_PER_PAGE_OPTIONS, ItemsPerPageOptions} from '../items-per-page';
import {PageComponent} from '../page/page.component';

@Component({
    selector: 'dvlib-pages',
    standalone: true,
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        PageComponent,
        TranslateModule,
        FormsModule,
        TooltipModule,
        PaginationModule,
    ],
})
export class PagesComponent {
    public itemsOnPageCount = input.required<number>();
    public totalItems = input.required<number | undefined>();
    public isLoading = input.required<boolean>();
    public itemsPerPage = model<ItemsPerPageOptions>(ITEMS_PER_PAGE_OPTIONS.TEN);
    public currentPage = model<number>(1);
    public entityName = input.required<string>();

    /**
     * @Input maxSize: number - the maximum number of pagination links to display.
     */
    public maxSize = input<number>(7);
    public itemsPerPageOptions = input<ItemsPerPageOptions[]>([
        ITEMS_PER_PAGE_OPTIONS.TEN,
        ITEMS_PER_PAGE_OPTIONS.TWENTY_FIVE,
        ITEMS_PER_PAGE_OPTIONS.HUNDRED,
        ITEMS_PER_PAGE_OPTIONS.TWO_HUNDRED,
    ]);

    public showSummary = computed(() => {
        return this.itemsOnPageCount() > 0;
    });

    public showPagination = computed(() => {
        return this.currentPage() > 1 || this.itemsOnPageCount() >= Math.min(...this.itemsPerPageOptions());
    });
}
