/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum KindergartenBelegung {
    KG1_INT = 'KG1_INT',
    KG1_EXT = 'KG1_EXT',
    KG2_INT = 'KG2_INT',
    KG2_EXT = 'KG2_EXT',
    SCHULE_INT = 'SCHULE_INT',
    SCHULE_EXT = 'SCHULE_EXT',
    KEINE = 'KEINE',
    WUNSCH = 'WUNSCH',
}

const internal: KindergartenBelegung[] = [
    KindergartenBelegung.KG1_INT,
    KindergartenBelegung.KG2_INT,
    KindergartenBelegung.SCHULE_INT,
];

const external: KindergartenBelegung[] = [
    KindergartenBelegung.KG1_EXT,
    KindergartenBelegung.KG2_EXT,
    KindergartenBelegung.SCHULE_EXT,
];

export class KGB {
    public static isInternal(kb: KindergartenBelegung): boolean {
        return internal.includes(kb);
    }

    public static isExternal(kb: KindergartenBelegung): boolean {
        return external.includes(kb);
    }

    public static isKG1(kb: KindergartenBelegung): boolean {
        return kb === KindergartenBelegung.KG1_EXT || kb === KindergartenBelegung.KG1_INT;
    }

    public static isKG2(kb: KindergartenBelegung): boolean {
        return kb === KindergartenBelegung.KG2_EXT || kb === KindergartenBelegung.KG2_INT;
    }

    public static isSchule(kb: KindergartenBelegung): boolean {
        return kb === KindergartenBelegung.SCHULE_EXT || kb === KindergartenBelegung.SCHULE_INT;
    }
}
