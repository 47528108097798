/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import type {AbstractControl} from '@angular/forms';
import type {IErrorService} from '@dv/kitadmin/models';
import {BackendErrorHandler, dialogChanged$} from '@dv/shared/angular';
import type {IDvbError} from '@dv/shared/code';
import {addError$, clearAll$, DvbError, ErrorType} from '@dv/shared/code';
import type {Observable} from 'rxjs';
import {BehaviorSubject, map, tap} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorService extends BackendErrorHandler implements IErrorService {

    private currentErrors: IDvbError[] = [];

    private readonly errorsSubject$ = new BehaviorSubject<IDvbError[]>(this.currentErrors);
    private readonly errors$ = this.errorsSubject$.asObservable()
        .pipe(map(errors => errors.slice()));

    // noinspection JSUnusedLocalSymbols
    private readonly clearAllSubscription = clearAll$.pipe(
        tap(() => this.clearAll()),
        takeUntilDestroyed(),
    ).subscribe();

    // noinspection JSUnusedLocalSymbols
    private readonly addError = addError$.pipe(
        tap(error => this.handleError(error)),
        takeUntilDestroyed(),
    ).subscribe();

    // noinspection JSUnusedLocalSymbols
    private clearErrorsOnDialogOpenClose = dialogChanged$.pipe(
        tap(() => this.clearAll()),
        takeUntilDestroyed(),
    ).subscribe();

    public getErrors$(): Observable<IDvbError[]> {
        return this.errors$;
    }

    /**
     * Clears all stored errors.
     */
    public clearAll(): void {
        this.currentErrors = [];
        this.errorsSubject$.next([]);
    }

    /**
     * Clear error by error reference.
     */
    public clearError(error: IDvbError): void {
        const index = this.currentErrors.indexOf(error);
        if (index > -1) {
            this.currentErrors.splice(index, 1);
            this.errorsSubject$.next(this.currentErrors);
        }
    }

    public clearErrorByMsgKey(msgKey: string): void {
        const cleared = this.currentErrors.filter(e => e.msgKey !== msgKey);

        if (cleared.length !== this.currentErrors.length) {
            this.currentErrors = cleared;
            this.errorsSubject$.next(cleared);
        }
    }

    /**
     * @param msgKey translation key
     * @param args message parameters
     */
    public addValidationError(msgKey: string, args?: any): void {
        this.handleError(DvbError.validationError(msgKey, args));
    }

    /**
     * @param isValid when FALSE a new validationError is added. Otherwise the validationError is cleared
     * @param msgKey
     * @param args
     */
    public handleValidationError(isValid: boolean, msgKey: string, args?: any): void {
        if (isValid) {
            this.clearErrorByMsgKey(msgKey);
        } else {
            this.addValidationError(msgKey, args);
        }
    }

    public handleControlError(
        control: AbstractControl | AbstractControl[] | undefined,
        msgKey: string,
        args?: any,
    ): void {
        const invalid = Array.isArray(control) ? control.some(c => c.invalid) : control?.invalid;
        if (invalid) {
            this.addValidationError(msgKey, args);
        } else {
            this.clearErrorByMsgKey(msgKey);
        }
    }

    public containsError(dvbError: IDvbError): boolean {
        return this.currentErrors.some(e => {
            return e.msgKey === dvbError.msgKey
                && e.type === dvbError.type
                && e.severity === dvbError.severity
                && e.errorCode === dvbError.errorCode
                && JSON.stringify(e.args) === JSON.stringify(dvbError.args);
        });
    }

    /**
     * @param dvbError adds a DvbError to the errors
     */
    public override handleError(dvbError: IDvbError): void {
        const isNewError = DvbError.isValid(dvbError) && !this.containsError(dvbError);
        if (!isNewError) {
            return;
        }

        this.currentErrors.push(dvbError);
        this.errorsSubject$.next(this.currentErrors);
    }

    /**
     * Creates and handles a new DvbError for displaying a success message.
     */
    public handleSuccess(msgKey: string, ...args: any[]): void {
        this.handleError(DvbError.success(msgKey, args));
    }

    /**
     * Creates and handles a new DvbError for displaying an already translated success message.
     */
    public handleSuccessTranslated(msgKey: string): void {
        this.handleError(DvbError.success(msgKey, [], ErrorType.SUCCESS_TRANSLATED));
    }

}
