import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dvlib-filter-option',
    standalone: true,
    imports: [NgClass],
    templateUrl: './filter-option.component.html',
    styleUrls: ['./filter-option.component.scss'],
})
export class FilterOptionComponent {
    @Input({required: true}) public isToggled: boolean = false;
    @Input({required: true}) public label: string = '';
    @Output() public readonly toggle = new EventEmitter<void>();
}
