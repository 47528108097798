/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum AuthState {
    /**
     * Nothing initialized yet
     */
    UNDEFINED = 'UNDEFINED',
    /**
     * Triggered initialization of OIDC client
     */
    OIDC_STARTING = 'OIDC_STARTING',
    /**
     * OIDC client is ready or it is not activated
     */
    OIDC_READY = 'OIDC_READY',
    /**
     * User is authenticated
     */
    AUTHENTICATED = 'AUTHENTICATED',
}
