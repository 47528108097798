/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export function removeDiacritics(text: string, form?: 'NFC' | 'NFD' | 'NFKC' | 'NFKD'): string {
    const removed = text
        // separate characters from their Diacritics (e.g. accents)
        .normalize('NFD')
        // remove characters from the Combining Diacritical Marks block.
        // https://unicode-table.com/en/blocks/combining-diacritical-marks/
        .replace(/[\u0300-\u036f]/g, '');

    const s: string = form
        ? removed.normalize(form)
        : removed;

    return s;
}
