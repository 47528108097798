/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/* eslint-disable max-classes-per-file */
import type {EmbeddedViewRef, TemplateRef, ViewContainerRef} from '@angular/core';
import {InjectionToken} from '@angular/core';
import {checkPresent} from '@dv/shared/code';

export interface Strategy {
    readonly name: string;
    setup: () => void;
    refresh: (granted: boolean) => void;
}

export const NOOP_STRATEGY: Strategy = {
    name: 'noop',
    setup: () => undefined,
    refresh: () => undefined,
};

export const REQUIRE_STRATEGY = new InjectionToken<Strategy>('Strategy to deal with DOM elements and prohibited access');

export class HideStrategy implements Strategy {
    public readonly name: string = 'hide';

    private visibleTemplateRef: EmbeddedViewRef<unknown> | undefined;

    public constructor(
        private readonly viewContainer: ViewContainerRef,
        private readonly templateRef: TemplateRef<unknown>,
    ) {
    }

    public setup(): void {
        // nothing to do: template has not been instantiated anyway
    }

    public refresh(granted: boolean): void {
        const templateRef = checkPresent(this.templateRef);
        if (granted) {
            if (!this.visibleTemplateRef) {
                this.visibleTemplateRef = this.viewContainer.createEmbeddedView(templateRef);
            }
            this.visibleTemplateRef.markForCheck();
        } else {
            this.viewContainer.clear();
            this.visibleTemplateRef = undefined;
        }
    }
}

export class CallbackStrategy implements Strategy {
    public readonly name: string = 'callback';

    public constructor(
        private readonly callback: (disabled: boolean) => void,
    ) {
    }

    public setup(): void {
        this.callback(true);
    }

    public refresh(granted: boolean): void {
        this.callback(!granted);
    }

}
