/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxBetreuungsZeitraum} from '@dv/shared/backend/model/jax-betreuungs-zeitraum';
import type {VerpflegungsTyp} from '@dv/shared/backend/model/verpflegungs-typ';
import type moment from 'moment';
import type {DayOfWeek} from '../../constants';
import type {IRestModel} from '../../types';
import {checkPresent} from '../../types';
import type {IZeitraum, TagUndZeitraum} from '../../util';
import {DvbDateUtil, DvbRestUtil} from '../../util';

export class BetreuungsZeitraum implements IZeitraum, IRestModel {

    public durationInMinutes: number = 0;

    public constructor(
        public id: string,
        public name: string,
        public von: moment.Moment,
        public bis: moment.Moment,
        public verpflegung: VerpflegungsTyp | null = null,
    ) {
        this.durationInMinutes = bis && von ? DvbDateUtil.getTimeDiff(von, bis) : 0;
    }

    public static apiResponseTransformer(data: JaxBetreuungsZeitraum): BetreuungsZeitraum {
        return new BetreuungsZeitraum(
            checkPresent(data.id),
            data.name,
            DvbRestUtil.localeHHMMTimeToMoment(data.von)!,
            DvbRestUtil.localeHHMMTimeToMoment(data.bis)!,
            data.verpflegung ?? null,
        );
    }

    public getDisplayName(): string {
        return this.name;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            von: DvbRestUtil.momentTolocaleHHMMTime(this.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(this.bis),
            verpflegung: this.verpflegung,
        };
    }

    public isSame(zeitraumFeld: TagUndZeitraum, dayOfWeek: DayOfWeek): boolean {
        return zeitraumFeld.dayOfWeek === dayOfWeek && zeitraumFeld.zeitraum.id === this.id;
    }
}
