/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '../types';

/**
 * Utility class that allows to manage the selection of a list of entity objects.
 */
export class EntitySelection<T> {

    /**
     * A map of the IDs of the entity objects. True value for selected objects, false or undefined for the rest.
     */
    public selected: { [id: string]: boolean } = {};

    public selectNone(): void {
        this.selected = {};
    }

    public selectAll(all: Persisted<T>[]): void {
        all.forEach(obj => {
            this.selected[obj.id] = true;
        });
    }

    public getSelectionCount(): number {
        return Object.values(this.selected).filter(val => val).length;
    }

    public getSelected(all: Persisted<T>[]): Persisted<T>[] {
        return all.filter(obj => this.selected[obj.id]);
    }
}
