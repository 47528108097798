/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ToastType} from './toast-type';

export const TOAST_STYLES: Record<ToastType, string> = {
    [ToastType.ERROR_ACTIONABLE]: 'error',
    [ToastType.ERROR_NAVIGABLE]: 'error',
    [ToastType.ERROR]: 'error',
    [ToastType.SUCCESS_ACTIONABLE]: 'success',
    [ToastType.SUCCESS_NAVIGABLE]: 'success',
    [ToastType.SUCCESS]: 'success',
};
