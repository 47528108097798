/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {BackendLocalDateTime} from '@dv/shared/backend/model/backend-local-date-time';
import {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {Nullish} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import moment from 'moment';

@Pipe({
    name: 'dvTimeFormat',
    standalone: true,
})
export class DvTimeFormatPipe implements PipeTransform {
    public transform(
        input: Date | moment.Moment | BackendLocalTimeHHMM | Nullish,
        format: string = 'HH:mm',
    ): string {
        const momentDateTime: moment.Moment | null = toMoment(input);

        return DvbDateUtil.isValidMoment(momentDateTime) ? momentDateTime.format(format) : '';
    }
}

function toMoment(value: Date | moment.Moment | BackendLocalDateTime | Nullish): moment.Moment | null {
    if (moment.isMoment(value)) {
        return value;
    }

    if (value instanceof Date) {
        return moment(value);
    }

    if (DvbUtil.isNotEmptyString(value)) {
        return DvbRestUtil.localeHHMMTimeToMoment(value);
    }

    return null;
}
