/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export type RechnungFilterStateName =
    'GESENDET' |
    'BEZAHLT' |
    'TEILBEZAHLT' |
    'ERINNERT' |
    'MAHNUNG1' |
    'MAHNUNG2' |
    'MAHNUNG3' |
    'BETRIEBEN' |
    'STORNIERT' |
    'SONSTIGES';

export const RECHNUNG_FILTER_STATES: {
    [k in RechnungFilterStateName]:
    { name: RechnungFilterStateName; rowIndex: number }
} = {
    GESENDET: {name: 'GESENDET', rowIndex: 0},
    BEZAHLT: {name: 'BEZAHLT', rowIndex: 0},
    TEILBEZAHLT: {name: 'TEILBEZAHLT', rowIndex: 0},
    ERINNERT: {name: 'ERINNERT', rowIndex: 1},
    MAHNUNG1: {name: 'MAHNUNG1', rowIndex: 1},
    MAHNUNG2: {name: 'MAHNUNG2', rowIndex: 1},
    MAHNUNG3: {name: 'MAHNUNG3', rowIndex: 1},
    BETRIEBEN: {name: 'BETRIEBEN', rowIndex: 1},
    STORNIERT: {name: 'STORNIERT', rowIndex: 0},
    SONSTIGES: {name: 'SONSTIGES', rowIndex: 0},
};
