/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

type Newable<T> = abstract new (...args: any[]) => T;

export function checkInstanceOf<T>(value: unknown, type: Newable<T>, description?: string): T {
    if (value instanceof type) {
        return value;
    }

    const typeName: string = (value as any)?.name ?? 'unknown';
    let message = `value ${String(value)} of type: ${typeName} is not an instance of ${type.name}`;
    if (description) {
        message = `${message}: ${description}`;
    }

    throw new Error(message);
}
